import React from 'react';

const BetaBadge = () => {
  return (
    <span className="inline-flex items-center justify-center h-4 px-1.5 ml-1.5 -mt-2.5 text-[10px] font-medium italic text-gray-700 bg-secondary/40 rounded-full">
      beta
    </span>
  );
};

export default BetaBadge;