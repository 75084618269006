import React, { createContext, useState, useContext, useEffect } from 'react';

const DashboardContext = createContext();

export const DashboardProvider = ({ children }) => {
  const [selectedDashboard, setSelectedDashboard] = useState(() => {
    const saved = localStorage.getItem('selectedDashboard');
    if (!saved) {
      return null;
    }
    try {
      const parsed = JSON.parse(saved);
      return parsed && typeof parsed === 'object' ? parsed : null;
    } catch (error) {
      console.error('Error parsing selectedDashboard from localStorage:', error);
      localStorage.removeItem('selectedDashboard');
      return null;
    }
  });

  useEffect(() => {
    if (selectedDashboard) {
      try {
        localStorage.setItem('selectedDashboard', JSON.stringify(selectedDashboard));
      } catch (error) {
        console.error('Error stringifying selectedDashboard for localStorage:', error);
      }
    } else {
      localStorage.removeItem('selectedDashboard');
    }
  }, [selectedDashboard]);

  const updateSelectedDashboard = (dashboardOrUpdater) => {
    // Add detailed logging
    console.log('Updating selected dashboard with:', dashboardOrUpdater);
    console.log('Type of dashboard:', typeof dashboardOrUpdater);
    console.log('Is dashboard truthy:', Boolean(dashboardOrUpdater));
    
    if (!dashboardOrUpdater) {
      console.error('Dashboard is null or undefined');
      setSelectedDashboard(null);
      return;
    }

    // First, check if it's a function
    if (typeof dashboardOrUpdater === 'function') {
      setSelectedDashboard(prevDashboard => {
        try {
          const newDashboard = dashboardOrUpdater(prevDashboard);
          
          // Validate the result of the function
          if (!newDashboard || typeof newDashboard !== 'object') {
            console.error('Invalid dashboard returned from update function');
            return prevDashboard; // Keep previous state if invalid
          }

          // If the dashboard has queries, process them
          if (Array.isArray(newDashboard.queries)) {
            const prevQueries = prevDashboard?.queries || [];
            
            const updatedQueries = newDashboard.queries.map(newQuery => {
              const prevQuery = prevQueries.find(q => q.id === newQuery.id);
              
              const mergedCardSettings = {
                viewMode: 'table',
                chartType: 'bar',
                sortColumn: '',
                sortOrder: 'asc',
                selectedXAxis: '',
                selectedYAxis: '',
                cardWidth: '100%',
                isNumberFormatted: true,
                ...(prevQuery?.cardSettings || {}),
                ...(newQuery.cardSettings || {}),
                visibleColumns: Array.isArray(newQuery.cardSettings?.visibleColumns)
                  ? newQuery.cardSettings.visibleColumns
                  : Array.isArray(prevQuery?.cardSettings?.visibleColumns)
                    ? prevQuery.cardSettings.visibleColumns
                    : Object.keys(newQuery.result?.[0] || {}),
                columnOrder: Array.isArray(newQuery.cardSettings?.columnOrder)
                  ? newQuery.cardSettings.columnOrder
                  : Array.isArray(prevQuery?.cardSettings?.columnOrder)
                    ? prevQuery.cardSettings.columnOrder
                    : Object.keys(newQuery.result?.[0] || {})
              };

              return {
                ...newQuery,
                cardSettings: mergedCardSettings
              };
            });

            return {
              ...prevDashboard,
              ...newDashboard,
              queries: updatedQueries
            };
          }

          return {
            ...prevDashboard,
            ...newDashboard
          };
        } catch (error) {
          console.error('Error processing dashboard update function:', error);
          return prevDashboard; // Keep previous state if there's an error
        }
      });
      return;
    }

    // If it's not a function, treat it as a direct dashboard object
    if (typeof dashboardOrUpdater === 'object' && dashboardOrUpdater !== null) {
      setSelectedDashboard(prevDashboard => {
        const prevQueries = prevDashboard?.queries || [];
        
        const updatedQueries = dashboardOrUpdater.queries?.map(newQuery => {
          const prevQuery = prevQueries.find(q => q.id === newQuery.id);
          
          const mergedCardSettings = {
            viewMode: 'table',
            chartType: 'bar',
            sortColumn: '',
            sortOrder: 'asc',
            selectedXAxis: '',
            selectedYAxis: '',
            cardWidth: '100%',
            isNumberFormatted: true,
            ...(prevQuery?.cardSettings || {}),
            ...(newQuery.cardSettings || {}),
            visibleColumns: Array.isArray(newQuery.cardSettings?.visibleColumns)
              ? newQuery.cardSettings.visibleColumns
              : Array.isArray(prevQuery?.cardSettings?.visibleColumns)
                ? prevQuery.cardSettings.visibleColumns
                : Object.keys(newQuery.result?.[0] || {}),
            columnOrder: Array.isArray(newQuery.cardSettings?.columnOrder)
              ? newQuery.cardSettings.columnOrder
              : Array.isArray(prevQuery?.cardSettings?.columnOrder)
                ? prevQuery.cardSettings.columnOrder
                : Object.keys(newQuery.result?.[0] || {})
          };

          return {
            ...newQuery,
            cardSettings: mergedCardSettings
          };
        }) || [];

        return {
          ...prevDashboard,
          ...dashboardOrUpdater,
          queries: updatedQueries
        };
      });
    } else {
      console.error('Invalid dashboard object provided to updateSelectedDashboard');
      setSelectedDashboard(null);
    }
  };

  const updateDashboardQuery = (queryId, updates) => {
    setSelectedDashboard(prevDashboard => {
      if (!prevDashboard) return null;
      return {
        ...prevDashboard,
        queries: prevDashboard.queries.map(query =>
          query.id === queryId ? { ...query, ...updates } : query
        )
      };
    });
  };

  const clearSelectedDashboard = () => {
    setSelectedDashboard(null);
  };

  return (
    <DashboardContext.Provider value={{ selectedDashboard, updateSelectedDashboard, updateDashboardQuery, clearSelectedDashboard }}>
      {children}
    </DashboardContext.Provider>
  );
};

export const useDashboard = () => {
  const context = useContext(DashboardContext);
  if (context === undefined) {
    throw new Error('useDashboard must be used within a DashboardProvider');
  }
  return context;
};