import React, { createContext, useContext, useState } from 'react';

const AskContext = createContext();

export function AskProvider({ children }) {
  const [queryResult, setQueryResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isQueryJustAdded, setIsQueryJustAdded] = useState(false);

  const handleQueryResult = (result) => {
    setQueryResult(result);
    setIsLoading(false);
  };

  const handleQueryStart = () => {
    setIsLoading(true);
    setQueryResult(null);
    setError(null);
  };

  const handleClearResult = () => {
    // Reset all relevant state
    setQueryResult(null);
    setIsLoading(false);
    setError(null);
    setIsQueryJustAdded(false);
  };

  const value = {
    queryResult,
    setQueryResult,
    isLoading,
    setIsLoading,
    error,
    setError,
    isQueryJustAdded,
    setIsQueryJustAdded,
    handleQueryResult,
    handleQueryStart,
    handleClearResult
  };

  return <AskContext.Provider value={value}>{children}</AskContext.Provider>;
}

export function useAsk() {
  const context = useContext(AskContext);
  if (context === undefined) {
    throw new Error('useAsk must be used within an AskProvider');
  }
  return context;
}