import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';

export const useTableData = (selectedTableId, user) => {
  const [columnData, setColumnData] = useState(null);
  const [columnStats, setColumnStats] = useState({});
  const [refreshing, setRefreshing] = useState({});
  const [refreshingStats, setRefreshingStats] = useState({});
  const [error, setError] = useState(null);

  // Fetch samples for a table
  const handleRefreshSamples = async (tableId, silent = false) => {
    if (!tableId) return null;
    
    try {
      setRefreshing(prev => ({ ...prev, [tableId]: true }));
      
      const getTableData = httpsCallable(functions, 'getTableDataSamples');
      const result = await getTableData({ tableId });
      
      const dataPrepDoc = doc(db, 'DataPrep', 'tables');
      const docSnap = await getDoc(dataPrepDoc);
      const tables = docSnap.exists() ? docSnap.data() : {};
      const tableName = Object.keys(tables).find(name => tables[name].id === tableId);
      
      if (tableName) {
        const updatedData = {
          schema: result.data.schema || [],
          dataSample: result.data.columns || [],
          lastUpdated: new Date().toISOString()
        };
        
        await setDoc(dataPrepDoc, {
          [tableName]: updatedData
        }, { merge: true });
      
        setColumnData({
          columns: result.data.columns || []
        });

        return updatedData;
      }

      return null;
    } catch (error) {
      console.error('Error refreshing samples:', error);
      setError('Failed to refresh samples: ' + error.message);
      throw error;
    } finally {
      setRefreshing(prev => ({ ...prev, [tableId]: false }));
    }
  };

  // Fetch stats for columns
  const fetchColumnStats = async (tableId, columnName = null, materializedViewId = null) => {
    if (!tableId) return;
    
    try {
      if (columnName) {
        setRefreshingStats(prev => ({ ...prev, [columnName]: true }));
        
        const calculateColumnStats = httpsCallable(functions, 'calculateColumnStats');
        const originalResult = await calculateColumnStats({ tableId, columnName });
        
        let materializedResult = null;
        if (materializedViewId) {
          materializedResult = await calculateColumnStats({ 
            tableId: materializedViewId, 
            columnName 
          });
        }
        
        if (originalResult.data.success) {
          setColumnStats(prev => ({
            ...prev,
            [`${tableId}_${columnName}`]: originalResult.data.stats,
            ...(materializedResult?.data.success ? {
              [`${materializedViewId}_${columnName}`]: materializedResult.data.stats
            } : {})
          }));
        }
      } else {
        setRefreshingStats(prev => ({ ...prev, all: true }));
        
        // Get schema from columnData or fetch it if not available
        let schema = columnData?.columns;
        if (!schema || !schema.length) {
          const samplesData = await handleRefreshSamples(tableId);
          schema = samplesData?.dataSample || [];
        }

        if (!schema.length) {
          console.warn('No schema available for table:', tableId);
          return;
        }
  
        const promises = schema.map(async (column) => {
          const calculateColumnStats = httpsCallable(functions, 'calculateColumnStats');
          
          const [originalStats, materializedStats] = await Promise.all([
            calculateColumnStats({ tableId, columnName: column.name }),
            materializedViewId ? 
              calculateColumnStats({ tableId: materializedViewId, columnName: column.name }) :
              Promise.resolve(null)
          ]);
  
          return {
            columnName: column.name,
            originalStats: originalStats.data.success ? originalStats.data.stats : null,
            materializedStats: materializedStats?.data.success ? materializedStats.data.stats : null
          };
        });
  
        const results = await Promise.all(promises);
        
        const newStats = {};
        results.forEach(({ columnName, originalStats, materializedStats }) => {
          if (originalStats) {
            newStats[`${tableId}_${columnName}`] = originalStats;
          }
          if (materializedStats && materializedViewId) {
            newStats[`${materializedViewId}_${columnName}`] = materializedStats;
          }
        });
  
        setColumnStats(newStats);
      }
    } catch (error) {
      console.error('Error fetching stats:', error);
      setError('Failed to fetch statistics: ' + error.message);
      throw error;
    } finally {
      if (columnName) {
        setRefreshingStats(prev => ({ ...prev, [columnName]: false }));
      } else {
        setRefreshingStats(prev => ({ ...prev, all: false }));
      }
    }
  };

  // Refresh all data for a table
  const handleDataRefresh = async (tableId, materializedViewId = null) => {
    if (!tableId) return;
    
    try {
      // Refresh samples first
      const samplesData = await handleRefreshSamples(tableId);
      
      if (materializedViewId) {
        await handleRefreshSamples(materializedViewId);
      }
      
      // Only fetch stats if we have sample data
      if (samplesData) {
        await fetchColumnStats(tableId, null, materializedViewId);
      }
      
      return samplesData;
    } catch (error) {
      console.error('Error refreshing all data:', error);
      setError('Failed to refresh data: ' + error.message);
      throw error;
    }
  };

  // Effect to fetch initial data when table changes
  useEffect(() => {
    if (selectedTableId) {
      // First check if there's a materialized view
      const fetchInitialData = async () => {
        try {
          const dataPrepDoc = doc(db, 'DataPrep', 'newTables');
          const docSnap = await getDoc(dataPrepDoc);
          
          if (docSnap.exists()) {
            const newTablesData = docSnap.data();
            const materializedTable = Object.values(newTablesData).find(
              table => table.originalTableId === selectedTableId
            );
            
            // Fetch data for both tables if materialized view exists
            await handleDataRefresh(selectedTableId, materializedTable?.id);
          } else {
            // Just fetch original table data if no materialized view
            await handleDataRefresh(selectedTableId);
          }
        } catch (err) {
          console.error('Error in initial data fetch:', err);
          setError('Failed to load initial data: ' + err.message);
        }
      };
  
      fetchInitialData();
    }
  }, [selectedTableId]);

  return {
    columnData,
    columnStats,
    refreshing,
    refreshingStats,
    error,
    handleRefreshSamples,
    fetchColumnStats,
    handleDataRefresh
  };
};