import React from 'react';
import { User, Copy, Check } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import { ClaudeIcon, OpenAIIcon } from './ModelIcons';

const parseMarkdown = (text) => {
  if (!text) return '';
  
  // Replace code blocks with proper formatting
  text = text.replace(/```([\s\S]*?)```/g, (_, code) => {
    return `<pre class="bg-gray-800 text-white p-3 rounded-md my-2 overflow-x-auto"><code>${code.trim()}</code></pre>`;
  });

  // Replace inline code
  text = text.replace(/`([^`]+)`/g, '<code class="bg-gray-100 text-gray-800 px-1 rounded">$1</code>');

  // Replace bold text
  text = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');

  // Replace italic text
  text = text.replace(/\*(.*?)\*/g, '<em>$1</em>');

  // Replace headers (h1 to h6)
  text = text.replace(/^(#{1,6})\s(.*)$/gm, (_, level, content) => {
    const size = 7 - level.length; // h1 = largest, h6 = smallest
    return `<h${level.length} class="text-${size}xl font-bold my-2">${content.trim()}</h${level.length}>`;
  });

  // Replace unordered lists
  text = text.replace(/^[\s]*[-*+]\s(.+)$/gm, '<li class="ml-4">$1</li>');
  text = text.replace(/(<li[^>]*>.*<\/li>)/gs, '<ul class="list-disc ml-4 my-2">$1</ul>');

  // Replace ordered lists
  text = text.replace(/^\d+\.\s(.+)$/gm, '<li class="ml-4">$1</li>');
  text = text.replace(/(<li[^>]*>.*<\/li>)/gs, '<ol class="list-decimal ml-4 my-2">$1</ol>');

  // Replace newlines with line breaks
  text = text.replace(/\n/g, '<br>');

  return text;
};

const ChatMessage = ({ message, isLastMessage }) => {
  const isUser = message.role === 'user';
  const [isCopied, setIsCopied] = useState(false);

  const getAIIcon = () => {
    if (message.model && (message.model.includes('gpt') || message.model.includes('GPT'))) {
      return <OpenAIIcon className="w-5 h-5 text-white" />;
    }
    return <ClaudeIcon className="w-5 h-5 text-white" />;
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(message.content);
      setIsCopied(true);
      toast.success('Message copied to clipboard');
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      toast.error('Failed to copy message');
    }
  };

  const formatTimestamp = (timestamp) => {
    let date;
    if (typeof timestamp === 'object' && timestamp !== null) {
      if (timestamp._seconds) {
        date = new Date(timestamp._seconds * 1000);
      } else if (timestamp.seconds) {
        date = new Date(timestamp.seconds * 1000);
      } else {
        date = new Date(timestamp);
      }
    } else if (typeof timestamp === 'number') {
      date = new Date(timestamp);
    } else {
      date = new Date();
    }

    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();

    if (isToday) {
      return date.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit'
      });
    } else {
      return date.toLocaleDateString([], {
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      });
    }
  };

  const isLoading = !message.timestamp;

  return (
    <div
      className={`flex gap-3 ${isUser ? 'justify-end' : 'justify-start'} mb-4 group relative pr-10`}
      data-last-message={isLastMessage}
    >
      {!isUser && (
        <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center flex-shrink-0">
          {getAIIcon()}
        </div>
      )}
      
      <div className={`flex flex-col max-w-[80%] ${isUser ? 'items-end' : 'items-start'}`}>
        <div
          className={`relative rounded-lg px-4 py-2 group ${
            isUser
              ? 'bg-primary text-white'
              : 'bg-gray-100 text-gray-900'
          }`}
        >
          {isLoading && (
            <div className="absolute -bottom-6 left-0">
              <div className="flex items-center space-x-1">
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.3s]"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce [animation-delay:-0.15s]"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
              </div>
            </div>
          )}

          <div 
            className="text-base whitespace-pre-wrap"
            dangerouslySetInnerHTML={{ __html: parseMarkdown(message.content) }}
          />
        </div>

        {!isLoading && (
          <span className="text-xs text-gray-500 mt-1 px-1">
            {formatTimestamp(message.timestamp)}
          </span>
        )}
      </div>

      <button
        onClick={handleCopy}
        className="absolute right-0 top-0 p-1.5 rounded-md opacity-0 group-hover:opacity-100 transition-opacity hover:bg-gray-100"
        title="Copy message"
      >
        {isCopied ? (
          <Check className="w-4 h-4 text-gray-600" />
        ) : (
          <Copy className="w-4 h-4 text-gray-600" />
        )}
      </button>

      {isUser && (
        <div className="w-8 h-8 rounded-full bg-secondary text-white flex items-center justify-center flex-shrink-0">
          <span className="text-xs font-medium">{message.userInitials || '--'}</span>
        </div>
      )}
    </div>
  );
};

export default ChatMessage;