// hooks/useDataPrepOperations.js
import { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';

export const useDataPrepOperations = (user) => {
  const [tables, setTables] = useState([]);
  const [newTable, setNewTable] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState({ tableId: null, type: '', message: '' });
  const [selectedTable, setSelectedTable] = useState(null);
  const [isInitializing, setIsInitializing] = useState(false);

  // Separate useEffect for fetching tables
  useEffect(() => {
    fetchTables();
  }, []);

  // Separate useEffect for handling selected table after tables are loaded
  useEffect(() => {
    const savedTableId = localStorage.getItem('selectedTableId');
    if (savedTableId && tables.length > 0) {
      const savedTable = tables.find(t => t.id === savedTableId);
      if (savedTable) {
        setSelectedTable(savedTable);
        checkNewTable(savedTable.id);
      }
    }
  }, [tables]); // This will run whenever tables are updated

  const fetchTables = async () => {
    try {
      setIsLoading(true);
      const dataPrepDoc = doc(db, 'DataPrep', 'tables');
      const docSnap = await getDoc(dataPrepDoc);
      
      if (docSnap.exists()) {
        const tablesData = Object.entries(docSnap.data())
          .map(([name, tableData]) => ({
            name,
            id: tableData.id || '',
            prompt: tableData.prompt || '',
            schema: tableData.schema || [],
            dataSample: tableData.dataSample || [],
            lastUpdated: tableData.lastUpdated || null
          }));
        setTables(tablesData);
      } else {
        await setDoc(dataPrepDoc, {});
      }
    } catch (error) {
      console.error('Error fetching tables:', error);
      setSaveStatus({
        tableId: null,
        type: 'error',
        message: 'Failed to fetch tables'
      });
    } finally {
      setIsLoading(false);
    }
  };


  const handleCreateTable = async (formData) => {
    let resultId;
    try {
      setIsLoading(true);
       
      // Validation: Ensure table name is provided
      if (!formData.name) {
        throw new Error('Table name is required');
      }
       
      // Check for existing table with the same name
      const existingTable = tables.find(t => t.name === formData.name);
      if (existingTable) {
        throw new Error('A table with this name already exists');
      }
  
      const dataPrepDoc = doc(db, 'DataPrep', 'tables');
      
      if (formData.type === 'new') {
        // Validation for new table creation
        if (!formData.dataset || !formData.file) {
          throw new Error('Dataset and file are required');
        }
     
        // Read and encode the file content as base64
        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const base64Content = e.target.result.split(',')[1];
              if (!base64Content) {
                reject(new Error('Failed to read file content'));
                return;
              }
              resolve(base64Content);
            } catch (err) {
              reject(new Error('Failed to process file content: ' + err.message));
            }
          };
          reader.onerror = () => reject(new Error('Failed to read file'));
          reader.readAsDataURL(formData.file);
        });
     
        // Call the cloud function to create the table
        const createTableFunc = httpsCallable(functions, 'createTable');
        const result = await createTableFunc({
          fileContent,
          name: formData.name,
          dataset: formData.dataset,
          prompt: formData.prompt,
          useRowForDescription: formData.useRowForDescription,
          descriptionRow: formData.descriptionRow,
          destination: 'dataPrep'
        });
  
        // Check if the table was created successfully
        if (!result.data.success || !result.data.tableId) {
          throw new Error('Failed to create table: ' + (result.data.error || 'Unknown error'));
        }
  
        resultId = result.data.tableId;
  
        // Create a new table object based on the response
        const newTable = {
          name: formData.name,
          id: resultId,
          prompt: formData.prompt,
          schema: result.data.schema || [],
          dataSample: result.data.samples || [],
          lastUpdated: new Date().toISOString()
        };
        
        // Update Firestore with the new table
        await setDoc(dataPrepDoc, {
          [formData.name]: newTable
        }, { merge: true });
        
      } else {
        // Handling for existing table selection
        if (!formData.id) {
          throw new Error('Table ID is required');
        }
  
        // Call the cloud function to get table data samples
        const getTableData = httpsCallable(functions, 'getTableDataSamples');
        const result = await getTableData({ tableId: formData.id });
        
        resultId = formData.id;
        
        // Create a new table object based on the response
        const newTable = {
          name: formData.name,
          id: resultId,
          prompt: formData.prompt || '',
          schema: result.data.schema || [],
          dataSample: result.data.samples || [],
          lastUpdated: new Date().toISOString()
        };
        
        // Update Firestore with the selected table data
        await setDoc(dataPrepDoc, {
          [formData.name]: newTable
        }, { merge: true });
      }
       
      // After successful creation or selection, re-fetch all tables to ensure fresh state
      await fetchTables();
  
      // Find the newly created or selected table from the refreshed tables list
      const refreshedTable = tables.find(t => t.id === resultId) || {
        name: formData.name,
        id: resultId,
        prompt: formData.prompt,
        schema: formData.schema || [],
        dataSample: formData.dataSample || [],
        lastUpdated: new Date().toISOString()
      };
  
      // Set the selected table
      setSelectedTable(refreshedTable);
      localStorage.setItem('selectedTableId', refreshedTable.id);
      
      // Update the save status to indicate success
      setSaveStatus({
        tableId: resultId,
        type: 'success',
        message: 'Table added successfully!'
      });
  
      // Clear the save status after 3 seconds
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      
      return refreshedTable;
       
    } catch (err) {
      console.error('Error creating table:', err);
      setSaveStatus({
        tableId: 'new',
        type: 'error',
        message: err.message || 'Failed to create table'
      });
      throw err;
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleInitializeView = async (tableId) => {
    if (!tableId) return;
    
    try {
      setIsInitializing(true);
      const executeTablePreparation = httpsCallable(functions, 'executeTablePreparation');
      const result = await executeTablePreparation({ 
        tableId,
        adminEmail: user.email
      });
  
      if (result.data.success) {
        setSaveStatus({
          tableId,
          type: 'success',
          message: 'New table initialized successfully!'
        });
        await checkNewTable(tableId);
      } else {
        throw new Error(result.data.error || 'Failed to initialize table');
      }
    } catch (error) {
      console.error('Error initializing view:', error);
      setSaveStatus({
        tableId,
        type: 'error',
        message: error.message || 'Failed to initialize table'
      });
    } finally {
      setIsInitializing(false);
    }
  };

  const checkNewTable = async (tableId) => {
    try {
      const newTablesDoc = doc(db, 'DataPrep', 'newTables');
      const docSnap = await getDoc(newTablesDoc);
      
      if (docSnap.exists()) {
        const newTablesData = docSnap.data();
        const matchingNewTable = Object.values(newTablesData).find(
          table => table.originalTableId === tableId
        );
        
        setNewTable(matchingNewTable);
        return matchingNewTable;
      }
      return null;
    } catch (error) {
      console.error('Error checking new table:', error);
      return null;
    }
  };

  const handleTableSelection = async (tableId) => {
    const table = tables.find(t => t.id === tableId);
    if (!table) return;
    
    try {
      // First set the selected table synchronously
      setSelectedTable(table);
      localStorage.setItem('selectedTableId', table.id);
      
      // Then wait for materialized table check
      const materializedTable = await checkNewTable(table.id);
      setNewTable(materializedTable);
      
      // Clear any existing status messages
      setSaveStatus({ tableId: null, type: '', message: '' });
  
      // Return the selected table to confirm selection worked
      return table;
    } catch (error) {
      console.error('Error during table selection:', error);
      setSaveStatus({
        tableId: table.id,
        type: 'error',
        message: 'Error fetching table data: ' + error.message
      });
      throw error;
    }
  };

  return {
    tables,
    selectedTable,
    newTable,
    isLoading,
    isInitializing,
    saveStatus,
    setSaveStatus,
    handleCreateTable,
    handleTableSelection,
    handleInitializeView,
    checkNewTable
  };
};