import React, { useState, useMemo } from 'react';
import { ChevronRight, Loader2, ChevronDown, Clock, CheckCircle, XCircle, AlertTriangle } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

const FixStatusBadge = ({ status }) => {
  const statusConfig = {
    proposed: {
      color: 'bg-yellow-100 text-yellow-800',
      icon: AlertTriangle,
      text: 'Proposed'
    },
    active: {
      color: 'bg-green-100 text-green-800',
      icon: CheckCircle,
      text: 'Active'
    },
    reversed: {
      color: 'bg-gray-100 text-gray-800',
      icon: XCircle,
      text: 'Reversed'
    },
    default: {
      color: 'bg-gray-100 text-gray-800',
      icon: AlertTriangle,
      text: 'Unknown'
    }
  };

  const config = statusConfig[status] || statusConfig.default;
  const Icon = config.icon;

  return (
    <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${config.color}`}>
      <Icon className="w-3 h-3 mr-1" />
      {status || 'Unknown'}
    </span>
  );
};

const SampleComparison = ({ samples }) => {
  return (
    <div className="grid grid-cols-2 gap-4 mt-2">
      <div>
        <h5 className="text-sm font-medium text-gray-700 mb-2">Before</h5>
        <div className="space-y-1">
          {samples.before.slice(0, 5).map((sample, idx) => (
            <div key={idx} className="text-sm bg-gray-50 p-2 rounded">
              {String(sample.value)}
            </div>
          ))}
        </div>
      </div>
      <div>
        <h5 className="text-sm font-medium text-gray-700 mb-2">After</h5>
        <div className="space-y-1">
          {samples.after.slice(0, 5).map((sample, idx) => (
            <div key={idx} className="text-sm bg-gray-50 p-2 rounded">
              {String(sample.value)}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const FixCard = ({ fix, onRevert, onApprove, onReject, loadingOperation }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const Icon = isExpanded ? ChevronDown : ChevronRight;

  const handleAction = (e, action, fixId) => {
    e.stopPropagation();
    action(fixId);
  };

  const truncatedDescription = useMemo(() => {
    return fix.description.length > 40 
      ? fix.description.substring(0, 40) + '...'
      : fix.description;
  }, [fix.description]);

  return (
    <div className="border rounded-lg bg-white shadow-sm">
      <div 
        className="p-4 cursor-pointer flex items-center justify-between"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <div className="flex items-center space-x-3">
          <Icon className="w-5 h-5 text-gray-400" />
          <FixStatusBadge status={fix.metadata.status} />
          <span 
            className="text-sm font-medium text-gray-900 group relative"
            title={fix.description}
          >
            {truncatedDescription}
            {fix.description.length > 40 && (
              <span className="absolute invisible group-hover:visible bg-gray-900 text-white px-2 py-1 rounded text-xs -top-8 left-0 whitespace-nowrap z-10">
                {fix.description}
              </span>
            )}
          </span>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-xs text-gray-500 flex items-center">
            <Clock className="w-3 h-3 mr-1" />
            {formatDistanceToNow(fix.metadata.createdAt.toDate(), { addSuffix: true })}
          </span>
          {fix.metadata.status === 'proposed' && (
            <div className="flex space-x-2">
              <button
                onClick={(e) => handleAction(e, onApprove, fix.id)}
                disabled={loadingOperation === `approve-${fix.id}`}
                className="text-xs px-2 py-1 bg-green-600 text-white rounded hover:bg-green-700 disabled:opacity-50 flex items-center gap-1 transition-colors"
              >
                {loadingOperation === `approve-${fix.id}` ? (
                  <Loader2 className="h-3 w-3 animate-spin" />
                ) : (
                  'Approve'
                )}
              </button>
              <button
                onClick={(e) => handleAction(e, onReject, fix.id)}
                disabled={loadingOperation === `reject-${fix.id}`}
                className="text-xs px-2 py-1 border border-red-600 text-red-600 rounded hover:bg-red-50 disabled:opacity-50 flex items-center gap-1 transition-colors"
              >
                {loadingOperation === `reject-${fix.id}` ? (
                  <Loader2 className="h-3 w-3 animate-spin" />
                ) : (
                  'Reject'
                )}
              </button>
            </div>
          )}
          {fix.metadata.status === 'active' && (
            <button
              onClick={(e) => handleAction(e, onRevert, fix.id)}
              disabled={loadingOperation === `revert-${fix.id}`}
              className="text-xs px-2 py-1 border border-yellow-600 text-yellow-600 rounded hover:bg-yellow-50 disabled:opacity-50 flex items-center gap-1 transition-colors"
            >
              {loadingOperation === `revert-${fix.id}` ? (
                <Loader2 className="h-3 w-3 animate-spin" />
              ) : (
                'Revert'
              )}
            </button>
          )}
        </div>
      </div>

      {isExpanded && (
        <div className="px-4 pb-4 border-t">
          <div className="mt-4 space-y-4">
            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-1">SQL Transformation</h4>
              <pre className="text-sm bg-gray-50 p-3 rounded overflow-x-auto">
                {fix.sql}
              </pre>
            </div>

            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-1">Sample Results</h4>
              <SampleComparison samples={fix.samples} />
            </div>

            {fix.metadata.impact && (
              <div>
                <h4 className="text-sm font-medium text-gray-700 mb-1">Impact Analysis</h4>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <p className="text-xs text-gray-500">Rows Affected</p>
                    <p className="text-sm font-medium">{fix.metadata.impact.rowsAffected.toLocaleString()}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-500">Nulls Introduced</p>
                    <p className="text-sm font-medium">{fix.metadata.impact.nullsIntroduced.toLocaleString()}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-500">Unique Values Change</p>
                    <p className="text-sm font-medium">{fix.metadata.impact.uniqueValuesChange > 0 ? '+' : ''}{fix.metadata.impact.uniqueValuesChange}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

const FixesList = ({ fixes, onRevert, onApprove, onReject, loadingOperation }) => {
  const sortedFixes = useMemo(() => {
    if (!fixes || fixes.length === 0) return [];
    
    return [...fixes].sort((a, b) => {
      // First, sort by status (proposed first)
      if (a.metadata.status === 'proposed' && b.metadata.status !== 'proposed') return -1;
      if (a.metadata.status !== 'proposed' && b.metadata.status === 'proposed') return 1;
      
      // Then by sequence within each status group
      return b.metadata.sequence - a.metadata.sequence;
    });
  }, [fixes]);

  if (!fixes || fixes.length === 0) {
    return (
      <div className="text-sm text-gray-500 text-center py-4">
        No changes available
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {sortedFixes.map((fix) => (
        <FixCard
          key={fix.id}
          fix={fix}
          onRevert={onRevert}
          onApprove={onApprove}
          onReject={onReject}
          loadingOperation={loadingOperation}
        />
      ))}
    </div>
  );
};

export default FixesList;