import React, { useState, useEffect } from 'react';
import TablePreview from './TablePreview';
import { ChevronDown, ChevronRight, Database, ArrowLeftRight, AlertTriangle, Loader2 } from 'lucide-react';

const FixedViewSection = ({ 
  viewData, 
  onRefreshView, 
  isRefreshing,
  activeFixes = [],
  user,
  onDataRefresh
}) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const [selectedTab, setSelectedTab] = useState('preview');
  const [copiedSQL, setCopiedSQL] = useState(false);
  const [localViewData, setLocalViewData] = useState(viewData);

  useEffect(() => {
    setLocalViewData(viewData);
  }, [viewData]);

  if (!viewData) return null;

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Never';
    // Handle Firestore timestamp
    if (timestamp.toDate) {
      return timestamp.toDate().toLocaleString();
    }
    // Handle regular date strings/objects
    return new Date(timestamp).toLocaleString();
  };

  const getTotalChangesCount = () => {
    const fixesCount = activeFixes.length || 0;
    const addedColumnsCount = viewData.columnAdditions?.length || 0;
    return fixesCount + addedColumnsCount;
  };

  const statusIndicator = () => {
    const getStatusColor = () => {
      if (viewData.status === 'active') return 'bg-green-100 text-green-800 border-green-200';
      if (viewData.status === 'refreshing') return 'bg-blue-100 text-blue-800 border-blue-200';
      return 'bg-yellow-100 text-yellow-800 border-yellow-200';
    };

    return (
      <div className={`flex items-center gap-2 px-3 py-1 rounded-full ${getStatusColor()} border`}>
        <div className={`w-2 h-2 rounded-full ${
          viewData.status === 'active' ? 'bg-green-500' :
          viewData.status === 'refreshing' ? 'bg-blue-500' :
          'bg-yellow-500'
        }`} />
        <span className="text-sm font-medium">
          {getTotalChangesCount()} active changes
        </span>
      </div>
    );
  };

  const renderHeader = () => (
    <div 
      className="flex items-start justify-between p-6 bg-gradient-to-r from-secondary/10 to-background rounded-t-lg cursor-pointer"
      onClick={() => setIsExpanded(!isExpanded)}
    >
      <div className="space-y-2">
        <div className="flex items-center gap-3">
          <Database className="h-5 w-5 text-primary" />
          <h2 className="text-lg font-semibold text-gray-900">New Table</h2>
          {statusIndicator()}
        </div>
        <div className="flex items-center gap-2">
          <p className="text-xs text-gray-500 font-mono truncate max-w-md hover:text-gray-700 cursor-pointer" 
            onClick={(e) => {
              e.stopPropagation();
              navigator.clipboard.writeText(viewData.id);
              // Create and show a temporary tooltip
              const tooltip = document.createElement('div');
              tooltip.textContent = 'Copied!';
              tooltip.className = 'absolute bg-gray-800 text-white px-2 py-1 rounded text-xs -mt-8';
              e.target.appendChild(tooltip);
              setTimeout(() => tooltip.remove(), 1000);
            }}>
            {viewData.id}
          </p>
        </div>
      </div>
      <div 
        className="text-gray-500 hover:text-gray-700"
        onClick={(e) => {
          e.stopPropagation();
          setIsExpanded(!isExpanded);
        }}
      >
        {isExpanded ? (
          <ChevronDown className="h-5 w-5" />
        ) : (
          <ChevronRight className="h-5 w-5" />
        )}
      </div>
    </div>
  );

  const renderTabs = () => (
    <div className="px-6 border-t">
      <div className="flex gap-6 -mb-px">
        <button
          onClick={() => setSelectedTab('preview')}
          className={`py-3 text-sm font-medium border-b-2 ${
            selectedTab === 'preview'
              ? 'text-primary border-primary'
              : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
          }`}
        >
          Preview
        </button>
        <button
          onClick={() => setSelectedTab('fixes')}
          className={`py-3 text-sm font-medium border-b-2 ${
            selectedTab === 'fixes'
              ? 'text-primary border-primary'
              : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
          }`}
        >
          Applied Changes ({getTotalChangesCount()})
        </button>
        <button
          onClick={() => setSelectedTab('sql')}
          className={`py-3 text-sm font-medium border-b-2 ${
            selectedTab === 'sql'
              ? 'text-primary border-primary'
              : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
          }`}
        >
          Generated SQL
        </button>
      </div>
    </div>
  );

  const renderFixesContent = () => (
    <div className="p-6 space-y-4">
      {/* Added Columns Section */}
      {viewData.columnAdditions?.length > 0 && (
        <div className="space-y-3">
          <h3 className="text-sm font-medium text-gray-900">Added Columns</h3>
          <div className="space-y-2">
            {viewData.columnAdditions.map((column, index) => (
              <div 
                key={index}
                className="flex items-center justify-between p-3 bg-secondary/10 rounded-lg"
              >
                <div className="flex items-center gap-3">
                  <ArrowLeftRight className="h-4 w-4 text-gray-400" />
                  <div>
                    <p className="text-sm font-medium text-gray-900">
                      {column.displayName || column.columnName}
                    </p>
                    {column.description && (
                      <p className="text-xs text-gray-600 mt-1">
                        {column.description}
                      </p>
                    )}
                    <div className="flex gap-4">
                      <p className="text-xs text-gray-500">
                        Created {formatDate(column.metadata?.createdAt)}
                      </p>
                      {column.metadata?.updatedBy && (
                        <p className="text-xs text-gray-500">
                          by {column.metadata.updatedBy}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* Column Fixes Section */}
      {Object.entries(localViewData.fixesByColumn || {}).map(([columnName, fixes]) => {
        // Filter to show only active fixes
        const activeFixes = fixes.filter(fix => fix.metadata?.status === 'active');
        
        // If no active fixes for this column, don't render the section
        if (activeFixes.length === 0) return null;

        return (
          <div key={columnName} className="space-y-3">
            <h3 className="text-sm font-medium text-gray-900">{columnName}</h3>
            <div className="space-y-2">
              {activeFixes.map((fix, index) => (
                <div 
                  key={index}
                  className="flex items-center justify-between p-3 bg-primary/10 rounded-lg"
                >
                  <div className="flex items-center gap-3">
                    <ArrowLeftRight className="h-4 w-4 text-gray-400" />
                    <div>
                      <p className="text-sm font-medium text-gray-900">
                        Fix for: {fix.description}
                      </p>
                      {fix.metadata?.details && (
                        <p className="text-xs text-gray-600 mt-1">
                          {fix.metadata.details}
                        </p>
                      )}
                      <div className="flex gap-4">
                        <p className="text-xs text-gray-500">
                          Created {formatDate(fix.metadata?.createdAt)}
                        </p>
                        {fix.metadata?.updatedBy && (
                          <p className="text-xs text-gray-500">
                            by {fix.metadata.updatedBy}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
      })}

      {/* Empty State */}
      {(!localViewData.fixesByColumn || Object.values(localViewData.fixesByColumn).every(fixes => 
        fixes.filter(fix => fix.metadata?.status === 'active').length === 0
      )) && !viewData.columnAdditions?.length && (
        <div className="text-center py-6 text-gray-500">
          No changes have been applied yet
        </div>
      )}
    </div>
  );

  const renderPreviewContent = () => {
    return (
      <div>
        {localViewData ? (
          <TablePreview 
            table={{
              id: localViewData.id,
              name: localViewData.name,
              schema: localViewData.schema || [],
              dataSample: localViewData.dataSample || [],
            }} 
            isFixedView={true}
            showHeader={false}
          />
        ) : (
          <div className="text-center py-6 text-gray-500">
            No preview data available
          </div>
        )}
      </div>
    );
  };

  const handleCopySQL = async () => {
    await navigator.clipboard.writeText(localViewData.sql);
    setCopiedSQL(true);
    setTimeout(() => setCopiedSQL(false), 2000);
  };

  const renderSqlContent = () => (
    <div className="p-6">
      <div className="relative">
        <pre className="text-sm bg-gray-50 p-4 rounded-lg overflow-x-auto">
          {localViewData.sql}
        </pre>
        <button
          onClick={handleCopySQL}
          className="absolute top-2 right-2 px-2 py-1 text-xs text-gray-500 hover:text-gray-700 bg-white rounded border"
        >
          {copiedSQL ? 'Copied!' : 'Copy'}
        </button>
      </div>
    </div>
  );

  return (
    <div className="bg-white rounded-lg shadow-sm border mb-8">
      {renderHeader()}
      {isExpanded && (
        <>
          {renderTabs()}
          {selectedTab === 'preview' && renderPreviewContent()}
          {selectedTab === 'fixes' && renderFixesContent()}
          {selectedTab === 'sql' && renderSqlContent()}
        </>
      )}
    </div>
  );
};

export default FixedViewSection;