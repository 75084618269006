import React, { useMemo } from 'react';
import { Database, Filter, Group } from 'lucide-react';

const MetadataSection = ({ icon: Icon, title, items, badgeColorClass }) => {
  if (!items || items.length === 0) return null;
  
  // Filter out any empty strings, null, or undefined values
  const validItems = items.filter(item => item && item.trim().length > 0);
  if (validItems.length === 0) return null;
  
  return (
    <div className="flex items-start space-x-2 flex-shrink-0">
      <div className="mt-1">
        <Icon size={16} className="text-gray-600" />
      </div>
      <div>
        <div className="font-medium text-gray-700 mb-1">{title}</div>
        <div className="flex flex-wrap gap-2">
          {validItems.map((item, index) => (
            <span
              key={index}
              className={`inline-flex items-center px-2.5 py-0.5 rounded-md text-xs ${badgeColorClass}`}
            >
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

const QueryMetadata = ({ sqlQuery }) => {
  const extractMetadata = useMemo(() => {
    try {
      if (!sqlQuery) return { tables: [], filterColumns: [], groupByColumns: [], selectColumns: [] };

      const tables = new Set();
      const filterColumns = new Set();
      const groupByColumns = new Set();
      const selectColumns = new Set();

      // Function to properly format table names
      const formatTableName = (name) => {
        if (!name) return '';
        const parts = name.split('.');
        if (parts.length < 3) return '';
        
        // Get the last part (actual table name)
        let tableName = parts[2].replace(/`/g, '');
        
        // Handle hyphenated names
        return tableName
          .split('-')
          .map(part => part
            .split('_')
            .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
            .join(' ')
          )
          .join(' ');
      };

      // Function to format column names
      const formatColumnName = (name) => {
        if (!name) return '';
        
        // Remove table/alias qualifiers, function calls, and AS clauses
        let columnName = name.split('.').pop()  // Remove table qualifier
          .split(' AS ')[0]    // Remove AS clause
          .replace(/DATE_TRUNC\s*\([^)]*\)/gi, '') // Remove DATE_TRUNC
          .replace(/COALESCE\s*\([^)]*\)/gi, '')   // Remove COALESCE
          .replace(/SUM\s*\([^)]*\)/gi, '')        // Remove SUM
          .replace(/[(),]/g, '')  // Remove parentheses and commas
          .trim();

        if (!columnName) return '';
        
        // Special cases mapping
        const specialCases = {
          'Polyram_Factory_Name': 'Factory Name',
          'Customer_Name': 'Customer Name',
          'Part_SKU': 'Part SKU',
          'Packing_Quantity_Ton': 'Packing Quantity Ton',
          'Budget_Quantity_Ton': 'Budget Quantity Ton',
          'Actual_Quantity_Ton': 'Actual Quantity Ton',
          'Budget_Month': 'Month'
        };
        
        if (specialCases[columnName]) {
          return specialCases[columnName];
        }
        
        // Regular formatting
        return columnName
          .replace(/`/g, '')
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ')
          .trim();
      };

      // Extract SELECT columns
      const extractSelectColumns = () => {
        const selectPattern = /SELECT\s+(.*?)\s+FROM/is;
        const match = sqlQuery.match(selectPattern);
        if (match) {
          const columns = match[1].split(',').map(col => col.trim());
          columns.forEach(col => {
            const formatted = formatColumnName(col);
            if (formatted) {
              selectColumns.add(formatted);
            }
          });
        }
      };

      // Extract tables
      const extractTables = () => {
        const tablePattern = /(?:FROM|JOIN)\s+`?([^`\s]+(?:\.[^`\s]+){2})`?/gi;
        let match;
        const seenTables = new Set();
        
        while ((match = tablePattern.exec(sqlQuery)) !== null) {
          if (match[1]) {
            const tableName = formatTableName(match[1]);
            if (tableName && !seenTables.has(tableName.toLowerCase())) {
              seenTables.add(tableName.toLowerCase());
              tables.add(tableName);
            }
          }
        }
      };

      // Extract filter columns
      const extractFilterColumns = () => {
        const seenColumns = new Set();
        
        // Extract columns from JOIN and WHERE conditions
        const conditionsPattern = /(?:ON|WHERE)\s+(.*?)(?:\sGROUP BY|\sORDER BY|\sLIMIT|\)|\s*$)/gis;
        let condMatch;
        
        while ((condMatch = conditionsPattern.exec(sqlQuery)) !== null) {
          if (condMatch[1]) {
            const conditions = condMatch[1].split(/\sAND\s/i).map(cond => cond.trim());
            
            conditions.forEach(condition => {
              // Extract column names from condition
              const parts = condition.split(/[=<>]/);
              parts.forEach(part => {
                const formatted = formatColumnName(part);
                if (formatted && !seenColumns.has(formatted.toLowerCase())) {
                  seenColumns.add(formatted.toLowerCase());
                  filterColumns.add(formatted);
                }
              });
            });
          }
        }
      };

      // Extract GROUP BY columns
      const extractGroupByColumns = () => {
        const groupByPattern = /GROUP BY\s+(.*?)(?:\sORDER BY|\sLIMIT|\)|\s*$)/gis;
        let groupByMatch;
        
        while ((groupByMatch = groupByPattern.exec(sqlQuery)) !== null) {
          if (groupByMatch[1]) {
            const columns = groupByMatch[1].split(',').map(col => col.trim());
            columns.forEach(col => {
              const formatted = formatColumnName(col);
              if (formatted) {
                groupByColumns.add(formatted);
              }
            });
          }
        }
      };

      // Execute all extractions
      extractSelectColumns();
      extractTables();
      extractFilterColumns();
      extractGroupByColumns();

      return {
        tables: Array.from(tables).filter(Boolean),
        filterColumns: Array.from(filterColumns).filter(Boolean),
        groupByColumns: Array.from(groupByColumns).filter(Boolean),
        selectColumns: Array.from(selectColumns).filter(Boolean)
      };
    } catch (error) {
      console.error('Error in extractMetadata:', error);
      return {
        tables: [],
        filterColumns: [],
        groupByColumns: [],
        selectColumns: []
      };
    }
  }, [sqlQuery]);

  if (!sqlQuery) return null;

  const sections = [
    {
      icon: Database,
      title: "Tables Used",
      items: extractMetadata.tables,
      badgeColorClass: "bg-blue-50 text-blue-700"
    },
    {
      icon: Filter,
      title: "Filter Columns",
      items: extractMetadata.filterColumns,
      badgeColorClass: "bg-green-50 text-green-700"
    },
    {
      icon: Group,
      title: "Grouped By",
      items: extractMetadata.groupByColumns,
      badgeColorClass: "bg-purple-50 text-purple-700"
    }
  ].filter(section => section.items && section.items.length > 0);

  return (
    <div className="text-sm">
      {/* Mobile Layout (Vertical) */}
      <div className="md:hidden space-y-4">
        {sections.map((section, index) => (
          <MetadataSection key={index} {...section} />
        ))}
      </div>

      {/* Desktop Layout (Horizontal) */}
      <div className="hidden md:flex md:flex-row md:space-x-8 md:divide-x divide-gray-200">
        {sections.map((section, index) => (
          <div key={index} className={index === 0 ? '' : 'pl-8'}>
            <MetadataSection {...section} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default QueryMetadata;