// src/components/UserEmailSelect.js
import React, { useState, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { X, Search, User, Mail } from 'lucide-react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const UserEmailSelect = ({ 
  value = [], 
  onChange, 
  allowCustomEmails = false,
  placeholder = "Select users or enter emails",
  className = ""
}) => {
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [customInput, setCustomInput] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef(null);
  
  // Fetch users directly from Firestore
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        setIsLoading(true);
        const querySnapshot = await getDocs(collection(db, 'registeredUsersPreferences'));
        const usersList = querySnapshot.docs.map(doc => ({
          email: doc.id,
          name: `${doc.data().hebrewName || ''} ${doc.data().englishName || ''}`.trim()
        }));
        setUsers(usersList);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const handleCustomInput = (e) => {
    const input = e.target.value;
    if (input.includes(',')) {
      const emails = input.split(',').map(email => email.trim()).filter(Boolean);
      const validEmails = emails.filter(email => 
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && !value.includes(email)
      );
      
      if (validEmails.length > 0) {
        onChange([...value, ...validEmails]);
      }
      setCustomInput('');
    } else {
      setCustomInput(input);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && customInput) {
      e.preventDefault();
      const email = customInput.trim();
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email) && !value.includes(email)) {
        onChange([...value, email]);
        setCustomInput('');
      }
    }
  };

  const handleRemove = (emailToRemove) => {
    onChange(value.filter(email => email !== emailToRemove));
  };

  const filteredUsers = users.filter(user => {
    const searchLower = searchTerm.toLowerCase();
    return (
      !value.includes(user.email) &&
      (user.email.toLowerCase().includes(searchLower) ||
       user.name?.toLowerCase().includes(searchLower))
    );
  });

  const handleSelectUser = (user) => {
    if (!value.includes(user.email)) {
      onChange([...value, user.email]);
    }
    setSearchTerm('');
    setIsOpen(false);
  };

  return (
    <div className={`relative ${className}`}>
      <div 
        ref={inputRef} 
        className="min-h-[38px] w-full border border-gray-300 rounded-md p-2 flex flex-wrap gap-2"
      >
        {/* Selected emails chips */}
        {value.map((email) => (
          <div 
            key={email}
            className="bg-primary bg-opacity-10 text-primary text-sm rounded-full px-3 py-1 flex items-center gap-1"
          >
            {users.find(u => u.email === email) ? (
              <User className="h-3 w-3" />
            ) : (
              <Mail className="h-3 w-3" />
            )}
            <span>{email}</span>
            <button
              type="button"
              onClick={() => handleRemove(email)}
              className="hover:bg-primary hover:bg-opacity-10 rounded-full p-0.5"
            >
              <X className="h-3 w-3" />
            </button>
          </div>
        ))}
        
        {/* Input field */}
        {allowCustomEmails ? (
          <input
            type="text"
            value={customInput}
            onChange={handleCustomInput}
            onKeyDown={handleKeyDown}
            onFocus={() => setIsOpen(true)}
            placeholder={value.length === 0 ? placeholder : ""}
            className="flex-1 min-w-[120px] outline-none border-none bg-transparent"
          />
        ) : (
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            onFocus={() => setIsOpen(true)}
            placeholder={value.length === 0 ? placeholder : ""}
            className="flex-1 min-w-[120px] outline-none border-none bg-transparent"
          />
        )}
      </div>

      {/* Dropdown Portal */}
      {isOpen && createPortal(
        <>
          {/* Backdrop to handle clicking outside */}
          <div 
            className="fixed inset-0" 
            onClick={() => setIsOpen(false)}
          />
          
          {/* Dropdown content */}
          <div 
            className="fixed z-[1000] bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto"
            style={{
              width: inputRef.current?.offsetWidth,
              top: inputRef.current?.getBoundingClientRect().bottom + 4,
              left: inputRef.current?.getBoundingClientRect().left,
            }}
          >
            <div className="sticky top-0 bg-white border-b border-gray-200 p-2">
              <div className="relative">
                <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onClick={(e) => e.stopPropagation()}
                  placeholder="Search users..."
                  className="w-full pl-8 pr-4 py-2 border border-gray-300 rounded-md"
                />
              </div>
            </div>

            {isLoading ? (
              <div className="p-4 text-center text-gray-500">Loading users...</div>
            ) : filteredUsers.length > 0 ? (
              <div className="py-2">
                {filteredUsers.map((user) => (
                  <button
                    key={user.email}
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectUser(user);
                    }}
                    className="w-full text-left px-4 py-2 hover:bg-gray-100 flex items-center gap-2"
                  >
                    <User className="h-4 w-4 text-gray-400" />
                    <div>
                      <div className="text-sm font-medium">{user.email}</div>
                      {user.name && (
                        <div className="text-xs text-gray-500">{user.name}</div>
                      )}
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <div className="p-4 text-center text-gray-500">No users found</div>
            )}
          </div>
        </>,
        document.body
      )}
    </div>
  );
};

export default UserEmailSelect;