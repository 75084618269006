// News.js
import React, { useState, useEffect } from 'react';
import CompanyNews from '../components/CompanyNews';
import NewsDigest from '../components/NewsDigest';
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

const News = () => {
  const { currentUser } = useAuth();
  const [digest, setDigest] = useState(null);

  useEffect(() => {
    const fetchDigest = async () => {
      if (!currentUser?.email) return;

      try {
        const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
        const userPrefsDoc = await getDoc(userPrefsRef);
        
        if (userPrefsDoc.exists() && userPrefsDoc.data().dailyDigest) {
          setDigest(userPrefsDoc.data().dailyDigest);
        }
      } catch (error) {
        console.error('Error fetching digest:', error);
      }
    };

    fetchDigest();
  }, [currentUser]);

  const handleDigestView = async () => {
    if (!currentUser?.email || digest?.viewed) return;

    try {
      const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
      await updateDoc(userPrefsRef, {
        'dailyDigest.viewed': true
      });
      setDigest(prev => ({
        ...prev,
        viewed: true
      }));
    } catch (error) {
      console.error('Error updating digest viewed status:', error);
    }
  };

  return (
    <div className="container mx-auto px-0 py-2 sm:py-4">
      {digest && <NewsDigest digest={digest} onView={handleDigestView} />}
      <h1 className="text-gray-500 text-xl font-semibold mb-6">News Feed</h1>
      <CompanyNews />
    </div>
  );
};

export default News;