// src/components/AgentForm.js
import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import TextEditor from './TextEditor';
import Modal from './Modal';
import UserEmailSelect from './UserEmailSelect';
import { AlertCircle, Settings, Clock, Database, Code, Bell } from 'lucide-react';

const AgentForm = ({
  isOpen,
  onClose,
  onSubmit,
  isLoading,
  initialData = null,
  models = [],
  isEdit = false
}) => {
  const { user } = useAuth();
  const [activeTab, setActiveTab] = useState('basic');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const initialFormState = {
    name: '',
    description: '',
    prompt: '',
    model: '',
    schedule: {
      enabled: false,
      interval: 60
    },
    contextConfig: {
      queryCount: 10,
      timeframeDays: 7,
      scope: 'all',
      userId: null
    },
    status: 'active',
    actions: {
      notifications: {
        enabled: false,
        email: {
          enabled: false,
          recipients: [],
          notifyAllUsers: false,
          includeOutput: true
        },
        inApp: {
          enabled: false,
          users: [],
          notifyAllUsers: false,
          includeOutput: true
        }
      }
    }
  };
  const [formData, setFormData] = useState(initialFormState);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (initialData) {
      setFormData({
        ...initialData,
        schedule: {
          enabled: initialData.schedule?.enabled || false,
          interval: initialData.schedule?.interval || 60
        },
        contextConfig: {
          queryCount: initialData.contextConfig?.queryCount || 10,
          timeframeDays: initialData.contextConfig?.timeframeDays || 7,
          scope: initialData.contextConfig?.scope || 'all',
          userId: initialData.contextConfig?.userId || null
        }
      });
    }
  }, [initialData]);

  const tabs = [
    { id: 'basic', name: 'Basic Info', icon: Settings },
    { id: 'prompt', name: 'Prompt', icon: Code },
    { id: 'schedule', name: 'Schedule', icon: Clock },
    { id: 'context', name: 'Context', icon: Database },
    { id: 'actions', name: 'Actions', icon: Bell }
  ];

  const validateForm = () => {
    const newErrors = {};
    console.log("Form Data:", {
      name: formData.name,
      model: formData.model,
      prompt: formData.prompt,
      schedule: formData.schedule,
      contextConfig: formData.contextConfig
    }); // Let's see the actual form data values
    
    // Name validation
    if (!formData.name?.trim()) {
      newErrors.name = 'Name is required';
      console.log("Name validation failed - empty name"); // Debug log
    }
  
    // Prompt validation  
    if (!formData.prompt?.trim()) {
      newErrors.prompt = 'Prompt template is required';
      console.log("Prompt validation failed - empty prompt"); // Debug log
    }
  
    // Model validation
    if (!formData.model) {
      newErrors.model = 'Model selection is required';
      console.log("Model validation failed - no model selected"); // Debug log
    }
  
    console.log("All form validation errors:", newErrors);
    setErrors(newErrors);
    
    // Return true only if there are no errors AND we have required fields
    const isValid = Object.keys(newErrors).length === 0 && formData.name?.trim() && formData.prompt?.trim() && formData.model;
    console.log("Final validation result:", isValid, "Required fields present:", {
      hasName: Boolean(formData.name?.trim()),
      hasPrompt: Boolean(formData.prompt?.trim()),
      hasModel: Boolean(formData.model)
    });
    
    return isValid;
  };

  const handleSubmit = async () => {
    console.log("Submit button clicked"); // Debug log
    const isValid = validateForm();
    console.log("Form validation result:", isValid); // Debug log
    
    if (isValid) {
      try {
        setIsSubmitting(true);
        
        const submissionData = {
          ...formData,
          contextConfig: {
            ...formData.contextConfig,
            // Keep the userId as is since it will be set when selecting a specific user
            userId: formData.contextConfig.userId
          }
        };
        
        console.log("Submitting data:", submissionData); // Debug log
        await onSubmit(submissionData);
      } catch (error) {
        console.error("Submission error:", error); // Debug log
        setErrors(prev => ({
          ...prev,
          general: error.message || 'An error occurred while submitting the form'
        }));
      } finally {
        setIsSubmitting(false);
      }
    } else {
      // If the form is invalid, switch to the first tab with an error
      const tabsWithErrors = tabs.filter(tab => {
        switch (tab.id) {
          case 'basic':
            return errors.name || errors.model;
          case 'prompt':
            return errors.prompt;
          case 'schedule':
            return errors.interval;
          case 'context':
            return errors.queryCount || errors.timeframeDays;
          default:
            return false;
        }
      });
      
      if (tabsWithErrors.length > 0) {
        setActiveTab(tabsWithErrors[0].id);
      }
    }
  };


  const handlePromptChange = (content) => {
    setFormData(prev => ({
      ...prev,
      prompt: content
    }));
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'basic':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name *
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className={`w-full p-2 border ${errors.name ? 'border-red-500' : 'border-gray-300'} rounded`}
                placeholder="Enter agent name"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-600">{errors.name}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={formData.description}
                onChange={(e) => setFormData(prev => ({ ...prev, description: e.target.value }))}
                className="w-full p-2 border border-gray-300 rounded"
                rows={3}
                placeholder="Enter agent description"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Model *
              </label>
              <select
                value={formData.model}
                onChange={(e) => setFormData(prev => ({ ...prev, model: e.target.value }))}
                className={`w-full p-2 border ${errors.model ? 'border-red-500' : 'border-gray-300'} rounded`}
              >
                <option value="">Select a model</option>
                {models.map((model) => (
                  <option key={model.name} value={model.name}>{model.id} ({model.platform})</option>
                ))}
              </select>
              {errors.model && (
                <p className="mt-1 text-sm text-red-600">{errors.model}</p>
              )}
            </div>
          </div>
        );

      case 'prompt':
        return (
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Prompt Template *
            </label>
            <div className="h-[400px]">
              <TextEditor
                initialContent={formData.prompt}
                onSave={handlePromptChange}
                title=""
              />
            </div>
            {errors.prompt && (
              <p className="mt-1 text-sm text-red-600">{errors.prompt}</p>
            )}
          </div>
        );

      case 'schedule':
        return (
          <div className="space-y-4">
            <div className="flex items-center mb-6">
              <input
                type="checkbox"
                id="scheduleEnabled"
                checked={formData.schedule.enabled}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  schedule: {
                    ...prev.schedule,
                    enabled: e.target.checked
                  }
                }))}
                className="h-4 w-4 text-primary border-gray-300 rounded"
              />
              <label htmlFor="scheduleEnabled" className="ml-2 block text-sm text-gray-700">
                Enable Scheduling
              </label>
            </div>

            {formData.schedule.enabled && (
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Run Every (minutes)
                </label>
                <input
                  type="number"
                  min="1"
                  max="1440"
                  value={formData.schedule.interval}
                  onChange={(e) => setFormData(prev => ({
                    ...prev,
                    schedule: {
                      ...prev.schedule,
                      interval: parseInt(e.target.value, 10)
                    }
                  }))}
                  className={`w-full p-2 border ${errors.interval ? 'border-red-500' : 'border-gray-300'} rounded`}
                />
                {errors.interval && (
                  <p className="mt-1 text-sm text-red-600">{errors.interval}</p>
                )}
              </div>
            )}
          </div>
        );

      case 'context':
        return (
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Query Context
              </label>
              <select
                value={formData.contextConfig.scope}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  contextConfig: {
                    ...prev.contextConfig,
                    scope: e.target.value
                  }
                }))}
                className="w-full p-2 border border-gray-300 rounded"
              >
                <option value="all">All Users' Queries</option>
                <option value="user">My Queries Only</option>
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Number of Recent Queries
              </label>
              <input
                type="number"
                min="1"
                max="100"
                value={formData.contextConfig.queryCount}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  contextConfig: {
                    ...prev.contextConfig,
                    queryCount: parseInt(e.target.value, 10)
                  }
                }))}
                className={`w-full p-2 border ${errors.queryCount ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
              {errors.queryCount && (
                <p className="mt-1 text-sm text-red-600">{errors.queryCount}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Timeframe (days)
              </label>
              <input
                type="number"
                min="1"
                max="90"
                value={formData.contextConfig.timeframeDays}
                onChange={(e) => setFormData(prev => ({
                  ...prev,
                  contextConfig: {
                    ...prev.contextConfig,
                    timeframeDays: parseInt(e.target.value, 10)
                  }
                }))}
                className={`w-full p-2 border ${errors.timeframeDays ? 'border-red-500' : 'border-gray-300'} rounded`}
              />
              {errors.timeframeDays && (
                <p className="mt-1 text-sm text-red-600">{errors.timeframeDays}</p>
              )}
            </div>
          </div>
        );

        case 'actions':
          return (
            <div className="space-y-6">
              <div className="space-y-4">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-medium">Notifications</h3>
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      id="notificationsEnabled"
                      checked={formData.actions.notifications.enabled}
                      onChange={(e) => setFormData(prev => ({
                        ...prev,
                        actions: {
                          ...prev.actions,
                          notifications: {
                            ...prev.actions.notifications,
                            enabled: e.target.checked
                          }
                        }
                      }))}
                      className="h-4 w-4 text-primary border-gray-300 rounded"
                    />
                    <label htmlFor="notificationsEnabled" className="ml-2 text-sm text-gray-600">
                      Enable Notifications
                    </label>
                  </div>
                </div>

                {formData.actions.notifications.enabled && (
                  <>
                    {/* Email Notifications */}
                    <div className="space-y-4 mt-4">
                      <div className="flex items-center justify-between">
                        <h4 className="text-sm font-medium">Email Notifications</h4>
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="emailEnabled"
                            checked={formData.actions.notifications.email.enabled}
                            onChange={(e) => setFormData(prev => ({
                              ...prev,
                              actions: {
                                ...prev.actions,
                                notifications: {
                                  ...prev.actions.notifications,
                                  email: {
                                    ...prev.actions.notifications.email,
                                    enabled: e.target.checked
                                  }
                                }
                              }
                            }))}
                            className="h-4 w-4 text-primary border-gray-300 rounded"
                          />
                          <label htmlFor="emailEnabled" className="ml-2 text-sm text-gray-600">
                            Enable Email
                          </label>
                        </div>
                      </div>

                      {formData.actions.notifications.email.enabled && (
                        <>
                          <div>
                            <label className="block text-sm font-medium text-gray-700 mb-1">
                              Recipients
                            </label>
                            <div className="space-y-2">
                              <div className="flex items-center mb-2">
                                <input
                                  type="checkbox"
                                  id="emailNotifyAllUsers"
                                  checked={formData.actions.notifications.email.notifyAllUsers}
                                  onChange={(e) => setFormData(prev => ({
                                    ...prev,
                                    actions: {
                                      ...prev.actions,
                                      notifications: {
                                        ...prev.actions.notifications,
                                        email: {
                                          ...prev.actions.notifications.email,
                                          notifyAllUsers: e.target.checked,
                                          recipients: e.target.checked ? ['*ALL_USERS*'] : []
                                        }
                                      }
                                    }
                                  }))}
                                  className="h-4 w-4 text-primary border-gray-300 rounded"
                                />
                                <label htmlFor="emailNotifyAllUsers" className="ml-2 text-sm text-gray-600">
                                  Email All Users
                                </label>
                              </div>
                              {!formData.actions.notifications.email.notifyAllUsers && (
                                <UserEmailSelect
                                  value={formData.actions.notifications.email.recipients}
                                  onChange={(recipients) => setFormData(prev => ({
                                    ...prev,
                                    actions: {
                                      ...prev.actions,
                                      notifications: {
                                        ...prev.actions.notifications,
                                        email: {
                                          ...prev.actions.notifications.email,
                                          recipients
                                        }
                                      }
                                    }
                                  }))}
                                  allowCustomEmails={true}
                                  placeholder="Select users or enter email addresses"
                                />
                              )}
                            </div>
                          </div>
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              id="emailIncludeOutput"
                              checked={formData.actions.notifications.email.includeOutput}
                              onChange={(e) => setFormData(prev => ({
                                ...prev,
                                actions: {
                                  ...prev.actions,
                                  notifications: {
                                    ...prev.actions.notifications,
                                    email: {
                                      ...prev.actions.notifications.email,
                                      includeOutput: e.target.checked
                                    }
                                  }
                                }
                              }))}
                              className="h-4 w-4 text-primary border-gray-300 rounded"
                            />
                            <label htmlFor="emailIncludeOutput" className="ml-2 text-sm text-gray-600">
                              Include agent output in email
                            </label>
                          </div>
                        </>
                      )}
                    </div>

                    {/* In-App Notifications */}
                    <div className="space-y-4 mt-4">
                      <div className="flex items-center justify-between">
                        <h4 className="text-sm font-medium">In-App Notifications</h4>
                        <div className="flex items-center">
                          <input
                            type="checkbox"
                            id="inAppEnabled"
                            checked={formData.actions.notifications.inApp.enabled}
                            onChange={(e) => setFormData(prev => ({
                              ...prev,
                              actions: {
                                ...prev.actions,
                                notifications: {
                                  ...prev.actions.notifications,
                                  inApp: {
                                    ...prev.actions.notifications.inApp,
                                    enabled: e.target.checked
                                  }
                                }
                              }
                            }))}
                            className="h-4 w-4 text-primary border-gray-300 rounded"
                          />
                          <label htmlFor="inAppEnabled" className="ml-2 text-sm text-gray-600">
                            Enable In-App
                          </label>
                        </div>
                      </div>

                      {formData.actions.notifications.inApp.enabled && (
                        <>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Users to notify
                          </label>
                          <div className="space-y-2">
                            <div className="flex items-center mb-2">
                              <input
                                type="checkbox"
                                id="notifyAllUsers"
                                checked={formData.actions.notifications.inApp.notifyAllUsers}
                                onChange={(e) => setFormData(prev => ({
                                  ...prev,
                                  actions: {
                                    ...prev.actions,
                                    notifications: {
                                      ...prev.actions.notifications,
                                      inApp: {
                                        ...prev.actions.notifications.inApp,
                                        notifyAllUsers: e.target.checked,
                                        users: e.target.checked ? ['*ALL_USERS*'] : []
                                      }
                                    }
                                  }
                                }))}
                                className="h-4 w-4 text-primary border-gray-300 rounded"
                              />
                              <label htmlFor="notifyAllUsers" className="ml-2 text-sm text-gray-600">
                                Notify All Users
                              </label>
                            </div>
                            {!formData.actions.notifications.inApp.notifyAllUsers && (
                              <UserEmailSelect
                                value={formData.actions.notifications.inApp.users}
                                onChange={(users) => setFormData(prev => ({
                                  ...prev,
                                  actions: {
                                    ...prev.actions,
                                    notifications: {
                                      ...prev.actions.notifications,
                                      inApp: {
                                        ...prev.actions.notifications.inApp,
                                        users
                                      }
                                    }
                                  }
                                }))}
                                allowCustomEmails={false}
                                placeholder="Select users to notify"
                              />
                            )}
                          </div>
                        </div>
                          <div className="flex items-center">
                            <input
                              type="checkbox"
                              id="inAppIncludeOutput"
                              checked={formData.actions.notifications.inApp.includeOutput}
                              onChange={(e) => setFormData(prev => ({
                                ...prev,
                                actions: {
                                  ...prev.actions,
                                  notifications: {
                                    ...prev.actions.notifications,
                                    inApp: {
                                      ...prev.actions.notifications.inApp,
                                      includeOutput: e.target.checked
                                    }
                                  }
                                }
                              }))}
                              className="h-4 w-4 text-primary border-gray-300 rounded"
                            />
                            <label htmlFor="inAppIncludeOutput" className="ml-2 text-sm text-gray-600">
                              Include agent output in notification
                            </label>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          );

      default:
        return null;
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        console.log("Modal closing"); // Debug log
        setFormData({...initialFormState});
        setErrors({});
        setActiveTab('basic');
        setIsSubmitting(false);
        onClose();
      }}
      title={isEdit ? "Edit Agent" : "Create Agent"}
      onSubmit={() => {
        console.log("Modal submit triggered"); // Debug log
        handleSubmit();
      }}
      isLoading={isSubmitting || isLoading}
      confirmText={isSubmitting 
        ? "Processing..." 
        : isEdit 
          ? "Update Agent" 
          : "Create Agent"
      }
      confirmDisabled={isSubmitting || isLoading}
      size="2xl"
    >
      <div className="space-y-4 max-h-[calc(100vh-300px)] overflow-y-auto pb-4">
        {/* Tabs Sidebar and Content Container */}
        <div className="flex">
          {/* Tabs Sidebar - Fixed width */}
          <div className="w-48 flex-shrink-0 border-r border-gray-200">
            {tabs.map((tab) => {
              const Icon = tab.icon;
              return (
                <button
                  key={tab.id}
                  onClick={() => setActiveTab(tab.id)}
                  className={`w-full text-left px-4 py-2 flex items-center space-x-2 
                    ${activeTab === tab.id 
                      ? 'bg-primary bg-opacity-10 text-primary border-r-2 border-primary' 
                      : 'text-gray-600 hover:bg-gray-50'}`}
                >
                  <Icon className="h-4 w-4" />
                  <span>{tab.name}</span>
                </button>
              );
            })}
          </div>
  
          {/* Main Content Area - Scrollable */}
          <div className="flex-1 p-6 overflow-y-auto">
            {renderTabContent()}
  
            {Object.keys(errors).length > 0 && (
              <div className="mt-4 p-3 bg-red-50 rounded-md flex flex-col space-y-2">
                <div className="flex items-start space-x-2">
                  <AlertCircle className="h-5 w-5 text-red-500 flex-shrink-0 mt-0.5" />
                  <div className="text-sm text-red-700">
                    Please fix the following errors:
                  </div>
                </div>
                <ul className="list-disc list-inside ml-5 text-sm text-red-600">
                  {Object.entries(errors).map(([field, error]) => (
                    <li key={field}>{error}</li>
                  ))}
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AgentForm;