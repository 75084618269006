// src/components/AgentRunResults.js
import React from 'react';
import Modal from './Modal';

const AgentRunResults = ({ isOpen, onClose, runs }) => {
  if (!runs || !isOpen) return null;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Agent Run Results"
    >
      <div className="space-y-4">
        {Object.entries(runs).map(([userId, run]) => (
          <div key={userId} className="border-b pb-4">
            <div className="font-medium">User: {userId}</div>
            <div className="text-sm text-gray-500 mt-1">
              {run.success ? (
                <div className="text-green-600">Success</div>
              ) : (
                <div className="text-red-600">Failed: {run.error}</div>
              )}
              {run.tokenUsage && (
                <div className="mt-1">Token usage: {run.tokenUsage}</div>
              )}
            </div>
            {run.output && (
              <div className="mt-2 text-sm bg-gray-50 p-2 rounded">
                {run.output}
              </div>
            )}
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default AgentRunResults;