import React from 'react';
import { formatColumnName } from './dataProcessing';

const SortingControls = ({ sortColumn, setSortColumn, sortOrder, setSortOrder, columns, schema }) => {
  const getColumnLabel = (columnName) => {
    const field = schema?.fields?.find(f => f.name === columnName);
    return field ? formatColumnName(field.name) : columnName;
  };

  return (
    <div className="flex items-center space-x-4">
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Sort By</label>
        <select 
          value={sortColumn} 
          onChange={(e) => setSortColumn(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="">None</option>
          {columns.map(key => (
            <option key={key} value={key}>{getColumnLabel(key)}</option>
          ))}
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700 mb-1">Order</label>
        <select 
          value={sortOrder} 
          onChange={(e) => setSortOrder(e.target.value)}
          className="p-2 border rounded"
        >
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
        </select>
      </div>
    </div>
  );
};

export default SortingControls;