import React from 'react';
import Modal from '../Modal';
import Button from '../Button';
import SavedQueriesComponent from '../QueryComponent/SavedQueriesComponent';
import EditDashboardModal from './EditDashboardModal';

const DashboardModals = ({
  isNewDashboardModalOpen,
  setIsNewDashboardModalOpen,
  newDashboardName,
  setNewDashboardName,
  selectedQueries,
  setSelectedQueries,
  handleCreateDashboard,
  currentUser,
  availableQueries,
  isAddToDashboardModalOpen,
  setIsAddToDashboardModalOpen,
  dashboards,
  handleAddQueryToDashboard,
  handleNewDashboard,
  isEditDashboardModalOpen,
  setIsEditDashboardModalOpen,
  selectedDashboard,
  handleUpdateDashboard,
  handleDeleteDashboard,
  filterAvailableQueries
}) => {
  const wrappedHandleUpdateDashboard = async (updatedDashboard) => {
    await handleUpdateDashboard(updatedDashboard);
    setIsEditDashboardModalOpen(false);
  };

  return (
    <>
      <Modal
        isOpen={isNewDashboardModalOpen}
        onClose={() => {
          setIsNewDashboardModalOpen(false);
          setNewDashboardName('');
          setSelectedQueries([]);
        }}
        title="Create New Dashboard"
        onSubmit={handleCreateDashboard}
        confirmText="Create"
        cancelText="Cancel"
        confirmDisabled={selectedQueries.length === 0 || newDashboardName.trim() === ''}
      >
        <div className="mb-4">
          <input
            type="text"
            value={newDashboardName}
            onChange={(e) => setNewDashboardName(e.target.value)}
            placeholder="Dashboard Name"
            className="w-full px-3 py-2 border rounded-md text-gray-700 placeholder-gray-500 border-gray-300"
          />
        </div>
        <div className="max-h-96 overflow-y-auto mb-4">
          {currentUser ? (
            <SavedQueriesComponent
              onSelectQuery={(query) => {
                setSelectedQueries(prev => 
                  prev.includes(query.id) 
                    ? prev.filter(id => id !== query.id)
                    : [...prev, query.id]
                );
              }}
              selectedQueries={selectedQueries}
              isSelectionMode={true}
              displayMode="list"
              currentUser={currentUser}
              onQueryItemClick={(query) => {
                setSelectedQueries(prev => 
                  prev.includes(query.id) 
                    ? prev.filter(id => id !== query.id)
                    : [...prev, query.id]
                );
              }}
              queries={availableQueries}
            />
          ) : (
            <p>Loading user data...</p>
          )}
        </div>
      </Modal>
      
      <Modal
        isOpen={isAddToDashboardModalOpen}
        onClose={() => setIsAddToDashboardModalOpen(false)}
        title="Add Query to Dashboard"
        onSubmit={() => {}}
        showButtons={false}
      >
        <div className="mt-4 space-y-4">
          {dashboards.map((dashboard) => (
            <Button
              key={dashboard.id}
              onClick={() => handleAddQueryToDashboard(dashboard.id)}
              className="w-full"
            >
              {dashboard.name}
            </Button>
          ))}
          <Button
            onClick={() => {
              setIsAddToDashboardModalOpen(false);
              handleNewDashboard();
            }}
            className="w-full"
          >
            Create New Dashboard
          </Button>
        </div>
      </Modal>
      
      <EditDashboardModal
        isOpen={isEditDashboardModalOpen}
        onClose={() => setIsEditDashboardModalOpen(false)}
        dashboard={selectedDashboard}
        onUpdateDashboard={wrappedHandleUpdateDashboard}
        onDeleteDashboard={handleDeleteDashboard}
        currentUser={currentUser}
        availableQueries={filterAvailableQueries(selectedDashboard ? selectedDashboard.queries : [])}
      />
    </>
  );
};

export default DashboardModals;