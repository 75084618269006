// NewsDigest.js
import React, { useState, useEffect } from 'react';
import { AlertCircle, Clock, ChevronDown, ChevronUp } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

const NewsDigest = ({ digest, onView }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  
  useEffect(() => {
    if (isExpanded && !digest.viewed && onView) {
      onView();
    }
  }, [isExpanded, digest.viewed, onView]);
  
  if (!digest || !digest.content) return null;

  const formattedTime = digest.timestamp ? formatDistanceToNow(
    new Date(digest.timestamp.seconds * 1000),
    { addSuffix: true }
  ) : '';

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-gray-500 text-xl font-semibold">Your Daily Digest</h2>
        <div className="flex items-center text-sm text-gray-500">
          <Clock className="w-4 h-4 mr-1" />
          <span>Generated {formattedTime}</span>
        </div>
      </div>
      
      <div className="bg-white shadow-card rounded-lg border-l-4 border-secondary bg-gradient-to-r from-secondary/5 to-secondary/20">
        <div 
          className="px-6 py-5 cursor-pointer hover:bg-gray-50"
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <div className="flex items-center justify-between">
            <div className="flex items-center space-x-2">
              <AlertCircle className="w-5 h-5 text-secondary" />
              <span className="text-sm text-gray-500">
                Personalized based on your role and recent activity
              </span>
            </div>
            {isExpanded ? (
              <ChevronUp className="w-5 h-5 text-gray-400" />
            ) : (
              <ChevronDown className="w-5 h-5 text-gray-400" />
            )}
          </div>
        </div>
        
        {isExpanded && (
          <div className="px-6 py-5 border-t">
            <div className="prose prose-sm max-w-none">
              {digest.content.split('\n').map((line, index) => (
                <React.Fragment key={index}>
                  {line}
                  <br />
                </React.Fragment>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default NewsDigest;