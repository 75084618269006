import React, { useState, useCallback, useEffect } from 'react';
import { useDashboard } from '../../contexts/DashboardContext';
import { useConfig } from '../../contexts/ConfigContext';

const DashboardManager = ({ 
  dashboards,
  setDashboards,
  setError,
  userPreferences,
  setUserPreferences,
  handleQueryResult,
  setIsDashboardLoading,
  currentUser,
  isAdmin
}) => {
  const { selectedDashboard, updateSelectedDashboard, clearSelectedDashboard } = useDashboard();
  const { config, isLoading: isConfigLoading } = useConfig();


  const executeIndividualQuery = async (queryId, dashboardId) => {
    try {
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'executeQuery',
            reportData: { 
              queryId,
              dashboardId
            },
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        }),
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const queryData = await response.json();
      return queryData;
    } catch (error) {
      console.error(`Error executing query ${queryId}:`, error);
      throw error;
    }
  };
  
  // Function to save user preferences to state and localStorage
  const saveUserPreferences = useCallback((dashboardId, preferences) => {
    const updatedPreferences = {
      ...userPreferences,
      [dashboardId]: preferences,
    };
    setUserPreferences(updatedPreferences);
    localStorage.setItem('dashboardPreferences', JSON.stringify(updatedPreferences));
  }, [userPreferences, setUserPreferences]);

  // Function to handle saving card settings for a specific query
  const handleSaveCardSettings = useCallback((queryId, settings) => {
    if (!selectedDashboard) return;
  
    // Retrieve existing preferences for the dashboard
    const existingPreferences = userPreferences[selectedDashboard.id] || {
      queryOrder: selectedDashboard.queries.map(q => q.id),
      querySettings: {},
    };
  
    // Get existing query settings
    const existingQuerySettings = existingPreferences.querySettings[queryId] || {};
  
    // Update the specific query's settings, ensuring we keep all settings
    const updatedQuerySettings = {
      ...existingPreferences.querySettings,
      [queryId]: {
        ...existingQuerySettings,
        viewMode: settings.viewMode || existingQuerySettings.viewMode,
        chartType: settings.chartType || existingQuerySettings.chartType,
        selectedXAxis: settings.selectedXAxis || existingQuerySettings.selectedXAxis,
        selectedYAxis: settings.selectedYAxis || existingQuerySettings.selectedYAxis,
        syncScales: settings.syncScales !== undefined ? settings.syncScales : existingQuerySettings.syncScales,
        sortColumn: settings.sortColumn || existingQuerySettings.sortColumn,
        sortOrder: settings.sortOrder || existingQuerySettings.sortOrder,
        ...settings // Add any other new settings
      }
    };
  
    const updatedPreferences = {
      ...existingPreferences,
      querySettings: updatedQuerySettings
    };
  
    // Save the updated preferences
    saveUserPreferences(selectedDashboard.id, updatedPreferences);
  }, [selectedDashboard, userPreferences, saveUserPreferences]);

  // Function to fetch dashboards from backend
  const fetchDashboards = useCallback(async () => {
    if (!currentUser) {
      console.log('Current user not available, skipping dashboard fetch');
      return;
    }
    try {
      console.log('Fetching dashboards...');
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'get',
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
      const data = await response.json();
      console.log('Fetched dashboards:', data);
      setDashboards(data);
    } catch (error) {
      console.error('Error fetching dashboards:', error);
      setError('Failed to fetch dashboards. Please try again later.');
    }
  }, [currentUser, setDashboards, setError, isAdmin]);

  useEffect(() => {
    if (currentUser) {
      fetchDashboards();
    }
  }, [fetchDashboards, currentUser]);

  // Function to handle selecting a dashboard
// Function to handle selecting a dashboard
const handleSelectDashboard = useCallback(async (dashboard) => {
  console.log('Selecting dashboard:', dashboard);
  setIsDashboardLoading(true);
  setError(null);
  try {
    // First, fetch dashboard structure
    const structureResponse = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ 
        data: { 
          operation: 'getDashboardStructure',
          reportData: { 
            dashboardId: dashboard.id,
            incrementRefreshCount: true
          },
          userId: currentUser.email,
          isAdmin: isAdmin
        }
      }),
      credentials: 'include'
    });

    if (!structureResponse.ok) {
      throw new Error(`HTTP error! status: ${structureResponse.status}`);
    }

    const dashboardStructure = await structureResponse.json();
    if (dashboardStructure.error) {
      throw new Error(dashboardStructure.error);
    }

    // Initialize dashboard with loading states for each query
    const initializedQueries = dashboardStructure.queries.map(query => {
      // Get existing query settings from userPreferences
      const existingQuerySettings = userPreferences[dashboard.id]?.querySettings?.[query.id] || {};
      
      return {
        ...query,
        isLoading: true,
        result: null,
        schema: null,
        error: null,
        cardSettings: {
          viewMode: existingQuerySettings.viewMode || 'table',
          chartType: existingQuerySettings.chartType || 'bar',
          selectedXAxis: existingQuerySettings.selectedXAxis || null,
          selectedYAxis: existingQuerySettings.selectedYAxis || null,
          syncScales: existingQuerySettings.syncScales || false,
          sortColumn: existingQuerySettings.sortColumn || null,
          sortOrder: existingQuerySettings.sortOrder || 'asc',
          visibleColumns: existingQuerySettings.visibleColumns || [],
          columnOrder: existingQuerySettings.columnOrder || [],
          cardWidth: existingQuerySettings.cardWidth || '100%'
        }
      };
    });

    // Create initial dashboard with all necessary properties
    const initialDashboard = {
      ...dashboard,                // Include all original dashboard properties
      ...dashboardStructure,       // Include structure properties
      id: dashboard.id,            // Ensure ID is preserved
      name: dashboard.name,        // Ensure name is preserved
      userId: dashboard.userId,    // Ensure userId is preserved
      lastRefreshed: dashboardStructure.lastRefreshed || dashboard.lastRefreshed,
      refreshCount: dashboardStructure.refreshCount || dashboard.refreshCount,
      queries: initializedQueries  // Use our initialized queries
    };

    // Update the dashboard immediately with loading states
    updateSelectedDashboard(initialDashboard);
    setIsDashboardLoading(false);

    // Execute queries in parallel
    const queryPromises = initializedQueries.map(async (query) => {
      try {
        const queryResponse = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ 
            data: { 
              operation: 'executeQuery',
              reportData: { 
                queryId: query.id,
                dashboardId: dashboard.id
              },
              userId: currentUser.email,
              isAdmin: isAdmin
            }
          }),
          credentials: 'include'
        });

        const queryData = await queryResponse.json();
        
        // Update the dashboard with each query result as it arrives
        // while preserving existing card settings
        updateSelectedDashboard(current => ({
          ...current,
          queries: current.queries.map(q => {
            if (q.id === query.id) {
              const existingSettings = q.cardSettings || {};
              return {
                ...q,
                ...queryData,
                isLoading: false,
                error: queryData.error || null,
                cardSettings: {
                  ...existingSettings,
                  // Only set these if they don't already exist
                  visibleColumns: existingSettings.visibleColumns?.length 
                    ? existingSettings.visibleColumns 
                    : Object.keys(queryData.result?.[0] || {}),
                  columnOrder: existingSettings.columnOrder?.length
                    ? existingSettings.columnOrder
                    : Object.keys(queryData.result?.[0] || {})
                }
              };
            }
            return q;
          })
        }));

        return queryData;
      } catch (error) {
        console.error(`Error executing query ${query.id}:`, error);
        updateSelectedDashboard(current => ({
          ...current,
          queries: current.queries.map(q => 
            q.id === query.id 
              ? {
                  ...q,
                  isLoading: false,
                  error: error.message
                }
              : q
          )
        }));
        return null;
      }
    });

    await Promise.all(queryPromises);

  } catch (error) {
    console.error('Error fetching dashboard structure:', error);
    setError(`Failed to load dashboard: ${error.message}`);
    setIsDashboardLoading(false);
  }
}, [currentUser, updateSelectedDashboard, userPreferences, setError, setIsDashboardLoading, isAdmin]);

  // Function to handle creating a new dashboard
  const handleCreateDashboard = async (newDashboardName, selectedQueries) => {
    if (!currentUser) {
      setError('Please log in to create a new dashboard.');
      return;
    }
    try {
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'create',
            reportData: {
              name: newDashboardName,
              queries: selectedQueries
            },
            userId: currentUser.email
          }
        })
      });
      const data = await response.json();
      setDashboards(prevDashboards => [...prevDashboards, data]);
      handleSelectDashboard(data);
      return data;
    } catch (error) {
      console.error('Error creating dashboard:', error);
      setError('Failed to create dashboard. Please try again.');
    }
  };

  // Function to handle updating a dashboard
  const handleUpdateDashboard = async (updatedDashboard) => {
    try {
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'update',
            reportData: updatedDashboard,
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      if (data.error) {
        throw new Error(data.error);
      }
      handleSelectDashboard(updatedDashboard);

      const updatedPreferences = {
        ...userPreferences[updatedDashboard.id],
        queryOrder: updatedDashboard.queries.map(q => q.id),
      };
      saveUserPreferences(updatedDashboard.id, updatedPreferences);
    } catch (error) {
      console.error('Error updating dashboard:', error);
      setError(`Failed to update dashboard: ${error.message}`);
    }
  };

  // Function to handle deleting a dashboard
  const handleDeleteDashboard = useCallback(async (dashboardId) => {
    if (!currentUser) {
      setError('Please log in to delete a dashboard.');
      return;
    }
    try {
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'delete',
            reportData: { id: dashboardId },
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      await response.json();
      setDashboards(prevDashboards => prevDashboards.filter(d => d.id !== dashboardId));
      if (selectedDashboard && selectedDashboard.id === dashboardId) {
        clearSelectedDashboard();
      }
      const { [dashboardId]: _, ...remainingPreferences } = userPreferences;
      setUserPreferences(remainingPreferences);
      localStorage.setItem('dashboardPreferences', JSON.stringify(remainingPreferences));
    } catch (error) {
      console.error('Error deleting dashboard:', error);
      setError('Failed to delete dashboard. Please try again.');
    }
  }, [currentUser, selectedDashboard, clearSelectedDashboard, userPreferences, setUserPreferences, setDashboards, setError, isAdmin]);

  // Function to handle adding a query to a dashboard
  const handleAddQueryToDashboard = async (dashboardId, queryToAdd) => {
    try {
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'addQueryToDashboard',
            dashboardId: dashboardId,
            query: queryToAdd,
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
  
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
  
      const updatedDashboard = await response.json();
  
      if (selectedDashboard && selectedDashboard.id === dashboardId) {
        // Add the new query with loading state
        const newQuery = {
          ...queryToAdd,
          isLoading: true,
          error: null,
          result: null,
          schema: null,
          cardSettings: {
            viewMode: 'table',
            chartType: 'bar',
            selectedXAxis: null,
            selectedYAxis: null,
            syncScales: false,
            sortColumn: null,
            sortOrder: 'asc'
          }
        };
  
        updateSelectedDashboard(current => ({
          ...current,
          ...updatedDashboard,
          queries: [...(current.queries || []), newQuery]
        }));
  
        // Execute the new query
        try {
          const queryData = await executeIndividualQuery(queryToAdd.id, dashboardId);
          
          updateSelectedDashboard(current => ({
            ...current,
            queries: current.queries.map(q => 
              q.id === queryToAdd.id 
                ? {
                    ...q,
                    ...queryData,
                    isLoading: false,
                    error: queryData.error || null
                  }
                : q
            )
          }));
        } catch (error) {
          updateSelectedDashboard(current => ({
            ...current,
            queries: current.queries.map(q => 
              q.id === queryToAdd.id 
                ? {
                    ...q,
                    isLoading: false,
                    error: error.message
                  }
                : q
            )
          }));
        }
      }
  
      await fetchDashboards();
      return true;
    } catch (error) {
      console.error('Error adding query to dashboard:', error);
      setError(`Failed to add query to dashboard: ${error.message}`);
      return false;
    }
  };

  const handleRefreshDashboard = useCallback(async (dashboard) => {
    if (!selectedDashboard) return;
    
    try {
      // First, refresh the dashboard structure
      const structureResponse = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/handleDashboardOperation`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          data: { 
            operation: 'getDashboardStructure',
            reportData: { 
              dashboardId: dashboard.id,
              incrementRefreshCount: true
            },
            userId: currentUser.email,
            isAdmin: isAdmin
          }
        })
      });
  
      if (!structureResponse.ok) {
        throw new Error(`HTTP error! status: ${structureResponse.status}`);
      }
  
      const updatedStructure = await structureResponse.json();
  
      // Initialize fresh query objects with loading states, preserving ALL card settings
      const initializedQueries = updatedStructure.queries.map(query => {
        const existingQuery = selectedDashboard.queries.find(q => q.id === query.id);
        const cardSettings = existingQuery?.cardSettings || {
          viewMode: 'table',
          chartType: 'bar',
          selectedXAxis: null,
          selectedYAxis: null,
          syncScales: false,
          sortColumn: null,
          sortOrder: 'asc',
          cardWidth: '100%',
          visibleColumns: [],
          columnOrder: []
        };
        
        return {
          ...query,
          isLoading: true,
          error: null,
          result: null,
          schema: null,
          cardSettings: {
            ...cardSettings  // Preserve ALL existing card settings
          }
        };
      });
  
      // Create fresh dashboard state
      const freshDashboard = {
        ...selectedDashboard,
        ...updatedStructure,
        queries: initializedQueries,
        lastRefreshed: updatedStructure.lastRefreshed,
        refreshCount: updatedStructure.refreshCount,
        _forceRefresh: Date.now()
      };
  
      // Update with fresh state
      updateSelectedDashboard(freshDashboard);
  
      // Execute all queries in parallel
      const queryPromises = initializedQueries.map(async (query) => {
        try {
          const queryData = await executeIndividualQuery(query.id, dashboard.id);
          
          // Update query data while preserving existing card settings
          updateSelectedDashboard(current => ({
            ...current,
            queries: current.queries.map(q => {
              if (q.id === query.id) {
                const existingSettings = q.cardSettings || {};
                return {
                  ...q,
                  ...queryData,
                  isLoading: false,
                  error: queryData.error || null,
                  cardSettings: {
                    ...existingSettings,
                    // Only set these if they don't already exist
                    visibleColumns: existingSettings.visibleColumns?.length 
                      ? existingSettings.visibleColumns 
                      : Object.keys(queryData.result?.[0] || {}),
                    columnOrder: existingSettings.columnOrder?.length
                      ? existingSettings.columnOrder
                      : Object.keys(queryData.result?.[0] || {})
                  }
                };
              }
              return q;
            })
          }));
  
          return queryData;
        } catch (error) {
          updateSelectedDashboard(current => ({
            ...current,
            queries: current.queries.map(q => 
              q.id === query.id 
                ? {
                    ...q,
                    isLoading: false,
                    error: error.message
                  }
                : q
            )
          }));
          return null;
        }
      });
  
      await Promise.all(queryPromises);
  
      // Update dashboards list with new refresh count and timestamp
      setDashboards(prevDashboards => 
        prevDashboards.map(d => 
          d.id === dashboard.id 
            ? {
                ...d,
                lastRefreshed: updatedStructure.lastRefreshed,
                refreshCount: updatedStructure.refreshCount
              }
            : d
        )
      );
  
    } catch (error) {
      console.error('Error refreshing dashboard:', error);
      setError('Failed to refresh dashboard. Please try again.');
    }
  }, [selectedDashboard, updateSelectedDashboard, currentUser, isAdmin, setError, setDashboards, executeIndividualQuery]);

  return {
    fetchDashboards,
    handleSelectDashboard,
    handleCreateDashboard,
    handleUpdateDashboard,
    handleDeleteDashboard,
    handleAddQueryToDashboard,
    saveUserPreferences,
    handleSaveCardSettings,
    handleRefreshDashboard,
    executeIndividualQuery
  };
};

export default DashboardManager;