import React, { useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@mui/material';

const LookerStudioVis = ({ embedUrl, title, width = '100%', height = '100%', style }) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const iframeRef = useRef(null);

  useEffect(() => {
    const loadedReports = JSON.parse(sessionStorage.getItem('loadedReports') || '{}');
    setIsLoaded(loadedReports[embedUrl] || false);

    const handleBeforeUnload = () => {
      sessionStorage.removeItem('loadedReports');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [embedUrl]);

  const handleIframeLoad = () => {
    setIsLoaded(true);
    const loadedReports = JSON.parse(sessionStorage.getItem('loadedReports') || '{}');
    loadedReports[embedUrl] = true;
    sessionStorage.setItem('loadedReports', JSON.stringify(loadedReports));
  };

  return (
    <Box sx={{ width: '100%', height: '100%', overflow: 'hidden' }}>
      {!isLoaded && (
        <Box sx={{ width: '100%', height: '100%', backgroundColor: '#f0f0f0', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography variant="body1">Loading report...</Typography>
        </Box>
      )}
      <iframe
        ref={iframeRef}
        width={width}
        height={height}
        src={embedUrl}
        frameBorder="0"
        style={{ 
          border: 0, 
          display: isLoaded ? 'block' : 'none',
          ...style 
        }}
        allowFullScreen
        title={title}
        onLoad={handleIframeLoad}
        sandbox="allow-storage-access-by-user-activation allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
      ></iframe>
    </Box>
  );
};

export default LookerStudioVis;