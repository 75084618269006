import React, { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom';
import { X } from 'lucide-react';
import Button from './Button';
import ExplanationButton from './ExplanationButton';

const PopupCard = ({ isOpen, onClose, title, children, footer, className = '', contentClassName = '', onExplanationClick, isExplanationLoading, explanation, showExplanationButton = false }) => {
  const cardRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (cardRef.current && !cardRef.current.contains(event.target)) {
        onClose();
      }
    };

    const handleEscKey = (event) => {
      if (event.key === 'Escape') {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
      document.addEventListener('keydown', handleEscKey);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keydown', handleEscKey);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  // Render modal in a portal at the root level
  return ReactDOM.createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <div className="fixed inset-0 bg-black bg-opacity-50"></div>
      <div ref={cardRef} className={`bg-white rounded-lg shadow-xl relative z-50 ${contentClassName}`}>
        <div className="flex justify-between items-center p-4 border-b">
          <h2 className="text-card-title-sm font-semibold">{title}</h2>
          <Button
            onClick={onClose}
            icon={X}
            className="text-sm"
            aria-label="Close"
          />
        </div>
        <div className="p-4 max-h-[70vh] overflow-y-auto">
          {showExplanationButton && !explanation && (
            <ExplanationButton onClick={onExplanationClick} isLoading={isExplanationLoading} />
          )}
          {explanation && (
            <div className="mb-4 p-3 bg-gray-100 rounded-md">
              <h3 className="font-semibold mb-2">Explanation:</h3>
              <p>{explanation}</p>
            </div>
          )}
          {children}
        </div>
        {footer && (
          <div className="p-4 border-t flex justify-end">
            {footer}
          </div>
        )}
      </div>
    </div>,
    document.body
  );
};

export default PopupCard;