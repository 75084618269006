import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Wrench, Loader2 } from 'lucide-react';
import { doc, collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../firebase';
import FixesList from './FixesList';
import PromptModal from './PromptModal';

const FixManagement = forwardRef(({ 
  tableId, 
  columnName, 
  onFixOperationStatus, 
  onFixesCountChange,
  onFixApproved,
  onDataRefresh,
  user,
  isPromptModalOpen,
  setIsPromptModalOpen,
  isFixesExpanded,
  onToggleFixesExpand
}, ref) => {
  const [fixes, setFixes] = useState([]);
  const [isLoadingFixes, setIsLoadingFixes] = useState(false);
  const [loadingFixOperation, setLoadingFixOperation] = useState(null);
  const activeFixesCount = fixes.filter(fix => fix.metadata?.status === 'active').length;

  // Expose handleGenerateFix to parent component
  useImperativeHandle(ref, () => ({
    handleGenerateFix: handleGenerateFix
  }));

  // Subscribe to fixes collection for this column
  useEffect(() => {
    if (!tableId || !columnName) return;

    setIsLoadingFixes(true);
    const fixesQuery = query(
      collection(db, 'columnFixes'),
      where('tableId', '==', tableId),
      where('columnName', '==', columnName),
      orderBy('metadata.status'),
      orderBy('metadata.sequence', 'desc')
    );

    const unsubscribe = onSnapshot(fixesQuery, (snapshot) => {
      const fixesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setFixes(fixesData);
      onFixesCountChange?.(fixesData.length);
      setIsLoadingFixes(false);
    }, (error) => {
      console.error('Error fetching fixes:', error);
      setIsLoadingFixes(false);
    });

    return () => unsubscribe();
  }, [tableId, columnName]);

  const handleGenerateFix = async ({ name, prompt }) => {
    console.log('handleGenerateFix called with:', { name, prompt });
    
    if (!tableId || !columnName) {
      if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
        onFixOperationStatus('Generate', false, 'Missing table or column information');
      }
      throw new Error('Missing table or column information');
    }
  
    try {
      setLoadingFixOperation('generate');
      const generateColumnFix = httpsCallable(functions, 'generateColumnFix');
      
      const fixData = {
        tableId,
        columnName,
        userPrompt: prompt,
        displayName: name
      };
  
      console.log('Sending fix data:', fixData);
      
      const result = await generateColumnFix(fixData);
  
      if (result.data && result.data.success) {
        const saveColumnFix = httpsCallable(functions, 'saveColumnFix');
        await saveColumnFix({
          tableId,
          columnName,
          sqlTransformation: result.data.sqlTransformation,
          description: prompt,
          name,
          samples: result.data.preview,
          impact: result.data.impact
        });
        
        if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
          onFixOperationStatus('Generate', true, 'Fix generated and saved successfully!');
        }
        setIsPromptModalOpen(false);
        return result;
      } else {
        throw new Error(result.data?.error || 'Failed to generate change');
      }
    } catch (error) {
      console.error('Error generating fix:', error);
      if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
        onFixOperationStatus('Generate', false, `Error: ${error.message}`);
      }
      throw error;
    } finally {
      setLoadingFixOperation(null);
    }
  };

  const handleApproveFix = async (fixId) => {
    try {
      setLoadingFixOperation(`approve-${fixId}`);
      const approveColumnFix = httpsCallable(functions, 'approveColumnFix');
      const result = await approveColumnFix({ 
        fixId,
        adminEmail: user.email
      });
      
      if (result.data.success) {
        if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
          onFixOperationStatus(
            'Approve', 
            true, 
            `Fix approved successfully. Materialized view has been updated.`
          );
        }
        if (onFixApproved && typeof onFixApproved === 'function') {
          await onFixApproved();
        }
        // Refresh all data after successful approval
        if (onDataRefresh && typeof onDataRefresh === 'function') {
          await onDataRefresh();
        }
      } else {
        throw new Error(result.data.error || 'Failed to approve fix');
      }
    } catch (error) {
      console.error('Error approving fix:', error);
      if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
        onFixOperationStatus(
          'Approve', 
          false, 
          `Failed to approve fix: ${error.message}`
        );
      }
    } finally {
      setLoadingFixOperation(null);
    }
  };

  const handleRejectFix = async (fixId) => {
    try {
      setLoadingFixOperation(`reject-${fixId}`);
      const rejectColumnFix = httpsCallable(functions, 'rejectColumnFix');
      const result = await rejectColumnFix({ 
        fixId,
        adminEmail: user.email
      });
      
      if (result.data.success) {
        if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
          onFixOperationStatus('Reject', true, 'Fix rejected successfully');
        }
      } else {
        throw new Error(result.data.error || 'Failed to reject fix');
      }
    } catch (error) {
      console.error('Error rejecting fix:', error);
      if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
        onFixOperationStatus('Reject', false, `Failed to reject fix: ${error.message}`);
      }
    } finally {
      setLoadingFixOperation(null);
    }
  };

  const handleRevertFix = async (fixId) => {
    try {
      setLoadingFixOperation(`revert-${fixId}`);
      const revertColumnFix = httpsCallable(functions, 'revertColumnFix');
      const result = await revertColumnFix({ 
        fixId,
        adminEmail: user.email
      });
      
      if (result.data.success) {
        if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
          onFixOperationStatus('Revert', true, 'Fix reverted successfully');
        }
        if (onFixApproved && typeof onFixApproved === 'function') {
          await onFixApproved();
        }
        // Refresh all data after successful revert
        if (onDataRefresh && typeof onDataRefresh === 'function') {
          await onDataRefresh();
        }
      } else {
        throw new Error(result.data.error || 'Failed to revert fix');
      }
    } catch (error) {
      console.error('Error reverting fix:', error);
      if (onFixOperationStatus && typeof onFixOperationStatus === 'function') {
        onFixOperationStatus('Revert', false, `Failed to revert fix: ${error.message}`);
      }
    } finally {
      setLoadingFixOperation(null);
    }
  };

  return (
    <div className={`bg-gray-50 p-4 rounded ${fixes.length === 0 ? 'py-2' : 'py-4'}`}>
      <div className="flex items-center justify-between gap-2 cursor-pointer" onClick={() => onToggleFixesExpand()}>
        <div className="flex items-center gap-2">
          <Wrench className="h-4 w-4 text-gray-500" />
          <h4 className="text-sm font-medium">
            Column Changes {activeFixesCount > 0 && `(${activeFixesCount} active)`}
          </h4>
          {fixes.length === 0 && (
            <span className="text-xs text-gray-400 ml-2">No changes yet</span>
          )}
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsPromptModalOpen(true);
          }}
          className="px-3 py-1.5 text-xs text-white bg-primary hover:bg-primary/90 rounded shadow-sm flex items-center gap-1.5 transition-colors duration-150"
        >
          <Wrench className="h-3.5 w-3.5" />
          Change Column
        </button>
      </div>

      {isFixesExpanded && fixes.length > 0 && (
        <div className="mt-4">
          <FixesList
            fixes={fixes}
            onRevert={handleRevertFix}
            onApprove={handleApproveFix}
            onReject={handleRejectFix}
            loadingOperation={loadingFixOperation}
          />
        </div>
      )}

      {isPromptModalOpen && (
        <PromptModal
          isOpen={true}
          onClose={() => {
            setIsPromptModalOpen(false);
            setLoadingFixOperation(null);
          }}
          onSubmit={async (data) => {
            try {
              await handleGenerateFix(data);
            } catch (error) {
              console.error('Error in modal submit:', error);
            }
          }}
          title="Generate Column Fix"
          description={`Create a new fix for column "${columnName}"`}
          isLoading={loadingFixOperation === 'generate'}
        />
      )}
    </div>
  );
});

export default FixManagement;