import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase'; // Import Firestore
import { collection, query, where, orderBy, getDocs, addDoc, updateDoc, doc, getDoc } from 'firebase/firestore';
import { functions } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { Loader2, Plus, PlayCircle, Edit, Trash2, AlertCircle, Power } from 'lucide-react';
import Card from './Card';
import AgentForm from './AgentForm';
import Modal from './Modal';
import AgentRunResults from './AgentRunResults';

const AgentManagement = () => {
  const { currentUser: user } = useAuth();
  const [agents, setAgents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAgent, setSelectedAgent] = useState(null);
  const [models, setModels] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [agentToDelete, setAgentToDelete] = useState(null);
  const [saveStatus, setSaveStatus] = useState(null);
  const [runningAgents, setRunningAgents] = useState({});
  const [runResults, setRunResults] = useState(null);
  const [isRunResultsModalOpen, setIsRunResultsModalOpen] = useState(false);

  useEffect(() => {
    const initializeData = async () => {
      if (!user) {
        setIsLoading(false);
        return;
      }

      try {
        setError(null);

        // Fetch agents first
        await fetchAgents();

        // Then fetch models
        await fetchModels();
        
      } catch (err) {
        console.error('Initialization error:', err);
        setError('Failed to load agents. Please try again.');
      } finally {
        setIsLoading(false);
      }
    };

    console.log('Auth state:', user);
    initializeData();
  }, [user]);

  const fetchAgents = async () => {
    if (!user?.email) {
      console.error('No user email available');
      return;
    }
  
    try {
      setError(null);
      setIsLoading(true);
      console.log('Fetching agents...');
      
      const agentsRef = collection(db, 'agents');
      // Remove the isDeleted filter and just sort by createdAt
      const agentsQuery = query(
        agentsRef,
        orderBy('createdAt', 'desc')
      );
      
      const snapshot = await getDocs(agentsQuery);
      const agentsList = snapshot.docs.map(doc => {
        const data = doc.data();
        console.log('Raw agent data:', data); // Add this for debugging
        return {
          id: doc.id,
          name: data.name || '',
          description: data.description || '',
          model: data.model || '',
          prompt: data.prompt || '',
          status: data.status || 'disabled',
          createdAt: data.createdAt?.toDate?.() || null,
          updatedAt: data.updatedAt?.toDate?.() || null,
          lastRunAt: data.lastRunAt?.toDate?.() || null,
          createdBy: data.createdBy || '',
          isDeleted: !!data.isDeleted, // Convert to boolean
          schedule: {
            enabled: data.schedule?.enabled || false,
            interval: data.schedule?.interval || 60
          },
          contextConfig: {
            queryCount: data.contextConfig?.queryCount || 10,
            timeframeDays: data.contextConfig?.timeframeDays || 7,
            scope: data.contextConfig?.scope || 'all',
            userId: data.contextConfig?.userId || null
          },
          actions: {
            notifications: {
              enabled: data.actions?.notifications?.enabled || false,
              email: {
                enabled: data.actions?.notifications?.email?.enabled || false,
                recipients: data.actions?.notifications?.email?.recipients || [],
                includeOutput: data.actions?.notifications?.email?.includeOutput || false
              },
              inApp: {
                enabled: data.actions?.notifications?.inApp?.enabled || false,
                users: data.actions?.notifications?.inApp?.users || [],
                includeOutput: data.actions?.notifications?.inApp?.includeOutput || false
              }
            }
          }
        };
      });
  
      // Filter out deleted agents after fetching
      const filteredAgents = agentsList.filter(agent => !agent.isDeleted);
  
      console.log('Agents fetched successfully:', filteredAgents);
      setAgents(filteredAgents);
      
    } catch (err) {
      console.error('Error fetching agents:', err);
      setError(err.message || 'Failed to load agents. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  const fetchModels = async () => {
    if (!user?.email) {
      console.error('No user email available');
      return;
    }
  
    try {
      console.log('Fetching models...');
      const modelsRef = doc(db, 'DBI Configuration', 'Models');
      const modelsSnap = await getDoc(modelsRef);
      
      if (modelsSnap.exists()) {
        const modelsData = modelsSnap.data();
        const modelsList = Object.entries(modelsData).map(([name, config]) => ({
          name: name,
          id: config.Model,
          platform: config.Platform
        }));
        console.log('Models fetched successfully:', modelsList);
        setModels(modelsList);
      } else {
        console.error('Models configuration not found');
        throw new Error('Models configuration not found');
      }
    } catch (err) {
      console.error('Error fetching models:', err);
      setError('Failed to fetch models. Please try again.');
    }
};

  const handleCreateAgent = async (agentData) => {
    if (!user?.email) {
      setError('User authentication required');
      return;
    }
  
    try {
      setIsSubmitting(true);
      setError(null);
      
      const newAgentData = {
        name: agentData.name,
        description: agentData.description || '',
        model: agentData.model,
        prompt: agentData.prompt,
        status: 'active',
        createdAt: new Date(),
        updatedAt: new Date(),
        createdBy: user.email,
        isDeleted: false, // Add this line
        lastRunAt: null,
        schedule: {
          enabled: agentData.schedule?.enabled || false,
          interval: agentData.schedule?.interval || 60
        },
        contextConfig: {
          queryCount: agentData.contextConfig?.queryCount || 10,
          timeframeDays: agentData.contextConfig?.timeframeDays || 7,
          scope: agentData.contextConfig?.scope || 'all',
          userId: agentData.contextConfig?.userId || null
        },
        actions: {
          notifications: {
            enabled: agentData.actions?.notifications?.enabled || false,
            email: {
              enabled: agentData.actions?.notifications?.email?.enabled || false,
              recipients: agentData.actions?.notifications?.email?.recipients || [],
              includeOutput: agentData.actions?.notifications?.email?.includeOutput || false
            },
            inApp: {
              enabled: agentData.actions?.notifications?.inApp?.enabled || false,
              users: agentData.actions?.notifications?.inApp?.users || [],
              includeOutput: agentData.actions?.notifications?.inApp?.includeOutput || false
            }
          }
        }
      };

      const docRef = await addDoc(collection(db, 'agents'), newAgentData);
      
      await fetchAgents();
      setIsCreateModalOpen(false);
      setSaveStatus({ type: 'success', message: 'Agent created successfully' });
      setTimeout(() => setSaveStatus(null), 3000);
      
    } catch (err) {
      console.error('Error creating agent:', err);
      setError(err.message || 'Failed to create agent');
      setSaveStatus({ type: 'error', message: err.message || 'Failed to create agent' });
      setTimeout(() => setSaveStatus(null), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleUpdateAgent = async (agentData) => {
    if (!selectedAgent?.id || !user?.email) {
      setError('Invalid operation');
      return;
    }
  
    try {
      setIsSubmitting(true);
      setError(null);

      const agentRef = doc(db, 'agents', selectedAgent.id);
      const updateData = {
        ...agentData,
        updatedAt: new Date()
      };
      
      await updateDoc(agentRef, updateData);
      
      await fetchAgents();
      setIsEditModalOpen(false);
      setSelectedAgent(null);
      setSaveStatus({ type: 'success', message: 'Agent updated successfully' });
      setTimeout(() => setSaveStatus(null), 3000);
      
    } catch (err) {
      console.error('Error updating agent:', err);
      setError(err.message || 'Failed to update agent');
      setSaveStatus({ type: 'error', message: err.message || 'Failed to update agent' });
      setTimeout(() => setSaveStatus(null), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };


  const handleToggleStatus = async (agent) => {
    if (!user?.email) {
      setError('User authentication required');
      return;
    }

    const newStatus = agent.status === 'active' ? 'disabled' : 'active';

    try {
      setRunningAgents(prev => ({ ...prev, [agent.id]: true }));
      const updateAgent = httpsCallable(functions, 'updateAgent');
      const result = await updateAgent({
        agentId: agent.id,
        adminEmail: user.email,
        status: newStatus
      });
      
      const responseData = result.data;
      
      if (responseData?.success) {
        await fetchAgents();
        setSaveStatus({
          type: 'success',
          message: `Agent ${newStatus === 'active' ? 'enabled' : 'disabled'} successfully`
        });
        setTimeout(() => setSaveStatus(null), 3000);
      } else {
        throw new Error(responseData?.error || `Failed to ${newStatus === 'active' ? 'enable' : 'disable'} agent`);
      }
    } catch (err) {
      console.error('Error toggling agent status:', err);
      setError(err.message || `Failed to ${newStatus === 'active' ? 'enable' : 'disable'} agent`);
      setSaveStatus({ type: 'error', message: err.message || `Failed to ${newStatus === 'active' ? 'enable' : 'disable'} agent` });
      setTimeout(() => setSaveStatus(null), 3000);
    } finally {
      setRunningAgents(prev => ({ ...prev, [agent.id]: false }));
    }
  };

  const handleDeleteClick = (agent) => {
    setAgentToDelete(agent);
    setIsDeleteModalOpen(true);
  };

  const handleDeleteConfirm = async () => {
    if (!agentToDelete?.id || !user?.email) {
      setError('Invalid operation');
      return;
    }
  
    try {
      setIsSubmitting(true);
      const deleteAgent = httpsCallable(functions, 'deleteAgent');
      const result = await deleteAgent({
        agentId: agentToDelete.id,
        adminEmail: user.email
      });
  
      const responseData = result.data;
      
      if (responseData?.success) {
        await fetchAgents();
        setIsDeleteModalOpen(false);
        setAgentToDelete(null);
        if (responseData.message) {
          setSaveStatus({ 
            type: 'success', 
            message: `${responseData.message}${responseData.cancelledRuns ? ` (Cancelled ${responseData.cancelledRuns} active runs)` : ''}`
          });
          setTimeout(() => setSaveStatus(null), 3000);
        }
      } else {
        // Handle specific error cases
        if (responseData?.error === 'Agent already deleted') {
          await fetchAgents(); // Refresh the list to remove the deleted agent
          setIsDeleteModalOpen(false);
          setAgentToDelete(null);
          setSaveStatus({ 
            type: 'info', 
            message: 'This agent has already been deleted.'
          });
          setTimeout(() => setSaveStatus(null), 3000);
          return;
        }
        throw new Error(responseData?.error || responseData?.details || 'Failed to delete agent');
      }
    } catch (err) {
      console.error('Error deleting agent:', err);
      setError(err.message || 'Failed to delete agent');
      setSaveStatus({ 
        type: 'error', 
        message: err.message === 'Agent already deleted' 
          ? 'This agent has already been deleted.'
          : (err.message || 'Failed to delete agent')
      });
      setTimeout(() => setSaveStatus(null), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleRunAgent = async (agentId) => {
    if (!user?.email) {
      setError('User authentication required');
      return;
    }
  
    try {
      setRunningAgents(prev => ({ ...prev, [agentId]: true }));
      const runAgent = httpsCallable(functions, 'runAgent');
      const result = await runAgent({
        agentId,
        adminEmail: user.email
      });
      
      const responseData = result.data;  // Remove the nested .data access
      
      if (responseData?.success) {
        await fetchAgents();
        
        // Format detailed run statistics
        const successMessage = [
          responseData.message,
          `Successfully completed: ${responseData.successfulRuns}`,
          `Failed: ${responseData.failedRuns}`,
          responseData.totalRuns > 0 ? `Total runs: ${responseData.totalRuns}` : null
        ].filter(Boolean).join(' - ');
  
        setSaveStatus({ 
          type: 'success', 
          message: successMessage
        });
        
        // Store and display run results if available
        if (responseData.runs && responseData.runs.length > 0) {
          const formattedResults = responseData.runs.reduce((acc, run) => {
            acc[run.userId || 'global'] = run;
            return acc;
          }, {});
          
          setRunResults(formattedResults);
          setIsRunResultsModalOpen(true);
        }
        
        setTimeout(() => setSaveStatus(null), 3000);
      } else {
        throw new Error(responseData?.error || 'Failed to run agent');
      }
    } catch (err) {
      console.error('Error running agent:', err);
      setError(err.message || 'Failed to run agent');
      setSaveStatus({ type: 'error', message: err.message || 'Failed to run agent' });
      setTimeout(() => setSaveStatus(null), 3000);
    } finally {
      setRunningAgents(prev => ({ ...prev, [agentId]: false }));
    }
  };

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  if (!user) {
    return (
      <div className="flex items-center justify-center min-h-[200px]">
        <div className="text-center">
          <AlertCircle className="h-8 w-8 text-red-500 mx-auto mb-2" />
          <p className="text-gray-700">Please sign in to continue.</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-card-title-xl">Agents</h2>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-primary hover:bg-opacity-90 h-9 px-4 text-white"
        >
          <Plus className="h-4 w-4 mr-2" />
          Create Agent
        </button>
      </div>
  
      {error && (
        <div className="bg-red-50 text-red-500 p-4 rounded-md flex items-center">
          <AlertCircle className="h-5 w-5 mr-2" />
          {error}
        </div>
      )}
  
      {saveStatus && (
        <div className={`${
          saveStatus.type === 'success' ? 'bg-green-50 text-green-500' : 'bg-red-50 text-red-500'
        } p-4 rounded-md flex items-center`}>
          {saveStatus.type === 'success' ? (
            <svg className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          ) : (
            <AlertCircle className="h-5 w-5 mr-2" />
          )}
          {saveStatus.message}
        </div>
      )}
  
      <div className="grid grid-cols-1 gap-6">
        {agents.map((agent) => (
          <Card key={agent.id}>
            <div className={`p-6 ${agent.status === 'disabled' ? 'opacity-60' : ''}`}>
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
                    {agent.name}
                    {agent.status === 'disabled' && (
                      <span className="text-xs bg-gray-200 text-gray-700 px-2 py-1 rounded">
                        Disabled
                      </span>
                    )}
                  </h3>
                  <p className="text-sm text-gray-500 mt-1">
                    {agent.description || 'No description'}
                  </p>
                </div>
                  <div className="flex items-center space-x-2">
                  <button
                    onClick={() => handleToggleStatus(agent)}
                    disabled={runningAgents[agent.id]}
                    className={`inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 h-9 w-9 text-white
                      ${agent.status === 'active' ? 'bg-yellow-500 hover:bg-yellow-600' : 'bg-green-500 hover:bg-green-600'}`}
                    title={`${agent.status === 'active' ? 'Disable' : 'Enable'} Agent`}
                  >
                    {runningAgents[agent.id] ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <Power className="h-4 w-4" />
                    )}
                  </button>
                  <button
                    onClick={() => handleRunAgent(agent.id)}
                    disabled={agent.status !== 'active' || runningAgents[agent.id]}
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-green-500 hover:bg-opacity-90 h-9 w-9 text-white"
                    title="Run Agent"
                  >
                    {runningAgents[agent.id] ? (
                      <Loader2 className="h-4 w-4 animate-spin" />
                    ) : (
                      <PlayCircle className="h-4 w-4" />
                    )}
                  </button>
                  <button
                    onClick={() => {
                      setSelectedAgent(agent);
                      setIsEditModalOpen(true);
                    }}
                    disabled={runningAgents[agent.id]}
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-blue-500 hover:bg-opacity-90 h-9 w-9 text-white"
                    title="Edit Agent"
                  >
                    <Edit className="h-4 w-4" />
                  </button>
                  <button
                    onClick={() => handleDeleteClick(agent)}
                    disabled={runningAgents[agent.id]}
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-gray-950 disabled:pointer-events-none disabled:opacity-50 bg-red-500 hover:bg-opacity-90 h-9 w-9 text-white"
                    title="Delete Agent"
                  >
                    <Trash2 className="h-4 w-4" />
                  </button>
                </div>
              </div>
              
              <div className="mt-4 grid grid-cols-2 gap-4 text-sm">
                <div>
                  <span className="font-medium">Status: </span>
                  <span className={`inline-block px-2 py-1 rounded-full text-xs ${
                    agent.status === 'active' ? 'bg-green-100 text-green-800' : 'bg-gray-100 text-gray-800'
                  }`}>
                    {agent.status}
                  </span>
                </div>
                <div>
                  <span className="font-medium">Model: </span>
                  {agent.model}
                </div>
                <div>
                  <span className="font-medium">Schedule: </span>
                  {agent.schedule?.enabled 
                    ? `Every ${agent.schedule.interval} minutes`
                    : 'Manual only'}
                </div>
                <div>
                  <span className="font-medium">Last Run: </span>
                  {agent.lastRunAt 
                    ? new Date(agent.lastRunAt).toLocaleString()
                    : 'Never'}
                </div>
                <div>
                  <span className="font-medium">Notifications: </span>
                  {agent.actions?.notifications?.enabled ? (
                    <span className="text-xs">
                      {[
                        agent.actions.notifications.email?.enabled && 'Email',
                        agent.actions.notifications.inApp?.enabled && 'In-App'
                      ].filter(Boolean).join(', ')}
                    </span>
                  ) : (
                    <span className="text-gray-500">Disabled</span>
                  )}
                </div>
              </div>
            </div>
          </Card>
        ))}
      </div>
  
      <AgentForm
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={handleCreateAgent}
        isLoading={isSubmitting}
        models={models}
      />
  
      <AgentForm
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          setSelectedAgent(null);
        }}
        onSubmit={handleUpdateAgent}
        isLoading={isSubmitting}
        initialData={selectedAgent}
        models={models}
        isEdit={true}
      />
  
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setAgentToDelete(null);
        }}
        title="Delete Agent"
        onSubmit={handleDeleteConfirm}
        isLoading={isSubmitting}
        confirmText="Delete"
        confirmStyle="danger"
      >
        <div className="space-y-4">
          <p>Are you sure you want to delete this agent?</p>
          {agentToDelete && (
            <div className="bg-gray-50 p-4 rounded-md">
              <p className="font-medium">{agentToDelete.name}</p>
              <p className="text-sm text-gray-500 mt-1">{agentToDelete.description}</p>
            </div>
          )}
          <p className="text-sm text-red-600">This action cannot be undone.</p>
        </div>
      </Modal>
  
      <AgentRunResults 
        isOpen={isRunResultsModalOpen}
        onClose={() => {
          setIsRunResultsModalOpen(false);
          setRunResults(null);
        }}
        runs={runResults}
      />
    </div>
  );
};

export default AgentManagement;