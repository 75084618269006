import React, { useState, useEffect } from 'react';
import { ArrowRight, ChevronDown, ChevronUp, RefreshCw, History, Wrench, ChevronLeft, ChevronRight } from 'lucide-react';
import Card from '../Card';
import { doc, collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { db } from '../../firebase';
import FixManagement from './FixManagement';
import PromptModal from './PromptModal';

const ColumnComparison = ({
  originalColumn,
  newColumn,
  originalStats,
  materializedStats,
  fixes = [],
  onRefreshStats,
  isRefreshing,
  tableId,
  user,
  onFixOperationStatus,
  onFixApproved,
  onDataRefresh,
  newTable,
  isExpanded,
  onToggleExpand,
  isFixesExpanded,
  onToggleFixesExpand,
  isNewlyAdded = false
}) => {
  const leftColumnRef = React.useRef(null);
  
  // Track if this card initiated the expand action
  const [wasClicked, setWasClicked] = React.useState(false);

  React.useEffect(() => {
    if (isExpanded && wasClicked) {
      // Reset the clicked state
      setWasClicked(false);
      // Scroll after a brief delay to allow for DOM updates
      setTimeout(() => {
        if (leftColumnRef.current) {
          const yOffset = -20;
          const y = leftColumnRef.current.getBoundingClientRect().top + window.pageYOffset + yOffset;
          window.scrollTo({ top: y, behavior: 'smooth' });
        }
      }, 50);
    }
  }, [isExpanded, wasClicked]);

  const [fixesCount, setFixesCount] = useState(0);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(null);
  const activeFixes = fixes && fixes.filter(fix => fix.metadata?.status === 'active') || [];
  const hasActiveFixes = activeFixes.length > 0;  
  const fixManagementRef = React.useRef(null);

  useEffect(() => {
    if (!tableId || !originalColumn.name) return;

    const fixesQuery = query(
      collection(db, 'columnFixes'),
      where('tableId', '==', tableId),
      where('columnName', '==', originalColumn.name),
      orderBy('metadata.status'),
      orderBy('metadata.sequence', 'desc')
    );

    const unsubscribe = onSnapshot(fixesQuery, (snapshot) => {
      const fixesData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setFixesCount(fixesData.length);
    }, (error) => {
      console.error('Error fetching fixes count:', error);
    });

    return () => unsubscribe();
  }, [tableId, originalColumn.name]);

  const formatSampleValue = (sample, type) => {
    const sampleValue = sample?.value || sample;
    
    if (sampleValue === null) return 'NULL';
    
    if (type.includes('TIMESTAMP') || type.includes('DATETIME') || type.includes('DATE')) {
      try {
        const date = new Date(sampleValue);
        if (!isNaN(date.getTime())) {
          return type.includes('TIME') ? date.toLocaleString() : date.toLocaleDateString();
        }
      } catch (error) {
        // Fall through to default handling
      }
    }
    
    return typeof sampleValue === 'object' ? JSON.stringify(sampleValue) : String(sampleValue);
  };

  const renderTypeSpecificStats = (stats) => {
    if (!stats) return null;
    
    const { nullCount, uniqueCount } = stats;
    
    if (stats.numeric) {
      const { min, max, mean, histogram } = stats.numeric;
      return (
        <div className="space-y-4">
          <div className="flex items-center justify-between space-x-4">
            <div>
              <p className="text-xs text-gray-500">Unique</p>
              <p className="text-sm font-semibold">{uniqueCount?.toLocaleString()}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Null</p>
              <p className="text-sm font-semibold">{nullCount?.toLocaleString()}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Min</p>
              <p className="text-sm font-semibold">{min?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Avg</p>
              <p className="text-sm font-semibold">{mean?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
            </div>
            <div>
              <p className="text-xs text-gray-500">Max</p>
              <p className="text-sm font-semibold">{max?.toLocaleString(undefined, { maximumFractionDigits: 2 })}</p>
            </div>
          </div>
          
          {histogram && (
            <div className="mt-4">
              <div className="flex">
                {/* Y-axis labels with actual counts */}
                <div className="w-12 flex flex-col justify-between text-xs text-gray-500 pr-1">
                  {(() => {
                    const maxCount = Math.max(...histogram.map(b => b.count));
                    return [1, 0.75, 0.5, 0.25, 0].map((fraction, idx) => (
                      <span key={idx} className="text-right">
                        {Math.round(maxCount * fraction).toLocaleString()}
                      </span>
                    ));
                  })()}
                </div>
                <div className="flex-1 overflow-hidden">
                  <div className="h-40 w-full">
                    {/* Histogram bars */}
                    <div className="flex h-[85%] items-end gap-0.5 overflow-x-auto">
                      {histogram.map((bucket, idx) => (
                        <div
                          key={idx}
                          className="group relative flex-1 bg-blue-500 hover:bg-blue-600 rounded-t transition-colors duration-150"
                          style={{ 
                            height: `${(bucket.count / Math.max(...histogram.map(b => b.count)) * 100)}%`,
                            minWidth: '16px',
                            maxWidth: '24px'
                          }}
                        >
                          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 px-2 py-1 bg-gray-900 text-white text-xs rounded opacity-0 group-hover:opacity-100 whitespace-nowrap pointer-events-none transition-opacity duration-150 z-10">
                            Range: {Number(bucket.min).toLocaleString(undefined, { maximumFractionDigits: 2 })} - {Number(bucket.max).toLocaleString(undefined, { maximumFractionDigits: 2 })}
                            <br />
                            Count: {bucket.count?.toLocaleString()}
                          </div>
                        </div>
                      ))}
                    </div>
                    
                    {/* X-axis labels */}
                    <div className="h-[15%] flex items-center">
                      <div className="flex-1 flex justify-between px-1">
                        <span className="text-[10px] text-gray-500">
                          {Number(histogram[0]?.min).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </span>
                        <span className="text-[10px] text-gray-500">
                          {Number(histogram[Math.floor(histogram.length / 2)]?.min).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </span>
                        <span className="text-[10px] text-gray-500">
                          {Number(histogram[histogram.length - 1]?.max).toLocaleString(undefined, { maximumFractionDigits: 0 })}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    }
    
    if (stats.string) {
      const { minLength, maxLength, avgLength } = stats.string;
      return (
        <div className="flex items-center justify-between space-x-4">
          <div>
            <p className="text-xs text-gray-500">Unique</p>
            <p className="text-sm font-semibold">{uniqueCount?.toLocaleString()}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Null</p>
            <p className="text-sm font-semibold">{nullCount?.toLocaleString()}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Min Length</p>
            <p className="text-sm font-semibold">{minLength}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Avg Length</p>
            <p className="text-sm font-semibold">{avgLength?.toFixed(1)}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Max Length</p>
            <p className="text-sm font-semibold">{maxLength}</p>
          </div>
        </div>
      );
    }
  
    if (stats.date) {
      const { earliest, latest, rangeDays } = stats.date;
      return (
        <div className="flex items-center justify-between space-x-4">
          <div>
            <p className="text-xs text-gray-500">Unique</p>
            <p className="text-sm font-semibold">{uniqueCount?.toLocaleString()}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Null</p>
            <p className="text-sm font-semibold">{nullCount?.toLocaleString()}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Earliest</p>
            <p className="text-sm font-semibold">{new Date(earliest).toLocaleDateString()}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Latest</p>
            <p className="text-sm font-semibold">{new Date(latest).toLocaleDateString()}</p>
          </div>
          <div>
            <p className="text-xs text-gray-500">Range (days)</p>
            <p className="text-sm font-semibold">{rangeDays}</p>
          </div>
        </div>
      );
    }
  
    return (
      <div className="flex items-center justify-between space-x-4">
        <div>
          <p className="text-xs text-gray-500">Unique</p>
          <p className="text-sm font-semibold">{uniqueCount?.toLocaleString()}</p>
        </div>
        <div>
          <p className="text-xs text-gray-500">Null</p>
          <p className="text-sm font-semibold">{nullCount?.toLocaleString()}</p>
        </div>
      </div>
    );
  };

  const renderStats = (stats) => {
    if (!stats) return null;
    
    return (
      <div className="space-y-6">
        {renderTypeSpecificStats(stats)}
        {/* Value distribution for string type */}
        {stats?.valueDistribution && !stats.numeric && (
          <div className="mt-4">
            <p className="text-sm font-medium mb-2">Top Values Distribution</p>
            <div className="space-y-2">
              {stats.valueDistribution.slice(0, 5).map((item, idx) => (
                <div key={idx} className="flex items-center gap-2">
                  <div className="w-24 truncate text-sm">{item.value}</div>
                  <div className="flex-1 h-4 bg-gray-100 rounded-full overflow-hidden">
                    <div
                      className="h-full bg-blue-500 rounded-full"
                      style={{ width: `${item.percentage}%` }}
                    />
                  </div>
                  <div className="w-16 text-sm text-right">{item.percentage}%</div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderColumnContent = (column, side = 'left') => {
    const isOriginalSide = side === 'left';
    const columnStats = isOriginalSide ? originalStats : materializedStats;
    const hasNoData = !isOriginalSide && (!newTable || !column.samples || column.samples.length === 0);
    
    const gradientClass = hasNoData ? 'bg-gray-50' : 
      isNewlyAdded ? 'bg-gradient-to-r from-primary/10 to-background' :
      (!isOriginalSide && hasActiveFixes && fixes.length > 0) ? 'bg-gradient-to-r from-secondary/10 to-background' :
      (!isOriginalSide && !hasActiveFixes) ? 'bg-white opacity-50' : 'bg-white';

    return (
      <div
        ref={leftColumnRef}
        className={`p-4 ${gradientClass} rounded-lg cursor-pointer ...`}
        onClick={(e) => {
          if (isOriginalSide) {
            setWasClicked(true);
            onToggleExpand();
          }
        }}
      >
        <div className="flex justify-between items-start">
          <div className="flex items-start gap-4">
            {isOriginalSide && (
              <div
                className="p-1 -ml-2"
                title={isExpanded ? "Collapse all" : "Expand all"}
              >
                {isExpanded ? (
                  <ChevronLeft className="h-4 w-4 text-gray-500" />
                ) : (
                  <ChevronRight className="h-4 w-4 text-gray-500" />
                )}
              </div>
            )}
            <div>
              <h3 className="text-lg font-semibold">{column.name}</h3>
              <div className="space-y-1">
              <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
                  {column.type}
                </span>
                {!isExpanded && column.description && (
                  <p className="text-sm text-gray-500 line-clamp-1">{column.description}</p>
                )}
              </div>
            </div>
          </div>
          <div className="flex items-center space-x-2">
            {isExpanded && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  onRefreshStats(isOriginalSide);
                }}
                disabled={isRefreshing}
                className="p-2 text-gray-500 hover:text-gray-700 rounded-full"
              >
                <RefreshCw className={`h-4 w-4 ${isRefreshing ? 'animate-spin' : ''}`} />
              </button>
            )}
            {!isOriginalSide && (
              hasActiveFixes ? (
                <div className="px-3 py-1 text-sm bg-green-100 text-green-800 rounded-full border border-green-200 shadow-sm">
                    {activeFixes.length} change{activeFixes.length !== 1 ? 's' : ''}
                </div>
              ) : isNewlyAdded ? (
                <div className="px-3 py-1 text-sm bg-green-100 text-green-800 rounded-full border border-green-200 shadow-sm">
                  <span>Added</span>
                </div>
              ) : (
                <div className="bg-white px-3 py-1 rounded-full border border-primary/20 shadow-sm">
                  <span className="text-sm text-primary">Identical</span>
                </div>
              )
            )}
          </div>
        </div>
  
        {isExpanded && (
          <>
            {/* Description */}
            {column.description && (
              <p className="text-sm text-gray-500 mt-1">{column.description}</p>
            )}
    
            {/* Stats Section */}
            {columnStats && (
              <div className="mt-4 bg-gray-50 p-4 rounded-lg">
                {renderStats(columnStats)}
              </div>
            )}
    
            {/* Sample Values */}
            <div className="mt-4">
              <div className="flex items-center gap-2 mb-2">
                <History className="h-4 w-4 text-gray-500" />
                <p className="text-sm font-medium">Sample Values</p>
              </div>
              <div className="space-y-2">
                {column.samples?.slice(0, 3).map((sample, idx) => (
                  <div key={idx} className="text-sm p-2 bg-gray-50 rounded truncate">
                    {formatSampleValue(sample, column.type)}
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
      </div>
    );
  };

  const handlePromptSubmit = async (data) => {
    try {
      // Call the handleGenerateFix function from FixManagement
      if (fixManagementRef.current) {
        await fixManagementRef.current.handleGenerateFix(data);
      }
    } catch (error) {
      console.error('Error in modal submit:', error);
      onFixOperationStatus('Generate', false, error.message);
    }
  };

  const renderPlaceholder = () => (
    <div className="p-4 bg-gray-50 rounded-lg h-full flex flex-col items-center justify-center text-gray-400">
      <div className="text-center">
        <p className="text-sm font-medium mb-2">Initialize Table</p>
        <p className="text-xs">Click 'Start Now' to create a duplicate table for transformations</p>
      </div>
    </div>
  );

  return (
    <Card className="mb-4 overflow-hidden">
    <div className="relative">        
        <div className="grid grid-cols-2 divide-x">
          {renderColumnContent(originalColumn, 'left')}
          {newTable ? (
            isExpanded ? 
            renderColumnContent(newColumn, 'right') : (
              <div className="relative">
                <div className={`absolute inset-0 ${
                  isNewlyAdded ? 'bg-gradient-to-r from-primary/10 to-background' :
                  hasActiveFixes && fixes.length > 0 ? 'bg-gradient-to-r from-secondary/10 to-background' : 
                  'bg-gray-50'
                } rounded-lg`} />
                <div className="relative p-4 h-full flex justify-between items-start z-10">
                  <div>
                    <h3 className="text-lg font-semibold">{newColumn.name}</h3>
                    <div className="space-y-1">
                    <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
                        {newColumn.type}
                      </span>
                      {newColumn.description && (
                        <p className="text-sm text-gray-500 mt-1 line-clamp-1">{newColumn.description}</p>
                      )}
                    </div>
                  </div>
                  <div>
                    {hasActiveFixes && activeFixes.length > 0 ? (
                      <div className="px-3 py-1 text-sm bg-green-100 text-green-800 rounded-full border border-green-200 shadow-sm">
                        {activeFixes.length} change{activeFixes.length !== 1 ? 's' : ''}
                      </div>
                    ) : isNewlyAdded ? (
                      <div className="px-3 py-1 text-sm bg-green-100 text-green-800 rounded-full border border-green-200 shadow-sm">
                        <span>Added</span>
                      </div>
                    ) : (
                      <div className="bg-white px-3 py-1 rounded-full border border-primary/20 shadow-sm">
                        <span className="text-sm text-primary">Identical</span>
                      </div>
                    )}
                  </div>
                </div>
            </div>
            )
          ) : renderPlaceholder()}
        </div>
        {isExpanded && newTable && !isNewlyAdded && (
          <FixManagement
            ref={fixManagementRef}
            tableId={tableId}
            columnName={originalColumn.name}
            onFixOperationStatus={onFixOperationStatus}
            onFixesCountChange={setFixesCount}
            onFixApproved={onFixApproved}
            user={user}
            isPromptModalOpen={isPromptModalOpen}
            setIsPromptModalOpen={setIsPromptModalOpen}
            onDataRefresh={onDataRefresh}
            isFixesExpanded={isFixesExpanded}
            onToggleFixesExpand={onToggleFixesExpand}
            isNewlyAdded={isNewlyAdded}
          />
        )}
      </div>
    </Card>
  );
};

export default ColumnComparison;