// File: src/hooks/useFollowUpQuery.js

import { useState, useCallback } from 'react';

const useFollowUpQuery = (onSubmit) => {
  const [selectedText, setSelectedText] = useState('');
  const [selectedColumns, setSelectedColumns] = useState([]);  
  const [showIntermediatePopup, setShowIntermediatePopup] = useState(false);
  const [intermediatePopupPosition, setIntermediatePopupPosition] = useState({ x: 0, y: 0 });
  const [showFollowUpInput, setShowFollowUpInput] = useState(false);
  const [isFromHighlight, setIsFromHighlight] = useState(false);

  const handleTextSelection = useCallback((selectionData) => {
    if (selectionData.text) {
      setSelectedText(selectionData.text);
      setSelectedColumns([...new Set(selectionData.columns)]); // Deduplicate using Set
      setIntermediatePopupPosition(selectionData.position || {
        x: window.innerWidth / 2,
        y: window.innerHeight / 2
      });
      setShowIntermediatePopup(true);
      setIsFromHighlight(true);
    } else {
      setShowIntermediatePopup(false);
    }
  }, []);

  const handleIntermediatePopupClick = useCallback((event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowIntermediatePopup(false);
    setTimeout(() => setShowFollowUpInput(true), 0);
  }, []);


  const handleFollowUpSubmit = useCallback(async (submittedQuery, selectionData) => {
    if (onSubmit && typeof onSubmit === 'function') {
      const columnConfig = selectionData?.columnConfig;
      
      await onSubmit(submittedQuery, { 
        text: selectedText, 
        columns: selectedColumns || [],
        isFollowUp: true,
        shouldResetTranspose: true,
        isTransposed: false,
        columnConfig: {
          visibleColumns: [], // We'll determine this from hiddenColumns in QueryComponent
          hiddenColumns: columnConfig?.visibleColumns 
            ? Object.keys(selectionData?.result?.[0] || {}).filter(col => 
                columnConfig.visibleColumns && !columnConfig.visibleColumns.includes(col)
              ) 
            : [], // These are the columns we explicitly want to hide
          columnOrder: columnConfig?.columnOrder || [],
          showSummary: columnConfig?.showSummary ?? true,
          viewMode: columnConfig?.viewMode || 'table',
          chartType: columnConfig?.chartType || 'bar',
          syncScales: columnConfig?.syncScales || false,
          selectedXAxis: columnConfig?.selectedXAxis || '',
          selectedYAxis: columnConfig?.selectedYAxis || [],
          sortColumn: columnConfig?.sortColumn || '',
          sortOrder: columnConfig?.sortOrder || 'asc'
        }
      });
    
    // Reset follow-up state
    setShowFollowUpInput(false);
    setSelectedText('');
    setSelectedColumns([]);
    setIsFromHighlight(false);
    setShowIntermediatePopup(false);
    
    // Scroll to top after state reset
    window.dispatchEvent(new Event('followUpQuery'));
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }
}, [onSubmit, selectedText, selectedColumns]);

  const formatColumnName = (columnName) => {
    return columnName
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const handleClickOutside = useCallback((event, modalRef) => {
    if (modalRef.current && !modalRef.current.contains(event.target) && !event.target.closest('.intermediate-popup')) {
      setShowFollowUpInput(false);
      setShowIntermediatePopup(false);
      setSelectedText('');
      setIsFromHighlight(false);
    }
  }, []);

  const handleEscKey = useCallback((event) => {
    if (event.key === 'Escape') {
      setShowFollowUpInput(false);
      setShowIntermediatePopup(false);
      setSelectedText('');
      setIsFromHighlight(false);
    }
  }, []);

  const resetFollowUpState = useCallback(() => {
    setSelectedText('');
    setSelectedColumns([]);
    setIsFromHighlight(false);
    setShowIntermediatePopup(false);
  }, []);

  return {
    selectedText,
    selectedColumns,
    showIntermediatePopup,
    intermediatePopupPosition,
    showFollowUpInput,
    isFromHighlight,
    handleTextSelection,
    handleIntermediatePopupClick,
    handleFollowUpSubmit,
    handleClickOutside,
    handleEscKey,
    setShowFollowUpInput,
    resetFollowUpState
  };
};

export default useFollowUpQuery;