import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Loader2, AlertCircle, RefreshCw, Check, Clock } from 'lucide-react';
import { useConfig } from '../contexts/ConfigContext';

const TrackingManagement = ({ table, onStatusUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [trackedColumns, setTrackedColumns] = useState([]);
  const [tableName, setTableName] = useState(null);
  const [fetchingNews, setFetchingNews] = useState(false);
  const { config } = useConfig();

  // Fetch initial tracking configuration
  useEffect(() => {
    const fetchTrackingConfig = async () => {
      try {
        setLoading(true);
        setError(null);
     
        // Get the table name from the configuration
        const tablesDoc = await getDoc(doc(db, 'appConfiguration', 'tables'));
        const tablesData = tablesDoc.data();
        const foundTableName = Object.keys(tablesData).find(name => tablesData[name].id === table.id);
        setTableName(foundTableName);
  
        // Get the tracking configuration
        const trackingDoc = await getDoc(doc(db, 'appConfiguration', 'tracking'));
        const trackingConfig = trackingDoc.exists() ? trackingDoc.data() : {};
        const tableConfig = trackingConfig[foundTableName] || { id: table.id, columns: [] };
        setTrackedColumns(tableConfig.columns || []);
   
      } catch (err) {
        console.error('Error fetching tracking configuration:', err);
        setError('Failed to load tracking configuration');
      } finally {
        setLoading(false);
      }
    };
   
    fetchTrackingConfig();
  }, [table.id]);

  const handleToggleColumn = async (columnName) => {
    try {
      setError(null);
  
      const trackingDoc = await getDoc(doc(db, 'appConfiguration', 'tracking'));
      const trackingConfig = trackingDoc.exists() ? trackingDoc.data() : {};
      const currentColumns = (trackingConfig[tableName]?.columns) || [];
  
      let updatedColumns;
      if (currentColumns.includes(columnName)) {
        updatedColumns = currentColumns.filter(col => col !== columnName);
      } else {
        updatedColumns = [...currentColumns, columnName];
      }
  
      await updateDoc(doc(db, 'appConfiguration', 'tracking'), {
        [tableName]: {
          id: table.id,
          columns: updatedColumns
        }
      });
  
      setTrackedColumns(updatedColumns);
      
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'success',
          message: `Column ${columnName} ${updatedColumns.includes(columnName) ? 'added to' : 'removed from'} tracking`
        });
      }
   
    } catch (err) {
      console.error('Error updating tracking configuration:', err);
      setError('Failed to update tracking configuration');
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'error',
          message: 'Failed to update tracking configuration'
        });
      }
    }
  };

  const handleFetchNews = async () => {
    try {
      setFetchingNews(true);
      setError(null);

      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/triggerNewsFetch`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: {} })
      });

      if (!response.ok) {
        throw new Error(`Failed to trigger news fetch: ${response.statusText}`);
      }

      const result = await response.json();
      
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'success',
          message: 'News fetch triggered successfully'
        });
      }

    } catch (err) {
      console.error('Error triggering news fetch:', err);
      setError('Failed to trigger news fetch');
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'error',
          message: 'Failed to trigger news fetch'
        });
      }
    } finally {
      setFetchingNews(false);
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center space-x-2 text-red-500 py-4">
        <AlertCircle className="h-5 w-5" />
        <p className="text-sm">{error}</p>
      </div>
    );
  }

  return (
    <div>
      {/* Header with Fetch News button */}
      <div className="flex justify-between items-center mb-4 px-4 py-2 bg-gray-50 rounded-t-lg">
        <button
          onClick={handleFetchNews}
          disabled={fetchingNews}
          className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white
            ${fetchingNews 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-primary hover:bg-primary/90'}`}
        >
          {fetchingNews ? (
            <>
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              Fetching News...
            </>
          ) : (
            <>
              <RefreshCw className="h-4 w-4 mr-2" />
              Fetch News
            </>
          )}
        </button>
      </div>

      {/* Main Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                Column Name
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                Type
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                Track News
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {table.schema.map((field, index) => (
              <tr key={index} className="hover:bg-gray-50 transition-colors">
                <td className="px-4 py-3 text-sm font-medium text-gray-900">
                  {field.name}
                </td>
                <td className="px-4 py-3 text-sm text-gray-600">
                  {field.type}
                </td>
                <td className="px-4 py-3 text-sm">
                  <button
                    onClick={() => handleToggleColumn(field.name)}
                    className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 ${
                      trackedColumns.includes(field.name) ? 'bg-primary' : 'bg-gray-200'
                    }`}
                  >
                    <span
                      className={`inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                        trackedColumns.includes(field.name) ? 'translate-x-5' : 'translate-x-0'
                      }`}
                    />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TrackingManagement;