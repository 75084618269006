import { useState, useEffect } from 'react';

const useRecentSearches = (fetchRecentSearches, maxDisplayedSearches = 10) => {
  const [recentSearches, setRecentSearches] = useState([]);
  const [filteredRecentSearches, setFilteredRecentSearches] = useState([]);

  // Filter recent searches based on input query
  const handleSearchFiltering = (query, searches = recentSearches) => {
    if (!Array.isArray(searches)) {
      console.warn('Received invalid searches:', searches);
      return setFilteredRecentSearches([]);
    }

    const filtered = searches.filter(search => {
      if (!search || typeof search.userQuery !== 'string') {
        console.warn('Invalid search entry:', search);
        return false;
      }
      try {
        return search.userQuery.toLowerCase().includes((query || '').toLowerCase());
      } catch (error) {
        console.warn('Error filtering search:', error);
        return false;
      }
    });
    setFilteredRecentSearches(filtered.slice(0, maxDisplayedSearches));
  };

  // Load searches from localStorage
  const loadFromLocalStorage = () => {
    const storedSearches = localStorage.getItem('recentSearches');
    if (storedSearches) {
      try {
        const parsedSearches = JSON.parse(storedSearches);
        setRecentSearches(parsedSearches);
        // For empty query, show all searches up to max limit
        setFilteredRecentSearches(parsedSearches.slice(0, maxDisplayedSearches));
      } catch (error) {
        console.warn('Error parsing stored searches:', error);
        setRecentSearches([]);
        setFilteredRecentSearches([]);
      }
    }
  };

  // Refresh searches from backend
  const refreshRecentSearches = async () => {
    const searches = await fetchRecentSearches();
    if (searches) {
      setRecentSearches(searches);
      handleSearchFiltering('', searches);
      localStorage.setItem('recentSearches', JSON.stringify(searches));
    }
    return searches;
  };

  // Clear recent searches and reload
  const clearRecentSearches = async () => {
    setRecentSearches([]);
    setFilteredRecentSearches([]);
    
    // Load from localStorage first
    loadFromLocalStorage();
    
    // Then fetch fresh data
    const searches = await fetchRecentSearches();
    if (searches) {
      setRecentSearches(searches);
      handleSearchFiltering('', searches);
      localStorage.setItem('recentSearches', JSON.stringify(searches));
    }
  };

  // Initial load effect
  useEffect(() => {
    loadFromLocalStorage();
    refreshRecentSearches();
  }, []);

  return {
    recentSearches,
    filteredRecentSearches,
    handleSearchFiltering,
    refreshRecentSearches,
    clearRecentSearches,
  };
};

export default useRecentSearches;