import React, { useState, useEffect } from 'react';
import { Newspaper, ChevronDown, ChevronUp, Search, Clock } from 'lucide-react';
import { db } from '../firebase';
import { collection, query, where, getDocs, Timestamp } from 'firebase/firestore';

const CompanyNews = () => {
  const [allCompanyNews, setAllCompanyNews] = useState([]);
  const [filteredCompanyNews, setFilteredCompanyNews] = useState([]);
  const [categoryNews, setCategoryNews] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [expandedCards, setExpandedCards] = useState({});
  const [expandedCategories, setExpandedCategories] = useState({});

  const formatCompanyName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ')
      .replace('Category ', '');
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate ? timestamp.toDate() : new Date(timestamp);
    return new Intl.RelativeTimeFormat('en', { numeric: 'auto' }).format(
      Math.ceil((date - new Date()) / (1000 * 60 * 60 * 24)),
      'day'
    );
  };

  const toggleCard = (companyId, event) => {
    if (event.target.closest('a')) return;
    setExpandedCards(prev => ({
      ...prev,
      [companyId]: !prev[companyId]
    }));
  };

  const toggleCategory = (category, event) => {
    if (event.target.closest('a')) return;
    setExpandedCategories(prev => ({
      ...prev,
      [category]: !prev[category]
    }));
  };

  useEffect(() => {
    const fetchAllNews = async () => {
      try {
        setLoading(true);
        setError(null);

        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        const timestamp = Timestamp.fromDate(threeDaysAgo);

        const newsQuery = query(
          collection(db, 'news'),
          where('lastUpdated', '>=', timestamp)
        );

        const querySnapshot = await getDocs(newsQuery);
        const companyNewsData = [];
        const categoryNewsMap = {};

        querySnapshot.forEach((doc) => {
          const newsItem = {
            id: doc.id,
            ...doc.data()
          };
          
          if (doc.id.startsWith('category_')) {
            const categoryName = formatCompanyName(doc.id);
            categoryNewsMap[categoryName] = {
              articles: newsItem.articles || [],
              lastUpdated: newsItem.lastUpdated
            };
          } else {
            companyNewsData.push(newsItem);
          }
        });

        setAllCompanyNews(companyNewsData);
        setFilteredCompanyNews(companyNewsData);
        setCategoryNews(categoryNewsMap);
      } catch (error) {
        console.error('Error fetching news:', error);
        setError('Failed to load news data');
      } finally {
        setLoading(false);
      }
    };

    fetchAllNews();
  }, []);

  useEffect(() => {
    const searchTermLower = searchTerm.toLowerCase();
    const filteredCompanies = allCompanyNews.filter(news => 
      formatCompanyName(news.id).toLowerCase().includes(searchTermLower)
    );
    setFilteredCompanyNews(filteredCompanies);
  }, [searchTerm, allCompanyNews]);

  if (loading) {
    return (
      <div className="flex justify-center p-4">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center justify-center space-x-2 text-yellow-600 py-4 bg-white shadow-card rounded-lg border-l-4 border-primary">
        <div className="flex items-center space-x-2">
          <Clock className="w-5 h-5" />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="relative w-full sm:w-72 mb-4">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
        <input
          type="text"
          placeholder="Filter..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="w-full pl-10 pr-4 py-2 text-sm border rounded-lg border-gray-200 focus:outline-none focus:border-primary"
        />
      </div>

      {/* Category News Section */}
      <div className="space-y-4 mb-8">
        <h2 className="text-gray-500 text-lg font-medium mb-4">Industry News</h2>
        {Object.entries(categoryNews)
          .filter(([category]) => category.toLowerCase().includes(searchTerm.toLowerCase()))
          .map(([category, data]) => (
          <div key={category} className="bg-white shadow-card rounded-lg border-l-4 border-primary">
            <div 
              className="px-4 py-5 sm:px-6 cursor-pointer hover:bg-gray-50"
              onClick={(e) => toggleCategory(category, e)}
            >
              <div className="flex items-center justify-between">
                <div className="flex items-center space-x-2 min-w-0">
                  <Newspaper className="w-5 h-5 text-primary flex-shrink-0" />
                  <h3 className="text-base font-medium truncate">{category}</h3>
                  <span className="text-sm text-gray-500 whitespace-nowrap">
                    ({data.articles.length})
                  </span>
                </div>
                <div className="flex items-center space-x-2 sm:space-x-4 flex-shrink-0">
                  <div className="hidden sm:flex items-center text-sm text-gray-500">
                    <Clock className="w-4 h-4 mr-1" />
                    <span>Updated {formatTimestamp(data.lastUpdated)}</span>
                  </div>
                  {expandedCategories[category] ? (
                    <ChevronUp className="w-5 h-5 text-gray-400" />
                  ) : (
                    <ChevronDown className="w-5 h-5 text-gray-400" />
                  )}
                </div>
              </div>
            </div>

            {expandedCategories[category] && (
              <div className="px-4 py-5 sm:p-6 border-t space-y-4">
                {data.articles
                  .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
                  .map((article, index) => (
                    <a
                      key={index}
                      href={article.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="block border rounded-lg p-4 hover:bg-gray-50 transition-colors duration-200"
                    >
                      <div>
                        <h4 className="font-medium text-base text-gray-900 hover:text-primary">
                          {article.title}
                        </h4>
                        {article.description && (
                          <p className="mt-2 text-sm text-gray-600">
                            {article.description}
                          </p>
                        )}
                        <div className="mt-2 text-sm text-gray-500">
                          {article.source} • {new Date(article.publishedAt).toLocaleString()}
                        </div>
                      </div>
                    </a>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      {/* Company News Section */}
      <div className="space-y-4">
        <h2 className="text-gray-500 text-lg font-medium mb-4">Customers News</h2>
        {filteredCompanyNews.length === 0 ? (
          <div className="text-gray-500 text-center py-4 bg-white shadow-card rounded-lg border-l-4 border-primary">
            <p>No news found</p>
          </div>
        ) : (
          filteredCompanyNews.map((newsItem) => (
            <div key={newsItem.id} className="bg-white shadow-card rounded-lg border-l-4 border-primary">
              <div 
                className="px-4 py-5 sm:px-6 cursor-pointer hover:bg-gray-50"
                onClick={(e) => toggleCard(newsItem.id, e)}
              >
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-2 min-w-0">
                    <Newspaper className="w-5 h-5 text-primary flex-shrink-0" />
                    <h3 className="text-base font-medium truncate">{formatCompanyName(newsItem.id)}</h3>
                    <span className="text-sm text-gray-500 whitespace-nowrap">
                      ({newsItem.articles?.length || 0})
                    </span>
                  </div>
                  <div className="flex items-center space-x-2 sm:space-x-4 flex-shrink-0">
                    <div className="hidden sm:flex items-center text-sm text-gray-500">
                      <Clock className="w-4 h-4 mr-1" />
                      <span>Updated {formatTimestamp(newsItem.lastUpdated)}</span>
                    </div>
                    {expandedCards[newsItem.id] ? (
                      <ChevronUp className="w-5 h-5 text-gray-400" />
                    ) : (
                      <ChevronDown className="w-5 h-5 text-gray-400" />
                    )}
                  </div>
                </div>
              </div>

              {expandedCards[newsItem.id] && newsItem.articles && newsItem.articles.length > 0 && (
                <div className="px-4 py-5 sm:p-6 border-t">
                  <div className="space-y-4">
                    {newsItem.articles.map((article, index) => (
                      <a
                        key={index}
                        href={article.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="block border rounded-lg p-4 hover:bg-gray-50 transition-colors duration-200"
                      >
                        <div>
                          <h4 className="font-medium text-base text-gray-900 hover:text-primary">
                            {article.title}
                          </h4>
                          {article.description && (
                            <p className="mt-2 text-sm text-gray-600">
                              {article.description}
                            </p>
                          )}
                          <div className="mt-2 text-sm text-gray-500">
                            {article.source} • {new Date(article.publishedAt).toLocaleString()}
                          </div>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default CompanyNews;
