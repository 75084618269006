import React, { useState, useCallback, useRef } from 'react';
import QueryComponent from '../components/QueryComponent/QueryComponent';
import SavedQueriesComponent from '../components/QueryComponent/SavedQueriesComponent';
import Card from '../components/Card';
import { useAuth } from '../contexts/AuthContext';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error('ErrorBoundary caught an error:', error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Card className="text-red-600 p-4 bg-red-100 rounded-lg">
          <h1 className="text-xl font-bold">Something went wrong.</h1>
          <details className="mt-2 whitespace-pre-wrap">
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
        </Card>
      );
    }

    return this.props.children;
  }
}

function DynamicQuery() {
  const [queryResult, setQueryResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { currentUser } = useAuth();
  const savedQueriesRef = useRef();
  const queryComponentRef = useRef(null);

  const handleQueryResult = useCallback((result) => {
    setQueryResult(result);
    setIsLoading(false);
  }, []);

  const handleQueryStart = useCallback(() => {
    setIsLoading(true);
    setQueryResult(null);
  }, []);

  const handleSaveQuery = useCallback(() => {
    if (savedQueriesRef.current) {
      savedQueriesRef.current.refreshQueries();
    }
  }, []);

  return (
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <h1 className="text-gray-500 mb-6 text-xl font-semibold">Playground</h1>
      <ErrorBoundary>
        <QueryComponent 
          ref={queryComponentRef}
          onQueryResult={handleQueryResult} 
          onQueryStart={handleQueryStart}
          queryResult={queryResult}
          isLoading={isLoading}
          currentUser={currentUser}
          onSaveQuery={handleSaveQuery}
        />
        <SavedQueriesComponent 
          onQueryResult={handleQueryResult} 
          onQueryStart={handleQueryStart}
          currentUser={currentUser}
          ref={savedQueriesRef}
          queryComponentRef={queryComponentRef}
        />
      </ErrorBoundary>
    </div>
  );
}

export default DynamicQuery;