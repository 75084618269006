import { useState, useEffect, useCallback } from 'react';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../firebase';

export const useFixedView = (tableId, user) => {
  const [materializedView, setMaterializedView] = useState(null);
  const [isRefreshingView, setIsRefreshingView] = useState(false);
  const [activeFixes, setActiveFixes] = useState([]);
  const [error, setError] = useState(null);

  const fetchAllData = useCallback(async () => {
    if (!tableId) return;

    try {
      console.log('Fetching all data for table:', tableId);
      setIsRefreshingView(true);
      setError(null);

      // Fetch materialized view data
      const tableName = tableId.split('.')[2];
      const safeViewName = `mv_${tableName}_prep`;
      const newTablesDoc = doc(db, 'DataPrep', 'newTables');
      const docSnap = await getDoc(newTablesDoc);
      
      let viewData = null;
      if (docSnap.exists()) {
        viewData = docSnap.data()[safeViewName];
        if (!viewData || viewData.originalTableId !== tableId) {
          viewData = null;
        }
      }

      // Fetch active fixes
      const fixesQuery = query(
        collection(db, 'columnFixes'),
        where('tableId', '==', tableId),
        where('metadata.status', '==', 'active')
      );
      
      const snapshot = await getDocs(fixesQuery);
      const fixes = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      
      // Group fixes by column
      const fixesByColumn = {};
      fixes.forEach(fix => {
        if (!fixesByColumn[fix.columnName]) {
          fixesByColumn[fix.columnName] = [];
        }
        fixesByColumn[fix.columnName].push(fix);
      });

      // Update state with all data
      setActiveFixes(fixes);
      
      if (viewData) {
        setMaterializedView({
          id: viewData.id,
          name: viewData.name,
          status: viewData.status || 'unknown',
          lastUpdated: viewData.lastUpdated?.toDate(),
          refreshIntervalMinutes: viewData.refreshIntervalMinutes,
          sql: viewData.sql,
          dataSample: viewData.dataSample || [],
          schema: viewData.schema || [],
          fixesByColumn: fixesByColumn, // Use the latest fixes
          columnAdditions: viewData.columnAdditions || [],
          originalTableId: viewData.originalTableId
        });
      } else {
        setMaterializedView(null);
      }

      console.log('Data fetch completed successfully');
    } catch (error) {
      console.error('Error fetching data:', error);
      setError('Failed to fetch view data');
    } finally {
      setIsRefreshingView(false);
    }
  }, [tableId]);

  // Initial fetch
  useEffect(() => {
    fetchAllData();
  }, [fetchAllData]);

  const handleRefreshView = async () => {
    try {
      if (!user?.email) {
        console.error('No user email available');
        setError('User email is required');
        return { success: false, error: 'User email is required' };
      }

      setIsRefreshingView(true);
      setError(null);
      
      const executePreparation = httpsCallable(functions, 'executeTablePreparation');
      const result = await executePreparation({ 
        tableId,
        adminEmail: user.email 
      });
      
      if (result.data.success) {
        // Wait a moment before fetching updated data
        await new Promise(resolve => setTimeout(resolve, 1000));
        await fetchAllData();
        return { success: true };
      }
      
      throw new Error(result.data.error || 'Failed to prepare table');
    } catch (error) {
      console.error('Error refreshing materialized view:', error);
      setError(error.message || 'Failed to refresh materialized view');
      return { success: false, error: error.message };
    } finally {
      setIsRefreshingView(false);
    }
  };

  return {
    materializedView,
    isRefreshingView,
    activeFixes,
    error,
    refreshView: handleRefreshView
  };
};