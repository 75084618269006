import React from 'react';

const Button = React.memo(({ 
  children, 
  onClick, 
  className = '', 
  icon: Icon, 
  disabled = false, 
  loading = false,
  title = '',
  align = 'center', // Retained alignment prop
  ...props 
}) => {
  return (
    <div className="relative group">
      <button
        className={`flex items-center px-2 py-1 text-sm font-medium text-text transition-colors duration-200
                    ${disabled || loading ? 'opacity-50 cursor-not-allowed' : 'hover:text-secondary'}
                    ${align === 'left' ? 'justify-start' : 'justify-center'}
                    ${className}`}
        onClick={onClick}
        disabled={disabled || loading}
        title={title}
        {...props}
      >
        {loading ? (
          <svg className="animate-spin h-5 w-5 text-current" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
          </svg>
        ) : Icon && <Icon size={18} className="flex-shrink-0" />}
        {children && (
          <span className={`${Icon ? 'ml-2' : 'w-full text-left'}`}>
            {children}
          </span>
        )}
      </button>
      {title && (
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
          {title}
        </div>
      )}
    </div>
  );
});

Button.displayName = 'Button';

export default Button;