import React from 'react';
import { Database, ArrowRight, TableProperties, GitCompare } from 'lucide-react';
import Button from '../Button';

const InitializeViewSection = ({ onInitialize, isLoading }) => {
  const steps = [
    {
      icon: Database,
      title: "Duplicate Table",
      description: "Create a new version of your table"
    },
    {
      icon: TableProperties,
      title: "Apply Changes",
      description: "Make modifications to boost your data"
    },
    {
      icon: GitCompare,
      title: "Compare Results",
      description: "Review and verify your improvements"
    }
  ];

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-200 p-8">
      <div className="text-center mb-8">
        <Database className="h-12 w-12 text-primary mx-auto mb-4" />
        <h3 className="text-xl font-semibold text-gray-900 mb-2">
          Ready to Boost Your Data?
        </h3>
        <p className="text-gray-500 mb-6">
          Create a duplicate of your table to get started.
        </p>
      </div>

      {/* Process Flow */}
      <div className="flex justify-between items-start mb-8">
        {steps.map((step, index) => (
          <React.Fragment key={index}>
            <div className="flex-1 text-center px-4">
              <div className="mx-auto w-12 h-12 rounded-full bg-primary/10 flex items-center justify-center mb-3">
                <step.icon className="h-6 w-6 text-primary" />
              </div>
              <h4 className="font-medium text-gray-900 mb-1">{step.title}</h4>
              <p className="text-sm text-gray-500">{step.description}</p>
            </div>
            {index < steps.length - 1 && (
              <ArrowRight className="h-6 w-6 text-gray-400 mt-3" />
            )}
          </React.Fragment>
        ))}
      </div>

      {/* Action Button */}
      <div className="text-center">
        <Button
          onClick={onInitialize}
          disabled={isLoading}
          className="px-8 py-3 bg-primary hover:bg-primary/90 text-white text-lg font-semibold rounded-full shadow-lg hover:shadow-xl transition-all duration-200"
        >
          {isLoading ? (
            <div className="flex items-center">
              <span className="animate-spin mr-2">⏳</span>
              Duplicating Table...
            </div>
          ) : (
            "Start Now"
          )}
        </Button>
      </div>
    </div>
  );
};

export default InitializeViewSection;