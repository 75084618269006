import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, Plus, Edit, RefreshCw } from 'lucide-react';
import Button from '../Button';
import { formatDistanceToNow } from 'date-fns';

const getProgressText = (dashboard) => {
  if (!dashboard) return '';
  const totalQueries = dashboard.queries?.length || 0;
  const loadedQueries = dashboard.queries?.filter(q => !q.isLoading).length || 0;
  
  if (totalQueries === 0) return '';
  if (loadedQueries === totalQueries) return '';
  
  return ` (${loadedQueries}/${totalQueries} queries loaded)`;
};

const DashboardList = ({ 
  dashboards, 
  selectedDashboard, 
  onSelectDashboard, 
  onNewDashboard, 
  onEditDashboard,
  onRefreshDashboard,
  currentUser,
  isAdmin
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [displayedDashboardName, setDisplayedDashboardName] = useState('Select Dashboard');
  const [userSelectedDashboardId, setUserSelectedDashboardId] = useState(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const dropdownRef = useRef(null);
  const [, setForceUpdate] = useState(0);


  useEffect(() => {
    if (selectedDashboard) {
      setDisplayedDashboardName(selectedDashboard.name);
      setUserSelectedDashboardId(selectedDashboard.id);
    } else {
      setDisplayedDashboardName('Select Dashboard');
      setUserSelectedDashboardId(null);
    }
  }, [selectedDashboard]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDashboardSelection = (dashboard) => {
    setIsDropdownOpen(false);
    setDisplayedDashboardName(dashboard.name);
    setUserSelectedDashboardId(dashboard.id);
    onSelectDashboard(dashboard);
  };

  useEffect(() => {
    if (userSelectedDashboardId) {
      const selectedDash = dashboards.find(d => d.id === userSelectedDashboardId);
      if (selectedDash) {
        setDisplayedDashboardName(selectedDash.name);
      }
    }
  }, [dashboards, userSelectedDashboardId]);

  const handleRefreshClick = async () => {
    if (!selectedDashboard || isRefreshing) return;
    
    setIsRefreshing(true);
    try {
      await onRefreshDashboard(selectedDashboard);
    } catch (error) {
      console.error('Error refreshing dashboard:', error);
    } finally {
      setIsRefreshing(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      setForceUpdate(prev => prev + 1);
    }, 60000); // Update every minute

    return () => clearInterval(intervalId);
  }, []);
  
  const getLastRefreshedText = (dashboard) => {
    if (!dashboard?.lastRefreshed) return 'Never refreshed';
    
    try {
      // If it's a Firestore Timestamp that was serialized as an object
      if (dashboard.lastRefreshed._seconds || dashboard.lastRefreshed.seconds) {
        const seconds = dashboard.lastRefreshed._seconds || dashboard.lastRefreshed.seconds;
        const date = new Date(seconds * 1000);
        return `Last refreshed ${formatDistanceToNow(date, { addSuffix: true })}`;
      }
      
      // If it's a timestamp string
      const date = new Date(dashboard.lastRefreshed);
      if (!isNaN(date.getTime())) {
        return `Last refreshed ${formatDistanceToNow(date, { addSuffix: true })}`;
      }
      
      throw new Error('Invalid date format');
    } catch (error) {
      console.error('Error formatting last refreshed time:', error);
      return 'Last refresh time unavailable';
    }
  };

  return (
    <div className="flex flex-col space-y-2">
      <div className="flex items-center flex-grow">
        <div className="relative inline-block text-left mr-2" ref={dropdownRef}>
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="inline-flex items-center justify-start text-lg font-bold text-gray-700 hover:text-primary transition-colors duration-200 focus:outline-none bg-white hover:bg-background px-4 py-2 rounded-full border-2 border-primary"
          >
            <span className="truncate max-w-[200px]">
              {displayedDashboardName}
            </span>
            <ChevronDown 
              className={`ml-2 h-5 w-5 flex-shrink-0 transition-transform duration-200 ease-in-out text-primary ${
                isDropdownOpen ? 'transform rotate-180' : ''
              }`}
              aria-hidden="true" 
            />
          </button>
          {isDropdownOpen && dashboards.length > 0 && (
            <div className="origin-top-left absolute left-0 mt-2 w-56 rounded-md bg-white ring-2 ring-primary focus:outline-none z-10">
              <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                {dashboards.map((dashboard) => (
                  <button
                    key={dashboard.id}
                    onClick={() => handleDashboardSelection(dashboard)}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-background hover:text-primary transition-colors duration-200"
                    role="menuitem"
                  >
                    <div className="flex flex-col">
                      <span className="font-medium">{dashboard.name}</span>
                      <span className="text-xs text-gray-500">
                        {getLastRefreshedText(dashboard)}
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="flex space-x-1">
          {selectedDashboard && (currentUser && (selectedDashboard.userId === currentUser.email || isAdmin)) && (
            <>
              <Button
                onClick={handleRefreshClick}
                className={`text-primary p-1 transition-opacity duration-200 ${
                  isRefreshing ? 'opacity-50' : ''
                }`}
                title="Refresh Dashboard"
                disabled={isRefreshing}
              >
                <div className="flex items-center">
                  <RefreshCw
                    className={`h-4 w-4 ${
                      isRefreshing ? 'animate-[spin_2s_linear_infinite]' : ''
                    }`}
                  />
                  <span className="hidden sm:inline ml-1">
                    {isRefreshing ? 
                      `Refreshing${getProgressText(selectedDashboard)}` : 
                      'Refresh'}
                  </span>
                </div>
              </Button>
              <Button
                onClick={() => onEditDashboard(selectedDashboard)}
                icon={Edit}
                className="text-primary p-1"
                title="Edit Dashboard"
                disabled={isRefreshing}
              >
                <span className="hidden sm:inline ml-1">Edit Dashboard</span>
              </Button>
            </>
          )}
          <Button
            onClick={onNewDashboard}
            icon={Plus}
            className="text-primary p-1"
            title="New Dashboard"
            disabled={isRefreshing}
          >
            <span className="hidden sm:inline ml-1">New Dashboard</span>
          </Button>
        </div>
      </div>
      {selectedDashboard && (
        <div className="text-sm text-gray-500">
          {getLastRefreshedText(selectedDashboard)}
        </div>
      )}
    </div>
  );
};

export default DashboardList;