import React, { useState, useEffect, useMemo } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { format, parseISO, isValid, startOfMonth, endOfMonth, isAfter, isBefore, addDays, lastDayOfMonth, startOfYear, endOfYear } from 'date-fns';
import { useConfig } from '../contexts/ConfigContext';
import Card from '../components/Card';
import HeatMap from '../components/HeatMap';

const LOCAL_STORAGE_KEY = 'forecastUserSelection';

const Plan = () => {
  const { currentUser } = useAuth();
  const [forecastData, setForecastData] = useState([]);
  const [forecastSystems, setForecastSystems] = useState([]);
  const [selectedForecastId, setSelectedForecastId] = useState('');
  const [selectedForecastName, setSelectedForecastName] = useState('');
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [dateRange, setDateRange] = useState({ 
    start: startOfMonth(new Date()), 
    end: endOfMonth(new Date()) 
  });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [hiddenLines, setHiddenLines] = useState({});
  const [heatMapData, setHeatMapData] = useState({});
  const [heatMapYear, setHeatMapYear] = useState(new Date().getFullYear());
  const { config, isLoading: isConfigLoading } = useConfig();


  const [dateInputs, setDateInputs] = useState({
    start: format(dateRange.start, 'yyyy-MM-dd'),
    end: format(dateRange.end, 'yyyy-MM-dd')
  });
  const [lastActualDate, setLastActualDate] = useState(null);

  const formatForecastName = (name) => {
    return name.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');
  };

  useEffect(() => {
    const fetchForecastSystems = async () => {
      try {
        console.log('Fetching forecast systems...');
        const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/getForecastSystems`);
        if (!response.ok) throw new Error('Failed to fetch forecast systems');
        const data = await response.json();
        console.log('Received forecast systems:', data);
        setForecastSystems(data);
        
        const savedSelection = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (savedSelection) {
          const { forecastId, start, end } = JSON.parse(savedSelection);
          const savedSystem = data.find(system => system.id === forecastId);
          if (savedSystem) {
            setSelectedForecastId(forecastId);
            setSelectedForecastName(formatForecastName(savedSystem.name));
          } else if (data.length > 0) {
            setSelectedForecastId(data[0].id);
            setSelectedForecastName(formatForecastName(data[0].name));
          }
          const savedStart = parseISO(start);
          const savedEnd = parseISO(end);
          if (isValid(savedStart) && isValid(savedEnd)) {
            setDateRange({ start: savedStart, end: savedEnd });
            setDateInputs({ start, end });
          }
        } else if (data.length > 0) {
          setSelectedForecastId(data[0].id);
          setSelectedForecastName(formatForecastName(data[0].name));
        }
      } catch (error) {
        console.error('Error fetching forecast systems:', error);
        setError('Failed to load forecast systems. Please try again later.');
      }
    };
    fetchForecastSystems();
  }, []);

  useEffect(() => {
    if (selectedForecastId) {
      const fetchCategoriesAndSetSelected = async () => {
        try {
          console.log('Fetching categories...');
          const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/getCategories?forecastId=${selectedForecastId}`);
          if (!response.ok) throw new Error('Failed to fetch categories');
          const data = await response.json();
          console.log('Received categories:', data);
          setCategories(data);
          
          const savedSelection = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
          if (savedSelection && savedSelection.forecastId === selectedForecastId) {
            const savedCategory = data.find(cat => cat === savedSelection.category);
            if (savedCategory) {
              setSelectedCategory(savedCategory);
            } else {
              setSelectedCategory(data[0]);
            }
          } else if (data.length > 0) {
            setSelectedCategory(data[0]);
          }
        } catch (error) {
          console.error('Error fetching categories:', error);
          setError('Failed to load categories. Please try again later.');
        }
      };
      fetchCategoriesAndSetSelected();
    }
  }, [selectedForecastId]);

  useEffect(() => {
    if (selectedForecastId && selectedCategory && isValid(dateRange.start) && isValid(dateRange.end)) {
      console.log('Triggering fetchForecastData with:', {
        selectedForecastId,
        selectedCategory,
        startDate: dateRange.start,
        endDate: dateRange.end
      });
      fetchForecastData();
      
      const selectionToSave = {
        forecastId: selectedForecastId,
        category: selectedCategory,
        start: format(dateRange.start, 'yyyy-MM-dd'),
        end: format(dateRange.end, 'yyyy-MM-dd')
      };
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(selectionToSave));
    }
  }, [selectedForecastId, selectedCategory, dateRange.start, dateRange.end]);

  const fetchForecastData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      console.log('Fetching forecast data with params:', {
        forecastId: selectedForecastId,
        categoryValue: selectedCategory,
        startDate: format(dateRange.start, 'yyyy-MM-dd'),
        endDate: format(dateRange.end, 'yyyy-MM-dd'),
      });
  
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/getForecastData`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          data: {
            forecastId: selectedForecastId,
            categoryValue: selectedCategory,
            startDate: format(dateRange.start, 'yyyy-MM-dd'),
            endDate: format(dateRange.end, 'yyyy-MM-dd'),
          },
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to fetch forecast data');
      }

      const data = await response.json();
      console.log('Received forecast data:', data);
      setForecastData(data.data);

      // Find the last date with actual data
      const lastActual = data.data.reduce((last, item) => {
        return item.actual !== null && (!last || new Date(item.date) > new Date(last)) ? item.date : last;
      }, null);
      setLastActualDate(lastActual ? new Date(lastActual) : null);

      // Update heatMapData
      const newHeatMapData = {};
      data.data.forEach(item => {
        const itemDate = new Date(item.date);
        if (lastActual && itemDate <= new Date(lastActual)) {
          newHeatMapData[item.date] = { value: item.actual, type: 'actual' };
        } else if (item.forecast !== null) {
          newHeatMapData[item.date] = { value: item.forecast, type: 'forecast' };
        }
      });
      setHeatMapData(newHeatMapData);

      // Update heatMapYear based on the start date of the selected range
      setHeatMapYear(dateRange.start.getFullYear());
    } catch (error) {
      console.error('Error fetching forecast data:', error);
      setError('Failed to load forecast data. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateChange = (type, value) => {
    const newDate = parseISO(value);
    if (isValid(newDate)) {
      const adjustedDate = getValidDate(newDate);
      setDateInputs(prev => ({ ...prev, [type]: format(adjustedDate, 'yyyy-MM-dd') }));
      setDateRange(prev => ({ ...prev, [type]: adjustedDate }));
    } else {
      setDateInputs(prev => ({ ...prev, [type]: value }));
      setError(`Invalid ${type} date. Please enter a valid date.`);
    }
  };

  const getValidDate = (date) => {
    const lastValidDay = lastDayOfMonth(date);
    return isBefore(date, lastValidDay) ? date : lastValidDay;
  };

  const handleDateKeyDown = (e, type) => {
    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
      e.preventDefault();
      const currentDate = parseISO(dateInputs[type]);
      if (isValid(currentDate)) {
        const newDate = e.key === 'ArrowUp' 
          ? addDays(currentDate, 1) 
          : addDays(currentDate, -1);
        const adjustedDate = getValidDate(newDate);
        setDateInputs(prev => ({ ...prev, [type]: format(adjustedDate, 'yyyy-MM-dd') }));
        setDateRange(prev => ({ ...prev, [type]: adjustedDate }));
      }
    }
  };

  const validateDateRange = () => {
    if (isAfter(dateRange.start, dateRange.end)) {
      setError('Start date cannot be after end date.');
      return false;
    }
    setError(null);
    return true;
  };

  useEffect(() => {
    validateDateRange();
  }, [dateRange]);

  const chartData = useMemo(() => {
    return forecastData.map(d => {
      const date = parseISO(d.date);
      return {
        date: isValid(date) ? format(date, 'yyyy-MM-dd') : 'Invalid Date',
        actual: d.actual,
        forecast: d.forecast,
        [selectedCategory.toLowerCase()]: d[selectedCategory.toLowerCase()]
      };
    });
  }, [forecastData, selectedCategory]);

  const summaryMetrics = useMemo(() => {
    let sumActual = 0;
    let sumForecast = 0;
    let mapeSum = 0;
    let mapeCount = 0;

    chartData.forEach(d => {
      if (d.actual !== null) {
        sumActual += d.actual;
      }
      if (d.forecast !== null) {
        sumForecast += d.forecast;
      }
      if (d.actual !== null && d.forecast !== null && d.actual !== 0) {
        mapeSum += Math.abs((d.actual - d.forecast) / d.actual);
        mapeCount++;
      }
    });

    const mape = mapeCount > 0 ? (mapeSum / mapeCount) * 100 : 0;

    return {
      sumActual,
      sumForecast,
      mape
    };
  }, [chartData]);

  const formatXAxis = (tickItem) => {
    const date = parseISO(tickItem);
    return isValid(date) ? format(date, 'dd MMM') : '';
  };

  const formatTooltipLabel = (value) => {
    const date = parseISO(value);
    return isValid(date) ? format(date, 'dd MMM yyyy') : 'Invalid Date';
  };

  const formatTooltipValue = (value) => {
    return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
  };

  const handleLegendClick = (e) => {
    const { dataKey } = e;
    setHiddenLines((prevHiddenLines) => ({
      ...prevHiddenLines,
      [dataKey]: !prevHiddenLines[dataKey]
    }));
  };

  const getLineOpacity = (dataKey) => hiddenLines[dataKey] ? 0 : 1;

  const handleHeatMapYearChange = (newYear) => {
    setHeatMapYear(newYear);
    // Update date range to show the entire year
    setDateRange({
      start: startOfYear(new Date(newYear, 0, 1)),
      end: endOfYear(new Date(newYear, 0, 1))
    });
    setDateInputs({
      start: format(startOfYear(new Date(newYear, 0, 1)), 'yyyy-MM-dd'),
      end: format(endOfYear(new Date(newYear, 0, 1)), 'yyyy-MM-dd')
    });
  };

  return (
    <div className="flex flex-col h-screen">
      <div className="sticky top-0 z-10">
        <div className="container mx-auto px-2 sm:px-0 py-2 sm:py-4">
          <div className="flex flex-col sm:flex-row flex-wrap gap-2 sm:gap-4 mb-4 sm:mb-8">
            <select
              value={selectedForecastId}
              onChange={(e) => {
                setSelectedForecastId(e.target.value);
                setSelectedForecastName(formatForecastName(forecastSystems.find(system => system.id === e.target.value).name));
              }}
              className="w-full sm:w-auto px-3 py-2 border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-transparent text-sm appearance-none bg-white"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 0.5rem center',
                backgroundSize: '1.5em 1.5em',
                paddingRight: '2.5rem'
              }}
            >
              {forecastSystems.map((system) => (
                <option key={system.id} value={system.id}>{formatForecastName(system.name)}</option>
              ))}
            </select>
            <select
              value={selectedCategory}
              onChange={(e) => setSelectedCategory(e.target.value)}
              className="w-full sm:w-auto px-3 py-2 border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-transparent text-sm appearance-none bg-white"
              style={{
                backgroundImage: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3E%3Cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3E%3C/svg%3E")`,
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'right 0.5rem center',
                backgroundSize: '1.5em 1.5em',
                paddingRight: '2.5rem'
              }}
            >
              {categories.map((category) => (
                <option key={category} value={category}>{category}</option>
              ))}
            </select>
            <div className="flex gap-2 w-full sm:w-auto">
              <input
                type="date"
                value={dateInputs.start}
                onChange={(e) => handleDateChange('start', e.target.value)}
                onKeyDown={(e) => handleDateKeyDown(e, 'start')}
                className="w-1/2 sm:w-auto px-3 py-2 border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-transparent text-sm"
              />
              <input
                type="date"
                value={dateInputs.end}
                onChange={(e) => handleDateChange('end', e.target.value)}
                onKeyDown={(e) => handleDateKeyDown(e, 'end')}
                className="w-1/2 sm:w-auto px-3 py-2 border border-transparent rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-transparent text-sm"
              />
            </div>
          </div>
        </div>
      </div>
      
      <div className="flex-grow overflow-auto">
        <div className="container mx-auto px-2 sm:px-0 py-2 sm:py-4">
          <h1 className="text-gray-500 mb-4 sm:mb-6 text-xl font-semibold">Overview</h1>
          
          {error && (
            <Card className="mb-4 sm:mb-8 bg-red-50 border-red-500">
              <div className="text-red-700" role="alert">
                <strong className="font-bold">Error: </strong>
                <span className="block sm:inline">{error}</span>
              </div>
            </Card>
          )}

          {isLoading ? (
            <div className="flex justify-center items-center h-64">
              <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
            </div>
          ) : (
            <>
              <HeatMap 
                data={heatMapData} 
                initialYear={heatMapYear}
                onYearChange={handleHeatMapYearChange}
                selectedRange={dateRange}
                lastActualDate={lastActualDate}
                forecastName={selectedForecastName}
              />

              <h1 className="text-gray-500 mb-4 sm:mb-6 text-xl font-semibold">Forecast</h1>

              <Card className="mb-4 sm:mb-8 pb-2"> 
                <h2 className="text-lg font-semibold mb-4 text-gray-700">{selectedForecastName}</h2>
                
                <div className="bg-gray-100 p-3 rounded-lg mb-4 inline-block">
                  <h3 className="text-sm font-semibold mb-1">Summary: {selectedCategory}</h3>
                  <div className="flex flex-col sm:flex-row sm:space-x-4 space-y-2 sm:space-y-0 text-sm">
                    <div>
                      <span className="text-gray-600">Sum Actual:</span>
                      <span className="font-semibold ml-1" style={{ color: '#170DF2' }}>{formatTooltipValue(summaryMetrics.sumActual)}</span>
                    </div>
                    <div>
                      <span className="text-gray-600">Sum Forecast:</span>
                      <span className="font-semibold ml-1" style={{ color: '#FFADFF' }}>{formatTooltipValue(summaryMetrics.sumForecast)}</span>
                    </div>
                    <div>
                      <span className="text-gray-600">MAPE:</span>
                      <span className="font-semibold ml-1">{summaryMetrics.mape.toFixed(2)}%</span>
                    </div>
                  </div>
                </div>

                <ResponsiveContainer width="100%" height={400}>
                  <LineChart data={chartData} margin={{ top: 40, right: 10, left: 0, bottom: 40 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis 
                      dataKey="date" 
                      interval="preserveStartEnd"
                      tickFormatter={formatXAxis}
                      tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
                      tickMargin={10}
                    />
                    <YAxis 
                      tick={{ fontSize: 10, fontFamily: 'Roboto, sans-serif' }}
                      tickMargin={5}
                    />
                    <Tooltip 
                      contentStyle={{
                        borderRadius: '8px',
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)'
                      }}
                      labelStyle={{
                        fontWeight: 'bold',
                        fontSize: '12px'
                      }}
                      labelFormatter={formatTooltipLabel}
                      formatter={(value, name) => [formatTooltipValue(value), name]}
                    />
                    <Legend
                      onClick={handleLegendClick}
                      wrapperStyle={{ 
                        cursor: 'pointer', 
                        paddingTop: '20px',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        gap: '10px'
                      }}
                      layout="horizontal"
                      align="center"
                      iconSize={8}
                      formatter={(value, entry) => {
                        const { dataKey } = entry;
                        return <span style={{ color: hiddenLines[dataKey] ? '#ccc' : '#000', fontSize: '12px' }}>{value}</span>;
                      }}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="actual" 
                      stroke="#170DF2" 
                      strokeWidth={2} 
                      name="Actual" 
                      opacity={getLineOpacity('actual')} 
                      dot={{ r: 1 }}
                    />
                    <Line 
                      type="monotone" 
                      dataKey="forecast" 
                      stroke="#FFADFF"
                      strokeWidth={2} 
                      name="Forecast" 
                      opacity={getLineOpacity('forecast')} 
                      dot={{ r: 1 }}
                    />
                  </LineChart>
                </ResponsiveContainer>
              </Card>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default Plan;