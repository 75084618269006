import React, { useState, useEffect } from 'react';
import { db, functions } from '../firebase';
import { collection, getDocs, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Loader2, AlertCircle, Plus } from 'lucide-react';
import PolicyForm from './PolicyForm';
import PolicyCard from './PolicyCard';

const PolicyManagement = () => {
  const [policies, setPolicies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingPolicy, setEditingPolicy] = useState(null);
  const [showNewPolicyForm, setShowNewPolicyForm] = useState(false);

  // Fetch policies on mount
  useEffect(() => {
    fetchPolicies();
  }, []);

  // Check view status periodically only for policies with restricted access tables
  useEffect(() => {
    if (!policies || !Array.isArray(policies)) return;

    const intervals = policies
      .filter(policy => 
        policy && 
        policy.tables && 
        typeof policy.tables === 'object' &&
        Object.values(policy.tables).some(table => table && !table.fullAccess)
      )
      .map(policy => {
        return setInterval(() => checkViewStatus(policy.id), 30000);
      });

    return () => intervals.forEach(clearInterval);
  }, [policies]);

  const fetchPolicies = async () => {
    try {
      setLoading(true);
      const querySnapshot = await getDocs(collection(db, 'policies'));
      const policiesData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setPolicies(policiesData);
    } catch (err) {
      console.error('Error fetching policies:', err);
      setError('Failed to fetch policies');
    } finally {
      setLoading(false);
    }
  };

  const checkViewStatus = async (policyId) => {
    try {
      const policy = policies.find(p => p.id === policyId);
      if (!policy || !policy.tables) return;

      // Only check status for tables with restricted access
      const restrictedTables = Object.entries(policy.tables)
        .filter(([_, table]) => table && !table.fullAccess);

      if (restrictedTables.length === 0) return;

      const getViewStatus = httpsCallable(functions, 'getMaterializedViewStatus');
      const result = await getViewStatus({ policyId });
      
      if (result.data) {
        setPolicies(prev => prev.map(p => 
          p.id === policyId 
            ? { ...p, viewStatus: result.data.status }
            : p
        ));
      }
    } catch (error) {
      console.error('Error checking view status:', error);
    }
  };

  const handleSavePolicy = async (policyData, policyId = null) => {
    try {
      if (!policyData.tables) {
        throw new Error('Policy must include tables configuration');
      }

      // Validate policy data
      if (!policyData.name || policyData.name.trim() === '') {
        throw new Error('Policy name is required');
      }

      // First save/update the policy in Firestore to get an ID
      let actualPolicyId = policyId;
      if (!actualPolicyId) {
        // Create new policy
        const docRef = await addDoc(collection(db, 'policies'), policyData);
        actualPolicyId = docRef.id;
        console.log('Created new policy with ID:', actualPolicyId);
      } else {
        // Update existing policy
        await updateDoc(doc(db, 'policies', actualPolicyId), policyData);
        console.log('Updated existing policy:', actualPolicyId);
      }

      // Separate tables by access type and create materialized views if needed
      const restrictedTables = {};
      Object.entries(policyData.tables).forEach(([name, table]) => {
        if (table && !table.fullAccess) {
          if (!table.allowedColumns || table.allowedColumns.length === 0) {
            throw new Error(`No columns selected for restricted access table: ${name}`);
          }
          restrictedTables[name] = {
            ...table,
            originalTable: table.originalTable,
            allowedColumns: [...table.allowedColumns],
            rowRestriction: table.rowRestriction || '',
            refreshInterval: table.refreshInterval || 360
          };
        }
      });

      // Only create materialized views for restricted access tables
      if (Object.keys(restrictedTables).length > 0) {
        console.log('Creating materialized views with data:', {
          policyId: actualPolicyId,
          tables: restrictedTables
        });

        const createMaterializedViews = httpsCallable(functions, 'createMaterializedViews');
        const viewResult = await createMaterializedViews({ 
          policyId: actualPolicyId,
          tables: restrictedTables
        });

        console.log('View creation result:', viewResult);

        if (!viewResult.data || !viewResult.data.success) {
          // If view creation fails, delete the policy if it was newly created
          if (!policyId) {
            await deleteDoc(doc(db, 'policies', actualPolicyId));
          }
          throw new Error(viewResult.data?.error || 'Failed to create materialized views');
        }
      }

      // Update local state
      if (policyId) {
        setPolicies(prev => prev.map(p => 
          p.id === policyId ? { ...p, ...policyData } : p
        ));
      } else {
        setPolicies(prev => [...prev, { id: actualPolicyId, ...policyData }]);
      }

      setEditingPolicy(null);
      setShowNewPolicyForm(false);
      await fetchPolicies(); // Refresh the policies list
      return true;
    } catch (error) {
      console.error('Error saving policy:', error);
      throw error;
    }
  };

  const handleDeletePolicy = async (policyId) => {
    try {
      const policy = policies.find(p => p.id === policyId);
      if (!policy) throw new Error('Policy not found');

      const hasRestrictedTables = policy.tables && 
        Object.values(policy.tables).some(table => table && !table.fullAccess);

      let confirmMessage = 'Are you sure you want to delete this policy?';
      if (hasRestrictedTables) {
        confirmMessage += ' This will also remove associated materialized views.';
      }

      if (!window.confirm(confirmMessage)) {
        return;
      }

      if (hasRestrictedTables) {
        const deleteMaterializedViews = httpsCallable(functions, 'deleteMaterializedViews');
        const deleteResult = await deleteMaterializedViews({ policyId });
        
        if (!deleteResult.data || !deleteResult.data.success) {
          throw new Error(deleteResult.data?.error || 'Failed to delete materialized views');
        }
      }

      await updateDoc(doc(db, 'policies', policyId), { isDeleted: true });
      setPolicies(prev => prev.filter(p => p.id !== policyId));
      return true;
    } catch (error) {
      console.error('Error deleting policy:', error);
      throw error;
    }
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {!showNewPolicyForm && !editingPolicy && (
        <button
          onClick={() => setShowNewPolicyForm(true)}
          className="inline-flex items-center px-4 py-2 text-sm text-white bg-primary rounded-md hover:bg-primary/90"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Policy
        </button>
      )}

      {(showNewPolicyForm || editingPolicy) && (
        <PolicyForm
          policy={editingPolicy}
          onSave={handleSavePolicy}
          onCancel={() => {
            setEditingPolicy(null);
            setShowNewPolicyForm(false);
          }}
        />
      )}

      {error && (
        <div className="flex items-center space-x-2 text-red-500 p-4">
          <AlertCircle className="h-5 w-5" />
          <p>{error}</p>
        </div>
      )}

      <div className="space-y-4">
        {policies.map(policy => (
          <PolicyCard
            key={policy.id}
            policy={policy}
            onEdit={() => {
              setShowNewPolicyForm(false);
              setEditingPolicy(policy);
            }}
            onDelete={() => handleDeletePolicy(policy.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default PolicyManagement;