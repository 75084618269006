import React, { useState, useEffect } from 'react';
import { Plus, Loader2, Check, X, History, ChevronDown, ChevronRight } from 'lucide-react';
import { doc, collection, query, where, orderBy, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { db, functions } from '../../firebase';
import PromptModal from './PromptModal';
import Card from '../Card';
import { formatDistanceToNow } from 'date-fns';

const ColumnAdditionManagement = ({ 
  tableId, 
  onColumnOperationStatus, 
  onColumnAdded,
  user 
}) => {
  const [columns, setColumns] = useState([]);
  const [isLoadingColumns, setIsLoadingColumns] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState(null);
  const [isPromptModalOpen, setIsPromptModalOpen] = useState(false);
  const [showReverted, setShowReverted] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);


  // Subscribe to column additions collection
  useEffect(() => {
    if (!tableId) return;

    setIsLoadingColumns(true);
    const columnsQuery = query(
      collection(db, 'columnAdditions'),
      where('tableId', '==', tableId),
      orderBy('metadata.status'),
      orderBy('metadata.sequence', 'desc')
    );

    const unsubscribe = onSnapshot(columnsQuery, (snapshot) => {
      const columnsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setColumns(columnsData);
      setIsLoadingColumns(false);
    }, (error) => {
      console.error('Error fetching columns:', error);
      setIsLoadingColumns(false);
    });

    return () => unsubscribe();
  }, [tableId]);

  const handleGenerateColumn = async ({ name, prompt }) => {
    try {
      setLoadingOperation('generate');
      const generateColumnAddition = httpsCallable(functions, 'generateColumnAddition');
      
      const columnData = {
        tableId,
        userPrompt: prompt,
        displayName: name
      };
      
      const result = await generateColumnAddition(columnData);
  
      if (result.data.success) {
        const saveColumnAddition = httpsCallable(functions, 'saveColumnAddition');
        await saveColumnAddition({
          tableId,
          columnName: result.data.columnName,
          sqlTransformation: result.data.sqlTransformation,
          description: prompt,
          name,
          samples: result.data.preview,
          dataType: result.data.dataType
        });
        
        onColumnOperationStatus('Generate', true, 'Column addition generated and saved successfully!');
        setIsPromptModalOpen(false);
      } else {
        throw new Error(result.data.error || 'Failed to generate column');
      }
    } catch (error) {
      console.error('Error generating column:', error);
      onColumnOperationStatus('Generate', false, `Error: ${error.message}`);
    } finally {
      setLoadingOperation(null);
    }
  };

  const handleApproveColumn = async (columnId) => {
    try {
      setLoadingOperation(`approve-${columnId}`);
      const approveColumnAddition = httpsCallable(functions, 'approveColumnAddition');
      const result = await approveColumnAddition({ 
        columnId,
        adminEmail: user.email,
        originalTableId: tableId
      });
      
      if (result.data.success) {
        onColumnOperationStatus('Approve', true, 'Column approved successfully');
        if (onColumnAdded) {
          await onColumnAdded();
        }
      } else {
        throw new Error(result.data.error || 'Failed to approve column');
      }
    } catch (error) {
      console.error('Error approving column:', error);
      onColumnOperationStatus('Approve', false, `Failed to approve column: ${error.message}`);
    } finally {
      setLoadingOperation(null);
    }
  };

  const handleRevertColumn = async (columnId) => {
    try {
      setLoadingOperation(`revert-${columnId}`);
      const revertColumnAddition = httpsCallable(functions, 'revertColumnAddition');
      const result = await revertColumnAddition({ 
        columnId,
        adminEmail: user.email,
        originalTableId: tableId
      });
      
      if (result.data.success) {
        onColumnOperationStatus('Revert', true, 'Column reverted successfully');
        if (onColumnAdded) {
          await onColumnAdded();
        }
      } else {
        throw new Error(result.data.error || 'Failed to revert column');
      }
    } catch (error) {
      console.error('Error reverting column:', error);
      onColumnOperationStatus('Revert', false, `Failed to revert column: ${error.message}`);
    } finally {
      setLoadingOperation(null);
    }
  };

  const handleRejectColumn = async (columnId) => {
    try {
      setLoadingOperation(`reject-${columnId}`);
      const rejectColumnAddition = httpsCallable(functions, 'rejectColumnAddition');
      const result = await rejectColumnAddition({ 
        columnId,
        adminEmail: user.email,
        originalTableId: tableId
      });
      
      if (result.data.success) {
        onColumnOperationStatus('Reject', true, 'Column rejected successfully');
      } else {
        throw new Error(result.data.error || 'Failed to reject column');
      }
    } catch (error) {
      console.error('Error rejecting column:', error);
      onColumnOperationStatus('Reject', false, `Failed to reject column: ${error.message}`);
    } finally {
      setLoadingOperation(null);
    }
  };

  const renderColumnCard = (column) => {
    const isPending = column.metadata?.status === 'proposed';
    const isApproved = column.metadata?.status === 'active';

    return (
      <Card 
        key={column.id} 
        className={`mb-4 ${
          isPending ? 'border-l-4 border-l-yellow-400' :
          isApproved ? 'border-l-4 border-l-primary/50' :
          ''
        }`}
      >
        <div 
          className="p-4 cursor-pointer"
          onClick={() => setIsExpanded(!isExpanded)} // Add this state at the top of component
        >
          <div className="flex justify-between items-start">
            <div className="flex items-start gap-3">
              <div className="flex items-center gap-2">
                {isExpanded ? 
                  <ChevronDown className="h-4 w-4 text-gray-400" /> : 
                  <ChevronRight className="h-4 w-4 text-gray-400" />
                }
                <div>
                  <h3 className="font-semibold text-gray-900">
                    {column.displayName || column.columnName}
                  </h3>
                  {column.displayName && (
                    <p className="text-sm text-gray-500">{column.columnName}</p>
                  )}
                  <div className="flex items-center gap-2 mt-1">
                    <span className="px-2 py-0.5 text-xs font-medium rounded-full bg-gray-100 text-gray-800">
                      {column.dataType}
                    </span>
                    <span className={`px-2 py-0.5 text-xs font-medium rounded-full ${
                      isPending ? 'bg-yellow-100 text-yellow-800' :
                      isApproved ? 'bg-green-100 text-green-800' :
                      'bg-red-100 text-red-800'
                    }`}>
                      {column.metadata?.status?.charAt(0).toUpperCase() + column.metadata?.status?.slice(1)}
                    </span>
                    <span className="text-xs text-gray-500">
                      {formatDistanceToNow(column.metadata?.createdAt?.toDate(), { addSuffix: true })}
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="flex gap-2">
              {isPending && (
                <>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleApproveColumn(column.id);
                    }}
                    disabled={loadingOperation === `approve-${column.id}`}
                    className="text-xs px-2 py-1 bg-green-600 text-white rounded hover:bg-green-700 disabled:opacity-50 flex items-center gap-1 transition-colors"
                  >
                    {loadingOperation === `approve-${column.id}` ? (
                      <>
                        <Loader2 className="h-3 w-3 animate-spin" />
                        Approving...
                      </>
                    ) : (
                      <>
                        <Check className="h-3 w-3" />
                        Approve
                      </>
                    )}
                  </button>
                  <button
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRejectColumn(column.id);
                    }}
                    disabled={loadingOperation === `reject-${column.id}`}
                    className="text-xs px-2 py-1 border border-red-600 text-red-600 rounded hover:bg-red-50 disabled:opacity-50 flex items-center gap-1 transition-colors"
                  >
                    {loadingOperation === `reject-${column.id}` ? (
                      <>
                        <Loader2 className="h-3 w-3 animate-spin" />
                        Rejecting...
                      </>
                    ) : (
                      <>
                        <X className="h-3 w-3" />
                        Reject
                      </>
                    )}
                  </button>
                </>
              )}
              {isApproved && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRevertColumn(column.id);
                  }}
                  disabled={loadingOperation === `revert-${column.id}`}
                  className="text-xs px-2 py-1 border border-yellow-600 text-yellow-600 rounded hover:bg-yellow-50 disabled:opacity-50 flex items-center gap-1 transition-colors"
                >
                  {loadingOperation === `revert-${column.id}` ? (
                    <>
                      <Loader2 className="h-3 w-3 animate-spin" />
                      Reverting...
                    </>
                  ) : (
                    <>
                      <History className="h-3 w-3" />
                      Revert
                    </>
                  )}
                </button>
              )}
            </div>
          </div>

          {/* Expandable Content */}
          {isExpanded && (
            <div className="mt-4 pt-4 border-t border-gray-100">
              {column.description && (
                <p className="text-sm text-gray-500 mb-4">{column.description}</p>
              )}
              
              {/* SQL Section */}
              <div className="mb-4">
                <h4 className="text-sm font-medium text-gray-700 mb-2">SQL Transformation</h4>
                <pre className="text-sm bg-gray-50 p-3 rounded overflow-x-auto">
                  {column.sqlTransformation || column.sql}
                </pre>
              </div>

              {/* Preview Section */}
              {column.preview && (
                <div>
                  <h4 className="text-sm font-medium text-gray-700 mb-2">Sample Results</h4>
                  <div className="text-sm">
                    <div className="bg-gray-50 p-3 rounded">
                      <div className="mb-2">
                        <span className="font-medium">Total Rows:</span> {column.preview.total_rows?.toLocaleString()}
                      </div>
                      <div>
                        <span className="font-medium">Non-null Rows:</span> {column.preview.non_null_rows?.toLocaleString()}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Card>
    );
  };

  const activeColumns = columns.filter(col => col.metadata?.status !== 'reverted');
  const revertedColumns = columns.filter(col => col.metadata?.status === 'reverted');
  const hasRevertedColumns = revertedColumns.length > 0;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between mb-6">
        <div className="flex items-center gap-2">
        </div>
        <button
          className="px-3 py-1.5 text-xs text-white bg-primary hover:bg-primary/90 rounded shadow-sm flex items-center gap-1.5 transition-colors duration-150"
          onClick={() => setIsPromptModalOpen(true)}
        >
          <Plus className="h-3.5 w-3.5" />
          Add Column
        </button>
      </div>

      {/* Loading State */}
      {isLoadingColumns && (
        <div className="flex justify-center py-4">
          <Loader2 className="h-6 w-6 animate-spin text-gray-400" />
        </div>
      )}

      {/* Active Columns */}
      {!isLoadingColumns && activeColumns.length > 0 && (
        <div className="space-y-4">
          {activeColumns.map(column => renderColumnCard(column))}
        </div>
      )}

      {/* Reverted Columns Toggle */}
      {hasRevertedColumns && (
        <div className="pt-2">
          <button
            onClick={() => setShowReverted(!showReverted)}
            className="text-sm text-gray-500 hover:text-gray-700 flex items-center gap-1"
          >
            {showReverted ? <ChevronDown className="h-4 w-4" /> : <ChevronRight className="h-4 w-4" />}
            {revertedColumns.length} reverted column additions{revertedColumns.length !== 1 ? 's' : ''}
          </button>
        </div>
      )}

      {/* Reverted Columns */}
      {showReverted && hasRevertedColumns && (
        <div className="space-y-4 pt-2 opacity-60">
          {revertedColumns.map(column => renderColumnCard(column))}
        </div>
      )}

      {/* Empty State */}
      {!isLoadingColumns && columns.length === 0 && (
        <div className="text-center py-6 text-gray-500">
          No column additions yet
        </div>
      )}

      {/* Add Column Modal */}
      <PromptModal
        isOpen={isPromptModalOpen}
        onClose={() => setIsPromptModalOpen(false)}
        onSubmit={handleGenerateColumn}
        title="Add New Column"
        description="Create a new column for this table"
        isLoading={loadingOperation === 'generate'}
      />
    </div>
  );
};

export default ColumnAdditionManagement;