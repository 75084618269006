// HiddenColumnsChips.js
import React, { useState } from 'react';
import { Plus, ChevronUp, ChevronDown } from 'lucide-react';

const HiddenColumnsChips = ({ allColumns, visibleColumns, onShowColumn }) => {
  const [showAll, setShowAll] = useState(false);
  const hiddenColumns = allColumns.filter(col => !visibleColumns.includes(col));
  const MAX_VISIBLE_CHIPS = 3;
  const extraCount = Math.max(0, hiddenColumns.length - MAX_VISIBLE_CHIPS);

  // Simple function to truncate text to show 6-10 characters
  const truncateText = (text) => {
    if (text.length <= 10) return text;
    return text.slice(0, 6) + '...';
  };

  if (hiddenColumns.length === 0) return null;

  // Determine which columns to show based on showAll state
  const displayColumns = showAll ? hiddenColumns : hiddenColumns.slice(0, MAX_VISIBLE_CHIPS);

  return (
    <div className="hidden md:flex flex-col gap-1 min-w-[80px] max-w-[120px] ml-2">
      {displayColumns.map(column => (
        <button
          key={column}
          onClick={() => onShowColumn(column)}
          className="inline-flex items-center gap-1 px-1.5 bg-white rounded-md 
                   border border-gray-200 text-xs text-gray-600 hover:bg-gray-50 hover:border-gray-300 
                   shadow-sm transition-colors duration-150 w-full overflow-hidden"
          title={`Show ${column}`}
        >
          <Plus size={12} className="flex-shrink-0" />
          <span className="block py-1 pr-1 truncate">{truncateText(column)}</span>
        </button>
      ))}
      {extraCount > 0 && (
        <button 
          onClick={() => setShowAll(!showAll)}
          className="inline-flex items-center justify-center gap-1 px-1.5 py-1 bg-white rounded-md 
                   border border-gray-200 text-xs text-gray-500 hover:bg-gray-50 shadow-sm w-full"
          title={showAll ? "Show less" : `Show ${extraCount} more hidden columns`}
        >
          {showAll ? (
            <>Less <ChevronUp size={12} /></>
          ) : (
            <>+{extraCount} <ChevronDown size={12} /></>
          )}
        </button>
      )}
    </div>
  );
};

export default HiddenColumnsChips;