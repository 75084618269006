// src/hooks/useAgentOutput.js
import { useState, useEffect } from 'react';
import { collection, query, where, orderBy, onSnapshot, doc, getDoc, limit } from 'firebase/firestore';
import { db } from '../firebase';
import { useAuth } from '../contexts/AuthContext';

export const useAgentOutput = () => {
  const [outputs, setOutputs] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { currentUser, isAdmin } = useAuth();

  useEffect(() => {
    if (!currentUser?.email) return;

    setLoading(true);
    setError(null);

    const agentsRef = collection(db, 'agents');
    
    // Different queries for admins and regular users
    const agentsQuery = isAdmin 
      ? query(
          agentsRef,
          where('status', '==', 'active'),
          where('isDeleted', '==', false),
          where('actions.notifications.enabled', '==', true),
          where('actions.notifications.inApp.enabled', '==', true)
        )
      : query(
          agentsRef,
          where('status', '==', 'active'),
          where('isDeleted', '==', false),
          where('actions.notifications.enabled', '==', true),
          where('actions.notifications.inApp.enabled', '==', true),
          where('actions.notifications.inApp.users', 'array-contains-any', [currentUser.email, '*ALL_USERS*'])
        );

    const subscriptions = [];

    const agentUnsubscribe = onSnapshot(
      agentsQuery, 
      async (agentSnapshot) => {
        try {
          subscriptions.forEach(unsub => unsub());
          subscriptions.length = 0;

          if (agentSnapshot.empty) {
            setOutputs({});
            setLoading(false);
            return;
          }

          const relevantAgents = agentSnapshot.docs;
          const newOutputs = {};

          relevantAgents.forEach(agentDoc => {
            const agentData = agentDoc.data();
            const runsRef = collection(db, 'agentRuns');
            
            const runsQuery = query(
              runsRef,
              where('agentId', '==', agentDoc.id),
              where('status', '==', 'completed'),
              orderBy('endTime', 'desc'),
              limit(10)
            );

            const runUnsubscribe = onSnapshot(
              runsQuery, 
              (runSnapshot) => {
                if (!runSnapshot.empty) {
                  const userOutputs = {};
                  runSnapshot.docs.forEach(doc => {
                    const runData = doc.data();
                    // Include outputs that are either global or specific to this user
                    if (!runData.executingUserId || runData.executingUserId === currentUser.email) {
                      userOutputs[runData.executingUserId || 'global'] = {
                        id: doc.id,
                        output: runData.output || '',
                        agentName: agentData.name || 'Unknown Agent',
                        timestamp: runData.endTime,
                        error: runData.error,
                        userId: runData.executingUserId,
                        isGlobal: !runData.executingUserId
                      };
                    }
                  });

                  if (Object.keys(userOutputs).length > 0) {
                    newOutputs[agentDoc.id] = userOutputs;
                    setOutputs(prev => ({...prev, [agentDoc.id]: userOutputs}));
                  }
                }
              },
              (err) => {
                console.warn(`Warning: Could not access runs for agent ${agentDoc.id}:`, err);
              }
            );

            subscriptions.push(runUnsubscribe);
          });

          setLoading(false);
        } catch (err) {
          console.error('Error setting up agent output subscriptions:', err);
          if (err.code === 'permission-denied') {
            setOutputs({});
            setError(null);
          } else {
            setError("Could not load agent outputs. Please try again later.");
          }
          setLoading(false);
        }
      },
      (err) => {
        console.error('Error in agents snapshot:', err);
        if (err.code === 'permission-denied') {
          setOutputs({});
          setError(null);
        } else {
          setError("Could not load agent outputs. Please try again later.");
        }
        setLoading(false);
      }
    );

    return () => {
      agentUnsubscribe();
      subscriptions.forEach(unsub => unsub());
    };
  }, [currentUser, isAdmin]);

  return { outputs, loading, error };
};