import React, { useState, forwardRef, useImperativeHandle } from 'react';
import { doc, updateDoc, deleteField, writeBatch, collection, query, where, getDocs } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import { Plus, Trash2 } from 'lucide-react';
import { db, functions } from '../firebase';
import Modal from './Modal';
import CreateTableModal from './CreateTableModal';

// Separate DeleteButton component
const DeleteButton = ({ table, onDelete }) => (
  <button
    onClick={() => onDelete(table)}
    className="p-2 text-gray-500 hover:text-red-600 focus:outline-none"
    title="Delete table"
  >
    <Trash2 className="h-5 w-5" />
  </button>
);

const TableActions = forwardRef(({ tables, setTables, setSaveStatus }, ref) => {
  // At the start of the component add:
  useImperativeHandle(ref, () => ({
    handleDeleteClick
  }));

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tableToDelete, setTableToDelete] = useState(null);
  const [deleteConfirmText, setDeleteConfirmText] = useState('');
  const [affectedPolicies, setAffectedPolicies] = useState([]);


  const handleCreateTable = async (formData) => {
    try {
      setIsLoading(true);
       
      if (!formData.name) {
        throw new Error('Table name is required');
      }
       
      // Check if table name already exists
      const existingTable = tables.find(t => t.name === formData.name);
      if (existingTable) {
        throw new Error('A table with this name already exists');
      }
  
      // Use the correct collection based on the component
      const isDataPrep = window.location.pathname.includes('/data-prep');
      const configDoc = doc(db, isDataPrep ? 'DataPrep' : 'appConfiguration', 'tables');
      
      if (formData.type === 'new') {
        if (!formData.dataset) {
          throw new Error('Dataset name is required');
        }
        if (!formData.file) {
          throw new Error('No file provided');
        }
     
        // Read file as base64
        const fileContent = await new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const base64Content = e.target.result.split(',')[1];
              if (!base64Content) {
                reject(new Error('Failed to read file content'));
                return;
              }
              resolve(base64Content);
            } catch (err) {
              reject(new Error('Failed to process file content: ' + err.message));
            }
          };
          reader.onerror = () => reject(new Error('Failed to read file'));
          reader.readAsDataURL(formData.file);
        });
     
        console.log('File content read successfully, calling createTable function');
     
        // Determine if we're in DataPrep based on the current route
        const isDataPrep = window.location.pathname.includes('/data-prep');

        // Call the cloud function to create table from file
        const createTableFunc = httpsCallable(functions, 'createTable');
        
        try {
          const result = await createTableFunc({
            fileContent,
            name: formData.name,
            dataset: formData.dataset,
            prompt: formData.prompt,
            useRowForDescription: formData.useRowForDescription,
            descriptionRow: formData.descriptionRow,
            destination: isDataPrep ? 'dataPrep' : 'appConfiguration'
          });
  
          console.log('Cloud function response:', result); // Debug log
  
          // The result from httpsCallable automatically unwraps the data field
          if (!result?.data) {
            console.error('Invalid cloud function response:', result);
            throw new Error('Invalid response from server');
          }
  
          const { success, tableId, schema, dataSample } = result.data;
  
          if (!success || !tableId) {
            console.error('Invalid response data:', result.data);
            throw new Error('Failed to create table: ' + (result.data.error || 'Unknown error'));
          }
  
          const configDoc = doc(db, isDataPrep ? 'DataPrep' : 'appConfiguration', 'tables');
          await updateDoc(configDoc, {
            [formData.name]: {
              id: tableId,
              prompt: formData.prompt,
              schema: schema || [],
              schemaHash: '',
              lastSchemaCheck: null
            }
          });

          // Update local state
          setTables(currentTables => [...currentTables, {
            name: formData.name,
            id: tableId,
            prompt: formData.prompt,
            schema: schema || [],
            schemaHash: '',
            lastSchemaCheck: null,
            dataSample: dataSample || []
          }]);
          
          setIsCreateModalOpen(false);
          setSaveStatus({
            tableId: tableId,
            type: 'success',
            message: 'Table created successfully!'
          });
          setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
  
        } catch (functionError) {
          console.error('Cloud function error:', functionError);
          
          let errorMessage = 'Failed to create table';
          if (functionError.code === 'functions/invalid-argument') {
            errorMessage = functionError.message;
          } else if (functionError.details) {
            errorMessage = functionError.details;
          } else if (functionError.message) {
            errorMessage = functionError.message;
          }
          
          throw new Error(errorMessage);
        }
     
      } else {
        // Existing table creation logic
        if (!formData.id) {
          throw new Error('Table ID is required');
        }
     
        const updatedData = {
          [formData.name]: {
            id: formData.id,
            prompt: formData.prompt || '',
            schema: [],
            schemaHash: '',
            lastSchemaCheck: null
          }
        };
     
        await updateDoc(configDoc, updatedData);
     
        // Update local state
        setTables(currentTables => [...currentTables, {
          name: formData.name,
          id: formData.id,
          prompt: formData.prompt,
          schema: [],
          schemaHash: '',
          lastSchemaCheck: null
        }]);
  
        setIsCreateModalOpen(false);
        setSaveStatus({
          tableId: formData.id,
          type: 'success',
          message: 'Table created successfully!'
        });
        setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      }
     
    } catch (err) {
      console.error('Error creating table:', err);
      
      // Extract the error message from the Firebase function response
      let errorMessage = 'Failed to create table';
      if (err?.details?.error) {
        errorMessage = err.details.error;
      } else if (err?.message) {
        errorMessage = err.message;
      }
      
      setSaveStatus({
        tableId: 'new',
        type: 'error',
        message: errorMessage
      });
      
      // If you want to see technical details in console
      if (err?.details?.technicalDetails) {
        console.debug('Technical details:', err.details.technicalDetails);
      }
      
      throw err;
    }
  };

  const handleDeleteClick = (table) => {
    setTableToDelete(table);
    setDeleteConfirmText('');  // Reset the confirmation text
    
    // First check for policies
    const checkPolicies = async () => {
      try {
        setIsLoading(true);
        const checkPoliciesFunc = httpsCallable(functions, 'checkTablePolicies');
        const result = await checkPoliciesFunc({ 
          tableId: table.id 
        });
        setAffectedPolicies(result.data.affectedPolicies || []);
      } catch (err) {
        console.error('Error checking policies:', err);
        setSaveStatus({
          tableId: 'delete',
          type: 'error',
          message: 'Failed to check affected policies: ' + (err.message || 'Unknown error')
        });
      } finally {
        setIsLoading(false);
      }
    };
  
    checkPolicies().then(() => {
      setIsDeleteModalOpen(true);  // Only open modal after checking policies
    });
  };

  const handleDeleteTable = async () => {
    try {
      if (deleteConfirmText !== 'delete' || !tableToDelete) return;
      
      setIsLoading(true);
  
      // Proceed with deletion
      const deleteTableFunc = httpsCallable(functions, 'deleteTable');
      const deleteResult = await deleteTableFunc({ 
        tableName: tableToDelete.name,
        tableId: tableToDelete.id 
      });
  
      // Check if deletion was successful
      if (!deleteResult.data.success) {
        throw new Error(deleteResult.data.error || 'Failed to delete table');
      }
      
      // Update local state
      setTables(currentTables => currentTables.filter(t => t.name !== tableToDelete.name));
      
      // Reset state and close modal
      setIsDeleteModalOpen(false);
      setTableToDelete(null);
      setDeleteConfirmText('');
      setAffectedPolicies([]); // Clear affected policies
      
      // Show success message
      setSaveStatus({
        tableId: 'delete',
        type: 'success',
        message: 'Table and all related configurations deleted successfully!'
      });
      setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '' }), 3000);
      
    } catch (err) {
      console.error('Error deleting table:', err);
      setSaveStatus({
        tableId: 'delete',
        type: 'error',
        message: err.message || 'Failed to delete table'
      });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-semibold">Table Management</h2>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="flex items-center px-4 py-2 text-sm text-gray-700 rounded-md hover:text-secondary transition-colors"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Table
        </button>
      </div>

      {/* Create Table Modal */}
      <CreateTableModal
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={handleCreateTable}
        isLoading={isLoading}
      />

      {/* Delete Confirmation Modal */}
      <Modal
        isOpen={isDeleteModalOpen}
        onClose={() => {
          setIsDeleteModalOpen(false);
          setTableToDelete(null);
          setDeleteConfirmText('');
          setAffectedPolicies([]);
        }}
        title={`Delete Table: ${tableToDelete?.name}`}
        onSubmit={handleDeleteTable}
        isLoading={isLoading}
        confirmText="Delete Table"
        confirmDisabled={deleteConfirmText !== 'delete'}
      >
        <div className="space-y-4">
          {affectedPolicies.length > 0 ? (
            <>
              <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-yellow-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Warning: This table is referenced in policies
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>The following policies reference this table:</p>
                      <ul className="list-disc list-inside mt-2">
                        {affectedPolicies.map(policy => (
                          <li key={policy.id}>{policy.name}</li>
                        ))}
                      </ul>
                      <p className="mt-2">Are you sure you want to proceed? This may affect the functionality of these policies.</p>
                    </div>
                  </div>
                </div>
              </div>
              <p className="text-sm text-gray-600">
                To proceed with deletion, type <span className="font-mono font-bold">delete</span> to confirm.
              </p>
            </>
          ) : (
            <p className="text-sm text-gray-600">
              This action cannot be undone. Please type <span className="font-mono font-bold">delete</span> to confirm.
            </p>
          )}
          <input
            type="text"
            value={deleteConfirmText}
            onChange={(e) => setDeleteConfirmText(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded"
            placeholder="Type 'delete' to confirm"
          />
        </div>
      </Modal>
    </div>
  );
});

export default TableActions;