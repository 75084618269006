// components/dataPrep/TablePreview.js
import React, { useState } from 'react';
import { ChevronDown, ChevronRight, Table } from 'lucide-react';

const TablePreview = ({ table, isFixedView = false, showHeader = true }) => {
  console.log('TablePreview props:', { table, isFixedView });  
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selectedTab, setSelectedTab] = useState('preview');  
  
  // Get samples from the appropriate location
  const getSampleData = () => {
    console.log('getSampleData:', {
      isFixedView,
      dataSample: table?.dataSample
    });
    
    if (!table?.dataSample || !Array.isArray(table.dataSample)) {
      return [];
    }

    // For both views, handle the same data structure
    const samplesByColumn = table.dataSample.reduce((acc, column) => {
      if (column.samples && column.name) {
        // Take only first 2 samples from each column
        acc[column.name] = column.samples.slice(0, 2);
      }
      return acc;
    }, {});

    // Get the length of samples (should be 2)
    const sampleLength = 2;

    // Construct rows
    return Array.from({ length: sampleLength }, (_, i) => {
      const row = {};
      Object.entries(samplesByColumn).forEach(([columnName, samples]) => {
        row[columnName] = samples[i];
      });
      return row;
    });
  };

  // Change the early return condition
  if (!table) {
    console.log('No table provided');
    return null;
  }

  const samples = getSampleData();
  if (!samples || samples.length === 0) {
    console.log('No samples available');
    return (
      <div className="bg-white rounded-xl shadow-sm p-6">
        <div className="flex justify-between items-center mb-4">
        </div>
        <div className="text-center p-4 text-gray-500">
          <p>No samples available</p>
          <p className="text-sm mt-2">Please try refreshing the data</p>
        </div>
      </div>
    );
  }

  // Get column schema
  const getColumnSchema = () => {
    return table.dataSample?.map(column => ({
      name: column.name,
      type: column.type,
      description: column.description,
      mode: column.mode
    })) || [];
  };

  const schema = getColumnSchema();

  const formatValue = (value, type) => {
    if (value === null || value === undefined) {
      return 'N/A';
    }

    // Handle JSON string values
    let parsedValue = value;
    if (typeof value === 'string' && value.startsWith('{') && value.endsWith('}')) {
      try {
        parsedValue = JSON.parse(value);
      } catch (e) {
        parsedValue = value;
      }
    }

    // Handle timestamp objects
    if (parsedValue && typeof parsedValue === 'object' && parsedValue.value) {
      parsedValue = parsedValue.value;
    }

    // Handle timestamps and dates
    if (typeof parsedValue === 'string' && 
        (parsedValue.includes('T') || parsedValue.includes('/')) && 
        (parsedValue.includes(':') || type.includes('TIMESTAMP') || type.includes('DATETIME'))) {
      try {
        const date = new Date(parsedValue);
        if (!isNaN(date.getTime())) {
          return date.toLocaleString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
          });
        }
      } catch (error) {
        // If date parsing fails, return original value
      }
    }

    // Handle other object types
    if (typeof parsedValue === 'object') {
      return JSON.stringify(parsedValue);
    }

    return String(parsedValue);
  };

  const renderHeader = () => (
    <div 
      className="flex items-start justify-between p-6 bg-gray-50 rounded-t-lg cursor-pointer"
      onClick={() => setIsCollapsed(!isCollapsed)}
    >
      <div className="space-y-2">
        <div className="flex items-center gap-3">
          <Table className="h-5 w-5 text-primary" />
          <h2 className="text-lg font-semibold text-gray-900">
            {isFixedView ? "Fixed Table Preview" : "Original Table"}
          </h2>
        </div>
          <div className="flex items-center gap-2">
            <p className="text-xs text-gray-500 font-mono truncate max-w-md hover:text-gray-700 cursor-pointer" 
              onClick={(e) => {
                e.stopPropagation();
                navigator.clipboard.writeText(table.id || table.name);
                // Create and show a temporary tooltip
                const tooltip = document.createElement('div');
                tooltip.textContent = 'Copied!';
                tooltip.className = 'absolute bg-gray-800 text-white px-2 py-1 rounded text-xs -mt-8';
                e.target.appendChild(tooltip);
                setTimeout(() => tooltip.remove(), 1000);
              }}>
              {table.id || table.name}
            </p>
          </div>
      </div>
      <div 
        className="text-gray-500 hover:text-gray-700"
        onClick={(e) => {
          e.stopPropagation();
          setIsCollapsed(!isCollapsed);
        }}
      >
        {!isCollapsed ? (
          <ChevronDown className="h-5 w-5" />
        ) : (
          <ChevronRight className="h-5 w-5" />
        )}
      </div>
    </div>
  );

  const renderTabs = () => (
    <div className="px-6 border-t">
      <div className="flex gap-6 -mb-px">
        <button
          onClick={() => setSelectedTab('preview')}
          className={`py-3 text-sm font-medium border-b-2 ${
            selectedTab === 'preview'
              ? 'text-primary border-primary'
              : 'text-gray-500 border-transparent hover:text-gray-700 hover:border-gray-300'
          }`}
        >
          Preview
        </button>
      </div>
    </div>
  );

  const renderPreviewContent = () => (
    <div className="p-6">
      <div className="overflow-x-auto">
        <table className="w-full border-collapse border border-gray-300">
          <thead className="bg-gray-50">
            <tr>
              {schema.map((column) => (
                <th
                  key={column.name}
                  className="border border-gray-300 px-4 py-2 text-left text-sm font-semibold text-gray-700"
                >
                  <div className="flex items-center">
                    {column.name}
                    <span className="ml-2 text-xs text-gray-500 font-normal">
                      ({column.type})
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {samples.map((row, rowIndex) => (
              <tr key={rowIndex} className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}>
                {schema.map((column) => (
                  <td
                    key={column.name}
                    className="border border-gray-300 px-4 py-2 text-sm text-gray-900"
                  >
                    {formatValue(row[column.name], column.type)}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );

  if (isFixedView) {
    // For fixed view, return only the table content without header
    return renderPreviewContent();
  } else {
    // For regular view, return with header
    return (
      <div className="bg-white rounded-lg shadow-sm border mb-8">
        {showHeader && renderHeader()}
        {!isCollapsed && (
          <>
            {showHeader && renderTabs()}
            {renderPreviewContent()}
          </>
        )}
      </div>
    );
  }
};

export default TablePreview;