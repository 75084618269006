// src/contexts/ConfigContext.js
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { db } from '../firebase';
import { collection, getDocs } from 'firebase/firestore';

const ConfigContext = createContext();
const CONFIG_CACHE_KEY = 'app_config_cache';

// Initialize with empty defaults but try to get cached config
export let currentConfig = {
  processing: {
    exemptedColumns: []
  }
};

// Try to load initial config from cache
try {
  const cachedConfig = localStorage.getItem(CONFIG_CACHE_KEY);
  if (cachedConfig) {
    const parsed = JSON.parse(cachedConfig);
    if (parsed?.processing?.exemptedColumns) {
      currentConfig = parsed;
    }
  }
} catch (error) {
  console.warn('Failed to load cached config:', error);
}

export function ConfigProvider({ children }) {
  const [config, setConfig] = useState(currentConfig);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchConfig = useCallback(async () => {
    try {
      const configDocs = await getDocs(collection(db, 'appConfiguration'));
      
      const configData = {};
      configDocs.forEach((doc) => {
        configData[doc.id] = doc.data();
      });

      // Validate the config before using it
      if (configData?.processing?.exemptedColumns) {
        // Update state and global config
        setConfig(configData);
        currentConfig = configData;
        
        // Cache the valid config
        try {
          localStorage.setItem(CONFIG_CACHE_KEY, JSON.stringify(configData));
        } catch (cacheError) {
          console.warn('Failed to cache config:', cacheError);
        }
      } else {
        console.warn('Invalid config structure from Firestore');
      }
    } catch (error) {
      console.error('Error fetching config:', error);
      setError(error);
      
      // If we have cached config, we can continue using it
      const cachedConfig = localStorage.getItem(CONFIG_CACHE_KEY);
      if (cachedConfig) {
        try {
          const parsed = JSON.parse(cachedConfig);
          if (parsed?.processing?.exemptedColumns) {
            setConfig(parsed);
            currentConfig = parsed;
          }
        } catch (cacheError) {
          console.warn('Failed to parse cached config:', cacheError);
        }
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    let mounted = true;
    
    const initConfig = async () => {
      if (!mounted) return;
      await fetchConfig();
    };

    initConfig();

    return () => {
      mounted = false;
    };
  }, [fetchConfig]);

  const contextValue = {
    config,
    isLoading,
    error,
    refresh: async () => {
      setIsLoading(true);
      await fetchConfig();
    }
  };

  return (
    <ConfigContext.Provider value={contextValue}>
      {children}
    </ConfigContext.Provider>
  );
}

export function useConfig() {
  const context = useContext(ConfigContext);
  if (context === undefined) {
    throw new Error('useConfig must be used within a ConfigProvider');
  }
  return context;
}