// src/components/AgentOutput.js
import React, { useState } from 'react';
import { X, Copy, Clock, CheckCircle2, Globe } from 'lucide-react';
import { formatDistanceToNow } from 'date-fns';

const AgentOutput = ({ 
  output = '',
  agentName = 'Unknown Agent',
  timestamp, 
  isGlobal = false,
  onClose,
  className = '' 
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  if (!isVisible) return null;

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(output || '');
      setIsCopied(true);
      setTimeout(() => setIsCopied(false), 2000);
    } catch (err) {
      console.error('Failed to copy text:', err);
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    if (onClose) onClose();
  };

  const formattedTime = timestamp ? formatDistanceToNow(
    new Date(timestamp.seconds * 1000),
    { addSuffix: true }
  ) : 'Unknown time';

  const outputLines = (output || '').split('\n');

  return (
    <div className={`bg-white shadow-card rounded-lg
      bg-gradient-to-br from-primary/5 via-primary/10 to-transparent max-w-2xl ${className}`}>
      <div className="px-4 py-3 flex items-center justify-between border-b border-gray-100/50">
        <div className="flex items-center space-x-2">
          <h3 className="font-medium text-gray-900">{agentName}</h3>
          {isGlobal && (
            <Globe className="w-4 h-4 text-primary" title="Global output" />
          )}
          <div className="flex items-center text-sm text-gray-500">
            <Clock className="w-4 h-4 mr-1" />
            <span>{formattedTime}</span>
          </div>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={handleCopy}
            className="p-1.5 hover:bg-white/50 rounded-md transition-colors"
            title={isCopied ? 'Copied!' : 'Copy to clipboard'}
          >
            {isCopied ? (
              <CheckCircle2 className="w-4 h-4 text-green-500" />
            ) : (
              <Copy className="w-4 h-4 text-gray-500" />
            )}
          </button>
          <button
            onClick={handleClose}
            className="p-1.5 hover:bg-white/50 rounded-md transition-colors"
            title="Close"
          >
            <X className="w-4 h-4 text-gray-500" />
          </button>
        </div>
      </div>
      <div className="px-4 py-3 max-h-60 overflow-y-auto">
        <div className="prose prose-sm max-w-none">
          {outputLines.map((line, index) => (
            <React.Fragment key={index}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default AgentOutput;