import { useState, useRef, useEffect } from 'react';
import { httpsCallable } from 'firebase/functions';
import { ref, onValue, push, set, update, query, orderByChild, limitToLast, serverTimestamp } from 'firebase/database';
import { database, functions } from '../firebase';
import { toast } from 'react-hot-toast';

const MESSAGES_PER_PAGE = 50;

export const useChatSession = (currentUser, currentModel) => {
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const chatMessageRef = useRef(httpsCallable(functions, 'chatMessage'));
  const endChatSessionRef = useRef(httpsCallable(functions, 'endChatSession'));

  const formatEmailForPath = (email) => email.replace(/[.#$[\]]/g, '_');

  useEffect(() => {
    if (!currentUser?.email) return;

    const initializeSession = async () => {
      try {
        const userPath = formatEmailForPath(currentUser.email);
        
        if (sessionId) {
          const messagesRef = query(
            ref(database, `chatMessages/${userPath}/${sessionId}`),
            orderByChild('timestamp'),
            limitToLast(MESSAGES_PER_PAGE)
          );
      
          const unsubscribe = onValue(messagesRef, (snapshot) => {
            if (snapshot.exists()) {
              const messageData = snapshot.val();
              const messageList = Object.entries(messageData)
                .map(([id, msg]) => ({
                  id,
                  ...msg,
                }))
                .sort((a, b) => (a.timestamp || 0) - (b.timestamp || 0));
              
              setMessages(messageList);
            } else {
              setMessages([]);
            }
            setIsInitialLoad(false);
          });
      
          return () => unsubscribe();
        } else {
          setIsInitialLoad(false);
        }
      } catch (error) {
        console.error('Error initializing session:', error);
        toast.error('Failed to initialize chat session');
        setIsInitialLoad(false);
      }
    };

    initializeSession();
  }, [currentUser?.email, sessionId, currentModel]);

  const handleEndSession = async () => {
    if (!currentUser?.email || !sessionId) return;

    try {
      const userPath = formatEmailForPath(currentUser.email);
      const sessionRef = ref(database, `chatSessions/${userPath}/${sessionId}`);
      
      await update(sessionRef, {
        status: 'ended',
        endTime: serverTimestamp()
      });

      await endChatSessionRef.current({
        userEmail: currentUser.email,
        sessionId: sessionId
      });

      setSessionId(null);
      toast.success('Chat session ended');
    } catch (error) {
      console.error('Error ending chat session:', error);
      toast.error('Failed to end chat session');
    }
  };

  const handleSubmit = async (message) => {
    if (isLoading || !message.trim() || !currentUser?.email) return;
    
    setError(null);
    setIsLoading(true);
    setIsInitialLoad(false);
  
    let activeSessionId = sessionId;
    let tempMessageId = `temp_${Date.now()}`;
  
    try {
      if (!sessionId) {
        const userPath = formatEmailForPath(currentUser.email);
        const newSessionId = `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        
        activeSessionId = newSessionId;
        setSessionId(newSessionId);
        
        const sessionRef = ref(database, `chatSessions/${userPath}/${newSessionId}`);
        await set(sessionRef, {
          startTime: serverTimestamp(),
          status: 'active',
          lastActivity: serverTimestamp(),
          userEmail: currentUser.email,
          model: currentModel
        });
      }
  
      const userMessage = {
        id: tempMessageId,
        role: 'user',
        content: message.trim(),
        timestamp: Date.now(),
        userEmail: currentUser.email
      };
      
      setMessages(prevMessages => [...prevMessages, userMessage]);
  
      const userPath = formatEmailForPath(currentUser.email);
      const sessionRef = ref(database, `chatSessions/${userPath}/${activeSessionId}`);
      await update(sessionRef, {
        lastActivity: serverTimestamp(),
        model: currentModel
      });
  
      const functionResponse = await chatMessageRef.current({
        userEmail: currentUser.email,
        message: message.trim(),
        sessionId: activeSessionId,
        model: currentModel
      });
  
      if (!functionResponse?.data) {
        throw new Error('Invalid response from chat function');
      }
  
      return functionResponse.data;
  
    } catch (error) {
      console.error('Full error object:', error);
      
      let errorMessage;
      if (error.code === 'functions/invalid-argument') {
        errorMessage = 'Session expired. Starting new session...';
      } else if (error.code === 'functions/internal') {
        errorMessage = 'Server error occurred. Please try again.';
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== tempMessageId));
      } else {
        errorMessage = error.message || 'Failed to send message';
        setMessages(prevMessages => prevMessages.filter(msg => msg.id !== tempMessageId));
      }
  
      setError(errorMessage);
      throw new Error(errorMessage);
  
    } finally {
      setIsLoading(false);
    }
  };

  const handleReset = async () => {
    if (!currentUser?.email) return;

    try {
      if (sessionId) {
        await handleEndSession();
      }
      
      const userPath = formatEmailForPath(currentUser.email);
      const newSessionId = `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      setSessionId(newSessionId);
      
      const sessionRef = ref(database, `chatSessions/${userPath}/${newSessionId}`);
      await set(sessionRef, {
        startTime: serverTimestamp(),
        status: 'active',
        lastActivity: serverTimestamp(),
        userEmail: currentUser.email
      });

      setError(null);
      toast.success('Started new chat session');
    } catch (error) {
      console.error('Error starting new session:', error);
      toast.error('Failed to start new session');
    }
  };

  return {
    messages,
    isLoading,
    error,
    sessionId,
    isInitialLoad,
    setError,
    handleEndSession,
    handleSubmit,
    handleReset,
    setSessionId
  };
};