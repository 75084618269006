import React, { useEffect, useRef, useState } from 'react';
import { doc, getDocs, getDoc, collection, query, where } from 'firebase/firestore';
import { db } from '../../firebase';
import { X } from 'lucide-react';
import ValueSearchList from '../ValueSearchList';
import { Search } from 'lucide-react';
import { useAuth } from '../../contexts/AuthContext';


const MentionList = ({ 
  onSelect,
  onClose,
  isVisible,
  triggerChar = '@',
  inputValue,
  cursorPosition,
  selectedTable = null,
  onRemoveTable,
  onTextFormat = () => {}
}) => {
  const [items, setItems] = useState([]);
  const [mentions, setMentions] = useState([]);
  const [tables, setTables] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [mentionType, setMentionType] = useState('table');
  const [mentionStartIndex, setMentionStartIndex] = useState(-1);
  const [showValueSearch, setShowValueSearch] = useState(false);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [valueSearchItems, setValueSearchItems] = useState([]);
  const [valueSearchSelectedIndex, setValueSearchSelectedIndex] = useState(0);
  const [isSearching, setIsSearching] = useState(false);
  const listRef = useRef(null);
  const [indexedColumns, setIndexedColumns] = useState({});
  const indexCacheRef = useRef(new Map()); // Cache for index lookups
  const { allowedTables } = useAuth();


  useEffect(() => {
    const formattedHtml = formatInputWithMentions(inputValue, mentions);
    onTextFormat(formattedHtml);
  }, [inputValue, mentions, onTextFormat]);
  
  useEffect(() => {
    const fetchTables = async () => {
      try {
        const configDoc = await getDoc(doc(db, 'appConfiguration', 'tables'));
        if (!configDoc.exists()) {
          console.warn('Tables configuration not found');
          setTables([]);
          return;
        }
  
        const docData = configDoc.data();
        if (!docData || typeof docData !== 'object') {
          console.warn('Invalid tables configuration data');
          setTables([]);
          return;
        }
  
        const tablesData = Object.entries(docData)
          .filter(([_, value]) => 
            value && 
            typeof value === 'object' && 
            value.schema && 
            Array.isArray(value.schema) &&
            value.id && // Ensure table has an ID
            allowedTables && // Check if allowedTables exists
            allowedTables[value.id] // Check if table is allowed
          )
          .map(([name, tableData]) => ({
            id: tableData.id || name,
            name: name,
            displayName: formatText(name),
            schema: (tableData.schema || []).filter(field => 
              field && 
              field.name &&
              !field.name.toLowerCase().includes('created') &&
              !field.name.toLowerCase().includes('modified') &&
              !field.name.toLowerCase().includes('timestamp') &&
              field.mode !== 'REPEATED'
            ),
            description: tableData.description || ''
          }))
          .filter(table => table.name);
  
        setTables(tablesData);
      } catch (err) {
        console.error('Error fetching tables:', err);
        setTables([]);
      }
    };
  
    fetchTables();
  }, [allowedTables]);

  // New optimized batch fetching of indexed columns
  useEffect(() => {
    const fetchIndexedColumns = async () => {
      if (!selectedTable?.id) return;
      
      const cacheKey = selectedTable.id;
      if (indexCacheRef.current.has(cacheKey)) {
        setIndexedColumns(indexCacheRef.current.get(cacheKey));
        return;
      }

      try {
        const safeTableId = selectedTable.id.replace(/\./g, '_');
        const indexPrefix = `${safeTableId}_`;
        
        // Get all documents from index collection that start with the table ID
        const indexesSnapshot = await getDocs(
          query(collection(db, 'index'))
        );

        const indexedColumnsMap = {};
        indexesSnapshot.forEach(doc => {
          const docId = doc.id;
          if (docId.startsWith(indexPrefix)) {
            const columnName = docId.slice(indexPrefix.length);
            indexedColumnsMap[columnName] = true;
          }
        });

        setIndexedColumns(indexedColumnsMap);
        indexCacheRef.current.set(cacheKey, indexedColumnsMap);
      } catch (err) {
        console.error('Error fetching indexed columns:', err);
        setIndexedColumns({});
      }
    };

    fetchIndexedColumns();
  }, [selectedTable]);

  // Rest of the component remains the same
  useEffect(() => {
    if (!isVisible || showValueSearch) return;

    const lastTriggerIndex = inputValue.lastIndexOf(triggerChar, cursorPosition);
    if (lastTriggerIndex === -1) {
      if (!showValueSearch) {
        onClose();
      }
      return;
    }

    const searchStr = inputValue.slice(lastTriggerIndex + 1, cursorPosition);
    setSearchText(searchStr);
    setMentionStartIndex(lastTriggerIndex);

    if (!selectedTable) {
      setMentionType('table');
      const filteredTables = filterTables(searchStr);
      setItems(filteredTables);
    } else {
      setMentionType('column');
      const filteredColumns = filterColumns(searchStr, selectedTable);
      setItems(filteredColumns);
    }

    setSelectedIndex(0);
  }, [inputValue, cursorPosition, isVisible, selectedTable, triggerChar]);

  useEffect(() => {
    if (isVisible && selectedIndex >= 0 && listRef.current) {
      const selectedElement = listRef.current.children[selectedIndex];
      if (selectedElement) {
        selectedElement.scrollIntoView({ block: 'nearest' });
      }
    }
  }, [selectedIndex, isVisible]);

  useEffect(() => {
    if (!isVisible) {
      setMentions([]);
      onTextFormat('');
    }
  }, [isVisible]);

  const formatInputWithMentions = (input, currentMentions) => {
    if (!input) return '';
  
    const lines = input.split('\n');
    let result = [];
    let currentPosition = 0;
    
    lines.forEach((line, lineIndex) => {
      let lineResult = '';
      let lastIndex = 0;
      
      // Get mentions that fall within this line
      const lineMentions = currentMentions
        .filter(mention => {
          const mentionStart = mention.start - currentPosition;
          const mentionEnd = mention.end - currentPosition;
          return mentionStart >= 0 && mentionStart < line.length;
        })
        .sort((a, b) => (a.start - currentPosition) - (b.start - currentPosition));
      
      lineMentions.forEach(mention => {
        const mentionStart = mention.start - currentPosition;
        
        // Add regular text before mention
        lineResult += `<span class="text-white">${line.slice(lastIndex, mentionStart)}</span>`;
        
        // Add mention with appropriate color
        const mentionText = mention.type === 'value' 
          ? `${mention.item.column.name}:${mention.item.value}`
          : mention.value;
        
        const colorClass = mention.type === 'table' 
          ? 'text-accent'
          : mention.type === 'column' 
            ? 'text-pink-400'
            : 'text-pink-400';
        
        lineResult += `<span class="${colorClass}">@${mentionText}</span>`;
        lastIndex = mentionStart + (mention.end - mention.start);
      });
      
      // Add remaining text in the line
      if (lastIndex < line.length) {
        lineResult += `<span class="text-white">${line.slice(lastIndex)}</span>`;
      }
      
      result.push(lineResult);
      currentPosition += line.length + 1; // +1 for the newline character
    });
  
    return result.join('<br>');
  };
  
  const formatText = (text) => {
    if (!text) return '';
    return text
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  const filterTables = (searchStr) => {
    if (!Array.isArray(tables)) return [];
    return tables.filter(table => 
      table && 
      table.displayName && 
      table.displayName.toLowerCase().includes((searchStr || '').toLowerCase())
    );
  };

  const filterColumns = (searchStr, table) => {
    if (!table || !Array.isArray(table.schema)) return [];
    return table.schema.filter(column =>
      column && 
      column.name && 
      formatText(column.name).toLowerCase().includes((searchStr || '').toLowerCase())
    );
  };

  const handleSelect = async (item) => {
    if (!item) return;
  
    const formattedValue = mentionType === 'table' 
      ? (item.displayName || '') 
      : formatText(item.name || '');
  
    if (mentionType === 'column' && item.name && indexedColumns[item.name]) {
      setSelectedColumn(item);
      setShowValueSearch(true);
      setValueSearchSelectedIndex(0);
      setIsSearching(true);
      return;
    }
  
    // Define newMention before using it
    const newMention = {
      type: mentionType,
      value: formattedValue,
      start: mentionStartIndex,
      end: mentionStartIndex + formattedValue.length,
      item,
      isFromValueSearch: false
    };
  
    onSelect({
      type: mentionType,
      item,
      mentionStartIndex,
      formattedValue,
      tagType: mentionType
    });
    setMentions(prev => [...prev, newMention]);
  };

  const handleValueSelect = (value) => {
    if (!selectedColumn) return;
    
    const formattedColumnName = formatText(selectedColumn.name);
    const wrappedValue = indexedColumns[selectedColumn.name] ? `\`${value}\`` : value;
    
    onSelect({
      type: 'value',
      item: {
        name: selectedColumn.name,
        value: wrappedValue,
        column: selectedColumn,
        isFromValueSearch: true
      },
      mentionStartIndex,
      formattedValue: `${formattedColumnName}: ${wrappedValue}`,
      tagType: 'value'
    });

    const newMention = {
      type: 'value',
      value: `${formattedColumnName}: ${wrappedValue}`,
      start: mentionStartIndex,
      end: mentionStartIndex + `${formattedColumnName}: ${wrappedValue}`.length,
      item: {
        name: selectedColumn.name,
        value: wrappedValue,
        column: selectedColumn,
        isFromValueSearch: true
      },
      isFromValueSearch: true
    };
    setShowValueSearch(false);
    setSelectedColumn(null);
    setIsSearching(false);
  };

  const handleKeyDown = (e) => {
    if (!isVisible) return;

    if (showValueSearch) {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setValueSearchSelectedIndex(prev => 
            prev < valueSearchItems.length - 1 ? prev + 1 : prev
          );
          break;
          
        case 'ArrowUp':
          e.preventDefault();
          setValueSearchSelectedIndex(prev => 
            prev > 0 ? prev - 1 : 0
          );
          break;
          
        case 'Enter':
          e.preventDefault();
          if (valueSearchItems.length > 0) {
            handleValueSelect(valueSearchItems[valueSearchSelectedIndex]);
          }
          break;
          
        case 'Escape':
          e.preventDefault();
          setShowValueSearch(false);
          setSelectedColumn(null);
          setIsSearching(false);
          break;
      }
    } else {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault();
          setSelectedIndex(prev => 
            prev < items.length - 1 ? prev + 1 : prev
          );
          break;
          
        case 'ArrowUp':
          e.preventDefault();
          setSelectedIndex(prev => 
            prev > 0 ? prev - 1 : 0
          );
          break;
          
        case 'Enter':
          e.preventDefault();
          if (items.length > 0) {
            handleSelect(items[selectedIndex]);
          }
          break;
          
        case 'Escape':
          e.preventDefault();
          onClose();
          break;
      }
    }
  };

  const highlightMatch = (text) => {
    if (!text || !searchText) return <span>{text || ''}</span>;

    const index = text.toLowerCase().indexOf(searchText.toLowerCase());
    if (index === -1) return <span>{text}</span>;

    return (
      <>
        {text.slice(0, index)}
        <span className="font-semibold text-secondary">
          {text.slice(index, index + searchText.length)}
        </span>
        {text.slice(index + searchText.length)}
      </>
    );
  };

  const handleValueSearchUpdate = (items) => {
    setValueSearchItems(items || []);
    setValueSearchSelectedIndex(0);
  };

  useEffect(() => {
    const handleGlobalKeyDown = (e) => {
      // Only handle keydown if the mention list is visible
      if (!isVisible || (!items.length && !showValueSearch)) {
        return;
      }
      handleKeyDown(e);
    };

    document.addEventListener('keydown', handleGlobalKeyDown);
    return () => {
      document.removeEventListener('keydown', handleGlobalKeyDown);
    };
  }, [items, selectedIndex, showValueSearch, valueSearchItems, valueSearchSelectedIndex, isVisible]);

  if (!isVisible || (!showValueSearch && items.length === 0)) return null;

  return (
    <div className="absolute z-50 w-full mt-1 bg-gray-700 border border-secondary rounded-md shadow-lg focus-within:ring-2 focus-within:ring-secondary">
      {selectedTable && (
        <div className="px-4 py-2 border-b border-gray-600 flex items-center justify-between">
          <span className="text-sm text-gray-400">
            {showValueSearch ? (
              <span>
                Select a value for <span className="font-medium text-secondary">{formatText(selectedColumn?.name || '')}</span>
              </span>
            ) : (
              <span>
                Table: <span className="font-medium text-secondary">{selectedTable.displayName || 'Selected Table'}</span>
              </span>
            )}
          </span>
          <button
            onClick={() => {
              onRemoveTable && onRemoveTable();
              setShowValueSearch(false);
              setSelectedColumn(null);
              setIsSearching(false);
              setMentionType('table'); // Reset mention type to table
              setItems(tables); // Reset items to show tables
            }}
            className="p-1 hover:bg-gray-600 rounded-full text-gray-400 hover:text-gray-200"
          >
            <X size={16} />
          </button>
        </div>
      )}

      {showValueSearch && selectedColumn ? (
        <div className="p-4">
          <ValueSearchList
            tableId={selectedTable?.id}
            columnName={selectedColumn?.name}
            onSelect={handleValueSelect}
            selectedIndex={valueSearchSelectedIndex}
            onItemsUpdate={handleValueSearchUpdate}
            autoFocus={true}
            isSearching={isSearching}
          />
        </div>
      ) : (
        <>
          <div className="px-4 py-2 text-sm text-gray-400 border-b border-gray-600">
            {mentionType === 'table' ? 'Select Table' : 'Select Column'}
          </div>
          <ul 
            className="py-1 overflow-y-auto text-base" 
            style={{ maxHeight: '260px' }}
            ref={listRef}
            role="listbox"
            tabIndex={-1}
          >
            {items.map((item, index) => {
              if (!item) return null;
              
              const isSelected = index === selectedIndex;
              const displayText = mentionType === 'table' 
                ? (item.displayName || item.name || 'Unnamed Table') 
                : formatText(item.name || 'Unnamed Column');

              const key = mentionType === 'table' 
                ? `table-${item.id || item.name || index}`
                : `column-${item.name || index}`;

              return (
                <li
                  key={key}
                  className={`group px-4 py-2 cursor-pointer text-white transition-all duration-200 ease-in-out ${
                    isSelected ? 'bg-gray-800' : 'hover:bg-gray-800'
                  }`}
                  onClick={() => handleSelect(item)}
                  role="option"
                  aria-selected={isSelected}
                >
                  <div className="flex items-center justify-between">
                    <div className="flex items-center flex-1">
                      <span className="mr-2 text-sm text-secondary">
                        {mentionType === 'table' ? '📊' : '📝'}
                      </span>
                      <span className="text-white">
                        {highlightMatch(displayText)}
                      </span>
                      {mentionType === 'column' && indexedColumns[item.name] && (
                        <Search size={16} className="text-secondary ml-4" />
                      )}
                    </div>
                  </div>
                  {mentionType === 'table' && item.description && (
                    <div className="ml-6 text-xs text-gray-400">
                      {item.description}
                    </div>
                  )}
                </li>
              );
            })}
          </ul>
        </>
      )}
    </div>
  );
};

export default MentionList;