// src/components/AgentOutputContainer.js
import React from 'react';
import { Loader2 } from 'lucide-react';
import { useAgentOutput } from '../hooks/useAgentOutput';
import AgentOutput from './AgentOutput';
import { useAuth } from '../contexts/AuthContext';

const AgentOutputContainer = ({ 
  agentId,
  onClose,
  className = '' 
}) => {
  const { outputs, loading, error } = useAgentOutput();
  const { currentUser } = useAuth();

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-red-500 p-4">
        Error loading agent outputs: {error}
      </div>
    );
  }

  // If specific agentId is provided, show only that output
  if (agentId && outputs[agentId]) {
    // Check for global output first, then user-specific output
    const globalOutput = outputs[agentId]['global'];
    const userOutput = outputs[agentId][currentUser.email];
    const outputToShow = globalOutput || userOutput;
    
    if (!outputToShow) {
      return (
        <div className="text-gray-500 p-4">
          No output available for this agent.
        </div>
      );
    }

    return (
      <AgentOutput
        output={outputToShow.output}
        agentName={outputToShow.agentName}
        timestamp={outputToShow.timestamp}
        isGlobal={outputToShow.isGlobal}
        onClose={onClose}
        className={className}
      />
    );
  }

  // If no specific agentId is provided, show all relevant outputs
  return (
    <div className="space-y-4">
      {Object.entries(outputs).map(([agentId, userOutputs]) => {
        // Check for global output first, then user-specific output
        const globalOutput = userOutputs['global'];
        const userOutput = userOutputs[currentUser.email];
        const outputToShow = globalOutput || userOutput;
        
        if (!outputToShow) return null;

        return (
          <AgentOutput
            key={agentId}
            output={outputToShow.output}
            agentName={outputToShow.agentName}
            timestamp={outputToShow.timestamp}
            isGlobal={outputToShow.isGlobal}
            onClose={() => onClose?.(agentId)}
            className={className}
          />
        );
      })}
    </div>
  );
};

export default AgentOutputContainer;