import React from 'react';
import Button from '../Button';
import { Table, BarChart2, LineChart, PieChart, Settings, X } from 'lucide-react';

const formatLargeNumber = (num) => {
  const roundedNum = parseFloat(num.toFixed(2));
  if (roundedNum >= 1000000000) {
    return (roundedNum / 1000000000).toFixed(2).replace(/\.?0+$/, '') + 'B';
  }
  if (roundedNum >= 1000000) {
    return (roundedNum / 1000000).toFixed(2).replace(/\.?0+$/, '') + 'M';
  }
  if (roundedNum >= 1000) {
    return (roundedNum / 1000).toFixed(2).replace(/\.?0+$/, '') + 'K';
  }
  return roundedNum.toString();
};

const formatLabel = (label) => {
  if (!label || typeof label !== 'string') {
    return '';
  }
  return label
    .split('_')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

const AlignedButtonLayout = ({
  viewMode,
  setViewMode,
  chartType,
  setChartType,
  canShowGraph,
  isSingleValue,
  hasResults,
  isMobile,
  isDashboardMode,
  isEditMode,
  toggleEditMode,
  statsData,
  yAxisLabel,
  isNumberFormatted,
  onNumberFormatToggle,
  showFormatCheckbox,
  isTransposed
}) => {
  const handleViewModeChange = (newMode) => {
    setViewMode(newMode);
    if (newMode !== 'table') {
      setChartType(newMode);
    }
  };

  const shouldUseEditButton = isMobile || isDashboardMode;

  const renderControls = () => (
    <>
      {showFormatCheckbox && !isTransposed && (
        <div className="flex items-center space-x-2 mr-2">
          <input
            type="checkbox"
            id="number-format"
            checked={isNumberFormatted}
            onChange={(e) => onNumberFormatToggle(e.target.checked)}
            className="form-checkbox h-4 w-4 border-gray-300 text-gray-500 focus:ring-blue-500"
          />
          <label
            htmlFor="number-format"
            className="text-sm font-medium leading-none text-gray-700 select-none"
            onClick={(e) => e.preventDefault()}
          >
            Formatted
          </label>
        </div>
      )}
      {showFormatCheckbox && isTransposed && (
        <div className="flex items-center space-x-2 mr-2">
          <input
            type="checkbox"
            id="number-format"
            checked={isNumberFormatted}
            onChange={(e) => onNumberFormatToggle(e.target.checked)}
            className="form-checkbox h-4 w-4 border-gray-300 text-gray-500 focus:ring-blue-500"
          />
          <label
            htmlFor="number-format"
            className="text-sm font-medium leading-none text-gray-700 select-none"
            onClick={(e) => e.preventDefault()}
          >
            Formatted
          </label>
        </div>
      )}
      {canShowGraph && !isTransposed && (
        <>
          <Button
            onClick={() => handleViewModeChange('table')}
            icon={Table}
            className={`text-sm h-9 px-2 sm:px-3 py-2 ${viewMode === 'table' ? 'bg-blue-100' : ''}`}
          >
            <span className="hidden sm:inline">Table</span>
          </Button>
          <Button
            onClick={() => handleViewModeChange('bar')}
            icon={BarChart2}
            className={`text-sm h-9 px-2 sm:px-3 py-2 ${viewMode === 'bar' ? 'bg-blue-100' : ''}`}
          >
            <span className="hidden sm:inline">Bar</span>
          </Button>
          <Button
            onClick={() => handleViewModeChange('line')}
            icon={LineChart}
            className={`text-sm h-9 px-2 sm:px-3 py-2 ${viewMode === 'line' ? 'bg-blue-100' : ''}`}
          >
            <span className="hidden sm:inline">Line</span>
          </Button>
          <Button
            onClick={() => handleViewModeChange('pie')}
            icon={PieChart}
            className={`text-sm h-9 px-2 sm:px-3 py-2 ${viewMode === 'pie' ? 'bg-blue-100' : ''}`}
          >
            <span className="hidden sm:inline">Pie</span>
          </Button>
        </>
      )}
    </>
  );

  return (
    <div className={`flex flex-col space-y-4 ${isEditMode ? 'bg-gray-50 rounded-md p-2' : ''}`}>
      <div className="flex justify-between items-center">
        {!isEditMode && viewMode !== 'table' && statsData && (
          <div className="bg-gray-100 rounded-md px-4 py-2 flex flex-col items-start">
            <span className="text-xs text-gray-500 mb-1">{formatLabel(Array.isArray(yAxisLabel) ? yAxisLabel[0] : yAxisLabel)}</span>
            <div className="flex items-center space-x-4 text-sm text-gray-600">
              <span><strong>Sum:</strong> {formatLargeNumber(parseFloat(statsData.sum))}</span>
              <span><strong>Avg:</strong> {formatLargeNumber(parseFloat(statsData.avg))}</span>
            </div>
          </div>
        )}
        <div className="flex items-center space-x-2 ml-auto">
          {shouldUseEditButton ? (
            <Button
              onClick={toggleEditMode}
              icon={isEditMode ? X : Settings}
              className={`text-sm h-9 px-2 sm:px-3 py-2 ${
                isEditMode
                  ? 'bg-gray-700 text-white font-semibold'
                  : 'text-gray-700'
              } rounded-md transition-all duration-200`}
              title={isMobile ? undefined : (isEditMode ? 'Close Edit' : 'Edit')}
            >
              <span className="hidden sm:inline">{isEditMode ? 'Close Edit' : 'Edit'}</span>
            </Button>
          ) : null}
          {((!shouldUseEditButton) || (shouldUseEditButton && isEditMode)) && renderControls()}
        </div>
      </div>
    </div>
  );
};

export default AlignedButtonLayout;