const convertHtmlToMarkdown = (html) => {
  // Remove any trailing/leading whitespace
  let text = html.trim();
  
  // Replace <br> tags with newlines
  text = text.replace(/<br\s*\/?>/gi, '\n');
  
  // Convert headers with reduced spacing
  text = text.replace(/<h([1-6])>(.*?)<\/h\1>/gi, (match, level, content) => {
    return '\n' + '#'.repeat(level) + ' ' + content.trim() + '\n';
  });
  
  // Convert <b> or <strong> tags to markdown bold
  text = text.replace(/<(b|strong)>(.*?)<\/(b|strong)>/gi, '**$2**');
  
  // Enhanced ordered lists with proper nesting and spacing
  text = text.replace(/<ol>(.*?)<\/ol>/gis, (match, content) => {
    let counter = 1;
    const processedContent = content.replace(/<li>([\s\S]*?)<\/li>/gis, (m, item) => {
      // Handle nested lists within ordered list items
      const hasNestedList = /<[ou]l>/i.test(item);
      let processedItem = item;
      
      if (hasNestedList) {
        processedItem = processedItem.replace(/<ol>([\s\S]*?)<\/ol>/gi, (m2, nestedContent) => {
          let nestedCounter = 1;
          return '\n' + nestedContent.replace(/<li>(.*?)<\/li>/gi, (m3, nestedItem) => {
            return '   ' + nestedCounter++ + '. ' + nestedItem.trim() + '\n';
          });
        });
        
        processedItem = processedItem.replace(/<ul>([\s\S]*?)<\/ul>/gi, (m2, nestedContent) => {
          return '\n' + nestedContent.replace(/<li>(.*?)<\/li>/gi, (m3, nestedItem) => {
            return '   * ' + nestedItem.trim() + '\n';
          });
        });
      }
      
      const cleanItem = processedItem.trim().replace(/\n$/, '');
      return `${counter++}. ${cleanItem}\n`;
    });
    
    return '\n' + processedContent.trim() + '\n';
  });
  
  // Enhanced unordered lists processing with better nesting
  const processUlContent = (content, depth = 0) => {
    return content.replace(/<li>([\s\S]*?)<\/li>/g, (match, itemContent) => {
      // Check for nested lists
      const hasNestedList = /<[ou]l>/i.test(itemContent);
      let processedContent = itemContent;
      
      if (hasNestedList) {
        // Handle nested unordered lists with proper indentation
        processedContent = processedContent.replace(/<ul>([\s\S]*?)<\/ul>/gi, (m, nestedContent) => {
          return '\n' + processUlContent(nestedContent, depth + 1);
        });
        
        // Handle nested ordered lists with proper indentation
        processedContent = processedContent.replace(/<ol>([\s\S]*?)<\/ol>/gi, (m, nestedContent) => {
          let counter = 1;
          return '\n' + nestedContent.replace(/<li>(.*?)<\/li>/gi, (m2, nestedItem) => {
            const indent = '  '.repeat(depth + 1);
            return `${indent}${counter++}. ${nestedItem.trim()}\n`;
          });
        });
      }
      
      // Clean up the content and add proper indentation
      const indent = '  '.repeat(depth);
      const cleanContent = processedContent.trim().replace(/\n\s*$/, '');
      return `${indent}* ${cleanContent}\n`;
    });
  };

  text = text.replace(/<ul>([\s\S]*?)<\/ul>/gi, (match, content) => {
    return '\n' + processUlContent(content).trim() + '\n';
  });
  
  // Convert code blocks
  text = text.replace(/<pre><code>(.*?)<\/code><\/pre>/gis, (match, content) => {
    return '\n```\n' + content.trim() + '\n```\n';
  });
  
  // Convert inline code
  text = text.replace(/<code>(.*?)<\/code>/gi, '`$1`');
  
  // Remove any remaining HTML tags
  text = text.replace(/<[^>]+>/g, '');
  
  // Fix multiple consecutive newlines while preserving intentional spacing
  text = text.replace(/\n{3,}/g, '\n\n');
  
  return text.trim();
};

const convertMarkdownToHtml = (markdown) => {
  if (!markdown) return '';
  
  // Enhanced header conversion with minimal spacing
  let html = markdown.replace(/^(#{1,6})\s(.*)$/gm, (match, hashes, content) => {
    const level = hashes.length;
    return `<h${level}>${content.trim()}</h${level}>`;
  });
  
  // Replace markdown bold with HTML bold
  html = html.replace(/\*\*(.*?)\*\*/g, '<b>$1</b>');
  
  // Convert code blocks
  html = html.replace(/```([\s\S]*?)```/g, (match, content) => {
    return `<pre><code>${content.trim()}</code></pre>`;
  });
  
  // Convert inline code
  html = html.replace(/`([^`]+)`/g, '<code>$1</code>');
  
  // Enhanced nested ordered lists processing
  const processOrderedList = (text, indent = 0) => {
    const items = [];
    const indentRegex = new RegExp(`^${' '.repeat(indent * 2)}(\\d+)\\.\\s+(.+(?:\\n(?!\\s*\\d+\\.\\s|\\s*$).+)*)`, 'gm');
    
    let match;
    while ((match = indentRegex.exec(text)) !== null) {
      let [_, number, content] = match;
      
      // Check for nested lists
      const nextIndent = indent + 1;
      const nestedRegex = new RegExp(`\\n${' '.repeat(nextIndent * 2)}\\d+\\.\\s`, 'g');
      
      if (nestedRegex.test('\n' + content)) {
        content = content.replace(new RegExp(`\\n${' '.repeat(nextIndent * 2)}\\d+\\.\\s.*`, 'g'), (nestedMatch) => {
          return processOrderedList(nestedMatch, nextIndent);
        });
      }
      
      items.push(`<li>${content.trim()}</li>`);
    }
    
    return items.length ? `<ol>${items.join('')}</ol>` : '';
  };
  
  // Enhanced nested unordered lists processing
  const processUnorderedList = (text, indent = 0) => {
    const items = [];
    const indentRegex = new RegExp(`^${' '.repeat(indent * 2)}\\*\\s+(.+(?:\\n(?!\\s*[\\*\\d]|\\s*$).+)*)`, 'gm');
    
    let match;
    while ((match = indentRegex.exec(text)) !== null) {
      let [_, content] = match;
      
      // Check for nested lists (both unordered and ordered)
      const nextIndent = indent + 1;
      const nestedUlRegex = new RegExp(`\\n${' '.repeat(nextIndent * 2)}\\*\\s`, 'g');
      const nestedOlRegex = new RegExp(`\\n${' '.repeat(nextIndent * 2)}\\d+\\.\\s`, 'g');
      
      if (nestedUlRegex.test('\n' + content)) {
        content = content.replace(new RegExp(`\\n${' '.repeat(nextIndent * 2)}\\*\\s.*`, 'g'), (nestedMatch) => {
          return processUnorderedList(nestedMatch, nextIndent);
        });
      }
      
      if (nestedOlRegex.test('\n' + content)) {
        content = content.replace(new RegExp(`\\n${' '.repeat(nextIndent * 2)}\\d+\\.\\s.*`, 'g'), (nestedMatch) => {
          return processOrderedList(nestedMatch, nextIndent);
        });
      }
      
      items.push(`<li>${content.trim()}</li>`);
    }
    
    return items.length ? `<ul>${items.join('')}</ul>` : '';
  };
  
  // Process ordered lists
  html = html.replace(/(?:^(?:\d+\.\s+.+\n?)+)/gm, (match) => {
    return processOrderedList(match);
  });
  
  // Process unordered lists
  html = html.replace(/(?:^(?:\*\s+.+\n?)+)/gm, (match) => {
    return processUnorderedList(match);
  });
  
  // Replace remaining newlines with <br> tags
  html = html.replace(/\n\n+/g, '<br>');
  html = html.replace(/\n/g, '<br>');
  
  return html.trim();
};

export { convertHtmlToMarkdown, convertMarkdownToHtml };