import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Filter, Square, X, Check } from 'lucide-react';
import _ from 'lodash';

const FilterModal = ({ 
  isOpen, 
  onClose, 
  columnName, 
  data, 
  activeFilters,
  onApplyFilter,
  position,
  schema,
  style = {}
}) => {
  const [selectedValues, setSelectedValues] = useState(new Set());
  const [searchTerm, setSearchTerm] = useState('');
  const modalRef = useRef();

  const formatColumnName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  // Get unique values for the column
  const uniqueValues = useMemo(() => {
    if (!data || !columnName) return [];
    
    // First, apply filters from other columns
    const filteredData = data.filter(row => {
      return Object.entries(activeFilters).every(([filterCol, allowedValues]) => {
        // Skip current column when applying filters
        if (filterCol === columnName) return true;
        
        const cellValue = row[filterCol]?.displayValue ?? row[filterCol];
        const stringValue = cellValue === null || cellValue === undefined 
          ? 'N/A' 
          : String(cellValue);
        return allowedValues.includes(stringValue);
      });
    });
  
    // Then get unique values with counts
    const valueMap = filteredData.reduce((acc, row) => {
      const value = row[columnName]?.displayValue ?? row[columnName];
      const stringValue = value === null || value === undefined ? 'N/A' : String(value);
      acc[stringValue] = (acc[stringValue] || 0) + 1;
      return acc;
    }, {});
  
    // Return array of objects with value and count
    return Object.entries(valueMap)
      .map(([value, count]) => ({ value, count }))
      .sort((a, b) => a.value.localeCompare(b.value));
  }, [data, columnName, activeFilters]);

    // Initialize selected values from active filters
    useEffect(() => {
      if (!isOpen) return; // Only update when modal opens
      
      if (activeFilters && activeFilters[columnName]) {
        setSelectedValues(new Set(activeFilters[columnName]));
      } else {
        // Set all values selected by default using the new uniqueValues structure
        setSelectedValues(new Set(uniqueValues.map(item => item.value)));
      }
    }, [isOpen, columnName, uniqueValues, activeFilters]); // Removed data from dependencies as it's included via uniqueValues

  // Handle Escape key
  useEffect(() => {
    const handleEsc = (event) => {
      if (event.keyCode === 27) onClose();
    };
    window.addEventListener('keydown', handleEsc);
    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, [onClose]);

  const handleClickOutside = (event) => {
    if (isOpen && modalRef.current && !modalRef.current.contains(event.target)) {
      onClose();
    }
  };

  const filteredValues = useMemo(() => {
    if (!searchTerm) return uniqueValues;
    return uniqueValues.filter(item => 
      item.value.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [uniqueValues, searchTerm]);
  
  const areAllSelected = selectedValues.size === uniqueValues.length;
  
  const handleToggleAll = () => {
    if (areAllSelected) {
      setSelectedValues(new Set());
    } else {
      setSelectedValues(new Set(uniqueValues.map(item => item.value)));
    }
  };

  const handleValueToggle = (value) => {
    const newSelected = new Set(selectedValues);
    if (newSelected.has(value)) {
      newSelected.delete(value);
    } else {
      newSelected.add(value);
    }
    setSelectedValues(newSelected);
  };

  const handleApply = () => {
    // If all values are selected, pass an empty array to clear the filter
    if (selectedValues.size === uniqueValues.length) {
      onApplyFilter(columnName, []);
    } else {
      onApplyFilter(columnName, Array.from(selectedValues));
    }
    onClose();
  };

  if (!isOpen) return null;

  return (
    <div 
      className="fixed inset-0 z-50"
      onClick={handleClickOutside}
    >
      <div 
        ref={modalRef}
        className="bg-white rounded-lg shadow-xl animate-popIn flex flex-col"
        style={{
          position: 'fixed',
          top: `${position.top}px`,
          left: `${position.left}px`,
          width: '300px',
          maxHeight: '80vh',
          ...style
        }}
      >
        {/* Header */}
        <div className="bg-gray-100 px-6 py-4 flex justify-between items-center border-b border-gray-200">
          <h2 className="text-xl font-semibold text-gray-800">Filter: {formatColumnName(columnName)}</h2>
          <button
            onClick={onClose}
            className="text-gray-600 hover:text-gray-800 transition-colors duration-150"
          >
            <X size={24} />
          </button>
        </div>

        <div className="px-6 py-4 flex-grow overflow-hidden flex flex-col">
          {/* Search Input */}
          <input
            type="text"
            placeholder="Search values..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 border border-gray-300 rounded mb-4 text-sm text-text placeholder-text-lighter focus:outline-none focus:ring-2 focus:ring-secondary focus:border-secondary transition-colors duration-200"
          />

          {/* Select/Deselect All Button */}
          <div className="mb-4 pb-3 border-b border-gray-200">
            <button
              onClick={handleToggleAll}
              className="flex items-center space-x-2 text-sm text-gray-700 hover:text-gray-900"
            >
              <div className={`p-1 rounded ${
                areAllSelected
                  ? 'text-blue-600 bg-blue-100'
                  : 'text-gray-400 bg-gray-200'
              }`}>
                {areAllSelected ? <Check size={14} /> : <Square size={14} />}
              </div>
              <span>
                {areAllSelected ? 'Deselect All' : 'Select All'}
              </span>
            </button>
          </div>

          {/* Values List */}
          <div className="overflow-y-auto flex-grow">
            {filteredValues.map(({value, count}) => (
              <div
                key={value}
                className="flex items-center px-3 py-2 hover:bg-gray-50 cursor-pointer transition-colors duration-200"
                onClick={() => handleValueToggle(value)}
              >
                <div className={`p-1 rounded ${
                  selectedValues.has(value)
                    ? 'text-blue-600 bg-blue-100'
                    : 'text-gray-400 bg-gray-200'
                }`}>
                  {selectedValues.has(value) ? <Check size={14} /> : <Square size={14} />}
                </div>
                <div className="flex flex-1 justify-between items-center ml-2">
                  <span className="text-sm text-gray-700 truncate">{value}</span>
                  <span className="text-xs text-gray-500 ml-2">{count}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Footer */}
        <div className="bg-gray-50 px-6 py-3 border-t border-gray-200 flex items-center justify-end gap-3">
          <button
            onClick={onClose}
            className="px-6 py-2.5 text-sm text-gray-600 hover:text-gray-900 font-medium transition-colors duration-200"
          >
            Cancel
          </button>
          <button
            onClick={handleApply}
            className="px-6 py-2.5 bg-blue-600 text-white rounded-md text-sm font-medium hover:bg-blue-700 transition-colors duration-200"
          >
            Apply Filter
          </button>
        </div>
      </div>
    </div>
  );
};

export default FilterModal;