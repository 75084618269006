import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { auth, functions, db } from '../firebase';
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  signOut, 
  onAuthStateChanged,
  sendPasswordResetEmail 
} from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { doc, setDoc, serverTimestamp, onSnapshot, collection } from 'firebase/firestore';

const getFilteredSchema = async (email) => {
  try {
    console.log('[Auth] Calling getFilteredSchema for email:', email);
    const getSchema = httpsCallable(functions, 'getFilteredSchema');
    const result = await getSchema({ email });
    console.log('[Auth] getFilteredSchema result:', result);
    if (!result.data) {
      throw new Error('No schema data received');
    }
    return result.data;
  } catch (error) {
    console.error('[Auth] Error getting filtered schema:', error);
    throw error;
  }
};

const AuthContext = createContext();
const REGISTRATION_CACHE_KEY = 'registration_status';
const ALLOWED_TABLES_CACHE_KEY = 'allowed_tables';

export function useAuth() {
  return useContext(AuthContext);
}

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isRegistrationComplete, setIsRegistrationComplete] = useState(false);
  const [registrationChecked, setRegistrationChecked] = useState(false);
  const [allowedTables, setAllowedTables] = useState(null);
  const [unsubscribers, setUnsubscribers] = useState([]);


  // Cache management functions
  const getCachedRegistration = (email) => {
    try {
      const cached = localStorage.getItem(REGISTRATION_CACHE_KEY + '_' + email);
      if (cached) {
        const { status, userData } = JSON.parse(cached);
        return { status, userData };
      }
    } catch (error) {
      console.error('Error reading registration cache:', error);
    }
    return null;
  };

  const setCachedRegistration = (email, status, userData = null) => {
    try {
      localStorage.setItem(
        REGISTRATION_CACHE_KEY + '_' + email,
        JSON.stringify({ status, userData })
      );
    } catch (error) {
      console.error('Error setting registration cache:', error);
    }
  };

  const clearRegistrationCache = (email) => {
    try {
      localStorage.removeItem(REGISTRATION_CACHE_KEY + '_' + email);
    } catch (error) {
      console.error('Error clearing registration cache:', error);
    }
  };

  async function checkUserRegistration(email) {
    if (!email) {
      setIsRegistrationComplete(false);
      setRegistrationChecked(true);
      return { isRegistrationComplete: false };
    }
    
    // Check cache first
    const cachedData = getCachedRegistration(email);
    if (cachedData !== null) {
      console.log('Using cached registration status');
      // Add validation of required fields
      const isComplete = cachedData.status && 
        cachedData.userData && 
        cachedData.userData.englishName && 
        cachedData.userData.role;
      setIsRegistrationComplete(isComplete);
      setRegistrationChecked(true);
      return { 
        data: { 
          isRegistrationComplete: isComplete,
          userData: cachedData.userData
        }
      };
    }
  
    try {
      console.log('Checking registration status from server');
      const checkRegistration = httpsCallable(functions, 'checkUserRegistration');
      const result = await checkRegistration({ email });
      
      // Add validation of required fields
      const isComplete = result.data && 
        result.data.isRegistrationComplete && 
        result.data.userData && 
        result.data.userData.englishName && 
        result.data.userData.role;
      
      setIsRegistrationComplete(isComplete);
      setRegistrationChecked(true);
      
      // Cache the result with user data
      setCachedRegistration(email, isComplete, result.data.userData);
      
      return { 
        data: { 
          isRegistrationComplete: isComplete,
          userData: result.data.userData
        }
      };
    } catch (error) {
      console.error('Error checking user registration:', error);
      setIsRegistrationComplete(false);
      setRegistrationChecked(true);
      return { data: { isRegistrationComplete: false } };
    }
  }

  const setupRealtimeListeners = useCallback(async (userEmail) => {
    console.log('[Auth] Setting up real-time listeners for:', userEmail);
    
    // Clear existing listeners
    unsubscribers.forEach(unsub => unsub());
    setUnsubscribers([]);
    
    if (!userEmail) {
        console.log('[Auth] No user email available');
        return;
    }
  
    const newUnsubscribers = [];
  
    // Listen for table configuration changes
    const tablesUnsubscribe = onSnapshot(
        doc(db, 'appConfiguration', 'tables'),
        async (snapshot) => {
            console.log('[Auth] Tables configuration changed');
            try {
                // Use the passed userEmail instead of relying on currentUser
                const newSchema = await getFilteredSchema(userEmail);
                if (newSchema) {
                    console.log('[Auth] Updating allowed tables after tables change:', Object.keys(newSchema));
                    setAllowedTables(newSchema);
                }
            } catch (error) {
                console.error('[Auth] Error refreshing schema after tables update:', error);
            }
        },
        (error) => {
            console.error('[Auth] Error in tables listener:', error);
        }
    );
    newUnsubscribers.push(tablesUnsubscribe);

    // Enhanced listener for user preferences
    const userPrefsUnsubscribe = onSnapshot(
        doc(db, 'registeredUsersPreferences', userEmail),
        async (snapshot) => {
            console.log('[Auth] User preferences changed for:', userEmail);
            
            try {
                // Clear the cache immediately
                clearAllowedTablesCache(userEmail);
                
                // Use the passed userEmail instead of relying on currentUser
                const newSchema = await getFilteredSchema(userEmail);
                if (newSchema) {
                    console.log('[Auth] New schema fetched after preferences change:', Object.keys(newSchema));
                    setAllowedTables(newSchema);
                    // Update the cache with new data
                    setCachedAllowedTables(userEmail, newSchema);
                }
            } catch (error) {
                console.error('[Auth] Error refreshing schema after preferences update:', error, 'for user:', userEmail);
            }
        },
        (error) => {
            console.error('[Auth] Error in user preferences listener:', error);
        }
    );
    newUnsubscribers.push(userPrefsUnsubscribe);

    // Update the roles collection listener
    const rolesUnsubscribe = onSnapshot(
        collection(db, 'roles'),
        async (snapshot) => {
            console.log('[Auth] Roles collection changed');
            try {
                // Clear the cache
                clearAllowedTablesCache(userEmail);

                // Add these lines:
                if (currentUser) {
                    await currentUser.getIdToken(true);
                    await checkAdminStatus(currentUser);
                }
                
                // Use the passed userEmail instead of relying on currentUser
                const newSchema = await getFilteredSchema(userEmail);
                if (newSchema) {
                    console.log('[Auth] Updating allowed tables after roles change:', Object.keys(newSchema));
                    setAllowedTables(newSchema);
                }
            } catch (error) {
                console.error('[Auth] Error refreshing schema after roles update:', error);
            }
        },
        (error) => {
            console.error('[Auth] Error in roles listener:', error);
        }
    );
    newUnsubscribers.push(rolesUnsubscribe);

    setUnsubscribers(newUnsubscribers);
}, []);

  
const fetchAllowedTables = useCallback(async (email = null) => {
  const userEmail = email || currentUser?.email;
  
  if (!userEmail) {
      console.log('[Auth] No user email available');
      return null;
  }
  
  try {
      console.log('[Auth] Fetching allowed tables for user:', userEmail);
      const schema = await getFilteredSchema(userEmail);
      
      if (!schema || Object.keys(schema).length === 0) {
          console.error('[Auth] Received empty schema from backend');
          throw new Error('No schema data received from backend');
      }
  
      console.log('[Auth] Received schema from backend:', Object.keys(schema));
      
      const tables = Object.keys(schema).reduce((acc, tableId) => {
          acc[tableId] = true;
          return acc;
      }, {});
      
      if (Object.keys(tables).length === 0) {
          console.error('[Auth] No tables found in schema');
          throw new Error('No tables found in schema');
      }
      
      // Cache the result
      setCachedAllowedTables(userEmail, tables);
      return tables;
  } catch (err) {
      console.error('[Auth] Error fetching allowed tables:', err);
      throw err;
  }
}, [currentUser?.email]);

  // Cleanup listeners on unmount or user change
  useEffect(() => {
    return () => {
      console.log('[Auth] Cleaning up listeners');
      unsubscribers.forEach(unsub => unsub());
    };
  }, [unsubscribers]);


  const getCachedAllowedTables = (email) => {
    try {
      const cached = localStorage.getItem(ALLOWED_TABLES_CACHE_KEY + '_' + email);
      if (cached) {
        const { tables, timestamp } = JSON.parse(cached);
        // Cache expires after 1 hour
        if (Date.now() - timestamp < 3600000) {
          return tables;
        }
      }
    } catch (error) {
      console.error('Error reading allowed tables cache:', error);
    }
    return null;
  };

  const setCachedAllowedTables = (email, tables) => {
    try {
      localStorage.setItem(
        ALLOWED_TABLES_CACHE_KEY + '_' + email,
        JSON.stringify({
          tables,
          timestamp: Date.now()
        })
      );
    } catch (error) {
      console.error('Error setting allowed tables cache:', error);
    }
  };

  const clearAllowedTablesCache = (email) => {
    try {
      localStorage.removeItem(ALLOWED_TABLES_CACHE_KEY + '_' + email);
    } catch (error) {
      console.error('Error clearing allowed tables cache:', error);
    }
  };
  
  async function saveUserPreferences(email, preferences) {
    try {
      console.log('Saving user preferences', preferences);  // Add logging
      const savePrefs = httpsCallable(functions, 'saveUserPreferences');
      const result = await savePrefs({
        email,
        englishName: preferences.englishName,
        role: preferences.role,
        preferences: preferences.preferences || '',  // Add preferences
        enablePersonalization: preferences.enablePersonalization  // Add enablePersonalization
      });
  
      // Update registration status and cache
      setIsRegistrationComplete(true);
      setCachedRegistration(email, true, {
        ...preferences,
        userId: email
      });
  
      // Force a token refresh after successful save
      if (currentUser) {
        await currentUser.getIdToken(true);
        await checkAdminStatus(currentUser);
      }
  
      console.log('User preferences saved successfully:', result);
      return result.data;
    } catch (error) {
      console.error('Error saving user preferences:', error);
      throw new Error('Failed to save user preferences: ' + error.message);
    }
  }

  async function signup(email, password) {
    console.log('Signup attempt');
    try {
      const checkAllowedEmail = httpsCallable(functions, 'checkAllowedEmail');
      const result = await checkAllowedEmail({ email });
  
      if (result.data.allowed) {
        const userCredential = await createUserWithEmailAndPassword(auth, email, password);
        const setUserRole = httpsCallable(functions, 'addUserRole');
        await setUserRole({ email });
  
        // Implement a more robust retry mechanism
        const maxRetries = 5;
        const delay = (retryCount) => new Promise(resolve => setTimeout(resolve, 2000 * (retryCount + 1)));
  
        for (let retries = 0; retries < maxRetries; retries++) {
          try {
            console.log(`Attempting token refresh, attempt ${retries + 1}`);
            await delay(retries);
            await userCredential.user.getIdToken(true);
            await checkAdminStatus(userCredential.user);
            console.log('Token refresh successful');
            return userCredential;
          } catch (error) {
            console.error('Token refresh attempt failed');
            if (retries === maxRetries - 1) throw new Error('Failed to complete signup. Please try again.');
          }
        }
      } else {
        throw new Error('Email not allowed to register');
      }
    } catch (error) {
      console.error('Error in signup:', error);
      throw error;
    }
  }

  async function login(email, password) {
    console.log('Login attempt');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Login successful');
      return userCredential;
    } catch (error) {
      console.error('Error in login', error);
      throw new Error('Failed to log in. Please try again.');
    }
  }

  async function logout() {
    console.log('Logout attempt');
    try {
      // Clear listeners first
      unsubscribers.forEach(unsub => unsub());
      setUnsubscribers([]);
      
      if (currentUser?.email) {
        clearRegistrationCache(currentUser.email);
        clearAllowedTablesCache(currentUser.email);
      }
      
      await signOut(auth);
      setIsAdmin(false);
      setCurrentUser(null);
      setIsRegistrationComplete(false);
      setRegistrationChecked(false);
      setAllowedTables(null);
      console.log('Logout successful');
    } catch (error) {
      console.error('Error in logout');
      throw new Error('Failed to log out. Please try again.');
    }
  }

  async function resetPassword(email) {
    console.log('Password reset attempt');
    try {
      await sendPasswordResetEmail(auth, email);
      console.log('Password reset email sent successfully');
    } catch (error) {
      console.error('Error in resetting password');
      throw new Error('Failed to reset password. Please try again.');
    }
  }

  async function checkAdminStatus(user) {
    if (user) {
      try {
        console.log('Checking admin status');
        const idTokenResult = await user.getIdTokenResult(true);
        const newIsAdmin = !!idTokenResult.claims.admin;
        console.log('Admin status:', newIsAdmin);
        setIsAdmin(newIsAdmin);
        return newIsAdmin;
      } catch (error) {
        console.error('Error fetching token');
        setIsAdmin(false);
        return false;
      }
    } else {
      console.log('No user to check admin status');
      setIsAdmin(false);
      return false;
    }
  }

  async function forceTokenRefresh() {
    if (currentUser) {
      try {
        console.log('Forcing token refresh');
        await currentUser.getIdToken(true);
        await checkAdminStatus(currentUser);
      } catch (error) {
        console.error('Error forcing token refresh');
      }
    }
  }

  async function setAdminRole(email) {
    try {
      console.log('Setting admin role');
      const setUserRole = httpsCallable(functions, 'addUserRole');
      await setUserRole({ email });
      await forceTokenRefresh();
    } catch (error) {
      console.error('Error setting admin role');
      throw new Error('Failed to set admin role. Please try again.');
    }
  }

  useEffect(() => {
    console.log('Setting up auth state listener');
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        console.log('User authenticated');
        setCurrentUser(user);
        
        // Force token refresh first
        await user.getIdToken(true);
        
        // Immediately check admin status
        const adminStatus = await checkAdminStatus(user);
        setIsAdmin(adminStatus);
        
        // Then handle registration and tables
        const cachedData = getCachedRegistration(user.email);
        if (cachedData !== null) {
          console.log('Using cached registration data');
          setIsRegistrationComplete(cachedData.status);
          setRegistrationChecked(true);
          
          try {
            const tables = await fetchAllowedTables();
            setAllowedTables(tables);
            await setupRealtimeListeners(user.email);
          } catch (error) {
            console.error('[Auth] Error in setup:', error);
            setAllowedTables({});
          } finally {
            setLoading(false);
          }
        } else {
          try {
            const [regStatus, tables] = await Promise.all([
              checkUserRegistration(user.email),
              fetchAllowedTables()
            ]);
            
            setIsRegistrationComplete(regStatus.data.isRegistrationComplete);
            setRegistrationChecked(true);
            setAllowedTables(tables);
            await setupRealtimeListeners(user.email);
          } catch (error) {
            console.error('Error in auth setup:', error);
            setIsRegistrationComplete(false);
            setRegistrationChecked(true);
            setAllowedTables(null);
          } finally {
            setLoading(false);
          }
        }
      } else {
        console.log('No user authenticated');
        setCurrentUser(null);
        setIsAdmin(false);
        setIsRegistrationComplete(false);
        setRegistrationChecked(true);
        setAllowedTables(null);
        setLoading(false);
        
        // Clear listeners when user logs out
        unsubscribers.forEach(unsub => unsub());
        setUnsubscribers([]);
      }
    });
  
    return () => {
      console.log('Cleaning up auth state listener');
      unsubscribe();
    };
}, [setupRealtimeListeners, fetchAllowedTables]);

  const value = {
    currentUser,
    isAdmin,
    isRegistrationComplete,
    registrationChecked,
    allowedTables,
    signup,
    login,
    logout,
    resetPassword,
    checkAdminStatus,
    forceTokenRefresh,
    setAdminRole,
    saveUserPreferences,
    checkUserRegistration
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}