import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import Card from './Card';
import { Loader2, AlertCircle, Database, RefreshCw } from 'lucide-react';
import { functions, db } from '../firebase';
import { httpsCallable } from 'firebase/functions';
import { doc, getDoc } from 'firebase/firestore';

const TableAccessInfo = () => {
  const { currentUser, isAdmin } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [tables, setTables] = useState([]);
  const [firestoreTableNames, setFirestoreTableNames] = useState({});
  const [refreshing, setRefreshing] = useState(false);

  useEffect(() => {
    fetchTableAccess();
    fetchTableNames();
  }, [currentUser]);

  const fetchTableNames = async () => {
    try {
      const tablesDoc = await getDoc(doc(db, 'appConfiguration', 'tables'));
      if (tablesDoc.exists()) {
        const data = tablesDoc.data();
        const tableMap = {};
        Object.entries(data).forEach(([name, tableData]) => {
          if (tableData && tableData.id) {
            tableMap[tableData.id] = name;
          }
        });
        setFirestoreTableNames(tableMap);
      }
    } catch (err) {
      console.error('Error fetching table names:', err);
    }
  };

  const fetchTableAccess = async () => {
    if (!currentUser?.email) return;

    try {
      setLoading(true);
      const getTableAccessInfo = httpsCallable(functions, 'getTableAccessInfo');
      const result = await getTableAccessInfo({
        userId: currentUser.email,
        isAdmin
      });

      setTables(result.data.tables || []);
    } catch (err) {
      console.error('Error fetching table access:', err);
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  const handleRefresh = async () => {
    setRefreshing(true);
    await fetchTableAccess();
    setRefreshing(false);
  };

  const formatTimestamp = (timestamp) => {
    if (!timestamp) return 'Not available';
    
    try {
      const date = typeof timestamp === 'object' && timestamp.seconds 
        ? new Date(timestamp.seconds * 1000)
        : new Date(timestamp);
  
      if (isNaN(date.getTime())) return 'Not available';
  
      const now = new Date();
      const diffInHours = Math.floor((now - date) / (1000 * 60 * 60));
      const diffInDays = Math.floor(diffInHours / 24);
      
      let timeAgo;
      if (diffInHours < 1) {
        const diffInMinutes = Math.floor((now - date) / (1000 * 60));
        timeAgo = `${diffInMinutes} minute${diffInMinutes === 1 ? '' : 's'} ago`;
      } else if (diffInHours < 24) {
        timeAgo = `${diffInHours} hour${diffInHours === 1 ? '' : 's'} ago`;
      } else {
        timeAgo = `${diffInDays} day${diffInDays === 1 ? '' : 's'} ago`;
      }
  
      return `${date.toLocaleString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      })} (${timeAgo})`;
    } catch {
      return 'Not available';
    }
  };

  if (loading) {
    return (
      <div className="min-h-[200px] flex items-center justify-center bg-white rounded-lg">
        <div className="flex flex-col items-center">
          <Loader2 className="h-8 w-8 animate-spin text-primary" />
          <span className="mt-4 text-gray-600">Loading table access information...</span>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-[200px] flex items-center justify-center bg-white rounded-lg">
        <div className="flex flex-col items-center text-red-500">
          <AlertCircle className="h-8 w-8" />
          <span className="mt-4">Error: {error}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg">
      <div className="px-4 py-3 border-b border-gray-200">
        <div className="flex items-center justify-between">
          <h2 className="text-lg font-semibold text-gray-900">Table Access Information</h2>
          <div className="flex items-center space-x-3">
            <span className="text-sm text-gray-500">
              {tables.length} table{tables.length !== 1 ? 's' : ''} available
            </span>
            <button
              onClick={handleRefresh}
              disabled={refreshing}
              className="p-1.5 text-gray-500 hover:text-primary hover:bg-gray-100 rounded-full transition-colors"
            >
              <RefreshCw className={`h-4 w-4 ${refreshing ? 'animate-spin' : ''}`} />
            </button>
          </div>
        </div>
      </div>
      
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                Table Name
              </th>
              <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider border-b">
                Last Modified
              </th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-200">
            {tables.map((table) => (
              <tr key={table.id} className="hover:bg-gray-50 transition-colors">
                <td className="px-4 py-3">
                  <div className="flex items-center">
                    <Database className="h-4 w-4 text-gray-400 mr-2 flex-shrink-0" />
                    <span className="text-sm font-medium text-gray-900 truncate">
                      {firestoreTableNames[table.id] || table.name}
                    </span>
                  </div>
                </td>
                <td className="px-4 py-3">
                  <span className="text-sm text-gray-500">
                    {formatTimestamp(table.lastModifiedTime)}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TableAccessInfo;