import React, { useState, useRef, useEffect } from 'react';
import { History, AlertTriangle, X, Plus } from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import ChatMessage from '../components/ChatMessage';
import ChatInput from '../components/ChatInput';
import ChatHistory from '../components/ChatHistory';
import { httpsCallable } from 'firebase/functions';
import { ref, serverTimestamp, update, set } from 'firebase/database';
import { database, functions } from '../firebase';
import { toast } from 'react-hot-toast';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { useChatSession } from '../hooks/useChatSession';
import ModelSelector from '../components/ModelSelector';

const Chat = ({ mainContentRef }) => {
  const { currentUser } = useAuth();
  const [usageStats, setUsageStats] = useState(null);
  const [currentModel, setCurrentModel] = useState('claude');
  const messagesEndRef = useRef(null);
  const [showUsageWarning, setShowUsageWarning] = useState(false);
  const [userName, setUserName] = useState('');
  const [isNameLoading, setIsNameLoading] = useState(true);
  const [sessions, setSessions] = useState([]);

  const {
    messages,
    isLoading,
    error,
    sessionId,
    isInitialLoad,
    setError,
    handleEndSession,
    handleSubmit: handleMessageSubmit,
    handleReset,
    setSessionId
  } = useChatSession(currentUser, currentModel);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    if (!isInitialLoad && messages.length > 0) {
      scrollToBottom();
    }
  }, [messages, isInitialLoad]);

  useEffect(() => {
    const fetchUserName = async () => {
      if (!currentUser?.email) return;
      
      try {
        setIsNameLoading(true);
        const userPrefsRef = doc(db, 'registeredUsersPreferences', currentUser.email);
        const docSnap = await getDoc(userPrefsRef);
        
        if (docSnap.exists()) {
          const data = docSnap.data();
          setUserName(data.englishName || '');
        }
      } catch (error) {
        console.error('Error fetching user preferences:', error);
      } finally {
        setIsNameLoading(false);
      }
    };
  
    fetchUserName();
  }, [currentUser]);

  useEffect(() => {
    const isDesktop = window.innerWidth >= 768;
    setIsHistoryVisible(isDesktop && sessions.length > 0);
  }, [sessions]);

  const [isHistoryVisible, setIsHistoryVisible] = useState(() => {
    const isDesktop = window.innerWidth >= 768;
    return isDesktop && sessions.length > 0;
  });

  const fetchChatHistory = async () => {
    if (!currentUser?.email) return;
    
    try {
      const result = await httpsCallable(functions, 'getChatHistory')({
        userEmail: currentUser.email
      });
      
      setSessions(result.data.sessions);
    } catch (error) {
      console.error('Error fetching chat history:', error);
      toast.error('Failed to load chat history');
    }
  };

  useEffect(() => {
    if (currentUser?.email) {
      fetchChatHistory();
    }
  }, [currentUser?.email]);

  const handleSelectSession = async (selectedSessionId) => {
    if (!currentUser?.email) return;
    
    try {
      const userPath = currentUser.email.replace(/[.#$[\]]/g, '_');
      const sessionRef = ref(database, `chatSessions/${userPath}/${selectedSessionId}`);
      
      await update(sessionRef, {
        status: 'active',
        lastActivity: serverTimestamp()
      });
      
      setSessionId(selectedSessionId);
      setError(null);
      setIsHistoryVisible(false);
    } catch (error) {
      console.error('Error reactivating session:', error);
      toast.error('Failed to reactivate chat session');
    }
  };

  const handleSubmit = async (message) => {
    try {
      const response = await handleMessageSubmit(message);
      
      if (response.usage) {
        setUsageStats(response.usage);
        setShowUsageWarning(response.usage.monthlyTotal / response.usage.monthlyLimit > 0.8);
      }
    } catch (error) {
      if (error.message.includes('Session expired')) {
        setTimeout(() => handleReset(), 100);
      }
    }
  };

  const handleModelChange = async (model) => {
    try {
      if (sessionId) {
        await handleEndSession();
      }

      const userPath = currentUser.email.replace(/[.#$[\]]/g, '_');
      const newSessionId = `session_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
      
      const sessionRef = ref(database, `chatSessions/${userPath}/${newSessionId}`);
      await set(sessionRef, {
        startTime: serverTimestamp(),
        status: 'active',
        lastActivity: serverTimestamp(),
        userEmail: currentUser.email,
        model: model
      });
      
      setCurrentModel(model);
      setSessionId(newSessionId);
      setError(null);
      
      toast.success(`Switched to ${model === 'claude' ? 'Claude' : 'GPT-4'}`);
    } catch (error) {
      console.error('Error switching model:', error);
      toast.error('Failed to switch model');
    }
  };

  return (
    <div className="flex h-screen overflow-hidden bg-background font-sans max-w-[1200px] mx-auto w-full relative">
      <ChatHistory 
        sessions={sessions}
        onSelectSession={handleSelectSession}
        activeSessionId={sessionId}
        isVisible={isHistoryVisible}
        onClose={() => setIsHistoryVisible(false)}
      />
  
      <div className={`flex-1 flex flex-col h-screen ${isHistoryVisible ? 'md:opacity-100 opacity-30' : 'opacity-100'}`}>
        {/* Fixed Header */}
        <div className="flex-none z-20 flex items-center justify-between px-4 h-16 border-b border-gray-200 bg-white">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setIsHistoryVisible(!isHistoryVisible)}
              className={`p-1.5 rounded-lg transition-colors ${isHistoryVisible ? 'bg-primary/10 text-primary' : 'hover:bg-gray-100 text-text-light'}`}
              title="Toggle chat history"
            >
              <History className="w-5 h-5" />
            </button>
            <ModelSelector 
              currentModel={currentModel}
              onModelChange={handleModelChange}
              isLoading={isLoading}
            />
          </div>
  
          <div className="flex items-center space-x-4">
            {showUsageWarning && (
              <div className="hidden md:flex items-center text-amber-600 text-xs">
                <AlertTriangle className="w-4 h-4 mr-1" />
                <span>Approaching limit</span>
              </div>
            )}
            
            {usageStats && (
              <div className="hidden md:block text-xs text-text-light">
                {usageStats.monthlyTotal}/{usageStats.monthlyLimit}
              </div>
            )}
            
            <button
              onClick={handleReset}
              className="p-1.5 hover:bg-white/50 rounded-md transition-colors flex items-center space-x-1.5 text-text-light hover:text-primary"
              title="New conversation"
            >
              <Plus className="w-4 h-4" />
              <span className="text-xs font-medium hidden md:inline">New Chat</span>
            </button>
          </div>
        </div>
  
        {/* Scrollable Content Area with Flex Auto */}
        <div className="flex-auto overflow-y-auto">
          <div className="flex flex-col min-h-full">
            {isInitialLoad ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
              </div>
            ) : messages.length === 0 ? (
              <div className="flex-1 flex items-center justify-center">
                <div className="text-center space-y-3 px-4">
                  <h3 className="text-xl md:text-2xl font-semibold bg-gradient-to-r from-secondary to-primary bg-clip-text text-transparent">
                    {userName ? (
                      <span className="inline-block transition-all duration-300 ease-in-out">
                        <span className={`${isNameLoading ? 'opacity-0 translate-y-1' : 'opacity-100 translate-y-0'}`}>
                          {userName}
                        </span>
                        , start a conversation
                      </span>
                    ) : (
                      "start a conversation"
                    )}
                  </h3>
                  <p className="text-text-light text-base md:text-lg">
                    Let's work together!
                  </p>
                </div>
              </div>
            ) : (
              <div className="space-y-8 py-6 px-4">
                {messages.map((message) => (
                  <ChatMessage 
                    key={message.id} 
                    message={{ 
                      ...message, 
                      userInitials: userName?.split(' ')
                        .filter(name => name)
                        .slice(0, 2)
                        .map(name => name[0])
                        .join('')
                        .toUpperCase() || '--'
                    }}                 
                  />
                ))}
                <div ref={messagesEndRef} />
              </div>
            )}
          </div>
        </div>
  
        {/* Fixed Footer */}
        <div className="flex-none z-20 bg-white border-t border-gray-200">
          {error && (
            <div className="p-3 mx-4 mt-4 bg-red-50 border border-red-200 rounded-lg flex items-center justify-between">
              <div className="flex items-center text-red-700 text-sm">
                <AlertTriangle className="w-4 h-4 mr-2" />
                <span>{error}</span>
              </div>
              <button 
                onClick={() => setError(null)}
                className="text-red-700 hover:text-red-800 ml-2"
              >
                <X className="w-4 h-4" />
              </button>
            </div>
          )}
  
          <div className="p-4">
            <ChatInput 
              onSubmit={handleSubmit} 
              isLoading={isLoading}
              error={error}
              disabled={isInitialLoad}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Chat;