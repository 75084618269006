// src/components/RoleManagement.js
import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { collection, doc, getDocs, addDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import { Loader2, Plus, Edit, Trash2, ChevronDown, ChevronUp, AlertCircle, Check, X, Users } from 'lucide-react';
import Card from './Card';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';

const RoleManagement = () => {
  const { config } = useConfig();
  const { currentUser } = useAuth();
  const [roles, setRoles] = useState([]);
  const [policies, setPolicies] = useState([]);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editingRole, setEditingRole] = useState(null);
  const [expandedRoles, setExpandedRoles] = useState({});
  const [showNewRoleForm, setShowNewRoleForm] = useState(false);

  // Initial form state
  const emptyRole = {
    name: '',
    policies: []
  };

  const [formData, setFormData] = useState(emptyRole);

  // Fetch roles, policies, and users
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [rolesSnapshot, policiesSnapshot, usersSnapshot] = await Promise.all([
          getDocs(collection(db, 'roles')),
          getDocs(collection(db, 'policies')),
          getDocs(collection(db, 'registeredUsersPreferences'))
        ]);

        const rolesData = rolesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          policies: doc.data().policies || [] // Ensure policies always exists as an array
        }));
        
        const policiesData = policiesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));

        const usersData = usersSnapshot.docs.map(doc => ({
          email: doc.id,
          ...doc.data(),
          roles: doc.data().roles || []
        }));

        setRoles(rolesData);
        setPolicies(policiesData);
        setUsers(usersData);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch roles and policies');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const getRoleUsers = (roleId) => {
    return users.filter(user => user.roles?.includes(roleId));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const roleData = {
        ...formData,
        policies: formData.policies || [] // Ensure policies exists when saving
      };

      if (editingRole) {
        await updateDoc(doc(db, 'roles', editingRole.id), roleData);
        setRoles(prev => prev.map(role => 
          role.id === editingRole.id ? { ...role, ...roleData } : role
        ));
      } else {
        const docRef = await addDoc(collection(db, 'roles'), roleData);
        setRoles(prev => [...prev, { id: docRef.id, ...roleData }]);
      }
      setFormData(emptyRole);
      setEditingRole(null);
      setShowNewRoleForm(false);
    } catch (err) {
      console.error('Error saving role:', err);
      setError('Failed to save role');
    }
  };

  const handleDelete = async (roleId) => {
    // Get the role data
    const role = roles.find(r => r.id === roleId);
    
    // Prevent deletion of Data Owner role
    if (role.fullAccessTables) {
      setError('The Data Owner role cannot be deleted as it is a system role.');
      return;
    }

    if (window.confirm('Are you sure you want to delete this role? This may affect users assigned to this role.')) {
      try {
        const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/deleteRole`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              roleId,
              adminEmail: currentUser.email
            }
          })
        });
  
        const result = await response.json();

        if (!result.data.success) {
          throw new Error(result.data.details || 'Failed to delete role');
        }

        setRoles(prev => prev.filter(role => role.id !== roleId));
        
      } catch (err) {
        console.error('Error deleting role:', err);
        setError(err.message || 'Failed to delete role');
      }
    }
  };

  const handleCancel = () => {
    setEditingRole(null);
    setShowNewRoleForm(false);
    setFormData(emptyRole);
  };

  const toggleExpand = (roleId) => {
    setExpandedRoles(prev => ({
      ...prev,
      [roleId]: !prev[roleId]
    }));
  };

  const handlePolicyToggle = (policyId) => {
    setFormData(prev => {
      const policies = prev.policies?.includes(policyId)
        ? prev.policies.filter(id => id !== policyId)
        : [...(prev.policies || []), policyId];
      
      return {
        ...prev,
        policies
      };
    });
  };

  const getPolicyNames = (policyIds = []) => {
    return policyIds
      .map(id => policies.find(policy => policy.id === id)?.name)
      .filter(Boolean)
      .join(', ');
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* New Role button */}
      {!showNewRoleForm && !editingRole && (
        <button
          onClick={() => setShowNewRoleForm(true)}
          className="inline-flex items-center px-4 py-2 text-sm text-white bg-primary rounded-md hover:bg-primary/90"
        >
          <Plus className="h-4 w-4 mr-2" />
          New Role
        </button>
      )}
  
      {/* Role form */}
      {(showNewRoleForm || editingRole) && (
        <Card>
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold">
                {editingRole ? 'Edit Role' : 'Create New Role'}
              </h2>
              <button
                type="button"
                onClick={handleCancel}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Role Name
                </label>
                <input
                  type="text"
                  value={formData.name}
                  onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  required
                />
              </div>
  
              <div className="space-y-4">
                <h3 className="text-lg font-medium">Choose Policies to Assign</h3>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                  {policies.map(policy => (
                    <div
                      key={policy.id}
                      className={`border rounded-md p-4 cursor-pointer transition-colors ${
                        formData.policies?.includes(policy.id)
                          ? 'border-primary bg-primary bg-opacity-5'
                          : 'border-gray-200 hover:border-primary'
                      }`}
                      onClick={() => handlePolicyToggle(policy.id)}
                    >
                      <div className="flex items-center justify-between">
                        <span className="font-medium">{policy.name}</span>
                        {formData.policies?.includes(policy.id) && (
                          <Check className="h-5 w-5 text-primary" />
                        )}
                      </div>
                      <p className="text-sm text-gray-500 mt-1">
                        {Object.keys(policy.tables || {}).length} table(s)
                      </p>
                    </div>
                  ))}
                </div>
              </div>
  
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-sm text-white bg-primary rounded-md hover:bg-primary/90"
                >
                  {editingRole ? 'Update Role' : 'Create Role'}
                </button>
              </div>
            </form>
          </div>
        </Card>
      )}
  
      {error && (
        <div className="flex items-center space-x-2 text-red-500 p-4">
          <AlertCircle className="h-5 w-5" />
          <p>{error}</p>
        </div>
      )}
  
      <div className="space-y-4">
        {roles.map(role => {
          const roleUsers = getRoleUsers(role.id);
          
          return (
            <Card key={role.id}>
              <div className="p-4">
                <div 
                  onClick={() => toggleExpand(role.id)}
                  className="flex items-center justify-between cursor-pointer"
                >
                  <div className="flex items-center space-x-4">
                    <h3 className="text-lg font-medium">{role.name}</h3>
                    <div className="flex space-x-2" onClick={(e) => e.stopPropagation()}>
                      <button
                        onClick={() => {
                          setShowNewRoleForm(false);
                          setEditingRole(role);
                          setFormData(role);
                        }}
                        className="text-gray-500 hover:text-gray-700"
                      >
                        <Edit className="h-5 w-5" />
                      </button>
                      {!role.fullAccessTables && (
                        <button
                          onClick={() => handleDelete(role.id)}
                          className="text-red-500 hover:text-red-700"
                        >
                          <Trash2 className="h-5 w-5" />
                        </button>
                      )}
                      {role.fullAccessTables && (
                        <span className="inline-flex items-center gap-1 px-2.5 py-1 text-xs font-semibold rounded-full bg-primary bg-opacity-15 text-primary border border-primary">
                          <svg 
                            className="w-3.5 h-3.5" 
                            viewBox="0 0 24 24" 
                            fill="none" 
                            stroke="currentColor" 
                            strokeWidth="2"
                          >
                            <path d="M12 15V3m0 12l-4-4m4 4l4-4" />
                            <path d="M2 17l.621 2.485A2 2 0 004.561 21h14.878a2 2 0 001.94-1.515L22 17" />
                          </svg>
                          Data Owner
                        </span>
                      )}
                    </div>
                  </div>
                  <div className="text-gray-500">
                    {expandedRoles[role.id] ? (
                      <ChevronUp className="h-5 w-5" />
                    ) : (
                      <ChevronDown className="h-5 w-5" />
                    )}
                  </div>
                </div>
  
                <div className="mt-2 text-sm text-gray-500 space-y-1">
                  <div className="flex items-center space-x-1">
                    <span>{(role.policies || []).length} assigned {(role.policies || []).length === 1 ? 'policy' : 'policies'}</span>
                  </div>
                  <div className="flex items-center space-x-1">
                    <Users className="h-4 w-4" />
                    <span>{roleUsers.length} assigned {roleUsers.length === 1 ? 'user' : 'users'}</span>
                  </div>
                </div>
  
                {expandedRoles[role.id] && (
                  <div className="mt-4 space-y-4">
                    <div className="border border-gray-200 rounded-md p-4">
                      <h4 className="font-medium mb-2">Assigned Policies</h4>
                      <div className="space-y-2">
                        {(role.policies || []).length > 0 ? (
                          policies
                            .filter(policy => role.policies?.includes(policy.id))
                            .map(policy => (
                              <div key={policy.id} className="text-sm">
                                <span className="font-medium">{policy.name}</span>
                                <span className="text-gray-500 ml-2">
                                  ({Object.keys(policy.tables || {}).length} tables)
                                </span>
                              </div>
                            ))
                        ) : (
                          <p className="text-sm text-gray-500">No policies assigned</p>
                        )}
                      </div>
                    </div>

                    <div className="border border-gray-200 rounded-md p-4">
                      <h4 className="font-medium mb-2">Assigned Users</h4>
                      <div className="flex flex-wrap gap-2">
                        {roleUsers.length > 0 ? (
                          roleUsers.map(user => (
                            <div
                              key={user.email}
                              className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-800"
                            >
                              <span>{user.email}</span>
                            </div>
                          ))
                        ) : (
                          <p className="text-sm text-gray-500">No users assigned</p>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </Card>
          );
        })}
      </div>
    </div>
  );
};

export default RoleManagement;