import React, { useState, useRef, useEffect } from 'react';
import { Cpu } from 'lucide-react';
import { toast } from 'react-hot-toast';

const MODELS = {
  claude: {
    label: 'Claude'
  },
  gpt4: {
    label: 'GPT-4'
  }
};

const ModelSelector = ({ currentModel, onModelChange, isLoading }) => {
  const [isModelMenuOpen, setIsModelMenuOpen] = useState(false);
  const modelMenuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modelMenuRef.current && !modelMenuRef.current.contains(event.target)) {
        setIsModelMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleModelSelect = async (model) => {
    if (model === currentModel || isLoading) {
      setIsModelMenuOpen(false);
      return;
    }

    try {
      await onModelChange(model);
      setIsModelMenuOpen(false);
    } catch (error) {
      console.error('Error switching model:', error);
      toast.error('Failed to switch model');
    }
  };

  return (
    <div className="relative" ref={modelMenuRef}>
      <button
        onClick={() => setIsModelMenuOpen(!isModelMenuOpen)}
        disabled={isLoading}
        className="flex items-center space-x-2 px-3 py-1.5 rounded-md hover:bg-white/50 transition-colors border border-gray-200 shadow-sm disabled:opacity-50 disabled:cursor-not-allowed"
      >
        <Cpu className="w-4 h-4 text-primary mr-1.5" />
        <span className="text-sm font-medium text-text">
          {MODELS[currentModel].label}
        </span>
      </button>

      {isModelMenuOpen && (
        <div className="absolute left-0 mt-1 w-48 bg-white rounded-lg shadow-lg border border-gray-200 py-1 z-[9999]">
          {Object.entries(MODELS).map(([key, model]) => (
            <button
              key={key}
              onClick={() => handleModelSelect(key)}
              disabled={isLoading}
              className={`w-full px-4 py-2 text-left text-sm hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed ${
                currentModel === key ? 'text-primary font-medium' : 'text-text'
              }`}
            >
              <span>{model.label}</span>
              {currentModel === key && (
                <span className="ml-auto text-xs text-primary">Active</span>
              )}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export { MODELS };
export default ModelSelector;