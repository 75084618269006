import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { Loader2, AlertCircle, RefreshCw, Check, Clock, AlertTriangle } from 'lucide-react';
import { useConfig } from '../contexts/ConfigContext';

const IndexManagement = ({ table, onStatusUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [indexedColumns, setIndexedColumns] = useState([]);
  const [columnCounts, setColumnCounts] = useState({});
  const [updating, setUpdating] = useState(false);
  const [runningIndex, setRunningIndex] = useState(false);
  const [indexResults, setIndexResults] = useState(null);
  const [pendingColumns, setPendingColumns] = useState([]);
  const [tableName, setTableName] = useState(null);
  const { config } = useConfig();

  // Fetch initial indexing configuration and counts
  useEffect(() => {
    const fetchIndexingConfig = async () => {
      try {
        setLoading(true);
        setError(null);
     
        // First get the table name from the configuration
        const tablesDoc = await getDoc(doc(db, 'appConfiguration', 'tables'));
        const tablesData = tablesDoc.data();
        const foundTableName = Object.keys(tablesData).find(name => tablesData[name].id === table.id);
        setTableName(foundTableName);
  
        // Get the indexing configuration
        const indexingDoc = await getDoc(doc(db, 'appConfiguration', 'indexing'));
        const indexingConfig = indexingDoc.exists() ? indexingDoc.data() : {};
        const tableConfig = indexingConfig[foundTableName] || { id: table.id, columns: [] };
        setIndexedColumns(tableConfig.columns || []);
   
        // Fetch counts for indexed columns from the index collection
        const counts = {};
        const pending = [];

        // Query the index collection for documents related to this table
        const indexQuery = query(
          collection(db, 'index'),
          where('tableId', '==', table.id)
        );
        
        const indexSnapshot = await getDocs(indexQuery);
        const indexedData = {};
        
        indexSnapshot.forEach(doc => {
          const data = doc.data();
          if (data.columnName && data.values) {
            indexedData[data.columnName] = data.values.length;
          }
        });

        // Process each configured column
        for (const column of tableConfig.columns || []) {
          if (indexedData[column] !== undefined) {
            counts[column] = indexedData[column];
          } else {
            pending.push(column);
          }
        }

        setColumnCounts(counts);
        setPendingColumns(pending);
   
      } catch (err) {
        console.error('Error fetching indexing configuration:', err);
        setError('Failed to load indexing configuration');
      } finally {
        setLoading(false);
      }
    };
   
    fetchIndexingConfig();
  }, [table.id]);
   
  const handleToggleColumn = async (columnName) => {
    try {
      setUpdating(true);
      setError(null);
  
      const indexingDoc = await getDoc(doc(db, 'appConfiguration', 'indexing'));
      const indexingConfig = indexingDoc.exists() ? indexingDoc.data() : {};
      const currentColumns = (indexingConfig[tableName]?.columns) || [];
  
      let updatedColumns;
      if (currentColumns.includes(columnName)) {
        updatedColumns = currentColumns.filter(col => col !== columnName);
        setPendingColumns(prev => prev.filter(col => col !== columnName));
        setColumnCounts(prev => {
          const newCounts = { ...prev };
          delete newCounts[columnName];
          return newCounts;
        });
      } else {
        updatedColumns = [...currentColumns, columnName];
        setPendingColumns(prev => [...prev, columnName]);
      }
  
      await updateDoc(doc(db, 'appConfiguration', 'indexing'), {
        [tableName]: {
          id: table.id,
          columns: updatedColumns
        }
      });
  
      setIndexedColumns(updatedColumns);
      
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'success',
          message: `Column ${columnName} ${updatedColumns.includes(columnName) ? 'added to' : 'removed from'} indexing`
        });
      }
   
    } catch (err) {
      console.error('Error updating indexing configuration:', err);
      setError('Failed to update indexing configuration');
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'error',
          message: 'Failed to update indexing configuration'
        });
      }
    } finally {
      setUpdating(false);
    }
  };

  const handleRunIndex = async () => {
    try {
      setRunningIndex(true);
      setError(null);
      setIndexResults(null);
  
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/triggerValueIndexUpdate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ data: {} })
      });
  
      if (!response.ok) {
        throw new Error(`Failed to trigger index update: ${response.statusText}`);
      }
  
      const result = await response.json();
      setIndexResults(result);
      
      // Get latest indexing config and refresh counts
      const indexingDoc = await getDoc(doc(db, 'appConfiguration', 'indexing'));
      const indexingConfig = indexingDoc.exists() ? indexingDoc.data() : {};
      const tableConfig = indexingConfig[tableName] || { id: table.id, columns: [] };
  
      // Query the index collection for updated counts
      const indexQuery = query(
        collection(db, 'index'),
        where('tableId', '==', table.id)
      );
      
      const indexSnapshot = await getDocs(indexQuery);
      const counts = {};
      const pending = [];
      const indexedData = {};
      
      indexSnapshot.forEach(doc => {
        const data = doc.data();
        if (data.columnName && data.values) {
          indexedData[data.columnName] = data.values.length;
        }
      });

      for (const column of tableConfig.columns || []) {
        if (indexedData[column] !== undefined) {
          counts[column] = indexedData[column];
        } else {
          pending.push(column);
        }
      }
      
      setIndexedColumns(tableConfig.columns || []);
      setColumnCounts(counts);
      setPendingColumns(pending);
  
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'success',
          message: 'Index update completed successfully'
        });
      }
  
    } catch (err) {
      console.error('Error running index:', err);
      setError('Failed to run index update');
      if (onStatusUpdate) {
        onStatusUpdate({
          type: 'error',
          message: 'Failed to run index update'
        });
      }
    } finally {
      setRunningIndex(false);
    }
  };

  const getColumnStatus = (columnName) => {
    if (pendingColumns.includes(columnName)) {
      return {
        status: 'pending',
        icon: <Clock className="h-4 w-4 text-yellow-500" />,
        text: 'Pending Index'
      };
    }
    if (columnCounts[columnName] !== undefined) {
      return {
        status: 'indexed',
        icon: <Check className="h-4 w-4 text-green-500" />,
        text: columnCounts[columnName].toLocaleString()
      };
    }
    return {
      status: 'none',
      icon: <AlertTriangle className="h-4 w-4 text-gray-400" />,
      text: 'Not Indexed'
    };
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-4">
        <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex items-center space-x-2 text-red-500 py-4">
        <AlertCircle className="h-5 w-5" />
        <p className="text-sm">{error}</p>
      </div>
    );
  }

  return (
    <div>
      {/* Header with Run Index button */}
      <div className="flex justify-between items-center mb-4 px-4 py-2 bg-gray-50 rounded-t-lg">
        <button
          onClick={handleRunIndex}
          disabled={runningIndex}
          className={`inline-flex items-center px-4 py-2 rounded-md text-sm font-medium text-white
            ${runningIndex 
              ? 'bg-gray-400 cursor-not-allowed' 
              : 'bg-primary hover:bg-primary/90'}`}
        >
          {runningIndex ? (
            <>
              <Loader2 className="h-4 w-4 mr-2 animate-spin" />
              Running Index...
            </>
          ) : (
            <>
              <RefreshCw className="h-4 w-4 mr-2" />
              Run Index
            </>
          )}
        </button>
      </div>

      {/* Index Results Summary */}
      {indexResults && (
        <div className="mb-4 px-4">
          <div className="bg-blue-50 border border-blue-200 rounded-md p-4">
            <h4 className="text-sm font-medium text-blue-900 mb-2">Last Index Run Results</h4>
            <div className="text-sm text-blue-700">
              <p>Status: {indexResults.summary.status}</p>
              <p>Total Tables: {indexResults.summary.totalTables}</p>
              <p>Total Errors: {indexResults.summary.totalErrors}</p>
              {indexResults.summary.totalErrors > 0 && (
                <details className="mt-2">
                  <summary className="cursor-pointer">View Errors</summary>
                  <pre className="mt-2 text-xs bg-blue-100 p-2 rounded">
                    {JSON.stringify(indexResults.details.errors, null, 2)}
                  </pre>
                </details>
              )}
            </div>
          </div>
        </div>
      )}

      {/* Main Table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                Column Name
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                Type
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                Indexed
              </th>
              <th className="px-4 py-3 text-left text-xs font-bold text-gray-700 uppercase tracking-wider">
                Status
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {table.schema.map((field, index) => {
              const columnStatus = getColumnStatus(field.name);
              return (
                <tr key={index} className="hover:bg-gray-50 transition-colors">
                  <td className="px-4 py-3 text-sm font-medium text-gray-900">
                    {field.name}
                  </td>
                  <td className="px-4 py-3 text-sm text-gray-600">
                    {field.type}
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <button
                      onClick={() => handleToggleColumn(field.name)}
                      disabled={updating}
                      className={`relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-primary focus:ring-offset-2 ${
                        indexedColumns.includes(field.name) ? 'bg-primary' : 'bg-gray-200'
                      }`}
                    >
                      <span
                        className={`inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out ${
                          indexedColumns.includes(field.name) ? 'translate-x-5' : 'translate-x-0'
                        }`}
                      />
                    </button>
                  </td>
                  <td className="px-4 py-3 text-sm">
                    <div className="flex items-center space-x-2">
                      {columnStatus.icon}
                      <span className={`text-sm ${
                        columnStatus.status === 'pending' ? 'text-yellow-700' :
                        columnStatus.status === 'indexed' ? 'text-green-700' :
                        'text-gray-500'
                      }`}>
                        {columnStatus.text}
                      </span>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default IndexManagement;