// src/components/PolicyManagement/PolicyForm.js
import React, { useState, useEffect } from 'react';
import { Plus, X, Loader2, Check } from 'lucide-react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';
import Card from './Card';
import RowRestrictionBuilder from './RowRestrictionBuilder';

const PolicyForm = ({ policy = null, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    name: '',
    tables: {}
  });
  const [availableTables, setAvailableTables] = useState([]);
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const isNumericType = (columnType) => ['INTEGER', 'INT64', 'FLOAT', 'FLOAT64', 'NUMERIC', 'BIGNUMERIC'].includes(columnType);
  const isBooleanType = (columnType) => columnType === 'BOOLEAN';

  const formatValueForSQL = (value, columnType, operator) => {
    if (!value && value !== 0) return "''";
    
    if (operator === 'IN') {
      const values = value.split(',').map(v => `'${v.trim()}'`).join(', ');
      return `(${values})`;
    }
    
    if (operator === 'LIKE') {
      return `'%${value}%'`;
    }

    if (isNumericType(columnType)) {
      return value.toString();
    }

    if (isBooleanType(columnType)) {
      return value === 'true' || value === true ? 'true' : 'false';
    }

    return `'${value}'`;
  };

  // Fetch available tables from appConfiguration
  useEffect(() => {
    const fetchTables = async () => {
      try {
        setLoading(true);
        const configDoc = await getDoc(doc(db, 'appConfiguration', 'tables'));
        if (configDoc.exists()) {
          const tablesData = configDoc.data();
          const tables = Object.entries(tablesData)
            .filter(([_, value]) => 
              // Only include tables that:
              // 1. Are valid objects
              // 2. Are not materialized views
              // 3. Don't have a mother table
              typeof value === 'object' && 
              value !== null && 
              !value.isMaterializedView && 
              !value.motherTable
            )
            .map(([name, data]) => ({
              name,
              id: data.id,
              schema: data.schema || []
            }));
          setAvailableTables(tables);
        }
      } catch (err) {
        console.error('Error fetching tables:', err);
        setError('Failed to fetch available tables');
      } finally {
        setLoading(false);
      }
    };

    fetchTables();
  }, []);

  // Initialize form when editing existing policy
  useEffect(() => {
    if (policy) {
      setFormData(policy);
    } else {
      setFormData({ name: '', tables: {} });
    }
  }, [policy]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setSaving(true);
      setError(null);

      // Validate tables have required fields
      for (const [tableName, tableData] of Object.entries(formData.tables)) {
        if (!tableData.originalTable) {
          throw new Error(`Incomplete configuration for table: ${tableName}`);
        }
        
        if (tableData.allowedColumns.length === 0) {
          throw new Error(`No columns selected for table: ${tableName}`);
        }
      }

      await onSave(formData, policy?.id);
      setFormData({ name: '', tables: {} });
    } catch (err) {
      setError(err.message);
    } finally {
      setSaving(false);
    }
  };

  const handleSelectTable = (tableName) => {
    const selectedTable = availableTables.find(t => t.name === tableName);
    if (selectedTable) {
      setFormData(prev => ({
        ...prev,
        tables: {
          ...prev.tables,
          [tableName]: {
            originalTable: selectedTable.id,
            allowedColumns: [],
            restrictions: [],
            rowRestriction: '',
            refreshInterval: 360
          }
        }
      }));
    }
  };

  const handleRemoveTable = (tableName) => {
    const newTables = { ...formData.tables };
    delete newTables[tableName];
    setFormData(prev => ({
      ...prev,
      tables: newTables
    }));
  };

  const handleToggleColumn = (tableName, columnName) => {
    setFormData(prev => {
      const table = prev.tables[tableName];
      const columns = table.allowedColumns || [];
      const newColumns = columns.includes(columnName)
        ? columns.filter(c => c !== columnName)
        : [...columns, columnName];

      return {
        ...prev,
        tables: {
          ...prev.tables,
          [tableName]: {
            ...table,
            allowedColumns: newColumns
          }
        }
      };
    });
  };

  const handleToggleAllColumns = (tableName) => {
    const table = availableTables.find(t => t.name === tableName);
    if (table) {
      const allColumns = table.schema.map(field => field.name);
      setFormData(prev => ({
        ...prev,
        tables: {
          ...prev.tables,
          [tableName]: {
            ...prev.tables[tableName],
            allowedColumns: prev.tables[tableName].allowedColumns.length === allColumns.length ? [] : allColumns
          }
        }
      }));
    }
  };

  // Helper function to generate materialized view ID
  const getMaterializedViewId = (tableName, policyName) => {
    const baseTableId = formData.tables[tableName]?.originalTable;
    if (!baseTableId || !policyName) return '';
    
    const viewSuffix = policyName.toLowerCase().replace(/[^a-z0-9_]/g, '_');
    return `${baseTableId}_${viewSuffix}`;
  };

  if (loading) {
    return (
      <Card>
        <div className="p-6 flex justify-center">
          <Loader2 className="h-6 w-6 animate-spin text-gray-500" />
        </div>
      </Card>
    );
  }

  return (
    <Card>
      <div className="p-6">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-xl font-semibold">
            {policy ? 'Edit Policy' : 'Create New Policy'}
          </h2>
          <button
            type="button"
            onClick={onCancel}
            className="text-gray-500 hover:text-gray-700"
          >
            <X className="h-5 w-5" />
          </button>
        </div>
        
        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Policy Name
            </label>
            <input
              type="text"
              value={formData.name}
              onChange={e => setFormData(prev => ({ ...prev, name: e.target.value }))}
              className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              required
            />
          </div>

          <div className="space-y-4">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-medium">Tables</h3>
              {/* Table Selection Dropdown */}
              <select
                onChange={(e) => handleSelectTable(e.target.value)}
                value=""
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
              >
                <option value="">Add Table</option>
                {availableTables
                  .filter(table => !formData.tables[table.name])
                  .map(table => (
                    <option key={table.name} value={table.name}>
                      {table.name}
                    </option>
                  ))}
              </select>
            </div>

            {Object.entries(formData.tables).map(([tableName, tableData]) => {
              const tableConfig = availableTables.find(t => t.name === tableName);
              const materializedViewId = getMaterializedViewId(tableName, formData.name);
              
              return (
                <div key={tableName} className="border border-gray-200 rounded-md p-4 space-y-4">
                  <div className="flex items-center justify-between">
                    <h4 className="text-base font-medium">{tableName}</h4>
                    <button
                      type="button"
                      onClick={() => handleRemoveTable(tableName)}
                      className="text-red-500 hover:text-red-700"
                    >
                      <X className="h-5 w-5" />
                    </button>
                  </div>

                  <div className="space-y-3">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-1">
                        Original Table ID
                      </label>
                      <input
                        type="text"
                        value={tableData.originalTable}
                        readOnly
                        className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md"
                      />
                    </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Materialized View ID
                          </label>
                          <input
                            type="text"
                            value={materializedViewId}
                            readOnly
                            className="w-full px-3 py-2 bg-gray-50 border border-gray-300 rounded-md"
                          />
                        </div>

                        <div>
                          <div className="flex items-center justify-between mb-2">
                            <label className="block text-sm font-medium text-gray-700">
                              Allowed Columns
                            </label>
                            <button
                              type="button"
                              onClick={() => handleToggleAllColumns(tableName)}
                              className="text-sm text-primary hover:text-primary/90"
                            >
                              {tableConfig && tableData.allowedColumns.length === tableConfig.schema.length
                                ? 'Deselect All'
                                : 'Select All'}
                            </button>
                          </div>
                          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-2">
                            {tableConfig?.schema.map(field => (
                              <label
                                key={field.name}
                                className="flex items-center space-x-2 p-2 rounded border border-gray-200 hover:bg-gray-50"
                              >
                                <input
                                  type="checkbox"
                                  checked={tableData.allowedColumns.includes(field.name)}
                                  onChange={() => handleToggleColumn(tableName, field.name)}
                                  className="rounded border-gray-300 text-primary focus:ring-primary"
                                />
                                <span className="text-sm text-gray-700">{field.name}</span>
                              </label>
                            ))}
                          </div>
                        </div>

                        <div>
                          <RowRestrictionBuilder
                            columns={tableConfig?.schema || []}
                            allowedColumns={tableData.allowedColumns || []}
                            restrictions={tableData.restrictions || []}
                            onChange={(newRestrictions) => {
                              const rowRestriction = newRestrictions
                                .map(({ column, operator, value, columnType }) => {
                                  if (operator === 'IN') {
                                    const values = value.split(',').map(v => `'${v.trim()}'`).join(', ');
                                    return `${column} IN (${values})`;
                                  } else if (operator === 'LIKE') {
                                    return `${column} LIKE '%${value}%'`;
                                  } else {
                                    const formattedValue = formatValueForSQL(value, columnType, operator);
                                    return `${column} ${operator} ${formattedValue}`;
                                  }
                                })
                                .join(' AND ');
                            
                              setFormData(prev => ({
                                ...prev,
                                tables: {
                                  ...prev.tables,
                                  [tableName]: {
                                    ...prev.tables[tableName],
                                    restrictions: newRestrictions,
                                    rowRestriction: rowRestriction
                                  }
                                }
                              }));
                            }}
                          />
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700 mb-1">
                            Refresh Interval (minutes)
                          </label>
                          <input
                            type="number"
                            value={tableData.refreshInterval}
                            onChange={e => setFormData(prev => ({
                              ...prev,
                              tables: {
                                ...prev.tables,
                                [tableName]: {
                                  ...prev.tables[tableName],
                                  refreshInterval: parseInt(e.target.value)
                                }
                              }
                            }))}
                            min="1"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                            required
                          />
                        </div>
                  </div>
                </div>
              );
            })}
          </div>

          {error && (
            <div className="text-sm text-red-600">
              {error}
            </div>
          )}

          <div className="flex justify-end space-x-3">
            {policy && (
              <button
                type="button"
                onClick={onCancel}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
              >
                Cancel
              </button>
            )}
            <button
              type="submit"
              disabled={saving}
              className="inline-flex items-center px-4 py-2 text-sm text-white bg-primary rounded-md hover:bg-primary/90 disabled:bg-gray-400"
            >
              {saving ? (
                <>
                  <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                  Saving...
                </>
              ) : (
                policy ? 'Update Policy' : 'Create Policy'
              )}
            </button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default PolicyForm;