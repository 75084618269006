import React, { useState, useMemo } from 'react';
import { RefreshCw, Search, X } from 'lucide-react';
import ColumnComparison from './ColumnComparison';
import ColumnAdditionManagement from './ColumnAdditionManagement';

const ColumnComparisonList = ({
  originalColumns = [],
  newColumns = [],
  onRefresh,
  onRefreshStats,
  isRefreshing,
  isRefreshingStats,
  selectedTable,
  columnStats = {},
  fixes = {},
  user,
  onFixOperationStatus,
  onColumnAdded,
  onFixApproved,
  newTable = null,
  onDataRefresh,
  onRefreshAll
}) => {
  const [typeFilter, setTypeFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [expandedColumn, setExpandedColumn] = useState(null);
  const [isFixesExpanded, setIsFixesExpanded] = useState(true);

  // Identify newly added columns (exist in newColumns but not in originalColumns)
  const addedColumns = useMemo(() => {
    if (!newColumns || !originalColumns) return [];
    return newColumns.filter(
      newCol => !originalColumns.some(origCol => origCol.name === newCol.name)
    );
  }, [newColumns, originalColumns]);

  // Get unique column types including those from added columns
  const columnTypes = useMemo(() => {
    const types = new Set(originalColumns.map(col => col.type));
    addedColumns.forEach(col => types.add(col.type));
    return types;
  }, [originalColumns, addedColumns]);

  // Filter columns based on type, search term, and added status
  const filteredColumns = useMemo(() => {
    // Create a Map to track unique columns by name
    const uniqueColumns = new Map();
    
    // Add original columns first
    originalColumns.forEach(col => {
      uniqueColumns.set(col.name, col);
    });
    
    // Add new columns, overwriting any existing ones and marking them as newly added
    addedColumns.forEach(col => {
      uniqueColumns.set(col.name, {
        ...col,
        isNewlyAdded: true
      });
    });
    
    // Convert back to array
    const allColumns = Array.from(uniqueColumns.values());
  
    return allColumns.filter(column => {
      const matchesType = typeFilter === 'all' || 
                         column.type === typeFilter || 
                         (typeFilter === 'changes' && fixes[column.name]?.some(fix => fix.metadata?.status === 'active')) ||
                         (typeFilter === 'added' && column.isNewlyAdded);
      
      const matchesSearch = column.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                          (column.description && column.description.toLowerCase().includes(searchTerm.toLowerCase()));
      
      return matchesType && matchesSearch;
    });
  }, [originalColumns, addedColumns, typeFilter, searchTerm, fixes]);

  const handleSearchClose = () => {
    setSearchTerm('');
    setIsSearchExpanded(false);
  };

  if (!selectedTable) return null;

  return (
    <div className="space-y-6">
      {/* Column Addition Management */}
      {newTable && (
        <div className="space-y-4">
          <h3 className="text-xl font-semibold text-gray-900">Column Additions</h3>
          <ColumnAdditionManagement
            tableId={selectedTable.id}
            onColumnOperationStatus={onFixOperationStatus}
            onColumnAdded={onColumnAdded}
            user={user}
          />
        </div>
      )}
  
      {/* Column Changes Section */}
      <div className="space-y-4">
        <div className="flex items-center justify-between">
          <h3 className="text-xl font-semibold text-gray-900">Column Changes</h3>
          <div className="flex items-center gap-4">
            <div className="text-sm text-gray-600">
              Showing {filteredColumns.length} of {originalColumns.length + addedColumns.length} columns
              {addedColumns.length > 0 && ` (including ${addedColumns.length} new)`}
            </div>
            <button
              onClick={onRefreshAll}
              disabled={isRefreshingStats.all || isRefreshing}
              className="flex items-center px-3 py-1.5 text-sm text-gray-600 hover:text-gray-900 border border-gray-200 rounded-md shadow-sm"
            >
              <RefreshCw 
                className={`h-4 w-4 mr-2 ${isRefreshingStats.all || isRefreshing ? 'animate-spin' : ''}`} 
              />
              Refresh All
            </button>
          </div>
        </div>
        
        {/* Filters and Search Section */}
        <div className="bg-gray-50 px-4 py-3 rounded-lg flex items-center justify-between">
          <div className="flex items-center gap-3 flex-wrap">
            <button
              onClick={() => setTypeFilter('all')}
              className={`px-3 py-1.5 rounded-full text-xs font-medium transition-colors ${
                typeFilter === 'all' 
                  ? 'bg-primary text-white' 
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              } border border-gray-200`}
            >
              All
            </button>
            <button
              onClick={() => setTypeFilter('changes')}
              className={`px-3 py-1.5 rounded-full text-xs font-medium transition-colors ${
                typeFilter === 'changes' 
                  ? 'bg-primary text-white' 
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              } border border-gray-200`}
            >
              Active Changes
            </button>
            <button
              onClick={() => setTypeFilter('added')}
              className={`px-3 py-1.5 rounded-full text-xs font-medium transition-colors ${
                typeFilter === 'added' 
                  ? 'bg-primary text-white' 
                  : 'bg-white text-gray-600 hover:bg-gray-100'
              } border border-gray-200`}
            >
              Added Columns
            </button>
            {Array.from(columnTypes).map(type => (
              <button
                key={type}
                onClick={() => setTypeFilter(type)}
                className={`px-3 py-1.5 rounded-full text-xs font-medium transition-colors ${
                  typeFilter === type 
                    ? 'bg-primary text-white' 
                    : 'bg-white text-gray-600 hover:bg-gray-100'
                } border border-gray-200`}
              >
                {type}
              </button>
            ))}
          </div>
  
          {/* Search */}
          <div className="relative">
            <div className={`flex items-center transition-all duration-300 ease-in-out ${isSearchExpanded ? 'w-64' : 'w-8'}`}>
              {isSearchExpanded ? (
                <div className="relative w-full">
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 h-4 w-4 text-gray-400" />
                  <input
                    type="text"
                    placeholder="Search columns..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="pl-10 pr-8 py-1.5 w-full border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                    onBlur={() => !searchTerm && setIsSearchExpanded(false)}
                    autoFocus
                  />
                  {searchTerm && (
                    <button
                      onClick={handleSearchClose}
                      className="absolute right-3 top-1/2 transform -translate-y-1/2"
                    >
                      <X className="h-4 w-4 text-gray-400 hover:text-gray-600" />
                    </button>
                  )}
                </div>
              ) : (
                <button
                  onClick={() => setIsSearchExpanded(true)}
                  className="p-1.5 hover:bg-gray-100 rounded-full transition-colors duration-200 border border-gray-200 shadow-sm"
                >
                  <Search className="h-4 w-4 text-gray-600" />
                </button>
              )}
            </div>
          </div>
        </div>
  
        {/* Column List */}
        <div className="space-y-6">
          {filteredColumns.map(column => {
            const isNewlyAdded = column.isNewlyAdded;
            const originalColumn = isNewlyAdded ? {
              name: column.name,
              type: column.type,
              samples: [],
              description: ''
            } : column;
            
            const newColumn = isNewlyAdded ? column : (
              newColumns?.find(c => c.name === column.name) || {
                name: column.name,
                type: column.type,
                samples: [],
                description: ''
              }
            );
            
            const columnFixes = newTable ? (fixes[column.name] || []) : [];
            
            return (
              <ColumnComparison
                key={column.name}
                originalColumn={originalColumn}
                newColumn={newColumn}
                originalStats={columnStats[`${selectedTable.id}_${column.name}`]}
                materializedStats={newTable?.id ? columnStats[`${newTable.id}_${column.name}`] : null}
                fixes={columnFixes}
                onRefreshStats={(isOriginal) => onRefreshStats(
                  isOriginal ? selectedTable.id : newTable?.id,
                  column.name
                )}
                isRefreshing={isRefreshingStats[column.name]}
                tableId={selectedTable.id}
                user={user}
                onFixOperationStatus={onFixOperationStatus}
                onFixApproved={onFixApproved}
                onDataRefresh={onDataRefresh}
                newTable={newTable}
                isExpanded={expandedColumn === column.name}
                onToggleExpand={() => setExpandedColumn(
                  expandedColumn === column.name ? null : column.name
                )}
                isFixesExpanded={isFixesExpanded}
                onToggleFixesExpand={() => setIsFixesExpanded(!isFixesExpanded)}
                isNewlyAdded={isNewlyAdded}
              />
            );
          })}
        </div>
  
        {/* Empty State */}
        {filteredColumns.length === 0 && (
          <div className="text-center py-8 text-gray-500">
            No columns match your search criteria
          </div>
        )}
      </div>
    </div>
  );
};

export default ColumnComparisonList;