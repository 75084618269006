// ChatHistory.js
import React, { useMemo } from 'react';
import { formatDistanceToNow } from 'date-fns';
import { MessageSquare, Clock, X } from 'lucide-react';

const ChatHistory = ({ sessions, onSelectSession, activeSessionId, isVisible, onClose }) => {
  // Sort sessions by lastMessageTime
  const sortedSessions = useMemo(() => {
    return [...sessions].sort((a, b) => {
      const aTime = a.lastMessageTime || a.startTime;
      const bTime = b.lastMessageTime || b.startTime;
      return new Date(bTime) - new Date(aTime);
    });
  }, [sessions]);

  // Function to get relative time with auto-update
  const getRelativeTime = (timestamp) => {
    return formatDistanceToNow(new Date(timestamp), { addSuffix: true });
  };
  
  if (!isVisible) return null;
  
  return (
    <div className="w-full md:w-72 border-r border-gray-200 overflow-y-auto h-full bg-white absolute md:relative z-30">
      <div className="p-4 border-b border-gray-200 flex justify-between items-center">
        <h2 className="text-lg font-semibold text-text">Chat History</h2>
        <button 
          onClick={onClose}
          className="md:hidden p-1.5 hover:bg-gray-100 rounded-lg transition-colors text-text-light"
          aria-label="Close history"
        >
          <X className="w-5 h-5" />
        </button>
      </div>
      
      <div className="space-y-1 p-2">
        {sortedSessions.map((session) => (
          <button
            key={session.id}
            onClick={() => onSelectSession(session.id)}
            className={`w-full p-3 rounded-lg text-left transition-colors
              ${activeSessionId === session.id 
                ? 'bg-primary/5 border-primary/10' 
                : 'hover:bg-gray-50'
              }`}
          >
            <div className="flex items-start justify-between">
              <div className="flex items-center">
                <MessageSquare className="w-4 h-4 text-text-light mr-2 flex-shrink-0" />
                <span className="text-sm font-medium text-text truncate max-w-[200px] md:max-w-[180px]">
                  {session.preview?.slice(0, 100)}...
                </span>
              </div>
            </div>
            <div className="mt-1 flex items-center text-xs text-text-lighter">
              <Clock className="w-3 h-3 mr-1 flex-shrink-0" />
              {getRelativeTime(session.lastMessageTime || session.startTime)}
            </div>
          </button>
        ))}
      </div>
    </div>
  );
};

export default ChatHistory;