import React, { useRef, useEffect, useState } from 'react';
import { Send, Loader2, AlertTriangle } from 'lucide-react';

const MAX_CHARS = 4000;

const ChatInput = ({ onSubmit, isLoading, error, disabled = false }) => {
  const [inputValue, setInputValue] = useState('');
  const [charCount, setCharCount] = useState(0);
  const [isNearLimit, setIsNearLimit] = useState(false);
  const textareaRef = useRef(null);
  
  const getCounterColor = () => {
    const ratio = charCount / MAX_CHARS;
    if (ratio >= 0.9) return 'text-red-500';
    if (ratio >= 0.75) return 'text-amber-500';
    return 'text-text-lighter';
  };

  useEffect(() => {
    const count = inputValue.trim().length;
    setCharCount(count);
    setIsNearLimit(count > MAX_CHARS * 0.9);
  }, [inputValue]);

  useEffect(() => {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const adjustHeight = () => {
      textarea.style.height = 'auto';
      textarea.style.height = `${Math.min(textarea.scrollHeight, 90)}px`;
    };

    textarea.addEventListener('input', adjustHeight);
    return () => textarea.removeEventListener('input', adjustHeight);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedValue = inputValue.trim();
    if (!trimmedValue || isLoading || trimmedValue.length > MAX_CHARS || disabled) return;
    
    onSubmit(trimmedValue);
    setInputValue('');
    setCharCount(0);
    setIsNearLimit(false);
  };

  const isInputDisabled = disabled || isLoading;
  const isOverLimit = charCount > MAX_CHARS;

  const getPlaceholderMessage = () => {
    if (disabled) return "No active chat session...";
    if (isLoading) return "Sending message...";
    return "Type your message...";
  };

  return (
    <div className="w-full font-sans">
      {/* Warning messages */}
      {isNearLimit && !isOverLimit && (
        <div className="px-4 py-2 bg-amber-50/80 backdrop-blur-sm border-t border-amber-100 flex items-center rounded-t-xl">
          <AlertTriangle className="w-4 h-4 text-amber-500 mr-2" />
          <span className="text-sm text-amber-700">
            Approaching character limit
          </span>
        </div>
      )}

      {isOverLimit && (
        <div className="px-4 py-2 bg-red-50/80 backdrop-blur-sm border-t border-red-100 flex items-center rounded-t-xl">
          <AlertTriangle className="w-4 h-4 text-red-500 mr-2" />
          <span className="text-sm text-red-700">
            Message exceeds maximum length of {MAX_CHARS} characters
          </span>
        </div>
      )}

      {/* Input area */}
      <form onSubmit={handleSubmit} className="relative">
        <div className="flex items-end gap-2 p-2">
          <div className="flex-grow relative">
            <textarea
              ref={textareaRef}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={getPlaceholderMessage()}
              className={`w-full p-3 pr-16 rounded-xl focus:outline-none focus:border-transparent resize-none
                text-base font-sans
                ${error ? 'border-red-300' : 'border-gray-200'}
                ${isNearLimit && !isOverLimit ? 'border border-amber-300' : 'border'}
                ${isOverLimit ? 'border-red-500' : ''}
                ${isInputDisabled ? 'bg-background cursor-not-allowed' : 'bg-background'}
                  placeholder:text-base placeholder:text-text-light text-text
              `}
              style={{ maxHeight: '90px', minHeight: '40px', overflow: 'auto' }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSubmit(e);
                }
              }}
              disabled={isInputDisabled}
            />
            
            <div className={`absolute bottom-3 right-3 text-xs ${getCounterColor()}`}>
              {charCount}/{MAX_CHARS}
            </div>
          </div>

          <button
            type="submit"
            disabled={!inputValue.trim() || isLoading || isOverLimit || disabled}
            className={`p-3 rounded-xl transition-all duration-200 flex items-center justify-center min-w-[48px] 
              ${!inputValue.trim() || isLoading || isOverLimit || disabled
                ? 'text-text-lighter cursor-not-allowed'
                : 'text-primary hover:bg-opacity-90 hover:shadow-glow-sm'
              }`}
            title={
              disabled 
                ? 'No active chat session'
                : isOverLimit 
                  ? 'Message too long' 
                  : isLoading 
                    ? 'Sending...' 
                    : 'Send message'
            }
          >
            {isLoading ? (
              <div className="relative">
                <Loader2 className="w-5 h-5 animate-spin" />
                <div className="absolute inset-0 rounded-full border-2 border-primary/20 animate-[ping_1.5s_cubic-bezier(0,0,0.2,1)_infinite]"></div>
                <div className="absolute inset-0 rounded-full border-2 border-primary/20 animate-[ping_2s_cubic-bezier(0,0,0.2,1)_infinite]"></div>
              </div>
            ) : (
              <Send className="w-5 h-5" />
            )}
          </button>
        </div>
      </form>

      {/* Error message */}
      {error && (
        <div className="px-4 py-2 bg-red-50/80 backdrop-blur-sm text-sm text-red-700 flex items-center rounded-b-xl">
          <AlertTriangle className="w-4 h-4 mr-2" />
          {error}
        </div>
      )}
    </div>
  );
};

export default ChatInput;