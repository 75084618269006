import React from 'react';
import { Plus, X } from 'lucide-react';

const OPERATORS = [
  { value: '=', label: 'equals' },
  { value: '<>', label: 'not equals' },
  { value: '>', label: 'greater than' },
  { value: '<', label: 'less than' },
  { value: '>=', label: 'greater than or equal' },
  { value: '<=', label: 'less than or equal' }
];

// Helper functions to determine column type characteristics
const isIntegerType = (columnType) => ['INTEGER', 'INT64'].includes(columnType);
const isFloatType = (columnType) => ['FLOAT', 'FLOAT64', 'NUMERIC', 'BIGNUMERIC'].includes(columnType);
const isNumericType = (columnType) => isIntegerType(columnType) || isFloatType(columnType);
const isDateType = (columnType) => ['DATE', 'DATETIME', 'TIMESTAMP'].includes(columnType);
const isBooleanType = (columnType) => columnType === 'BOOLEAN';

const getInputType = (columnType, operator) => {
  if (operator === 'IN' || operator === 'LIKE') return 'text';
  if (isDateType(columnType)) return 'datetime-local';
  if (isNumericType(columnType)) return 'number';
  if (isBooleanType(columnType)) return 'checkbox';
  return 'text';
};

const getInputStep = (columnType) => {
  if (isFloatType(columnType)) return '0.000001'; // Allow for high precision
  if (isIntegerType(columnType)) return '1';
  return undefined;
};

const formatValueForSQL = (value, columnType, operator) => {
  if (!value && value !== 0) return "''";
  
  if (operator === 'IN') {
    const values = value.split(',').map(v => `'${v.trim()}'`).join(', ');
    return `(${values})`;
  }
  
  if (operator === 'LIKE') {
    return `'%${value}%'`;
  }

  if (isNumericType(columnType)) {
    return value.toString();
  }

  if (isBooleanType(columnType)) {
    return value === 'true' || value === true ? 'true' : 'false';
  }

  if (isDateType(columnType)) {
    // Ensure proper date formatting
    return `'${value}'`;
  }

  return `'${value}'`;
};

const RowRestrictionBuilder = ({ 
  columns = [], 
  restrictions = [], 
  onChange,
  allowedColumns = []
}) => {
  const availableColumns = columns.filter(col => allowedColumns.includes(col.name));

  const handleAddRestriction = () => {
    const firstColumn = availableColumns[0];
    onChange([
      ...restrictions,
      { 
        column: firstColumn?.name || '', 
        operator: '=', 
        value: '',
        columnType: firstColumn?.type || 'STRING'
      }
    ]);
  };

  const handleRemoveRestriction = (index) => {
    const newRestrictions = restrictions.filter((_, i) => i !== index);
    onChange(newRestrictions);
  };

  const handleRestrictionChange = (index, field, value) => {
    const newRestrictions = restrictions.map((restriction, i) => {
      if (i === index) {
        const updates = { ...restriction, [field]: value };
        
        if (field === 'column') {
          const columnDef = availableColumns.find(col => col.name === value);
          updates.columnType = columnDef?.type || 'STRING';
          updates.value = ''; // Reset value when changing column type
        }
        
        if (field === 'value') {
          // Handle value conversion based on column type
          if (isNumericType(restriction.columnType)) {
            updates.value = value === '' ? '' : Number(value);
          } else if (isBooleanType(restriction.columnType)) {
            updates.value = value === 'true' || value === true;
          } else {
            updates.value = value;
          }
        }
        
        return updates;
      }
      return restriction;
    });
    onChange(newRestrictions);
  };

  const buildWhereClause = () => {
    return restrictions
      .map(({ column, operator, value, columnType }) => {
        const formattedValue = formatValueForSQL(value, columnType, operator);
        if (operator === 'IN') {
          return `${column} IN ${formattedValue}`;
        } else if (operator === 'LIKE') {
          return `${column} LIKE ${formattedValue}`;
        } else {
          return `${column} ${operator} ${formattedValue}`;
        }
      })
      .join(' AND ');
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <label className="block text-sm font-medium text-gray-700">
          Row Restrictions
        </label>
        <button
          type="button"
          onClick={handleAddRestriction}
          className="inline-flex items-center px-3 py-1.5 text-sm text-primary hover:text-primary/90"
        >
          <Plus className="h-4 w-4 mr-1" />
          Add Condition
        </button>
      </div>

      {restrictions.map((restriction, index) => (
        <div
          key={index}
          className="flex items-center space-x-2 p-2 bg-gray-50 rounded-md"
        >
          <select
            value={restriction.column}
            onChange={(e) => handleRestrictionChange(index, 'column', e.target.value)}
            className="px-3 py-1.5 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
          >
            {availableColumns.map((column) => (
              <option key={column.name} value={column.name}>
                {column.name} ({column.type})
              </option>
            ))}
          </select>

          <select
            value={restriction.operator}
            onChange={(e) => handleRestrictionChange(index, 'operator', e.target.value)}
            className="px-3 py-1.5 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
          >
            {OPERATORS.map((op) => (
              <option key={op.value} value={op.value}>
                {op.label}
              </option>
            ))}
          </select>

          <input
            type={getInputType(restriction.columnType, restriction.operator)}
            value={restriction.value}
            onChange={(e) => handleRestrictionChange(index, 'value', 
              restriction.columnType === 'BOOLEAN' ? e.target.checked : e.target.value
            )}
            checked={restriction.columnType === 'BOOLEAN' ? restriction.value : undefined}
            step={getInputStep(restriction.columnType)}
            placeholder={restriction.operator === 'IN' ? "Value1, Value2, ..." : "Value"}
            className="flex-1 px-3 py-1.5 text-sm border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
          />

          <button
            type="button"
            onClick={() => handleRemoveRestriction(index)}
            className="p-1 text-red-500 hover:text-red-700"
          >
            <X className="h-4 w-4" />
          </button>
        </div>
      ))}

      <input
        type="hidden"
        value={buildWhereClause()}
        name="rowRestriction"
      />
    </div>
  );
};

export default RowRestrictionBuilder;