// QuerySection.js
import React, { useCallback } from 'react';
import QueryComponent from '../QueryComponent/QueryComponent';

const QuerySection = ({ 
  onQueryResult, 
  onQueryStart,
  queryResult,
  isLoading,
  currentUser,
  onSaveQuery,
  onAddToDashboard,
  onFollowUpQuery,
  mainContentRef,
  submitQuery,
  onDashboardUpdate,
  dashboardManager,
  isQueryJustAdded,
  setIsQueryJustAdded,
  isAskPage,
  isDashboardMode,
  isMobileFixed,
  onClearResult
}) => {
  const handleQueryResult = useCallback((result) => {
    onQueryResult(result);
  }, [onQueryResult]);

  const handleQueryStart = useCallback(() => {
    onQueryStart();
  }, [onQueryStart]);

  const handleClearResult = useCallback(() => {
    if (onClearResult) {
      onClearResult();
    }
  }, [onClearResult]);

  const handleFollowUpQuery = useCallback((followUpQuery, selectionData, originalSqlQuery) => {
    console.log('Follow-up query in QuerySection:', followUpQuery);
    console.log('Selection data:', selectionData);
    console.log('Original SQL query:', originalSqlQuery);
    
    submitQuery(
      followUpQuery,
      true,
      selectionData.text,
      originalSqlQuery,
      false,
      null,
      selectionData.columns
    );
  
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  
    if (mainContentRef && mainContentRef.current) {
      mainContentRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [submitQuery, mainContentRef]);

  // Only render query input for mobile fixed version, or everything for desktop/main version
  if (isMobileFixed) {
    return (
      <div className="rounded-lg p-0 mb-0">
        <QueryComponent 
          onQueryResult={handleQueryResult} 
          onQueryStart={handleQueryStart}
          queryResult={null} // Don't show results in fixed input
          isLoading={isLoading}
          currentUser={currentUser}
          onSaveQuery={onSaveQuery}
          onAddToDashboard={onAddToDashboard}
          onFollowUpQuery={handleFollowUpQuery}
          mainContentRef={mainContentRef}
          dashboardManager={dashboardManager}
          onDashboardUpdate={onDashboardUpdate}
          isQueryJustAdded={isQueryJustAdded}
          setIsQueryJustAdded={setIsQueryJustAdded}
          isAskPage={isAskPage}
          isDashboardMode={isDashboardMode}
          isMobileFixed={true}
          onClearResult={onClearResult}
        />
      </div>
    );
  }

  return (
    <div className="rounded-lg p-0 mb-0 w-full">
      <QueryComponent 
        onQueryResult={handleQueryResult} 
        onQueryStart={handleQueryStart}
        queryResult={queryResult}
        isLoading={isLoading}
        currentUser={currentUser}
        onSaveQuery={onSaveQuery}
        onAddToDashboard={onAddToDashboard}
        onFollowUpQuery={handleFollowUpQuery}
        mainContentRef={mainContentRef}
        dashboardManager={dashboardManager}
        onDashboardUpdate={onDashboardUpdate}
        isQueryJustAdded={isQueryJustAdded}
        setIsQueryJustAdded={setIsQueryJustAdded}
        isAskPage={isAskPage}
        isDashboardMode={isDashboardMode}
        isMobileFixed={isMobileFixed || false}
        onClearResult={handleClearResult}
      />
    </div>
  );
};

export default QuerySection;