import React from 'react';

const Card = ({ children, title, className = '', titleSize = 'text-lg', contentSize = 'text-sm', isNewQuery = false }) => {
  // Separate styles for new query cards
  const newQueryClasses = isNewQuery
    ? 'border-l-4 border-secondary bg-gradient-to-b from-white to-secondary/20 shadow-glow-sm transition-transform transform hover:scale-102 hover:shadow-md duration-200 ease-in-out'
    : '';

  const defaultClasses = 'bg-white shadow-card rounded-lg p-4 mb-4 flex flex-col';
  const combinedClasses = `${defaultClasses} ${newQueryClasses} ${className}`.trim();

  return (
    <div className={`${combinedClasses} relative`} style={isNewQuery ? { borderTop: '2px solid #FFADFF', zIndex: 1, position: 'relative' } : {}}>
      {title && <h2 className={`${titleSize} font-semibold mb-4 text-gray-700`}>{title}</h2>}
      <div className={`${contentSize} flex-grow flex flex-col`}>
        {children}
      </div>
    </div>
  );  
}
export default Card;
