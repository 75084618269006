import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Button from '../Button';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { Trash, Plus, GripVertical, Edit2 } from 'lucide-react';
import SavedQueriesComponent from '../QueryComponent/SavedQueriesComponent';

const EditDashboardModal = ({ isOpen, onClose, dashboard, onUpdateDashboard, onDeleteDashboard, currentUser, availableQueries }) => {
  const [queries, setQueries] = useState([]);
  const [isAddQueryModalOpen, setIsAddQueryModalOpen] = useState(false);
  const [selectedQueries, setSelectedQueries] = useState([]);
  const [isDeleteConfirmationOpen, setIsDeleteConfirmationOpen] = useState(false);
  const [dashboardName, setDashboardName] = useState('');
  const [isEditingName, setIsEditingName] = useState(false);

  useEffect(() => {
    if (dashboard) {
      setQueries(dashboard.queries);
      setDashboardName(dashboard.name);
    }
  }, [dashboard]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(queries);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setQueries(items);
  };

  const handleDeleteQuery = (index) => {
    const newQueries = queries.filter((_, i) => i !== index);
    setQueries(newQueries);
  };

  const handleAddQueries = () => {
    const newQueries = [...queries, ...selectedQueries];
    setQueries(newQueries);
    setSelectedQueries([]);
    setIsAddQueryModalOpen(false);
  };

  const handleSave = async () => {
    const updatedDashboard = {
      ...dashboard,
      name: dashboardName,
      queries: queries.map(q => q.id)
    };
    await onUpdateDashboard(updatedDashboard);
    onClose();
  };

  const handleSelectQuery = (query) => {
    setSelectedQueries(prev => {
      const newSelection = prev.some(q => q.id === query.id)
        ? prev.filter(q => q.id !== query.id)
        : [...prev, query];
      return newSelection;
    });
  };

  const handleDeleteDashboard = () => {
    setIsDeleteConfirmationOpen(true);
  };

  const confirmDeleteDashboard = () => {
    onDeleteDashboard(dashboard.id);
    setIsDeleteConfirmationOpen(false);
    onClose();
  };

  const handleNameChange = (e) => {
    setDashboardName(e.target.value);
  };

  const toggleNameEdit = () => {
    setIsEditingName(!isEditingName);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title="Edit Dashboard"
        confirmText="Save"
        cancelText="Cancel"
        onSubmit={handleSave}
      >
        <div className="mb-6">
          <div className="flex items-center justify-between">
            {isEditingName ? (
              <input
                type="text"
                value={dashboardName}
                onChange={handleNameChange}
                className="w-full mr-2 px-3 py-2 text-lg border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                autoFocus
                onBlur={toggleNameEdit}
                onKeyPress={(e) => {
                  if (e.key === 'Enter') {
                    toggleNameEdit();
                  }
                }}
              />
            ) : (
              <div className="text-xl font-semibold mr-2">{dashboardName}</div>
            )}
            <Button
              onClick={toggleNameEdit}
              icon={Edit2}
              className="p-1"
              aria-label="Edit dashboard name"
            />
          </div>
        </div>
        <div className="flex justify-between items-center mb-4">
          <Button
            onClick={() => setIsAddQueryModalOpen(true)}
            icon={Plus}
            className="w-auto"
          >
            Add Query
          </Button>
          <button
            onClick={handleDeleteDashboard}
            className="px-3 py-2 text-sm font-medium bg-white text-red-500 hover:text-red-600 hover:bg-red-100 transition-colors duration-200 rounded-md"
          >
            Delete Dashboard
          </button>
        </div>
        <div className="max-h-[50vh] overflow-y-auto">
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="queries">
              {(provided) => (
                <ul {...provided.droppableProps} ref={provided.innerRef} className="space-y-2">
                  {queries.map((query, index) => (
                    <Draggable key={query.id} draggableId={query.id} index={index}>
                      {(provided) => (
                        <li
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className="flex items-center justify-between p-2 bg-gray-100 rounded"
                        >
                          <div className="flex items-center">
                            <span {...provided.dragHandleProps} className="mr-2">
                              <GripVertical size={20} />
                            </span>
                            <span>{query.name}</span>
                          </div>
                          <Button
                            onClick={() => handleDeleteQuery(index)}
                            icon={Trash}
                            className="text-red-500"
                          />
                        </li>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </ul>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Modal>
      <Modal
        isOpen={isAddQueryModalOpen}
        onClose={() => setIsAddQueryModalOpen(false)}
        title="Add Queries"
        onSubmit={handleAddQueries}
        confirmText="Add Selected Queries"
        cancelText="Cancel"
      >
        <div className="max-h-[60vh] overflow-y-auto">
          <SavedQueriesComponent
            onSelectQuery={handleSelectQuery}
            selectedQueries={selectedQueries.map(q => q.id)}
            isSelectionMode={true}
            displayMode="list"
            currentUser={currentUser}
            queries={availableQueries}
          />
        </div>
      </Modal>
      <Modal
        isOpen={isDeleteConfirmationOpen}
        onClose={() => setIsDeleteConfirmationOpen(false)}
        title="Confirm Deletion"
        onSubmit={confirmDeleteDashboard}
        confirmText="Delete"
        cancelText="Cancel"
        confirmButtonClassName="bg-white text-red-500 hover:text-red-600 hover:bg-red-100"
      >
        <p>Are you sure you want to delete this dashboard? This action cannot be undone.</p>
      </Modal>
    </>
  );
};

export default EditDashboardModal;