import React, { useState, useEffect } from 'react';
import Modal from '../Modal';
import Button from '../Button';
import { PlusCircle } from 'lucide-react';

const AddToDashboardModal = ({
  isOpen,
  onClose,
  dashboards,
  onAddToDashboard,
  onCreateNewDashboard,
  onDashboardUpdate,
  isQueryJustAdded,
  fetchDashboards
}) => {
  const [newDashboardName, setNewDashboardName] = useState('');
  const [isCreatingNew, setIsCreatingNew] = useState(false);

  useEffect(() => {
    if (isOpen) {
      fetchDashboards();
      // Only reset these states if we're not already creating a new dashboard
      if (!isCreatingNew) {
        setIsCreatingNew(false);
        setNewDashboardName('');
      }
    }
  }, [isOpen, fetchDashboards, isCreatingNew]);

  const handleCreateNewDashboard = async () => {
    const newDashboard = await onCreateNewDashboard(newDashboardName);
    if (newDashboard) {
      setNewDashboardName('');
      setIsCreatingNew(false);
      if (onDashboardUpdate) {
        onDashboardUpdate(newDashboard);
      }
    }
  };

  const handleAddToDashboard = async (dashboardId) => {
    await onAddToDashboard(dashboardId);
    if (onDashboardUpdate) {
      const updatedDashboard = dashboards.find(d => d.id === dashboardId);
      if (updatedDashboard) {
        onDashboardUpdate(updatedDashboard);
      }
    }
  };

  const handleClose = () => {
    setIsCreatingNew(false);
    setNewDashboardName('');
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Add Query to a Dashboard"
      showButtons={false}
    >
      {!isQueryJustAdded ? (
        <>
          {!isCreatingNew ? (
            <div className="space-y-4">
              <h3 className="text-lg font-semibold mb-1 border-b border-gray-200 pb-1">
                Add to an existing dashboard:
              </h3>
              <div className="max-h-64 overflow-y-auto">
                {dashboards.map((dashboard, index) => (
                  <React.Fragment key={dashboard.id}>
                    <button
                      onClick={() => handleAddToDashboard(dashboard.id)}
                      className="w-full text-left py-2 px-4 hover:bg-gray-100 transition-colors duration-150"
                    >
                      {dashboard.name}
                    </button>
                    {index < dashboards.length - 1 && (
                      <div className="border-b border-gray-200"></div>
                    )}
                  </React.Fragment>
                ))}
              </div>
              <h3 className="text-lg font-semibold mb-1 border-b border-gray-200 pb-1">
                Or create a new one:
              </h3>
              <div className="flex justify-start">
                <Button
                  onClick={() => setIsCreatingNew(true)}
                  icon={PlusCircle}
                  className="flex items-center"
                >
                  Create New Dashboard
                </Button>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <h3 className="text-lg font-semibold mb-1 text-gray-600 border-b border-gray-200 pb-1">
                Create new dashboard:
              </h3>
              <input
                type="text"
                value={newDashboardName}
                onChange={(e) => setNewDashboardName(e.target.value)}
                placeholder="Dashboard Name"
                className="w-full px-3 py-2 border rounded-md text-gray-700 placeholder-gray-500 border-gray-300"
              />
              <div className="flex justify-end space-x-2">
                <Button 
                  onClick={() => setIsCreatingNew(false)}
                  className="border border-gray-300"
                >
                  Cancel
                </Button>
                <Button
                  onClick={handleCreateNewDashboard}
                  disabled={!newDashboardName.trim()}
                >
                  Create and Add Query
                </Button>
              </div>
            </div>
          )}
        </>
      ) : (
        <div className="p-4 text-center">
          <p className="text-secondary">Query successfully added to the dashboard!</p>
          <Button
            onClick={handleClose}
            className="mt-4 w-full border border-gray-300"
          >
            Close
          </Button>
        </div>
      )}
    </Modal>
  );
};

export default AddToDashboardModal;