import React, { useState } from 'react';
import { Share2 } from 'lucide-react';
import Button from '../Button';
import * as XLSX from 'xlsx';
import { createImageWrapper, captureImage } from './imageUtils';

const ShareQueryResult = ({ 
  viewMode,
  chartRef,
  tableData,
  fileName = 'query_result',
  cardTitle,
  statsData,
  selectedYAxis
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleShare = async () => {
    if (isLoading) return;
    setIsLoading(true);
    
    try {
      if (viewMode !== 'table') {
        try {
          const wrapper = createImageWrapper(chartRef, cardTitle, statsData, selectedYAxis);
          const canvas = await captureImage(wrapper);

          canvas.toBlob(async (blob) => {
            const file = new File([blob], `${fileName}.png`, { type: 'image/png' });
            
            if (navigator.share && navigator.canShare({ files: [file] })) {
              try {
                await navigator.share({
                  files: [file],
                  title: cardTitle || 'Chart Results',
                  text: 'Check out these results'
                });
              } catch (shareError) {
                console.error('Chart sharing failed:', shareError);
                if (shareError.name !== 'AbortError') {
                  alert('Unable to share. Please try downloading instead.');
                }
              }
            } else {
              alert('Sharing is not supported on this device. Please try downloading instead.');
            }
          }, 'image/png');
        } catch (error) {
          console.error('Error creating chart image:', error);
          alert('Failed to create chart image. Please try again.');
        }
      } else {
        // Handle table sharing
        try {
          const workbook = XLSX.utils.book_new();
          const worksheet = XLSX.utils.json_to_sheet(tableData);
          const csvContent = XLSX.utils.sheet_to_csv(worksheet);

          if (navigator.share) {
            await navigator.share({
              title: cardTitle || 'Table Results',
              text: csvContent,
            });
          } else {
            alert('Sharing is not supported on this device. Please try downloading instead.');
          }
        } catch (shareError) {
          console.error('Table sharing failed:', shareError);
          if (shareError.name !== 'AbortError') {
            alert('Unable to share. Please try downloading instead.');
          }
        }
      }
    } catch (error) {
      console.error('Error in share handler:', error);
      if (error.name !== 'AbortError') {
        alert('Failed to share. Please try downloading instead.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Button
      onClick={handleShare}
      icon={Share2}
      className={`p-2 ${isLoading ? 'opacity-50 cursor-not-allowed' : ''}`}
      title="Share Result"
      disabled={isLoading}
    />
  );
};

export default ShareQueryResult;