import React, { useState, useEffect } from 'react';
import { Loader2, ChevronDown, Plus } from 'lucide-react';
import CreateTableSection from '../components/dataPrep/CreateTableSection';
import { useAuth } from '../contexts/AuthContext';
import FixedViewSection from '../components/dataPrep/FixedViewSection';
import { useFixedView } from '../hooks/useFixedView';
import TablePreview from '../components/dataPrep/TablePreview';
import ColumnComparisonList from '../components/dataPrep/ColumnComparisonList';
import InitializeViewSection from '../components/dataPrep/InitializeViewSection';
import { useTableData } from '../hooks/useTableData';
import { useDataPrepOperations } from '../hooks/useDataPrepOperations';
import { useColumnOperations } from '../hooks/useColumnOperations';

const DataPrep = () => {
  const { currentUser } = useAuth();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  
  const {
    tables,
    selectedTable,
    newTable,
    isLoading,
    isInitializing,
    saveStatus,
    setSaveStatus,
    handleCreateTable,
    handleTableSelection,
    handleInitializeView,
  } = useDataPrepOperations(currentUser);
  
  const {
    columnData,
    columnStats,
    refreshing,
    refreshingStats,
    error: tableDataError,
    handleRefreshSamples,
    fetchColumnStats,
    handleDataRefresh
  } = useTableData(selectedTable?.id, currentUser);

  const { 
    materializedView, 
    isRefreshingView, 
    activeFixes,
    error,
    refreshView,
    revertFix 
  } = useFixedView(selectedTable?.id, currentUser);

  const handleFixOperationStatus = (operation, success, message) => {
    setSaveStatus({
      tableId: selectedTable?.id,
      type: success ? 'success' : 'error',
      message: message,
      operation: operation
    });
    setTimeout(() => setSaveStatus({ tableId: null, type: '', message: '', operation: '' }), 3000);
  };

  const {
    handleApproveColumn,
    handleRejectColumn,
    handleRevertColumn,
    handleColumnAdded
  } = useColumnOperations(currentUser, selectedTable, refreshView, handleFixOperationStatus);

  const handleFixApproved = async () => {
    if (selectedTable?.id) {
      try {
        const refreshedView = await refreshView();
        if (refreshedView?.success) {
          setSaveStatus({
            tableId: selectedTable.id,
            type: 'success',
            message: 'Fixed view updated successfully'
          });
        } else {
          throw new Error(refreshedView?.error || 'Failed to update fixed view');
        }
      } catch (error) {
        console.error('Error refreshing view:', error);
        setSaveStatus({
          tableId: selectedTable.id,
          type: 'error',
          message: error.message || 'Failed to refresh fixed view'
        });
      }
    }
  };

  const handleRefreshAll = async () => {
    if (!selectedTable?.id) return;
    
    try {
      const mvId = newTable?.id;
      await handleDataRefresh(selectedTable.id, mvId);
    } catch (error) {
      console.error('Error refreshing all:', error);
      setSaveStatus({
        tableId: selectedTable.id,
        type: 'error',
        message: 'Failed to refresh data: ' + error.message
      });
    }
  };

  return (
    <div className="container mx-auto px-0 py-2 sm:py-4">
      {/* Header Section */}
      <div className="flex items-center justify-between mb-6">
        <h1 className="text-gray-500 text-xl font-semibold">Boost Your Data</h1>
        <button
          onClick={() => setIsCreateModalOpen(true)}
          className="flex items-center px-4 py-2 text-sm text-gray-700 rounded-md hover:text-secondary transition-colors"
        >
          <Plus className="h-5 w-5 mr-2" />
          Add Table
        </button>
      </div>

      {/* Table Selection */}
      <div className="flex items-center space-x-4 mb-8">
        <div className="relative flex-grow max-w-md">
          <select
            value={selectedTable?.id || ''}
            onChange={(e) => handleTableSelection(e.target.value)}
            className="w-full pl-4 pr-10 py-2 text-lg font-bold text-gray-700 border-2 border-primary rounded-full appearance-none cursor-pointer bg-white"
          >
            <option value="">Select a table to analyze</option>
            {tables.map(table => (
              <option key={table.id} value={table.id}>
                {table.name}
              </option>
            ))}
          </select>
          <ChevronDown className="absolute right-3 top-1/2 -translate-y-1/2 h-5 w-5 text-primary pointer-events-none" />
        </div>
      </div>

      {/* Loading State */}
      {isLoading && (
        <div className="flex justify-center py-12">
          <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
        </div>
      )}

      {/* Main Content */}
      {!isLoading && selectedTable && (
        <div className="space-y-6">
          {/* Table Preview and Fixed View Section Side by Side */}
          <div className="grid grid-cols-2 gap-6">
            <div>
              <TablePreview table={selectedTable} isFixedView={false} />
            </div>
            <div>
              {newTable ? (
                <FixedViewSection
                  viewData={newTable}
                  onRefreshView={refreshView}
                  onRevertFix={(fixId, userEmail) => revertFix(fixId, userEmail, selectedTable?.id)}
                  onApproveColumn={handleApproveColumn}
                  onRejectColumn={handleRejectColumn}
                  onRevertColumn={handleRevertColumn}
                  onColumnAdded={handleColumnAdded}
                  isRefreshing={isRefreshingView}
                  activeFixes={activeFixes}
                  tableId={selectedTable.id}
                  user={currentUser}
                  onFixOperationStatus={handleFixOperationStatus}
                  onDataRefresh={() => handleDataRefresh(selectedTable.id)}
                />
              ) : (
                <InitializeViewSection 
                  onInitialize={() => handleInitializeView(selectedTable?.id)}
                  isLoading={isInitializing}
                />
              )}
            </div>
          </div>

          {/* Column Management Sections */}
          <div className="space-y-6">
            {/* Column Comparison Section */}
            <ColumnComparisonList
              originalColumns={columnData?.columns || []}
              newColumns={newTable?.dataSample || []}
              onRefresh={handleRefreshSamples}
              onRefreshStats={fetchColumnStats}
              isRefreshing={refreshing[selectedTable.id]}
              isRefreshingStats={refreshingStats}
              selectedTable={selectedTable}
              columnStats={columnStats}
              fixes={newTable?.fixesByColumn || {}}
              user={currentUser}
              onFixOperationStatus={handleFixOperationStatus}
              onFixApproved={handleFixApproved}
              onRefreshAll={handleRefreshAll}
              newTable={newTable}
              onDataRefresh={() => handleDataRefresh(selectedTable.id)}
            />
          </div>
        </div>
      )}

      {/* Empty State */}
      {!isLoading && !selectedTable && (
        <div className="text-center py-12">
          <p className="text-gray-500">Select a table to begin data preparation.</p>
        </div>
      )}

      {/* Create Table Modal */}
      <CreateTableSection 
        isOpen={isCreateModalOpen}
        onClose={() => setIsCreateModalOpen(false)}
        onSubmit={async (formData) => {
          try {
            const createdTable = await handleCreateTable(formData);
            setIsCreateModalOpen(false);
            
            if (createdTable?.id) {
              await handleTableSelection(createdTable.id);
              await handleDataRefresh(createdTable.id);
            }
          } catch (error) {
            console.error('Error creating table:', error);
            setSaveStatus({
              tableId: 'new',
              type: 'error',
              message: error.message || 'Failed to create table'
            });
          }
        }}
        isLoading={isLoading}
      />

      {/* Status Messages */}
      {saveStatus.message && (
        <div 
          className={`fixed bottom-4 right-4 p-4 rounded-md shadow-lg z-50 ${
            saveStatus.type === 'error' ? 'bg-red-50 text-red-700' : 
            saveStatus.type === 'success' ? 'bg-green-50 text-green-700' : 
            'bg-blue-50 text-blue-700'
          }`}
        >
          {saveStatus.message}
        </div>
      )}
    </div>
  );
};

export default DataPrep;