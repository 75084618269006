import React, { useState, useRef, useEffect } from 'react';
import { Newspaper } from 'lucide-react';
import { useCompanyNews } from '../hooks/useCompanyNews';
import { useAuth } from '../contexts/AuthContext';
import { doc, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../firebase';

function NewsIndicator({ companyName }) {
  const [showTooltip, setShowTooltip] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });
  const { getCompanyNews } = useCompanyNews();
  const { currentUser } = useAuth();
  const timeoutRef = useRef(null);
  const tooltipRef = useRef(null);
  const indicatorRef = useRef(null);
  
  useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);
  
  const newsData = getCompanyNews(companyName);
  
  if (!newsData || !newsData.articles || newsData.articles.length === 0) {
    return null;
  }

  // Filter out articles that the user has already viewed
  const unviewedArticles = newsData.usersViewed 
    ? newsData.articles.filter(() => !newsData.usersViewed.includes(currentUser?.email))
    : newsData.articles;

  const recentArticles = newsData.articles
    .sort((a, b) => new Date(b.publishedAt) - new Date(a.publishedAt))
    .slice(0, 3);

  const markAsViewed = async () => {
    if (!currentUser?.email || !newsData.id) return;

    try {
      const newsRef = doc(db, 'news', newsData.id);
      await updateDoc(newsRef, {
        usersViewed: arrayUnion(currentUser.email)
      });
      // Refresh data after marking as viewed
      if (getCompanyNews.refetch) {
        getCompanyNews.refetch();
      }
    } catch (error) {
      console.error('Error marking news as viewed:', error);
    }
  };

  const handleMouseEnter = (e) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setTooltipPosition({ x: e.clientX, y: e.clientY });
    setShowTooltip(true);
    // Mark as viewed when tooltip is shown
    markAsViewed();
  };

  const handleMouseLeave = (e) => {
    if (tooltipRef.current) {
      const tooltipRect = tooltipRef.current.getBoundingClientRect();
      const indicatorRect = indicatorRef.current.getBoundingClientRect();
      
      // Calculate the vector from indicator to tooltip
      const tooltipCenterX = (tooltipRect.left + tooltipRect.right) / 2;
      const tooltipCenterY = (tooltipRect.top + tooltipRect.bottom) / 2;
      const indicatorCenterX = (indicatorRect.left + indicatorRect.right) / 2;
      const indicatorCenterY = (indicatorRect.top + indicatorRect.bottom) / 2;
      
      // Calculate the vector from indicator to mouse
      const mouseVectorX = e.clientX - indicatorCenterX;
      const mouseVectorY = e.clientY - indicatorCenterY;
      
      // Calculate the vector from indicator to tooltip
      const tooltipVectorX = tooltipCenterX - indicatorCenterX;
      const tooltipVectorY = tooltipCenterY - indicatorCenterY;
      
      // Calculate dot product to determine if moving towards tooltip
      const dotProduct = (mouseVectorX * tooltipVectorX + mouseVectorY * tooltipVectorY);
      const movingTowards = dotProduct > 0;

      if (movingTowards && 
          e.clientX >= tooltipRect.left - 10 && 
          e.clientX <= tooltipRect.right + 10 && 
          e.clientY >= tooltipRect.top - 10 && 
          e.clientY <= tooltipRect.bottom + 10) {
        return;
      }
    }

    timeoutRef.current = setTimeout(() => {
      setShowTooltip(false);
      // Refresh data when tooltip is closed
      if (getCompanyNews.refetch) {
        getCompanyNews.refetch();
      }
    }, 300);
  };

  const handleTooltipMouseEnter = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setShowTooltip(true);
  };

  const handleTooltipMouseLeave = () => {
    setShowTooltip(false);
    // Refresh data when tooltip is closed
    if (getCompanyNews.refetch) {
      getCompanyNews.refetch();
    }
  };

  return (
    <div className="relative inline-block ml-2">
      <div
        ref={indicatorRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="cursor-pointer"
      >
        <Newspaper 
          size={14}
          className="text-primary hover:text-primary/80 transition-colors"
        />
        {unviewedArticles.length > 0 && (
          <span className="absolute -top-1 -right-1 bg-accent text-white rounded-full text-[9px] w-2.5 h-2.5 flex items-center justify-center">
            {unviewedArticles.length}
          </span>
        )}
      </div>

      {showTooltip && (
        <div 
          ref={tooltipRef}
          onMouseEnter={handleTooltipMouseEnter}
          onMouseLeave={handleTooltipMouseLeave}
          className="fixed bg-white rounded-lg shadow-lg border border-gray-200 p-3 mt-2"
          style={{
            zIndex: 9999,
            width: "18rem",
            left: tooltipPosition.x,
            top: tooltipPosition.y + 10,
            transform: "translateX(-50%)"
          }}
        >
          <div className="font-medium mb-2 text-sm">Recent News</div>
          <div className="space-y-2">
            {recentArticles.map((article, index) => (
              <a
                key={index}
                href={article.url}
                target="_blank"
                rel="noopener noreferrer"
                className="block text-xs hover:bg-gray-50 p-1 rounded transition-colors"
              >
                <div className="font-medium text-primary hover:text-primary/80">
                  {article.title}
                </div>
                <div className="text-gray-500 text-[10px] mt-0.5">
                  {new Date(article.publishedAt).toLocaleDateString()}
                </div>
              </a>
            ))}
          </div>
          {newsData.articles.length > 3 && (
            <div className="text-[10px] text-gray-500 mt-2 text-center">
              +{newsData.articles.length - 3} more articles
            </div>
          )}
        </div>
      )}
    </div>
  );
}

export default NewsIndicator;