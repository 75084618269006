import { useState, useEffect } from 'react';
import { collection, query, where, getDocs, Timestamp, doc, getDoc } from 'firebase/firestore';
import { db } from '../firebase';

export function useCompanyNews() {
  const [newsMap, setNewsMap] = useState(new Map());
  const [trackedColumns, setTrackedColumns] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch tracked columns from appConfiguration
        const configDoc = await getDoc(doc(db, 'appConfiguration', 'tracking'));
        if (configDoc.exists()) {
          const trackingData = configDoc.data();
          // Flatten all tracked columns from all tracking objects into a single array
          const allTrackedColumns = Object.values(trackingData)
            .flatMap(obj => obj.columns || [])
            .filter(Boolean);
          setTrackedColumns(allTrackedColumns);
        }

        // Fetch news data
        const threeDaysAgo = new Date();
        threeDaysAgo.setDate(threeDaysAgo.getDate() - 3);
        const timestamp = Timestamp.fromDate(threeDaysAgo);

        const newsQuery = query(
          collection(db, 'news'),
          where('lastUpdated', '>=', timestamp)
        );

        const querySnapshot = await getDocs(newsQuery);
        const newsData = new Map();

        querySnapshot.forEach((doc) => {
          if (!doc.id.startsWith('category_')) {
            const data = doc.data();
            const formattedName = formatCompanyName(data.value || doc.id); // Use value field, fallback to doc.id
            newsData.set(formattedName.toLowerCase(), {
              id: doc.id,
              ...data
            });
          }
        });

        setNewsMap(newsData);
      } catch (error) {
        console.error('Error fetching news data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatCompanyName = (name) => {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const getCompanyNews = (companyName) => {
    return newsMap.get(companyName.toLowerCase()) || null;
  };

  const isTrackedColumn = (columnName) => {
    return trackedColumns.includes(columnName);
  };

  return { getCompanyNews, isTrackedColumn, loading };
}