import React, { useState, useRef, useEffect } from 'react';
import Sidebar from './Sidebar';
import { Menu } from 'lucide-react';
import logo from '../assets/logo.png';
import { useAuth } from '../contexts/AuthContext';
import { useLocation } from 'react-router-dom';
import { useConfig } from '../contexts/ConfigContext';

const ALLOWED_UNREGISTERED_PATHS = ['/login', '/signup', '/reset-password'];

const Layout = ({ children, mainContentRef, showSidebar = true }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { currentUser, isAdmin, isRegistrationComplete } = useAuth();
  const { config } = useConfig();
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      if (mainContentRef.current) {
        mainContentRef.current.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    };
  
    window.addEventListener('followUpQuery', handleScroll);
  
    return () => {
      window.removeEventListener('followUpQuery', handleScroll);
    };
  }, [mainContentRef]);

  // Show full layout for auth routes and when registration is complete
  const shouldShowFullLayout = isRegistrationComplete || ALLOWED_UNREGISTERED_PATHS.includes(location.pathname);

  // If we're on a protected route and registration is not complete, show minimal layout
  if (!shouldShowFullLayout && currentUser) {
    return (
      <div className="flex h-screen bg-gray-100 overflow-hidden">
        <main className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100">
          <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6">
            {children}
          </div>
        </main>
      </div>
    );
  }

  const testModeBannerClasses = `
    w-full 
    bg-white text-accent
    px-4 py-2 text-center
    font-medium text-sm
    border-b border-primary/10
    animate-bannerSlideDown
  `;

  return (
    <div className="flex flex-col h-screen bg-gray-100 overflow-hidden">
      {/* Test Mode Banner */}
      {config?.core?.isProduction === false && (
        <div className={testModeBannerClasses}>
          📢 Setup Mode: This is a work-in-progress environment. Data might be incomplete or inaccurate.
        </div>
      )}
      
      <div className="flex flex-1 overflow-hidden">
        {/* Only show sidebar for authenticated routes */}
        {!ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) && (
          <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} isAdmin={isAdmin} />
        )}
        <div className="flex-1 flex flex-col overflow-hidden">
          {/* Mobile Header - Added transition and conditional visibility based on sidebar state */}
          {!ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) && (
            <header className={`bg-white shadow-sm lg:hidden sticky top-0 z-50 transition-opacity duration-300 ${
              sidebarOpen ? 'opacity-0 pointer-events-none' : 'opacity-100'
            }`}>
              <div className="px-4 py-2 flex items-center justify-between">
                <button
                  onClick={() => setSidebarOpen(true)}
                  className="text-gray-500 focus:outline-none focus:text-gray-700"
                >
                  <Menu className="h-6 w-6" />
                </button>
                <img src={logo} alt="Logo" className="h-8 w-auto" />
                <div className="w-6" /> {/* Spacer for alignment */}
              </div>
            </header>
          )}
          <main 
            ref={mainContentRef} 
            className="flex-1 overflow-x-hidden overflow-y-auto bg-gray-100"
          >
            <div className={`container mx-auto px-4 sm:px-6 lg:px-8 py-4 sm:py-6 ${
              ALLOWED_UNREGISTERED_PATHS.includes(location.pathname) ? 'max-w-md' : ''
            }`}>
              {React.cloneElement(children, { isAdmin, mainContentRef })}
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;