import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import UserPreferencesModal from '../components/UserPreferencesModal';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getApp } from 'firebase/app';

function Signup() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [preferencesLoading, setPreferencesLoading] = useState(false);
  const functions = getFunctions(getApp());
  const firestore = getFirestore(getApp());
  const checkAllowedEmail = httpsCallable(functions, 'checkAllowedEmail');
  
  const { 
    signup, 
    saveUserPreferences, 
    currentUser,
    isRegistrationComplete,
    registrationChecked 
  } = useAuth();
  const { refresh: refreshConfig } = useConfig();
  const navigate = useNavigate();
  const location = useLocation();

  // Clear errors when inputs change
  useEffect(() => {
    if (error) setError('');
    if (message) setMessage('');
  }, [email, password, confirmPassword]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    if (isLoading) return;
    
    // Validation
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }
  
    if (password.length < 6) {
      setError('Password must be at least 6 characters');
      return;
    }
  
    setIsLoading(true);
    setError('');
    setMessage('');
  
    try {
      const normalizedEmail = email.toLowerCase();
      console.log('Attempting to sign up with email:', normalizedEmail);
      
    // First check if the email is allowed and get registration status
    const emailCheckResult = await checkAllowedEmail({ email: normalizedEmail });
    const emailCheck = emailCheckResult.data;
      
      if (!emailCheck.allowed) {
        setError('This email is not allowed to register. Please contact the administrator.');
        return;
      }
  
      // Try to sign up the user
      await signup(normalizedEmail, password);
        
      // If successful, check if there are pre-existing preferences
      const userPrefsRef = doc(firestore, 'registeredUsersPreferences', normalizedEmail);
      const userPrefs = await getDoc(userPrefsRef);

      if (userPrefs.exists() && userPrefs.data().isPreregistered) {
        // User was pre-registered by admin, but we'll still show the preferences modal
        // Just update the isPreregistered flag
        await updateDoc(userPrefsRef, {
          isPreregistered: false,
          lastUpdated: serverTimestamp()
        });
      }
  
      // Show preferences modal for ALL users
      setMessage('Account created successfully. Please complete your profile.');
  
    } catch (error) {
      console.error('Signup error:', error);
      
      if (error.message.includes('Email not allowed to register')) {
        setError('This email is not allowed to register. Please contact the administrator.');
      } else if (error.code === 'auth/email-already-in-use') {
        setError('An account with this email already exists.');
      } else if (error.code === 'auth/invalid-email') {
        setError('Please enter a valid email address.');
      } else if (error.code === 'auth/operation-not-allowed') {
        setError('Signup is currently disabled. Please try again later.');
      } else if (error.code === 'auth/weak-password') {
        setError('Please choose a stronger password.');
      } else {
        setError('Failed to create account. Please try again.');
      }
    } finally {
      setIsLoading(false);
    }
  };

const handlePreferencesSubmit = async (preferences) => {
  if (!currentUser) {
    setError('No user found. Please try signing up again.');
    return;
  }

  setPreferencesLoading(true);
  setError('');
  
  try {
    // Save user preferences
    await saveUserPreferences(currentUser.email, preferences);
    setMessage('Registration completed successfully.');
    
    // Add a delay to ensure Firebase updates are complete
    await new Promise(resolve => setTimeout(resolve, 1000));
    
    // Force a config refresh and wait for it to complete
    await refreshConfig();
    
    // Navigate to dashboard
    try {
      navigate('/ask', { replace: true });
    } catch (navError) {
      console.error('Navigation error:', navError);
      // Fallback: force page reload to dashboard
      window.location.href = '/ask';
    }
  } catch (error) {
    console.error('Error saving preferences:', error);
    setError('Failed to save preferences. Please try again.');
  } finally {
    setPreferencesLoading(false);
  }
};

  // Determine if modal should be shown based on auth state
  const showPreferencesModal = currentUser && registrationChecked && !isRegistrationComplete;

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="w-full max-w-md px-4 py-8">
        <h1 className="text-title text-text-light mb-6 text-center">Sign Up</h1>
        <div className="bg-white shadow-card rounded-lg p-6">
          {error && (
            <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded mb-4" role="alert">
              <p>{error}</p>
            </div>
          )}
          {message && (
            <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4" role="alert">
              <p>{message}</p>
            </div>
          )}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-text-light text-sm font-medium mb-2">
                Email Address
              </label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                disabled={isLoading}
                placeholder="Enter your email"
              />
            </div>
            <div className="mb-4">
              <label htmlFor="password" className="block text-text-light text-sm font-medium mb-2">
                Password
              </label>
              <input
                type="password"
                id="password"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                disabled={isLoading}
                placeholder="Create a password"
                minLength={6}
              />
            </div>
            <div className="mb-6">
              <label htmlFor="confirmPassword" className="block text-text-light text-sm font-medium mb-2">
                Confirm Password
              </label>
              <input
                type="password"
                id="confirmPassword"
                className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                disabled={isLoading}
                placeholder="Confirm your password"
                minLength={6}
              />
            </div>
            <button
              type="submit"
              className={`w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300 ${
                isLoading ? 'opacity-50 cursor-not-allowed' : ''
              }`}
              disabled={isLoading}
            >
              {isLoading ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Creating Account...
                </span>
              ) : (
                'Sign Up'
              )}
            </button>
          </form>
          <div className="mt-4 flex flex-col items-center space-y-4 text-sm">
            <div className="w-full border-t border-gray-200"></div>
            <p className="text-text-light">
              Already have an account?{' '}
              <Link 
                to="/login" 
                className="text-primary hover:underline font-medium"
                tabIndex={isLoading ? -1 : 0}
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>

      <UserPreferencesModal
        isOpen={showPreferencesModal}
        onClose={() => null}  // Modal can't be closed until preferences are saved
        onSubmit={handlePreferencesSubmit}
        isLoading={preferencesLoading}
      />
    </div>
  );
}

export default Signup;