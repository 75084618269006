import React, { useMemo, useState } from 'react';
import { eachDayOfInterval, startOfYear, endOfYear, format, getMonth, getDay, differenceInWeeks, isWithinInterval, isAfter } from 'date-fns';

const HeatMap = ({ data, initialYear, onYearChange, selectedRange, lastActualDate, forecastName }) => {
  const [currentYear, setCurrentYear] = useState(initialYear || new Date().getFullYear());
  const startDate = startOfYear(new Date(currentYear, 0, 1));
  const endDate = endOfYear(new Date(currentYear, 0, 1));
  const dateRange = eachDayOfInterval({ start: startDate, end: endDate });
  const totalWeeks = differenceInWeeks(endDate, startDate) + 1;

  const getActivityLevel = (date) => {
    const formattedDate = format(date, 'yyyy-MM-dd');
    return data[formattedDate] || { value: 0, type: 'none' };
  };

  const daysOfWeek = ['', 'Mon', '', 'Wed', '', 'Fri', ''];
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

  const getMonthLabels = () => {
    let labels = [];
    let currentMonth = -1;
    dateRange.forEach((date, index) => {
      const month = getMonth(date);
      if (month !== currentMonth) {
        const weekIndex = Math.floor(index / 7);
        labels.push({ month: months[month], weekIndex });
        currentMonth = month;
      }
    });
    return labels;
  };

  const monthLabels = getMonthLabels();

  const { maxActivityLevel, activityLevels } = useMemo(() => {
    const levels = dateRange.map(date => {
      if (isWithinInterval(date, { start: selectedRange.start, end: selectedRange.end })) {
        return getActivityLevel(date).value || 0;
      }
      return 0;
    });
    return {
      maxActivityLevel: Math.max(...levels, 1),
      activityLevels: levels
    };
  }, [data, dateRange, selectedRange]);

  const hexToRgb = (hex) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return [r, g, b];
  };

  const getColor = (activityLevel, type) => {
    if (activityLevel === 0) {
      return 'bg-background-light';
    }
    
    const normalizedLevel = Math.min(Math.max(activityLevel / maxActivityLevel, 0), 1);
    const adjustedLevel = Math.pow(normalizedLevel, 0.3);

    const actualColor = '#170DF2';
    const forecastColor = '#FFADFF';
    const baseColor = type === 'forecast' ? forecastColor : actualColor;
    const [r2, g2, b2] = hexToRgb(baseColor);
    
    const backgroundLight = '#E5E7EB';
    const [r1, g1, b1] = hexToRgb(backgroundLight);
    
    const r = Math.round(r1 + (r2 - r1) * adjustedLevel);
    const g = Math.round(g1 + (g2 - g1) * adjustedLevel);
    const b = Math.round(b1 + (b2 - b1) * adjustedLevel);
    
    return `rgb(${r}, ${g}, ${b})`;
  };

  const formatNumber = (num) => {
    if (num === null || num === undefined) {
      return 'N/A';
    }
    if (num >= 1000000000) {
      return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
    }
    if (num >= 1000000) {
      return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
    }
    if (num >= 1000) {
      return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
    }
    return num.toFixed(2);
  };

  const handlePreviousYear = () => {
    const newYear = currentYear - 1;
    setCurrentYear(newYear);
    onYearChange(newYear);
  };

  const handleNextYear = () => {
    const newYear = currentYear + 1;
    setCurrentYear(newYear);
    onYearChange(newYear);
  };

  return (
    <div className="bg-white shadow-card rounded-lg p-4 sm:p-6 mb-4 sm:mb-8">
      <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center mb-4">
        <h2 className="text-lg font-semibold text-gray-700 mb-2 sm:mb-0">{forecastName}</h2>
        <div className="flex items-center">
          <button
            onClick={handlePreviousYear}
            className="px-2 py-1 text-xs bg-gray-200 rounded-md hover:bg-gray-300 transition-colors mr-2"
          >
            &#8592; {currentYear - 1}
          </button>
          <span className="text-sm font-semibold">{currentYear}</span>
          <button
            onClick={handleNextYear}
            className="px-2 py-1 text-xs bg-gray-200 rounded-md hover:bg-gray-300 transition-colors ml-2"
          >
            {currentYear + 1} &#8594;
          </button>
        </div>
      </div>
      <div className="flex flex-col overflow-x-auto">
        <div className="grid grid-cols-[auto_repeat(53,_minmax(0,_1fr))] gap-1 mb-2 min-w-[800px]">
          <div className="col-start-2 col-span-full">
            <div className="flex justify-start text-xs text-gray-400">
              {monthLabels.map((label, index) => (
                <div
                  key={index}
                  style={{
                    width: `${(100 / totalWeeks) * (index === monthLabels.length - 1 ? totalWeeks - label.weekIndex : monthLabels[index + 1].weekIndex - label.weekIndex)}%`,
                    paddingLeft: index === 0 ? `${(label.weekIndex / totalWeeks) * 100}%` : '0'
                  }}
                >
                  {label.month}
                </div>
              ))}
            </div>
          </div>
          <div className="col-start-1 col-end-2 row-start-2 row-end-9">
            {daysOfWeek.map((day, index) => (
              <div key={index} className="h-4 text-xs text-gray-400 text-right pr-2">
                {day}
              </div>
            ))}
          </div>
          {dateRange.map((date, index) => {
            const dayOfWeek = getDay(date);
            const weekNumber = Math.floor(index / 7) + 1;
            const { value: activityLevel, type } = getActivityLevel(date);
            const color = getColor(activityLevel || 0, type);
            const isWithinSelectedRange = isWithinInterval(date, { start: selectedRange.start, end: selectedRange.end });
            const isForecast = lastActualDate && isAfter(date, lastActualDate);
            return (
              <div
                key={index}
                className={`w-3 sm:w-4 h-3 sm:h-4 rounded-sm transition-colors duration-200 relative group ${color.startsWith('bg-') ? color : ''} ${isWithinSelectedRange ? '' : 'opacity-50'}`}
                style={{
                  backgroundColor: color.startsWith('rgb') ? color : undefined,
                  gridColumnStart: weekNumber + 1,
                  gridRowStart: dayOfWeek === 0 ? 8 : dayOfWeek + 1,
                }}
              >
                <div className="absolute left-1/2 transform -translate-x-1/2 bottom-full mb-1 px-2 sm:px-3 py-1 sm:py-2 bg-white text-gray-800 text-xs sm:text-sm rounded-lg opacity-0 group-hover:opacity-100 transition-opacity duration-200 whitespace-nowrap z-10 pointer-events-none shadow-md border border-gray-200">
                  <div className="font-bold">{format(date, 'MMM dd, yyyy')}</div>
                  <div>{formatNumber(activityLevel)} {isForecast ? '(Forecast)' : '(Actual)'}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="mt-2 text-xs text-gray-400 flex flex-col sm:flex-row items-start sm:items-center justify-between">
        <div className="flex items-center mb-2 sm:mb-0">
          <span className="mr-2">Less</span>
          {[0, 0.25, 0.5, 0.75, 1].map((level, index) => (
            <div
              key={index}
              className={`w-3 sm:w-4 h-3 sm:h-4 rounded-sm mr-1 ${getColor(level * maxActivityLevel, 'actual').startsWith('bg-') ? getColor(level * maxActivityLevel, 'actual') : ''}`}
              style={{ 
                backgroundColor: getColor(level * maxActivityLevel, 'actual').startsWith('rgb') ? getColor(level * maxActivityLevel, 'actual') : undefined 
              }}
            ></div>
          ))}
          <span className="ml-1">More</span>
        </div>
        <div className="flex items-center">
          <span className="mr-2">Actual</span>
          <div className="w-3 sm:w-4 h-3 sm:h-4 rounded-sm mr-4" style={{ backgroundColor: '#170DF2' }}></div>
          <span className="mr-2">Forecast</span>
          <div className="w-3 sm:w-4 h-3 sm:h-4 rounded-sm" style={{ backgroundColor: '#FFADFF' }}></div>
        </div>
      </div>
    </div>
  );
};

export default HeatMap;