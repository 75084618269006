// hooks/useColumnOperations.js
import { httpsCallable } from 'firebase/functions';
import { functions } from '../firebase';

export const useColumnOperations = (currentUser, selectedTable, refreshView, handleFixOperationStatus) => {
  const handleOperation = async (operation, columnId, operationName) => {
    try {
      const functionCall = httpsCallable(functions, operation);
      const result = await functionCall({ 
        columnId,
        adminEmail: currentUser.email,
        tableId: selectedTable?.id
      });
      
      if (result.data.success) {
        // First notify about the operation success
        handleFixOperationStatus(
          operationName, 
          true, 
          `Column ${operationName.toLowerCase()}ed successfully.`
        );
        
        // Then trigger a full refresh of the view
        await Promise.all([
          refreshView(), // Refresh the fixed view data
          selectedTable?.onRefresh?.(), // Refresh table data if available
        ]);

        // Return true to indicate success
        return true;
      }
      return false;
    } catch (error) {
      console.error(`Error ${operationName.toLowerCase()}ing column:`, error);
      handleFixOperationStatus(operationName, false, `Error: ${error.message}`);
      return false;
    }
  };

  const handleApproveColumn = async (columnId) => {
    return handleOperation('approveColumnAddition', columnId, 'Approve');
  };
  
  const handleRejectColumn = async (columnId) => {
    return handleOperation('rejectColumnAddition', columnId, 'Reject');
  };
  
  const handleRevertColumn = async (columnId) => {
    return handleOperation('revertColumnAddition', columnId, 'Revert');
  };

  const handleColumnAdded = async (columnId) => {
    return handleOperation('addColumn', columnId, 'Add');
  };

  return {
    handleApproveColumn,
    handleRejectColumn,
    handleRevertColumn,
    handleColumnAdded
  };
};