import React from 'react';
import Card from '../components/Card';
import LookerStudioVis from '../components/LookerStudioVis';

const dashboardItems = [
  { id: 1, title: '', embedUrl: '', width: 'full', height: 900 },
  // Add more dashboard items as needed
];

const getGridClass = (width) => {
  switch (width) {
    case 'full':
      return 'col-span-12';
    case 'half':
      return 'col-span-12 md:col-span-6';
    case 'one-third':
      return 'col-span-12 md:col-span-4';
    default:
      return 'col-span-12';
  }
};

const StaticDashboard = () => {
  return (
    <div className="container mx-auto px-4 py-4 sm:py-8">
      <h1 className="text-gray-500 mb-6 text-xl font-semibold">Sales Overview</h1>
      <div className="grid grid-cols-12 gap-6">
        {dashboardItems.map((item) => (
          <div key={item.id} className={getGridClass(item.width)}>
            <Card title={item.title} titleSize="text-card-title-large">
              <div style={{ height: `${item.height}px` }}>
                <LookerStudioVis embedUrl={item.embedUrl} title={item.title} />
              </div>
            </Card>
          </div>
        ))}
      </div>
    </div>
  );
};

export default StaticDashboard;