import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { Link } from 'react-router-dom';  // Remove useNavigate import

function PasswordReset() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const { resetPassword } = useAuth();

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setError('');
      setMessage('');
      await resetPassword(email);
      setMessage('Check your inbox for further instructions');
    } catch (error) {
      setError('Failed to reset password: ' + error.message);
    }
  }

  return (
    <div className="flex items-center justify-center min-h-screen bg-background">
      <div className="w-full max-w-md px-4 py-8">
        <h1 className="text-title text-text mb-6 text-center">Password Reset</h1>
        <div className="bg-white shadow-card rounded-lg p-6">
          {error && <div className="bg-red-500 text-white px-4 py-3 rounded mb-4" role="alert">{error}</div>}
          {message && <div className="bg-accent text-white px-4 py-3 rounded mb-4" role="alert">{message}</div>}
          <form onSubmit={handleSubmit}>
            <div className="mb-4">
              <label htmlFor="email" className="block text-text-light text-sm font-medium mb-2">Email Address</label>
              <input
                type="email"
                id="email"
                className="w-full px-3 py-2 border border-text-lighter rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <button
              type="submit"
              className="w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 transition duration-300"
            >
              Reset Password
            </button>
          </form>
          <div className="mt-4 text-center">
            <Link to="/login" className="text-primary hover:underline">
              Back to Login
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PasswordReset;
