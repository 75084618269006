import { useState, useEffect } from 'react';

export const MIN_TEXTAREA_HEIGHT = 48;
export const LINE_HEIGHT = 24;
export const MAX_LINES = 4;
export const VERTICAL_PADDING = 24; // 12px top + 12px bottom padding

export const useTextareaHeight = (textareaRef, value) => {
  const [height, setHeight] = useState(MIN_TEXTAREA_HEIGHT);

  const adjustHeight = () => {
    const textarea = textareaRef.current;
    if (!textarea) return;
    
    // Store the current scroll position
    const scrollTop = textarea.scrollTop;
    
    // Reset height to minimum to get proper scrollHeight
    textarea.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
    
    // Calculate the content height excluding padding
    const contentHeight = textarea.scrollHeight - VERTICAL_PADDING;
    const maxHeight = MIN_TEXTAREA_HEIGHT + (LINE_HEIGHT * (MAX_LINES - 1));
    
    // Only expand if content height exceeds the minimum content area
    const newHeight = contentHeight <= (MIN_TEXTAREA_HEIGHT - VERTICAL_PADDING)
      ? MIN_TEXTAREA_HEIGHT
      : Math.min(textarea.scrollHeight, maxHeight);
    
    // Update height
    textarea.style.height = `${newHeight}px`;
    setHeight(newHeight);
    
    // Restore scroll position
    textarea.scrollTop = scrollTop;
  };

  useEffect(() => {
    if (textareaRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        adjustHeight();
      });
      
      resizeObserver.observe(textareaRef.current);
      return () => resizeObserver.disconnect();
    }
  }, []);

  useEffect(() => {
    adjustHeight();
  }, [value]);

  const resetHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = `${MIN_TEXTAREA_HEIGHT}px`;
      setHeight(MIN_TEXTAREA_HEIGHT);
    }
  };

  return { height, adjustHeight, resetHeight, MIN_TEXTAREA_HEIGHT };
};