// components/dataPrep/CreateTableSection.js
import React from 'react';
import CreateTableModal from '../CreateTableModal';

const CreateTableSection = ({ 
  isOpen, 
  onClose, 
  onSubmit, 
  isLoading 
}) => {
  const handleSubmit = async (formData) => {
    try {
      await onSubmit(formData);
    } catch (error) {
      // Let the error propagate up
      throw error;
    }
  };

  return (
    <CreateTableModal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      isLoading={isLoading}
    />
  );
};

export default CreateTableSection;