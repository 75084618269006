import React from 'react';
import { AlertCircle, BarChart3, Table } from 'lucide-react';
import QueryResultCard from '../QueryComponent/QueryResultCard';

const DashboardContent = ({
  error,
  isDashboardLoading,
  selectedDashboard,
  currentUser,
  onSaveCardSettings,
  onFollowUpQuery,
  onSaveQuery,
  onExplanationClick,
  isAdmin,
}) => {
  if (error) {
    return (
      <div className="mt-4 p-4 bg-red-100 border-l-4 border-red-500 text-red-700">
        <div className="flex">
          <AlertCircle className="h-5 w-5 mr-2" />
          <p>{error}</p>
        </div>
      </div>
    );
  }

  if (isDashboardLoading) {
    return (
      <div className="mt-10 text-center">
        <p className="text-gray-600">Loading dashboard structure...</p>
      </div>
    );
  }

  if (selectedDashboard && selectedDashboard.queries && selectedDashboard.queries.length > 0) {
    return (
      <div className="space-y-4">
        {selectedDashboard.queries.map((query) => (
          <div 
            key={`${query.id}-${query.cardSettings?.cardWidth || '100%'}`} 
            className="w-full"
          >
            {query.isLoading ? (
              // Loading state for individual cards with wave animation
              <div className="p-6 bg-white rounded-lg shadow-sm border border-gray-200">
                {/* Header Skeleton */}
                <div className="flex justify-between items-center mb-6">
                  <div className="h-6 bg-gray-200 rounded w-1/3 animate-pulse"></div>
                  <div className="flex space-x-2">
                    <Table className="h-5 w-5 text-gray-300" />
                    <BarChart3 className="h-5 w-5 text-gray-300" />
                  </div>
                </div>
                
                {/* Main Content Skeleton with Wave Effect */}
                <div className="space-y-4">
                  <div className="h-48 bg-gray-100 rounded relative overflow-hidden">
                    {/* Wave Animation Element */}
                    <div className="absolute inset-0">
                      <div 
                        className="absolute inset-0 bg-gradient-to-r from-transparent via-primary/20 to-transparent"
                        style={{
                          animation: 'wave 0.8s infinite linear',
                          width: '200%',
                          transform: 'translateX(-50%)'
                        }}
                      />
                    </div>
                  </div>
                  
                  {/* Stats Skeleton */}
                  <div className="flex justify-between pt-4 border-t">
                    <div className="h-4 bg-gray-200 rounded w-1/4 animate-pulse"></div>
                    <div className="h-4 bg-gray-200 rounded w-1/4 animate-pulse"></div>
                  </div>
                </div>

                <style jsx>{`
                  @keyframes wave {
                    0% {
                      transform: translateX(-50%);
                    }
                    100% {
                      transform: translateX(0%);
                    }
                  }
                `}</style>
              </div>
            ) : (
              <QueryResultCard
                query={query}
                currentUser={currentUser}
                isDashboardMode={true}
                isAdmin={isAdmin}
                onSaveCardSettings={(settings) => onSaveCardSettings(query.id, settings)}
                cardWidth={query.cardSettings?.cardWidth || "100%"}
                onCardWidthChange={(width) => onSaveCardSettings(query.id, { cardWidth: width })}
                onFollowUpQuery={onFollowUpQuery}
                onSaveQuery={onSaveQuery}
                onExplanationClick={() => onExplanationClick(query)}
                truncationMessage={query.truncationMessage || ''}
                isLoading={query.isLoading}
                error={query.error}
              />
            )}
          </div>
        ))}
      </div>
    );
  }

  if (selectedDashboard) {
    return (
      <div className="mt-8 text-center">
        <p className="text-gray-600">This dashboard has no queries.</p>
      </div>
    );
  }

  return (
    <div className="mt-8 text-center">
      <p className="text-gray-600">No dashboard selected. Please select or create a dashboard.</p>
    </div>
  );
};

export default DashboardContent;