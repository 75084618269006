// src/components/PolicyManagement/PolicyCard.js
import React, { useState } from 'react';
import { Edit, ChevronDown, ChevronUp, Check, Loader2, AlertCircle } from 'lucide-react';
import Card from './Card';

const PolicyCard = ({ policy, onEdit }) => {
  const [expanded, setExpanded] = useState(false);

  // Add safety check for policy and policy.tables
  if (!policy) {
    return null;
  }

  const getViewStatusIndicator = (status) => {
    switch (status) {
      case 'READY':
        return (
          <span className="text-green-500 flex items-center text-sm">
            <Check className="h-4 w-4 mr-1" />
            View Ready
          </span>
        );
      case 'PENDING':
        return (
          <span className="text-yellow-500 flex items-center text-sm">
            <Loader2 className="h-4 w-4 mr-1 animate-spin" />
            Creating View
          </span>
        );
      case 'ERROR':
        return (
          <span className="text-red-500 flex items-center text-sm">
            <AlertCircle className="h-4 w-4 mr-1" />
            Error Creating View
          </span>
        );
      default:
        return null;
    }
  };

  return (
    <Card>
      <div className="p-4">
        {/* Header */}
        <div 
          className="flex items-center justify-between cursor-pointer"
          onClick={() => setExpanded(!expanded)}
        >
          <div className="flex items-center space-x-4">
            <h3 className="text-lg font-medium">{policy.name || 'Unnamed Policy'}</h3>
            <button
              onClick={(e) => {
                e.stopPropagation(); // Prevent header click event
                onEdit();
              }}
              className="text-gray-500 hover:text-gray-700"
              title="Edit policy"
            >
              <Edit className="h-5 w-5" />
            </button>
          </div>
          <div className="text-gray-500">
            {expanded ? (
              <ChevronUp className="h-5 w-5" />
            ) : (
              <ChevronDown className="h-5 w-5" />
            )}
          </div>
        </div>

        {/* Table Tags (always visible) */}
        <div className="mt-3">
          <div className="flex flex-wrap gap-2">
            {policy.tables && Object.entries(policy.tables).map(([tableName, tableData]) => (
              <div
                key={tableName}
                className={`inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium ${
                  tableData.fullAccess
                    ? 'bg-green-100 text-green-800'
                    : 'bg-blue-100 text-blue-800'
                }`}
              >
                {tableName}
                <span className="ml-1.5 text-xs opacity-75">
                  {tableData.fullAccess ? '(Full)' : '(Restricted)'}
                </span>
              </div>
            ))}
          </div>
        </div>

        {/* Expanded Content */}
        {expanded && policy.tables && (
          <div className="mt-4 space-y-4">
            {Object.entries(policy.tables).map(([tableName, tableData]) => (
              <div key={tableName} className="border border-gray-200 rounded-md p-4">
                <div className="flex items-center justify-between mb-2">
                  <h4 className="font-medium">{tableName}</h4>
                  <div className="flex items-center">
                    {policy.viewStatus && tableData.materializedViewName && 
                      getViewStatusIndicator(policy.viewStatus[tableData.materializedViewName])}
                  </div>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 text-sm text-gray-600">
                  <div>
                    <p className="font-medium text-gray-700">Original Table</p>
                    <p className="break-all">{tableData.originalTable || 'N/A'}</p>
                  </div>
                  
                  <div>
                    <p className="font-medium text-gray-700">Materialized View</p>
                    <p className="break-all">{tableData.materializedViewName || 'N/A'}</p>
                  </div>
                  
                  <div>
                    <p className="font-medium text-gray-700">Allowed Columns</p>
                    <p className="break-all">
                      {tableData.allowedColumns?.join(', ') || 'All'}
                    </p>
                  </div>
                  
                  <div>
                    <p className="font-medium text-gray-700">Row Restriction</p>
                    <p className="break-all">{tableData.rowRestriction || 'None'}</p>
                  </div>
                  
                  <div>
                    <p className="font-medium text-gray-700">Refresh Interval</p>
                    <p>{tableData.refreshInterval || 'N/A'} minutes</p>
                  </div>

                  {policy.viewStatus?.[tableData.materializedView] === 'ERROR' && (
                    <div className="col-span-full">
                      <p className="font-medium text-red-700">Error Details</p>
                      <p className="text-red-600">
                        {policy.viewStatus?.[tableData.materializedView]?.error || 'Unknown error'}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </Card>
  );
};

export default PolicyCard;