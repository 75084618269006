import React, { useState } from 'react';
import Card from '../components/Card';
import { X, Type, Briefcase } from 'lucide-react';

const UserPreferencesModal = ({ isOpen, onClose, onSubmit, isLoading }) => {
  const [formData, setFormData] = useState({
    englishName: '',
    role: ''
  });
  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};
    const englishName = formData.englishName.trim();
    const sanitizedRole = formData.role.trim();
  
    // Stricter name validation
    if (!englishName) {
      newErrors.englishName = 'Name is required';
    } else if (englishName.length < 2) {
      newErrors.englishName = 'Name must be at least 2 characters long';
    } else if (!/^[\p{L}\s]+$/u.test(englishName)) {
      newErrors.englishName = 'Name can only contain letters and spaces';
    }
    
    // Role validation
    if (!sanitizedRole) {
      newErrors.role = 'Role is required';
    } else if (sanitizedRole.length < 3) {
      newErrors.role = 'Role must be at least 3 characters long';
    } else if (!/^[\p{L}\s"״״]+$/u.test(sanitizedRole)) {
      newErrors.role = 'Role can only contain letters, spaces, and quotation marks';
    }
    
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0 && englishName && sanitizedRole;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Sanitize the role before submission
      const sanitizedFormData = {
        ...formData,
        role: formData.role.trim()
      };
      onSubmit(sanitizedFormData);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gradient-to-br from-white via-white to-secondary/20 rounded-lg shadow-xl w-full max-w-md">
        <div className="bg-gradient-to-r from-secondary/10 via-secondary/5 to-transparent px-6 py-4 rounded-t-lg">
          <h2 className="text-xl font-semibold text-gray-800">Let's Personalize Your Experience</h2>
          <p className="text-sm text-gray-600 mt-1">
            Just a few quick questions and then we begin
          </p>
        </div>
        <form onSubmit={handleSubmit} className="p-6">
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                <Type className="w-4 h-4 mr-2 text-secondary" />
                Full Name
              </label>
              <input
                type="text"
                value={formData.englishName}
                onChange={(e) => setFormData(prev => ({ ...prev, englishName: e.target.value }))}
                className={`w-full p-2 border rounded-md ${errors.englishName ? 'border-red-500' : 'border-gray-300'}
                  focus:ring-2 focus:ring-secondary focus:ring-opacity-50 focus:border-transparent
                  transition duration-200 ease-in-out`}
              />
              {errors.englishName && (
                <p className="text-red-500 text-xs mt-1">{errors.englishName}</p>
              )}
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1 flex items-center">
                <Briefcase className="w-4 h-4 mr-2 text-secondary" />
                Role
              </label>
              <input
                type="text"
                value={formData.role}
                onChange={(e) => setFormData(prev => ({ ...prev, role: e.target.value }))}
                placeholder="Enter your role"
                className={`w-full p-2 border rounded-md ${errors.role ? 'border-red-500' : 'border-gray-300'}
                  focus:ring-2 focus:ring-secondary focus:ring-opacity-50 focus:border-transparent
                  transition duration-200 ease-in-out`}
              />
              {errors.role && (
                <p className="text-red-500 text-xs mt-1">{errors.role}</p>
              )}
            </div>
          </div>

          <div className="mt-6">
            <button
              type="submit"
              className="w-full bg-secondary text-text py-2 px-4 rounded-md hover:bg-opacity-80 
                focus:outline-none focus:ring-2 focus:ring-secondary focus:ring-opacity-50 
                transition duration-300 disabled:opacity-50 transform hover:scale-[1.02] active:scale-[0.98]"
              disabled={isLoading}
            >
              {isLoading ? 'Saving...' : 'Complete Registration'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserPreferencesModal;