import React, { useState, useEffect } from 'react';
import { Loader2, Plus, Trash2, Shield, Mail, X, Check, AlertCircle, ChevronDown, ChevronUp } from 'lucide-react';
import Card from './Card';
import { useAuth } from '../contexts/AuthContext';
import { useConfig } from '../contexts/ConfigContext';

const UserManagement = () => {
  const { currentUser } = useAuth();
  const { config } = useConfig();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [expandedRows, setExpandedRows] = useState({});
  const [showNewUserForm, setShowNewUserForm] = useState(false);
  const [sortConfig, setSortConfig] = useState({ key: 'email', direction: 'asc' });
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [saveStatus, setSaveStatus] = useState(null);
  const [processingUsers, setProcessingUsers] = useState({});

  // Form state
  const emptyUser = {
    email: '',
    isAdmin: false,
    roles: []
  };

  const [formData, setFormData] = useState(emptyUser);

  // Fetch users and roles
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        
        // Fetch roles from Firestore roles collection
        const rolesResponse = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/getRoles`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              adminEmail: currentUser.email
            }
          })
        });
        const rolesData = await rolesResponse.json();
        
        // Fetch users
        const usersResponse = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/getUsers`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              adminEmail: currentUser.email
            }
          })
        });
        const usersData = await usersResponse.json();

        if (!usersData.data.success) {
          throw new Error(usersData.data.details || 'Failed to fetch users');
        }

        setUsers(usersData.data.users);
        setRoles(rolesData.data.roles || []); // Adjusted to match the expected response structure
      } catch (err) {
        console.error('Error fetching data:', err);
        setError(err.message || 'Failed to fetch data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentUser.email, config.core.projectId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setIsSubmitting(true);
      setError(null);
      
      const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/createUser`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          data: {
            adminEmail: currentUser.email.toLowerCase(),
            userEmail: formData.email.toLowerCase(),
            isAdmin: formData.isAdmin,
            roles: formData.roles
          }
        })
      });
  
      const result = await response.json();
  
      if (!result.data.success) {
        throw new Error(result.data.details || 'Failed to create user');
      }
  
      setUsers(prev => [...prev, {
        email: formData.email,
        englishName: formData.englishName,
        isAdmin: formData.isAdmin,
        roles: formData.roles,
        createdAt: new Date().toISOString()
      }]);
  
      setFormData(emptyUser);
      setShowNewUserForm(false);
      setSaveStatus({ type: 'success', message: 'User created successfully' });
      setTimeout(() => setSaveStatus(null), 3000);
    } catch (err) {
      console.error('Error creating user:', err);
      setError(err.message || 'Failed to create user');
      setSaveStatus({ type: 'error', message: err.message || 'Failed to create user' });
      setTimeout(() => setSaveStatus(null), 3000);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleDelete = async (email) => {
    if (window.confirm('Are you sure you want to delete this user? This action cannot be undone.')) {
      try {
        setProcessingUsers(prev => ({ ...prev, [email]: true }));
        setError(null);
  
        const response = await fetch(`https://us-central1-${config.core.projectId}.cloudfunctions.net/deleteUser`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            data: {
              adminEmail: currentUser.email,
              userEmail: email
            }
          })
        });
  
        const result = await response.json();
  
        if (!result.data.success) {
          throw new Error(result.data.details || 'Failed to delete user');
        }
  
        setUsers(prev => prev.filter(user => user.email !== email));
        setSaveStatus({ type: 'success', message: 'User deleted successfully' });
        setTimeout(() => setSaveStatus(null), 3000);
      } catch (err) {
        console.error('Error deleting user:', err);
        setError(err.message || 'Failed to delete user');
        setSaveStatus({ type: 'error', message: err.message || 'Failed to delete user' });
        setTimeout(() => setSaveStatus(null), 3000);
      } finally {
        setProcessingUsers(prev => ({ ...prev, [email]: false }));
      }
    }
  };

  const handleCancel = () => {
    setShowNewUserForm(false);
    setFormData(emptyUser);
  };

  const toggleExpand = (email) => {
    setExpandedRows(prev => ({
      ...prev,
      [email]: !prev[email]
    }));
  };

  const handleRoleToggle = (roleId) => {
    setFormData(prev => ({
      ...prev,
      roles: prev.roles.includes(roleId)
        ? prev.roles.filter(id => id !== roleId)
        : [...prev.roles, roleId]
    }));
  };

  const handleSort = (key) => {
    setSortConfig(prevConfig => ({
      key,
      direction: prevConfig.key === key && prevConfig.direction === 'asc' ? 'desc' : 'asc'
    }));
  };

  const sortedUsers = [...users].sort((a, b) => {
    if (sortConfig.key === 'email') {
      return sortConfig.direction === 'asc' 
        ? a.email.localeCompare(b.email)
        : b.email.localeCompare(a.email);
    }
    if (sortConfig.key === 'englishName') {
      return sortConfig.direction === 'asc'
        ? (a.englishName || '').localeCompare(b.englishName || '')
        : (b.englishName || '').localeCompare(a.englishName || '');
    }
    if (sortConfig.key === 'createdAt') {
      return sortConfig.direction === 'asc'
        ? new Date(a.createdAt) - new Date(b.createdAt)
        : new Date(b.createdAt) - new Date(a.createdAt);
    }
    return 0;
  });

  if (loading) {
    return (
      <div className="flex items-center justify-center p-4">
        <Loader2 className="h-8 w-8 animate-spin text-gray-500" />
      </div>
    );
  }

  return (
    <div className="space-y-6">
      {/* New User button */}
      {!showNewUserForm && (
        <button
          onClick={() => setShowNewUserForm(true)}
          className="inline-flex items-center px-4 py-2 text-sm text-white bg-primary rounded-md hover:bg-primary/90"
        >
          <Plus className="h-4 w-4 mr-2" />
          New User
        </button>
      )}

      {/* User form */}
      {showNewUserForm && (
        <Card>
          <div className="p-6">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-xl font-semibold">Create New User</h2>
              <button
                type="button"
                onClick={handleCancel}
                className="text-gray-500 hover:text-gray-700"
              >
                <X className="h-5 w-5" />
              </button>
            </div>
            
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={e => setFormData(prev => ({ ...prev, email: e.target.value.toLowerCase() }))}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-primary"
                  required
                />
              </div>

              <div className="flex items-center space-x-2">
                <input
                  type="checkbox"
                  id="isAdmin"
                  checked={formData.isAdmin}
                  onChange={e => setFormData(prev => ({ ...prev, isAdmin: e.target.checked }))}
                  className="h-4 w-4 text-primary focus:ring-primary border-gray-300 rounded"
                />
                <label htmlFor="isAdmin" className="text-sm font-medium text-gray-700">
                  Admin User
                </label>
              </div>

              <div className="space-y-4">
                <h3 className="text-lg font-medium">Assign Roles (Optional)</h3>
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3">
                  {roles.map(role => (
                    <div
                      key={role.id}
                      className={`border rounded-md p-4 cursor-pointer transition-colors ${
                        formData.roles.includes(role.id)
                          ? 'border-primary bg-primary bg-opacity-5'
                          : 'border-gray-200 hover:border-primary'
                      }`}
                      onClick={() => handleRoleToggle(role.id)}
                    >
                      <div className="flex items-center justify-between">
                        <span className="font-medium">{role.name}</span>
                        {formData.roles.includes(role.id) && (
                          <Check className="h-5 w-5 text-primary" />
                        )}
                      </div>
                      <p className="text-sm text-gray-500 mt-1">
                        {role.policies?.length || 0} {role.policies?.length === 1 ? 'policy' : 'policies'}
                      </p>
                    </div>
                  ))}
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={handleCancel}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-900"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="px-4 py-2 text-sm text-white bg-primary rounded-md hover:bg-primary/90 disabled:opacity-50"
                >
                  {isSubmitting ? (
                    <Loader2 className="h-4 w-4 animate-spin" />
                  ) : (
                    'Create User'
                  )}
                </button>
              </div>
            </form>
          </div>
        </Card>
      )}

      {error && (
        <div className="flex items-center space-x-2 text-red-500 p-4">
          <AlertCircle className="h-5 w-5" />
          <p>{error}</p>
        </div>
      )}

      {saveStatus && (
        <div className={`${
          saveStatus.type === 'success' ? 'bg-green-50 text-green-500' : 'bg-red-50 text-red-500'
        } p-4 rounded-md flex items-center`}>
          {saveStatus.type === 'success' ? (
            <svg className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
            </svg>
          ) : (
            <AlertCircle className="h-5 w-5 mr-2" />
          )}
          {saveStatus.message}
        </div>
      )}

      {/* Users table */}
      <div className="overflow-x-auto">
        <table className="min-w-full divide-y divide-gray-200">
          <thead className="bg-gray-50">
            <tr>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <button 
                  className="flex items-center space-x-1 hover:text-gray-700"
                  onClick={() => handleSort('email')}
                >
                  <span>Email/Name</span>
                  {sortConfig.key === 'email' && (
                    <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                  )}
                </button>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                <button 
                  className="flex items-center space-x-1 hover:text-gray-700"
                  onClick={() => handleSort('createdAt')}
                >
                  <span>Created At</span>
                  {sortConfig.key === 'createdAt' && (
                    <span>{sortConfig.direction === 'asc' ? '↑' : '↓'}</span>
                  )}
                </button>
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Status
              </th>
              <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-gray-200">
            {sortedUsers.map(user => (
              <React.Fragment key={user.email}>
                <tr className={`${expandedRows[user.email] ? 'bg-gray-50' : 'hover:bg-gray-50'}`}>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="flex items-center">
                      <div className="flex-shrink-0">
                        <Mail className="h-5 w-5 text-gray-400" />
                      </div>
                      <div className="ml-4">
                        <div className="flex items-center space-x-2">
                          <div className="text-sm font-medium text-gray-900">{user.email}</div>
                          {user.isAdmin && <Shield className="h-4 w-4 text-primary" />}
                        </div>
                      </div>
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <div className="text-sm text-gray-900">
                      {new Date(user.createdAt).toLocaleDateString()}
                    </div>
                    <div className="text-sm text-gray-500">
                      {new Date(user.createdAt).toLocaleTimeString()}
                    </div>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap">
                    <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                      user.disabled 
                        ? 'bg-red-100 text-red-800' 
                        : 'bg-green-100 text-green-800'
                    }`}>
                      {user.disabled ? 'Disabled' : 'Active'}
                    </span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                    <div className="flex items-center space-x-4">
                      <button
                        onClick={() => toggleExpand(user.email)}
                        className="text-gray-400 hover:text-gray-500"
                      >
                        {expandedRows[user.email] ? (
                          <ChevronUp className="h-5 w-5" />
                        ) : (
                          <ChevronDown className="h-5 w-5" />
                        )}
                      </button>
                      <button
                        onClick={() => handleDelete(user.email)}
                        disabled={processingUsers[user.email]}
                        className="text-red-500 hover:text-red-700"
                      >
                        {processingUsers[user.email] ? (
                          <Loader2 className="h-5 w-5 animate-spin" />
                        ) : (
                          <Trash2 className="h-5 w-5" />
                        )}
                      </button>
                    </div>
                  </td>
                </tr>
                {expandedRows[user.email] && (
                  <tr>
                    <td colSpan="4" className="px-6 py-4">
                      <div className="space-y-4">
                        <div className="border border-gray-200 rounded-md p-4">
                          <h4 className="font-medium mb-2">User Details</h4>
                          <div className="space-y-2 text-sm">
                            <div>
                              <span className="text-gray-500">Last Sign In:</span>{' '}
                              {user.lastSignIn ? new Date(user.lastSignIn).toLocaleString() : 'Never'}
                            </div>
                            <div>
                              <span className="text-gray-500">Email Verified:</span>{' '}
                              {user.emailVerified ? 'Yes' : 'No'}
                            </div>
                          </div>
                        </div>

                        <div className="border border-gray-200 rounded-md p-4">
                          <h4 className="font-medium mb-2">Assigned Roles</h4>
                          <div className="flex flex-wrap gap-2">
                          {(!user.roles || user.roles.length === 0) ? (
                              <p className="text-sm text-gray-500">No roles assigned</p>
                            ) : (
                              <div className="flex flex-wrap gap-2">
                                {user.roles.map(roleId => {
                                  const role = roles.find(r => r.id === roleId);
                                  if (role) {
                                    return (
                                      <span
                                        key={roleId}
                                        className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-gray-100 text-gray-800"
                                        title={`Role ID: ${roleId}`}
                                      >
                                        {role.name}
                                      </span>
                                    );
                                  }
                                  // Only show unknown role in development environment
                                  if (process.env.NODE_ENV === 'development') {
                                    return (
                                      <span
                                        key={roleId}
                                        className="inline-flex items-center px-3 py-1 rounded-full text-sm bg-red-100 text-red-800"
                                        title="Role not found in database"
                                      >
                                        Unknown Role ({roleId})
                                      </span>
                                    );
                                  }
                                  return null; // Don't show unknown roles in production
                                })}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default UserManagement;