import React, { useMemo, useEffect, useState } from 'react';
import { BarChart, LineChart, PieChart, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Bar, Line, Pie, Cell, ResponsiveContainer } from 'recharts';
import { formatDate, formatNumber, sortData } from './dataProcessing';
import { ArrowUpDown } from 'lucide-react';
import { currentConfig } from '../../contexts/ConfigContext';

const COLORS = ['#8884d8', '#FFADFF', '#170DF2', '#ff8042', '#0088FE', '#00C49F'];

const CustomPieLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent }) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  return (
    <text 
      x={x} 
      y={y} 
      fill="white" 
      textAnchor={x > cx ? 'start' : 'end'} 
      dominantBaseline="central"
      fontSize="12"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const ChartView = ({ 
  data, 
  schema, 
  chartType, 
  selectedXAxis, 
  selectedYAxis, 
  sortColumn, 
  sortOrder, 
  onYAxisRemove,
  syncScales,
  onSyncScalesChange,
  isNumberFormatted  // Add this line
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const getSchemaType = (fieldName) => {
    const field = schema?.fields?.find(f => f.name === fieldName);
    return field ? field.type : null;
  };

  const formatAxisValue = (value, axisName) => {
    const schemaType = getSchemaType(axisName);
    if (schemaType && schemaType.toLowerCase().includes('date')) {
      return formatDate(value, schemaType);
    } else if (schemaType && ['INTEGER', 'FLOAT', 'NUMERIC'].includes(schemaType.toUpperCase())) {
      return isNumberFormatted ? formatNumber(value, axisName) : value;
    }
    return value;
  };

  const formatAxisLabel = (label) => {
    return label
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  const yAxes = Array.isArray(selectedYAxis) ? selectedYAxis : [selectedYAxis];

  const validData = useMemo(() => {
    if (!Array.isArray(data) || !selectedXAxis || !yAxes.length) return [];
  
    // First process the data while maintaining structure
    const processedData = data
      .map(item => {
        // Create a new object with the same structure as TableView
        const newItem = {};
        
        // Process X axis
        newItem[selectedXAxis] = {
          displayValue: item[selectedXAxis]?.value ?? item[selectedXAxis]?.displayValue ?? item[selectedXAxis],
          sortValue: item[selectedXAxis]?.sortValue ?? item[selectedXAxis]?.value ?? item[selectedXAxis]
        };
  
        // Process Y axes
        yAxes.forEach(axis => {
          newItem[axis] = {
            displayValue: item[axis]?.displayValue ?? item[axis],
            sortValue: item[axis]?.sortValue ?? item[axis]
          };
        });
  
        return newItem;
      })
      .filter(item => yAxes.some(axis => {
        const value = item[axis]?.sortValue;
        return typeof value === 'number' && !isNaN(value);
      }));
  
    // Use the same sortData function as TableView
    const sortedData = sortData(processedData, sortColumn, sortOrder);
  
    // Transform for chart display
    return sortedData.map(item => {
      const transformedItem = {
        [selectedXAxis]: item[selectedXAxis].displayValue
      };
      yAxes.forEach(axis => {
        transformedItem[axis] = item[axis].sortValue;
      });
      return transformedItem;
    });
  }, [data, selectedXAxis, yAxes, sortColumn, sortOrder]);

  const getYDomain = (axis) => {
    if (validData.length === 0) return [0, 1];
  
    if (syncScales && yAxes.length > 1) {
      const allValues = validData.flatMap(item => 
        yAxes.map(yAxis => item[yAxis])
      ).filter(val => typeof val === 'number' && !isNaN(val));
      
      const minValue = Math.min(...allValues);
      const maxValue = Math.max(...allValues);
  
      if (minValue === maxValue) {
        return [0, maxValue * 1.1];
      }
  
      const padding = (maxValue - minValue) * 0.1;
      
      // For bar charts, always start from 0
      if (chartType === 'bar') {
        return [0, maxValue + padding];
      }
      
      // For line charts, use adaptive scaling
      return [Math.max(0, minValue - padding), maxValue + padding];
    } else {
      const values = validData.map(item => item[axis])
        .filter(val => typeof val === 'number' && !isNaN(val));
      const minValue = Math.min(...values);
      const maxValue = Math.max(...values);
  
      if (minValue === maxValue) {
        return [0, maxValue * 1.1];
      }
  
      const padding = (maxValue - minValue) * 0.1;
      
      // For bar charts, always start from 0
      if (chartType === 'bar') {
        return [0, maxValue + padding];
      }
      
      // For line charts, use adaptive scaling
      return [Math.max(0, minValue - padding), maxValue + padding];
    }
  };

  const getChartHeight = () => {
    const baseHeight = 450;
    return isMobile ? baseHeight * 0.7 : baseHeight * 0.8;
  };

  const commonProps = {
    data: validData,
    margin: isMobile
      ? { top: 60, right: 30, left: 30, bottom: 40 }
      : { top: 60, right: 40, left: 40, bottom: 40 }
  };

  const commonAxisProps = {
    tickFormatter: (value) => formatAxisValue(value, selectedXAxis),
    angle: -45,
    textAnchor: "end",
    height: 60,
    style: { fontSize: '12px', fontFamily: 'inherit' }
  };

  const handleAxisRemove = (axis) => {
    // Only allow removal if there will be at least one axis remaining
    if (yAxes.length > 1 && typeof onYAxisRemove === 'function') {
      onYAxisRemove(axis);
    }
  };

  const renderChart = () => {
    switch (chartType) {
      case 'bar':
      case 'line':
        const ChartComponent = chartType === 'bar' ? BarChart : LineChart;
        const DataComponent = chartType === 'bar' ? Bar : Line;

        return (
          <div style={{ width: '100%', height: '100%', position: 'relative' }}>
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              {/* Scale Toggle */}
              {yAxes.length > 1 && (
                <button
                  onClick={() => onSyncScalesChange && onSyncScalesChange(!syncScales)}
                  className={`absolute left-1/2 -translate-x-1/2 top-3 flex items-center gap-1.5 px-2 py-1 rounded 
                    transition-colors duration-200 hover:text-secondary
                    ${syncScales 
                      ? 'bg-gray-700 text-white' 
                      : 'text-gray-600 bg-gray-50'} 
                    hover:bg-gray-100 active:bg-gray-700 active:text-white`}
                  title={syncScales ? "Disable scale comparison" : "Enable scale comparison"}
                  style={{ zIndex: 30 }}
                >
                  <ArrowUpDown 
                    size={13} 
                    className={`transition-transform hover:scale-110 ${
                      syncScales ? 'stroke-[2.5px]' : 'stroke-[2px]'
                    }`}
                  />
                  <span className="hidden md:inline text-[11px] font-medium">
                    {syncScales ? 'Comparing' : 'Compare'}
                  </span>
                </button>
              )}

              {/* Left Y-axis label */}
              {yAxes.length > 0 && (
                <div
                  style={{
                    position: 'absolute',
                    left: isMobile ? '4px' : '8px',
                    top: '12px',
                    zIndex: 20,
                    pointerEvents: 'all'
                  }}
                >
                  <div
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: 'white',
                      backgroundColor: COLORS[0],
                      cursor: yAxes.length > 1 ? 'pointer' : 'default',
                      padding: '2px 8px',
                      borderRadius: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      transition: 'transform 0.2s ease-in-out'
                    }}
                    onClick={() => handleAxisRemove(yAxes[0])}
                    title={yAxes.length > 1 ? `Click to remove ${formatAxisLabel(yAxes[0])}` : undefined}
                    onMouseEnter={(e) => yAxes.length > 1 && (e.currentTarget.style.transform = 'scale(1.05)')}
                    onMouseLeave={(e) => yAxes.length > 1 && (e.currentTarget.style.transform = 'scale(1)')}
                  >
                    {formatAxisLabel(yAxes[0])}
                    {yAxes.length > 1 && <span style={{ fontSize: '10px' }}>×</span>}
                  </div>
                </div>
              )}

              {/* Right Y-axis label */}
              {yAxes.length > 1 && (
                <div
                  style={{
                    position: 'absolute',
                    right: isMobile ? '4px' : '8px',
                    top: '12px',
                    zIndex: 20,
                    pointerEvents: 'all'
                  }}
                >
                  <div
                    style={{
                      fontSize: '12px',
                      fontWeight: 500,
                      color: 'white',
                      backgroundColor: COLORS[1],
                      cursor: 'pointer',
                      padding: '2px 8px',
                      borderRadius: '12px',
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                      transition: 'transform 0.2s ease-in-out'
                    }}
                    onClick={() => handleAxisRemove(yAxes[1])}
                    title={`Click to remove ${formatAxisLabel(yAxes[1])}`}
                    onMouseEnter={(e) => e.currentTarget.style.transform = 'scale(1.05)'}
                    onMouseLeave={(e) => e.currentTarget.style.transform = 'scale(1)'}
                  >
                    {formatAxisLabel(yAxes[1])}
                    <span style={{ fontSize: '10px' }}>×</span>
                  </div>
                </div>
              )}

              <ResponsiveContainer>
                <ChartComponent {...commonProps}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey={selectedXAxis} {...commonAxisProps} />
                  {yAxes.map((axis, index) => (
                    <YAxis 
                    key={axis}
                    yAxisId={axis}
                    orientation={index === 0 ? "left" : "right"}
                    tickFormatter={(value) => {
                      const exemptedColumns = currentConfig?.processing?.exemptedColumns || [];
                      if (exemptedColumns.includes(axis)) {
                        return value;
                      }
                      return isNumberFormatted ? 
                        formatAxisValue(value, axis) : 
                        value.toLocaleString('en-US', {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 2
                        });
                    }}
                    style={{ fontSize: '12px', fontFamily: 'inherit' }}
                    domain={getYDomain(axis)}
                  />
                  ))}
                  <Tooltip 
                    labelFormatter={(value) => formatAxisValue(value, selectedXAxis)}
                    formatter={(value, name) => {
                      const exemptedColumns = currentConfig?.processing?.exemptedColumns || [];
                      if (exemptedColumns.includes(name)) {
                        return [value, formatAxisLabel(name)];
                      }
                      return [
                        isNumberFormatted ? 
                          formatNumber(value, name) : 
                          value.toLocaleString('en-US', {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 2
                          }), 
                        formatAxisLabel(name)
                      ];
                    }}
                  />
                  {yAxes.map((axis, index) => (
                    <DataComponent 
                      key={axis}
                      type={chartType === 'line' ? "monotone" : undefined}
                      dataKey={axis}
                      fill={COLORS[index % COLORS.length]}
                      stroke={COLORS[index % COLORS.length]}
                      yAxisId={axis}
                      name={formatAxisLabel(axis)}
                    />
                  ))}
                </ChartComponent>
              </ResponsiveContainer>
            </div>
          </div>
        );

        case 'pie':
          const primaryYAxis = Array.isArray(selectedYAxis) ? selectedYAxis[0] : selectedYAxis;
          const pieData = validData
            .map(item => ({
              name: String(item[selectedXAxis]),
              value: Number(item[primaryYAxis])
            }))
            .filter(item => !isNaN(item.value) && item.value > 0);
        
          const total = pieData.reduce((sum, item) => sum + item.value, 0);
        
          return (
            <div style={{ width: '100%', height: '100%', position: 'relative' }}>
              {/* Y-axis label */}
              <div
                style={{
                  position: 'absolute',
                  left: isMobile ? '4px' : '8px',
                  top: '12px',
                  zIndex: 20
                }}
              >
                <div
                  style={{
                    fontSize: '12px',
                    fontWeight: 500,
                    color: 'white',
                    backgroundColor: COLORS[0],
                    padding: '2px 8px',
                    borderRadius: '12px',
                    display: 'flex',
                    alignItems: 'center',
                    gap: '4px'
                  }}
                >
                  {formatAxisLabel(primaryYAxis)}
                </div>
              </div>
        
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={pieData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    outerRadius={isMobile ? "70%" : "80%"}
                    fill="#8884d8"
                    dataKey="value"
                    label={<CustomPieLabel />}
                  >
                    {pieData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                  </Pie>
                  <Tooltip 
                    formatter={(value) => [
                      `${isNumberFormatted ? formatNumber(value, primaryYAxis) : value} (${((value / total) * 100).toFixed(1)}%)`,
                      formatAxisLabel(primaryYAxis)
                    ]}
                  />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          );
      default:
        return null;
    }
  };

  return (
    <div style={{ width: '100%', height: getChartHeight(), position: 'relative' }}>
      {renderChart()}
    </div>
  );
};

export default ChartView;