import React from 'react';
import Modal from '../Modal';

const SaveQueryModal = ({
  isOpen,
  onClose,
  onSubmit,
  queryName,
  setQueryName,
  isShared,
  setIsShared,
  isSaving
}) => {
  const handleSubmit = () => {
    if (queryName.trim() && !isSaving) {
      onSubmit();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onSubmit={handleSubmit}
      title="Save Query"
      confirmText="Save"
      cancelText="Cancel"
      isLoading={isSaving}
      confirmDisabled={!queryName.trim() || isSaving}
    >
      <div className="mb-4">
        <input
          type="text"
          value={queryName}
          onChange={(e) => setQueryName(e.target.value)}
          placeholder="Query Name"
          className="w-full px-3 py-2 border rounded-md text-gray-700 placeholder-gray-500 border-gray-300"
        />
      </div>
      <div className="flex items-center mb-4">
        <input
          type="checkbox"
          id="isShared"
          checked={isShared}
          onChange={(e) => setIsShared(e.target.checked)}
          className="mr-2"
        />
        <label htmlFor="isShared">Share this query with others</label>
      </div>
    </Modal>
  );
};

export default SaveQueryModal;